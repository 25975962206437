import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {addEditCustomer,getBuildings, getRooms, get_response_or_errors, remove_session} from "../../../functions/general";
import Select from 'react-select';
import countryList from 'react-select-country-list'

function AddCustomers(props) {
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState(false);
    const [response, setResponse] = React.useState("");
    const [name, setName] = React.useState("");

    const [email, setEmail] = React.useState("");
    const [phone, setPhone] = React.useState("");
    // const [passport, setPassport] = React.useState("");
    const [emiratesId, setEmiratesId] = React.useState("");
    const [emiratesIdImage, setEmiratesIdImage] = React.useState("");
    const [country, setCountry] = React.useState("");
    const [countryValue, setCountryValue] = React.useState("");
    const [city, setCity] = React.useState("");
    const [state, setState] = React.useState("");
    const [address, setAddress] = React.useState("");
    const [documents, setCustomerDocs] = React.useState("");
    const [status, setStatus] = React.useState(1);
    const [errors, setErrors] = React.useState("");
    const [gracePeriod, setGracePeriod] = React.useState("");
    const [selectedOption, setSelectedOption] = React.useState("");
    const [members, setMembers] = React.useState("");
    const [selectedType, setSelectedType] = React.useState("");
    const [trade, setTrade] = React.useState("");
    // const [rooms, setRooms] = React.useState([]);
    // const [roomId, setRoomId] = React.useState("");
    // const [roomOptions, setRoomOptions] = React.useState([]);
    // const [roomIdValue, setRoomIdValue] = React.useState("");
    // const [buildings, setBuildings] = React.useState([]);
    // const [buildingId, setBuildingId] = React.useState("");
    // const [buildingOptions, setBuildingOptions] = React.useState([]);
    // const [buildingIdValue, setBuildingIdValue] = React.useState("");

    const countries = React.useMemo(() => countryList().getData(), []);
    const countriesOptions = []
    countries?.length > 0 && countries.map((contry) => {
        countriesOptions.push({label: contry.label, value: contry.label})
    })
    useEffect(() => {
        // getBuildings().then(res => {
        //     if (res && res.status == 200) {
        //         setBuildings(res.data.data);
        //         const buildingOptionsTemp = [];
        //         res.data.data?.length > 0 && res.data.data.map((buildingValue) => {
        //             buildingOptionsTemp.push({label: buildingValue.name + " ( " + buildingValue.ownerName + " )", value: buildingValue.id})
        //         })
        //         setBuildingOptions(buildingOptionsTemp)
        //     } else if (res && res.status == 203) {
        //         remove_session();
        //         props.getLoggedInUser();
        //         navigate('/login');
        //     } else {
        //     }
        // })
        // getRooms().then(res => {
        //     if (res && res.status == 200) {
        //         setRooms(res.data.data);
        //     } else if (res && res.status == 203) {
        //         remove_session();
        //         props.getLoggedInUser();
        //         navigate('/login');
        //     } else {
        //     }
        // })
    }, [])
    const onSubmit = (e) => {
        e.preventDefault()
        setResponse("");
        if (loading)
            return false;
        if (validate()) {
            setLoading(true);
            const customer = {
                name: name,
                phone: phone,
                email: email,
                // passport: passport,
                emiratesId: emiratesId,
                emiratesIdImage: emiratesIdImage,
                documents:documents,
                country: country,
                city: city,
                state: state,
                address: address,
                gracePeriod:gracePeriod,
                status: status,
                // buildingId: buildingId,
                // roomId: roomId,
                category: selectedOption,
                no_of_members: members,
                type: selectedType,
                trade_liscence:trade
            }
            addEditCustomer(customer).then(res => {
                if (res && res.status == 200) {
                    navigate("/customers");
                } else if (res && res.status == 203) {
                    remove_session();
                    props.getLoggedInUser();
                    navigate('/login');
                } else {
                    setResponse(get_response_or_errors(res))
                    setErrors(get_response_or_errors(res))
                    setLoading(false)
                }
            })
        }
    }
    const validate = () => {
        let errors = {
            name: '',
            phone: '',
            email: '',
            // buildingId: '',
            // roomId: '',
            // passport: '',
            emiratesId: '',
            country: '',
            city: '',
            state: '',
            address: '',
            gracePeriod: '',
            selectedOption: '',
            selectedType:'',
            members: '',
            trade:''
        };
        let isValid = true;
        if (!name) {
            isValid = false;
            errors.name = "Please enter name.";
        }
        if (!phone) {
            isValid = false;
            errors.phone = "Please enter phone.";
        }
        if (!email) {
            isValid = false;
            errors.email = "Please enter email.";
        }
        // if (!buildingId) {
        //     isValid = false;
        //     errors.buildingId = "Please select building.";
        // }
        // if (!roomId) {
        //     isValid = false;
        //     errors.roomId = "Please select room.";
        // }
        if (!emiratesId) {
            isValid = false;
            errors.emiratesId = "Please enter passport/emirates id.";
        }
        if (!country) {
            isValid = false;
            errors.country = "Please enter nationality.";
        }
        if (!city) {
            isValid = false;
            errors.city = "Please enter city.";
        }
        if (!state) {
            isValid = false;
            errors.state = "Please enter state.";
        }
        if (!address) {
            isValid = false;
            errors.address = "Please enter address.";
        }
        // if (!gracePeriod) {
        //     isValid = false;
        //     errors.gracePeriod = "Please enter Grace Period Days.";
        // }
        if (!selectedOption) {
            isValid = false;
            errors.selectedOption = "Please enter Category.";
        }
        if (!selectedType) {
            isValid = false;
            errors.selectedType = "Please enter Type.";
        }
        if((selectedOption && selectedOption == "family") && !members){
            isValid = false;
            errors.members = "Please enter Members.";
        }
        if((selectedType && selectedType == "company") && !trade){
            isValid = false;
            errors.trade = "Please enter Trade Liscence.";
        }
        console.log(errors);
        setErrors(errors);
        return isValid;
    }
    const imageUploadHandler = (e) => {
        var file = e.target.files[0];
        console.log("file",file)
        var reader = new FileReader();
        var url = reader.readAsDataURL(file);

        reader.onloadend = function (e) {
            setEmiratesIdImage(reader.result)

        }.bind(this);
    }
    const docUploadHandler = (e) => {
        const files = e.target.files;
    
        // Ensure there are files to process
        if (files?.length > 0) {
            const newCustomerDocs = [];
    
            // Loop through each selected file
            for (let i = 0; i < files?.length; i++) {
                const file = files[i];
                const reader = new FileReader();
    
                reader.onloadend = function () {
                    // Append the base64 data of the file to the array
                    newCustomerDocs.push({
                        name: file.name,
                        type: file.type,
                        size: file.size,
                        data: reader.result,
                    });
    
                    // If all files are processed, update the state with the array of documents
                    if (i === files?.length - 1) {
                       console.log(newCustomerDocs);
                        setCustomerDocs(newCustomerDocs);
                    }
                };
    
                // Read the file as data URL
                reader.readAsDataURL(file);
            }
        }
    }
    const handleCountryChange = (selectedOption) => {
        setCountry(selectedOption.value)
        setCountryValue(selectedOption)
    };
    const handleCategorySelectChange = (e) => {
        setSelectedOption(e.target.value);
        // Reset members field when the user changes the selection
        setMembers('');
      };
    const handleTypeSelectChange = (e) => {
        setSelectedType(e.target.value);
        setTrade('');
    };
    // const handleBuildingChange = (selectedOption) => {
    //     setBuildingId(selectedOption.value)
    //     setBuildingIdValue(selectedOption)
    //     setRoomId('')
    //     setRoomIdValue('')
    //     const roomOptionsTemp = [];
    //     rooms?.length > 0 && rooms.map((roomValue) => {
    //         if(roomValue.status == '1' && roomValue.buildingId == selectedOption.value && roomValue.isOnRent == 0) {
    //             roomOptionsTemp.push({label: roomValue.name, value: roomValue.id})
    //         }
    //     })
    //     setRoomOptions(roomOptionsTemp)
    // };
    // const handleRoomChange = (selectedOption) => {
    //     setRoomId(selectedOption.value)
    //     setRoomIdValue(selectedOption)
    // };
    return (
        <>
            <div className="col-lg-12">
                <div className="d-flex flex-wrap align-items-center justify-content-between mb-4">
                    <div>
                        <h4 className="mb-3">Add Tenants</h4>
                    </div>
                </div>
            </div>
            <div className="col-sm-12">
                <div className="card">
                    <div className="card-header d-flex justify-content-between">
                        <div className="header-title">
                            <h4 className="card-title">Add New Tenant</h4>
                        </div>
                    </div>
                    <div className="card-body">
                        <form noValidate onSubmit={onSubmit}>
                            {response?.responseMessage &&
                            <div
                                className={`alert alert-${response?.responseFlag} fade show`}
                                role="alert">
                                {response?.responseMessage}
                            </div>
                            }
                            <div className="row">
                                {/* <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Building/Villa/Camp *</label>
                                        <Select
                                            value={buildingIdValue}
                                            onChange={handleBuildingChange}
                                            options={buildingOptions}
                                        />
                                        <div className="text-warning font-size-12">{errors.buildingId}</div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Room *</label>
                                        <Select
                                            value={roomIdValue}
                                            onChange={handleRoomChange}
                                            options={roomOptions}
                                        />
                                        <div className="text-warning font-size-12">{errors.roomId}</div>
                                    </div>
                                </div> */}
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Name *</label>
                                        <input type="text" className="form-control" placeholder="Enter Name"
                                               value={name} onChange={(e) => setName(e.target.value)}/>
                                        <div className="text-warning font-size-12">{errors.name}</div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Emirates ID/Passport *</label>
                                        <input type="text" className="form-control" placeholder="Enter Emirates Id"
                                               value={emiratesId} onChange={(e) => setEmiratesId(e.target.value)}/>
                                        <div className="text-warning font-size-12">{errors.emiratesId}</div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Phone Number *</label>
                                        <input type="text" className="form-control" placeholder="Enter Phone Number"
                                               value={phone} onChange={(e) => setPhone(e.target.value)}/>
                                        <div className="text-warning font-size-12">{errors.phone}</div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Email </label>
                                        <input type="text" className="form-control" placeholder="Enter Email"
                                                value={email} onChange={(e) => setEmail(e.target.value)}/>
                                        <div className="text-warning font-size-12">{errors.email}</div>
                                    </div>
                                </div>

                                {/* <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Passport</label>
                                        <input type="text" className="form-control" placeholder="Enter Emirates Id"
                                               value={passport} onChange={(e) => setPassport(e.target.value)}/>
                                        <div className="text-warning font-size-12">{errors.passport}</div>
                                    </div>
                                </div>  */}
                            
                               {/* Emirates Image */}
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Emirates Id Image</label>
                                        <div className="custom-file">
                                            <input type="file" className="custom-file-input" id="receipt" onChange={e => imageUploadHandler(e)}  accept="image/*"/>
                                            <label className="custom-file-label" htmlFor="receipt">Choose
                                                file</label>
                                        </div>
                                    </div>
                                </div>
                                {/* Category */}
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="mySelect">Select Category *:</label>
                                        <select id="mySelect" value={selectedOption} className="form-control" onChange={handleCategorySelectChange}>
                                            <option value="" >Select</option>
                                            <option value="family">Family</option>
                                            <option value="bachelor">Bachelor</option>
                                        </select>
                                        <div className="text-warning font-size-12">{errors.selectedOption}</div>
                                    </div>
                                </div>
                                {/* this one relaed to Emirates Image  */}
                                <div className="col-md-6 mb-3">
                                    {emiratesIdImage && (
                                        <img className="border rounded" style={{maxHeight: '100px'}}
                                             src={emiratesIdImage}
                                             alt=""/>
                                    )}
                                </div>
                                {/* if category is family */}
                                {selectedOption == "family" && (
                                <div className="col-md-6">
                                    <div className="form-group">
                                  
                                        <div>
                                            <label htmlFor="members">Members *:</label>
                                            <input
                                                className="form-control"
                                                type="number"
                                                id="members"
                                                value={members} onChange={(e) => setMembers(e.target.value)}/>
                                            <div className="text-warning font-size-12">{errors.members}</div>
                                        </div>
                                  
                                    </div>
                                </div>  )}
                                {selectedOption == "bachelor" && (
                                <div className="col-md-6">
                                    <div className="form-group">
                                  
                                        <div>
                                            <label htmlFor="members">Persons *:</label>
                                            <input
                                                className="form-control"
                                                type="number"
                                                id="members"
                                                value={members} onChange={(e) => setMembers(e.target.value)}/>
                                            <div className="text-warning font-size-12">{errors.members}</div>
                                        </div>
                                  
                                    </div>
                                </div>  )}
                                {/* Type */}
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="tenant">Tenant Type *:</label>
                                        <select id="tenant" value={selectedType} className="form-control" onChange={handleTypeSelectChange}>
                                            <option value="" >Select</option>
                                            <option value="indvidual">Indvidual</option>
                                            <option value="company">Company</option>
                                        </select>
                                        <div className="text-warning font-size-12">{errors.selectedType}</div>
                                    </div>
                                </div>
                                {/* if type is company */}
                                {selectedType == "company" && (
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <div>
                                            <label htmlFor="trade">Trade Liscence *:</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                id="trade"
                                                value={trade} onChange={(e) => setTrade(e.target.value)}/>
                                            
                                            <div className="text-warning font-size-12">{errors.trade}</div>
                                        </div>
                                    </div>
                                </div>  )}
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Country *</label>
                                        <Select
                                            value={countryValue}
                                            onChange={handleCountryChange}
                                            options={countriesOptions}
                                        />
                                        <div className="text-warning font-size-12">{errors.country}</div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>City *</label>
                                        <input type="text" className="form-control" placeholder="Enter City"
                                               value={city} onChange={(e) => setCity(e.target.value)}/>
                                        <div className="text-warning font-size-12">{errors.city}</div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>State *</label>
                                        <input type="text" className="form-control" placeholder="Enter State"
                                               value={state} onChange={(e) => setState(e.target.value)}/>
                                        <div className="text-warning font-size-12">{errors.state}</div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Address *</label>
                                        <textarea className="form-control" rows="4" value={address}
                                                  onChange={(e) => setAddress(e.target.value)}></textarea>
                                        <div className="text-warning font-size-12">{errors.address}</div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Attach Documents</label>
                                        <div className="custom-file">
                                            <input type="file" className="custom-file-input" id="doc" onChange={e => docUploadHandler(e)} multiple/>
                                            <label className="custom-file-label" htmlFor="receipt">Choose
                                                file</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 mb-3">
                                        {documents && (documents?.length +' Files')} 
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Status *</label>
                                        <select className="form-control"
                                                value={status} onChange={(e) => setStatus(e.target.value)}>
                                            <option value="1">Active</option>
                                            <option value="2">Inactive</option>
                                        </select>
                                        <div className="text-warning font-size-12">{errors.status}</div>
                                    </div>
                                </div>
                                {/* <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Add Grace period (days)</label>
                                        <input type="number" className="form-control" placeholder="Enter Grace period (days)"
                                               value={gracePeriod} onChange={(e) => setGracePeriod(e.target.value)}/>
                                        <div className="text-warning font-size-12">{errors.gracePeriod}</div>
                                    </div>
                                </div> */}
                            </div>
                            <button type="submit" className="btn btn-primary mr-2">Add</button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AddCustomers;
