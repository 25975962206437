
import React, {useEffect} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import logoImage from '../../../images/logoPrint.png'
import {
    date_time_to_date_format,
    getBuilding, getTransaction,
    getOwner,
    deleteCustomerDocuments,addCustomerDocuments,get_response_or_errors, remove_session
} from "../../../functions/general";
import ListTransactions from "../transactions/ListTransactions";
import PrintTransation from "../transactions/PrintTransation"
import ModalImage from "react-modal-image";
import { Col, Row } from "react-bootstrap";

function PrintTransaction(props) {
    const currentUser = props.currentUser;
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState(false);
    const [response, setResponse] = React.useState("");
    const params = useParams();
  
    const [transaction, setTransaction] = React.useState([]);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [owner, setOwner] = React.useState([]);
    const [ownerId, setOwnerId] = React.useState("");
   
    useEffect(() => {
        getTransaction(params.id).then(res => {
            if (res && res.status == 200) {
                if(res.data.data){
                    setTransaction(res.data.data);
                  
                } 
            } else if (res && res.status == 203) {
                remove_session();
                props.getLoggedInUser();
                navigate('/login');
            }
        })
    }, [])
  
    const handlePrint = () => {
        window.print();
    };

    useEffect(() => {
        // Update the current page number when the component mounts
        const updatePageNumber = () => {
            setCurrentPage(window.location.href);
        };

    window.addEventListener("beforeprint", updatePageNumber);
    window.addEventListener("afterprint", updatePageNumber);

    return () => {
        window.removeEventListener("beforeprint", updatePageNumber);
        window.removeEventListener("afterprint", updatePageNumber);
    };
}, []);

  return (
      <div className="w-100 pt-0 mt-0 print-layout">
        <div className="text-right mb-3">
                    <button className="btn btn-primary  print-button" onClick={handlePrint}>
                    <i className="ri-printer-line mr-0"></i>
                    </button>
        </div> 
        <Row className="justify-content-center align-items-center header-text text-center mt-0">
            <Col md={4}>
                <h1 className="fw-bold">Royal Societe Property Management LLC</h1>
            </Col>
            <Col md={2} className="text-center">
                <Link to="/" className="print-logo-2 p-0 ">
                    <img src={logoImage} className="img-fluid p-0  " alt="logo"/>
                </Link>
            </Col>
            <Col md={4} className="text-left">
                <h1>رويال سوسيتيه</h1>
            </Col>
        </Row>
        <div className="col-12 mt-3">
            <div className="card print-card">
                <div className=" pb-0 mb-0 px-5">
                    <div className="text-center mb-3 red d-flex justify-content-start top">
                        <p className="bg-primary  red py-3 h4 px-5">
                            Transaction Detail
                        </p>
                    </div>
                    <div className="row tenant-print">
                            <div className="col-6">
                                <div className="form-group">
                                <label>Transaction Receipt: </label> 
                                  {transaction.transactionReceipt && (
                                    <div className="transactionTableImage">
                                      <ModalImage
                                        small={
                                          process.env.REACT_APP_APIPATH +
                                          "/api/images/" +
                                          transaction.transactionReceipt
                                        }
                                        large={
                                          process.env.REACT_APP_APIPATH +
                                          "/api/images/" +
                                          transaction.transactionReceipt
                                        }
                                      />
                                    </div>
                                  )}
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group ">
                                    <label>Amount: </label>  
                                    <span className="ms-5 ps-5">{transaction.transactionAmount}</span>
                                
                                </div>
                            </div>

                           

                           {/* <div className="col-6">
                                <div className="form-group">
                                <label>Passport: </label> {customer.passport}
                                </div>
                            </div> */}
                            
                            <div className="col-6">
                                <div className="form-group">
                                <label>Building Name: </label>  {transaction.buildingId > 0 ? transaction.buildingName
                          : ""} <br/>
                                <label>Room: </label>   {transaction.roomId > 0 ? transaction.roomName : ""} <br/>
                                </div>
                            </div>

                            <div className="col-6">
                                <div className="form-group">
                                    <br/>
                                    <label>Description: </label> {transaction.transactionDescription}
                                    
                                </div>
                            </div>
                          
                            <div className="col-6">
                                <div className="form-group">
                                <label>Approved: </label> {transaction.transactionCollected == 1 ? "Yes" : "No"}<br/>
                                <label>Dewa Account : </label> {transaction.dewaAccount? transaction.dewaAccount : '--'}
                                      <br/>
                                      <label>Type: </label> 
                                      <span className="badge badge-light">{transaction.transactionType == 1 && 'Rent'}</span>
                                      <span className="badge badge-info">{transaction.transactionType == 2 && 'Dewa'}</span>
                                      <span className="badge badge-dark">{transaction.transactionType == 3 && 'Maintenance'}</span>
                                      <span className="badge badge-success">{transaction.transactionType == 4 && 'Salary'}</span>
                                      <span className="badge badge-primary">{transaction.transactionType == 5 && 'Other Expense'}</span>
                                      <span className="badge badge-warning">{transaction.transactionType == 6 && 'Security Refund'}</span>
                                      <span className="badge badge-secondary">{transaction.transactionType == 7 && 'Security Deposit'}</span>
                                      <span className="badge badge-danger">{transaction.transactionType == 8 && 'Advance Payment'}</span>
                                      <br/>
                                      <label>Transaction Date: </label> {date_time_to_date_format(transaction.transactionDate)}
                                         
                                </div>
                            </div>
                        </div>
                        
                </div>
                
            </div>
        </div>
      </div>
  );
}

export default PrintTransaction;