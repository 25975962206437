import React, {useEffect} from "react";
import {useNavigate, useSearchParams, useLocation} from "react-router-dom";
import {
    addEditTransaction,
    get_response_or_errors, getBuilding,getBuildingRooms, getBuildings, getEmployee, getRent, getRoom,
    remove_session
} from "../../../functions/general";
import Select from 'react-select';

function AddTransaction(props) {
    Date.prototype.toDateInputValue = (function() {
        var local = new Date(this);
        local.setMinutes(this.getMinutes() - this.getTimezoneOffset());
        return local.toJSON().slice(0,10);
    });
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const returnUrl = searchParams.get('returnUrl');
    const [loading, setLoading] = React.useState(false);
    const [response, setResponse] = React.useState("");
    const [building, setBuilding] = React.useState([]);
    const [employee, setEmployee] = React.useState([]);
    const [room, setRoom] = React.useState([]);
    const [transactionFor, setTransactionFor] = React.useState("");
    const [transactionType, setTransactionType] = React.useState("");
    const [employeeId, setEmployeeId] = React.useState(null);
    const [buildings, setBuildings] = React.useState([]);
    const [buildingOptions, setBuildingOptions] = React.useState([]);
    const [buildingIdValue, setBuildingIdValue] = React.useState("");
    const [buildingId, setBuildingId] = React.useState(null);
    const [rooms, setRooms] = React.useState([]);
    const [roomOptions, setRoomOptions] = React.useState([]);
    const [roomIdValue, setRoomIdValue] = React.useState("");
    const [roomId, setRoomId] = React.useState(null);
    const [rent, setRent] = React.useState("");
    const [rentId, setRentId] = React.useState(null);
    const [customerId, setCustomerId] = React.useState(null);
    const [transactionDate, setTransactionDate] = React.useState(new Date().toDateInputValue());
    const [transactionAmount, setTransactionAmount] = React.useState(0);
    const [transactionDescription, setTransactionDescription] = React.useState("");
    const [transactionReceipt, setTransactionReceipt] = React.useState("");
    const [errors, setErrors] = React.useState("");
    useEffect(() => {
        if(searchParams.get('cheque')) {
            setTransactionType(1)
        }
        if(searchParams.get('type')) {
            setTransactionFor(searchParams.get('type'));
            if (searchParams.get('id')) {
                if(searchParams.get('type') == 'employee') {
                    setEmployeeId(searchParams.get('id'));
                    getEmployee(searchParams.get('id')).then(res => {
                        if (res && res.status == 200) {
                            if (res.data.data) {
                                setEmployee(res.data.data);
                            } else {
                                navigate('/admins');
                            }
                        } else if (res && res.status == 203) {
                            remove_session();
                            props.getLoggedInUser();
                            navigate('/login');
                        }
                    })
                }
                if(searchParams.get('type') == 'building') {
                    setBuildingId(searchParams.get('id'));
                    getBuilding(searchParams.get('id')).then(res => {
                        if (res && res.status == 200) {
                            if (res.data.data) {
                                setBuilding(res.data.data);
                            } else {
                                navigate('/admins');
                            }
                        } else if (res && res.status == 203) {
                            remove_session();
                            props.getLoggedInUser();
                            navigate('/login');
                        }
                    })
                }
                if(searchParams.get('type') == 'buildingrooms') {
                    setBuildingId(searchParams.get('id'));
                    getBuilding(searchParams.get('id')).then(res => {
                        if (res && res.status == 200) {
                            if (res.data.data) {
                                setBuilding(res.data.data);
                            } 
                        } 
                    })
                    getBuildingRooms(searchParams.get('id')).then(res => {
                        if (res && res.status == 200) {
                            setRooms(res.data.data);
                            const roomOptionsTemp = [];
                            res.data.data?.length > 0 && res.data.data.map((roomValue) => {
                                roomOptionsTemp.push({label: roomValue.name , value: roomValue.id})
                            })
                            setRoomOptions(roomOptionsTemp)
                        } else if (res && res.status == 203) {
                            remove_session();
                            props.getLoggedInUser();
                            navigate('/login');
                        } else {
                        }
                    })
                }
                if(searchParams.get('type') == 'room') {
                    setRoomId(searchParams.get('id'));
                    getRoom(searchParams.get('id')).then(res => {
                        if (res && res.status == 200) {
                            if (res.data.data) {
                                setRoom(res.data.data);
                                setBuildingId(res.data.data.buildingId);
                            } else {
                                navigate('/admins');
                            }
                        } else if (res && res.status == 203) {
                            remove_session();
                            props.getLoggedInUser();
                            navigate('/login');
                        }
                    })
                }
                if(searchParams.get('type') == 'rent') {
                    setRentId(searchParams.get('id'));
                    getRent(searchParams.get('id')).then(res => {
                        if (res && res.status == 200) {
                            if (res.data.data) {
                                setRent(res.data.data);
                                setRoomId(res.data.data.roomId);
                                setCustomerId(res.data.data.customerId);
                                getRoom(res.data.data.roomId).then(res => {
                                    if (res && res.status == 200) {
                                        if (res.data.data) {
                                            setRoom(res.data.data);
                                            setBuildingId(res.data.data.buildingId);
                                        } else {
                                            navigate('/admins');
                                        }
                                    } else if (res && res.status == 203) {
                                        remove_session();
                                        props.getLoggedInUser();
                                        navigate('/login');
                                    }
                                })
                            } else {
                                navigate('/admins');
                            }
                        } else if (res && res.status == 203) {
                            remove_session();
                            props.getLoggedInUser();
                            navigate('/login');
                        }
                    })
                }
            }
        }
        else{
            getBuildings().then(res => {
                if (res && res.status == 200) {
                    setBuildings(res.data.data);
                    const buildingOptionsTemp = [];
                    res.data.data?.length > 0 && res.data.data.map((buildingValue) => {
                        buildingOptionsTemp.push({label: buildingValue.name + " ( " + buildingValue.ownerName + " )", value: buildingValue.id})
                    })
                    setBuildingOptions(buildingOptionsTemp)
                } else if (res && res.status == 203) {
                    remove_session();
                    props.getLoggedInUser();
                    navigate('/login');
                } else {
                }
            })
        }
    }, [])
    const onSubmit = (e) => {
        e.preventDefault()
        setResponse("");
        if (loading)
            return false;
        if (validate()) {
            setLoading(true);
            const transaction = {
                buildingId: buildingId,
                employeeId: employeeId,
                roomId: roomId,
                rentId: rentId,
                customerId: customerId,
                transactionType: transactionType,
                transactionDate: transactionDate,
                transactionAmount: transactionAmount,
                transactionDescription: transactionDescription,
                transactionReceipt: transactionReceipt
            }
            addEditTransaction(transaction).then(res => {
                if (res && res.status == 200) {
                    if(transactionFor == 'building'){
                        navigate('/buildings-villas/' + buildingId);
                        
                    }
                    else if(transactionFor == 'buildingrooms'){
                        navigate('/buildings-villas/' + buildingId);
                        
                    }
                    else if(transactionFor == 'room'){
                        navigate('/rooms/' + roomId);
                    }
                    else if(transactionFor == 'rent'){
                        if (returnUrl) {
                            navigate(returnUrl);
                          } else {
                            navigate('/rent/' + rentId ); // Fallback in case returnUrl is not provided
                          }
                    }
                    else if(transactionFor == 'employee'){
                        navigate('/employees/' + employeeId);
                    }
                    else{
                        navigate('/transactions/');
                    }
                } else if (res && res.status == 203) {
                    remove_session();
                    props.getLoggedInUser();
                    navigate('/login');
                } else {
                    setResponse(get_response_or_errors(res))
                    setErrors(get_response_or_errors(res))
                    setLoading(false)
                }
            })
        }
    }
    const validate = () => {
        let errors = {
            transactionType: '',
            transactionDate: '',
            transactionReceipt: '',
            transactionAmount: '',
        };
        let isValid = true;
        if (!transactionType) {
            isValid = false;
            errors.transactionType = "Please select transaction type.";
        }
        if (!transactionDate) {
            isValid = false;
            errors.date = "Please enter transaction date.";
        }
        // if (!transactionReceipt) {
        //     isValid = false;
        //     errors.receipt = "Please enter transaction receipt.";
        // }
        if (!transactionAmount) {
            isValid = false;
            errors.transactionAmount = "Please enter transaction amount.";
        }
        if (transactionAmount && (parseInt(transactionAmount) != transactionAmount || parseInt(transactionAmount) <= 0)) {
            isValid = false;
            errors.transactionAmount = "Please enter valid transaction amount.";
        }
        setErrors(errors);
        return isValid;
    }
    const imageUploadHandler = (e) => {
        var file = e.target.files[0];
        console.log("file",file)
        var reader = new FileReader();
        var url = reader.readAsDataURL(file);

        reader.onloadend = function (e) {
            setTransactionReceipt(reader.result)

        }.bind(this);
    }

    const handleBuildingChange = (selectedOption) => {
        setBuildingId(selectedOption.value)
        setBuildingIdValue(selectedOption)
    };
    const handleRoomChange = (selectedOption) => {
        setRoomId(selectedOption.value)
        setRoomIdValue(selectedOption)
    };
    return (
        <>
            <div className="col-lg-12">
                <div className="d-flex flex-wrap align-items-center justify-content-between mb-4">
                    <div>
                        <h4 className="mb-3">Add Transactions</h4>
                    </div>
                </div>
            </div>
            <div className="col-sm-12">
                <div className="card">
                    <div className="bg-black-custom card-header d-flex justify-content-between rounded-top">
                        <div className="header-title">
                            {transactionFor == 'employee'?

                                (<h4 className="card-title text-white">Add New Transaction - Employee : {employee.name}</h4>):
                                (<h4 className="card-title text-white">Add New Transaction - {transactionFor == 'building' || transactionFor == 'buildingrooms'? ("Building (" + building.name + " - " + building.phone + ")"): ("Room (" + room.name + ")")}</h4>)}
                        </div>
                    </div>
                    <div className="card-body">
                        <form noValidate onSubmit={onSubmit}>
                            {response?.responseMessage &&
                            <div
                                className={`alert alert-${response?.responseFlag} fade show`}
                                role="alert">
                                {response?.responseMessage}
                            </div>
                            }
                            <div className="row">
                                { searchParams.get('id') == null && (<div className="col-md-6">
                                    <div className="form-group">
                                        <label>Building/Villa/Camp *</label>
                                        <Select
                                            value={buildingIdValue}
                                            onChange={handleBuildingChange}
                                            options={buildingOptions}
                                        />
                                        <div className="text-warning font-size-12">{errors.buildingId}</div>
                                    </div>
                                </div>)}
                                { transactionFor == 'buildingrooms' && (<div className="col-md-6">
                                    <div className="form-group">
                                        <label>Rooms *</label>
                                        <Select
                                            value={roomIdValue}
                                            onChange={handleRoomChange}
                                            options={roomOptions}
                                        />
                                        <div className="text-warning font-size-12">{errors.roomId}</div>
                                    </div>
                                </div>)}
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Transaction Type *</label>
                                        <select className="form-control" value={transactionType} onChange={(e) => setTransactionType(e.target.value)}>
                                            <option value="">Select Type</option>
                                            {transactionFor == 'building' && (
                                            <>
                                                <option value="1">Rent Payment</option>
                                                <option value="2">Dewa Bill Payment</option>
                                                <option value="3">Maintenance Payment</option>
                                                <option value="5">Other Expense Payment</option>
                                                <option value="7">Security Deposit Payment</option>
                                                <option value="8">Security Deposit Receive</option>
                                                {/*{building && building.dewa == '' && (*/}
                                                {/*    <option value="2">Dewa</option>*/}
                                                {/*)}*/}
                                                {/*{building && building.maintenance == '' && (*/}
                                                {/*    <option value="3">Maintenance</option>*/}
                                                {/*)}*/}
                                            </>)}
                                            {(transactionFor == 'room' || transactionFor == 'buildingrooms') && (
                                                <>
                                                    <option value="2">Dewa Bill Payment</option>
                                                    <option value="3">Maintenance Payment</option>
                                                    <option value="5">Other Expense Payment </option>
                                                    <option value="6">Security Refund</option>
                                                </>)}

                                            {transactionFor == 'rent' && (
                                                <>
                                                <option value="9">Rent Received</option>
                                                <option value="10">Rent Adjustment</option>
                                                <option value="11">Rent Unpaid</option>
                                                <option value="2">Dewa bill Payment</option>
                                                <option value="3">Maintenance Payment</option>
                                                <option value="5">Other Expense Payment</option>
                                                <option value="7">Security Deposit Refund</option>
                                                <option value="8">Security Deposit Receive</option>
                                                </>)}
                                            {transactionFor == 'employee' && (
                                                <>
                                                    <option value="4">Salary</option>
                                                </>)}
                                            {transactionFor =="" && (
                                            <>
                                                <option value="1">Rent</option>
                                                <option value="2">Dewa</option>
                                                <option value="3">Maintenance</option>
                                                <option value="5">Other Expense </option>
                                                <option value="7">Security Deposit</option>
                                                <option value="8">Security Deposit Receive</option>
                                            </>
                                            )}
                                        </select>
                                        <div className="text-warning font-size-12">{errors.transactionType}</div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Transaction Date *</label>
                                        <input type="date" className="form-control" placeholder="Enter Date"
                                               value={transactionDate} onChange={(e) => setTransactionDate(e.target.value)}/>
                                        <div className="text-warning font-size-12">{errors.date}</div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Transaction Amount *</label>
                                        <input type="text" className="form-control" placeholder="Enter Amount"
                                               value={transactionAmount} onChange={(e) => setTransactionAmount(e.target.value)}/>
                                        <div className="text-warning font-size-12">{errors.transactionAmount}</div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Transaction Receipt</label>
                                        <div className="custom-file">
                                            <input type="file" className="custom-file-input" id="receipt" onChange={e => imageUploadHandler(e)}  accept="image/*"/>
                                                <label className="custom-file-label" htmlFor="receipt">Choose
                                                    file</label>
                                        </div>
                                        <div className="text-warning font-size-12">{errors.receipt}</div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                </div>
                                <div className="col-md-6 mb-3">
                                    {transactionReceipt && (
                                    <img className="border rounded" style={{maxHeight: '100px'}}
                                         src={transactionReceipt}
                                         alt=""/>
                                    )}
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Description</label>
                                        <textarea type="text" className="form-control" placeholder="Enter Description"
                                                  onChange={(e) => setTransactionDescription(e.target.value)}>{transactionDescription}</textarea>
                                    </div>
                                </div>
                            </div>
                            <button type="submit" className="btn btn-primary mr-2">Add</button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AddTransaction;
