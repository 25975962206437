import React, {useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {
    addEditRoom,
    get_response_or_errors, getBuildings,
    getRoom,
    remove_session
} from "../../../functions/general";
import Select from 'react-select';

function EditRooms(props) {
    const navigate = useNavigate();
    const params = useParams();
    const [loading, setLoading] = React.useState(false);
    const [response, setResponse] = React.useState("");
    const [buildings, setBuildings] = React.useState([]);
    const [buildingId, setBuildingId] = React.useState("");
    const [buildingOptions, setBuildingOptions] = React.useState([]);
    const [buildingIdValue, setBuildingIdValue] = React.useState("");
    const [name, setName] = React.useState("");
    const [status, setStatus] = React.useState(1);
    const [errors, setErrors] = React.useState("");
    useEffect(() => {
        getBuildings().then(res => {
            if (res && res.status == 200) {
                setBuildings(res.data.data);
                const buildingOptionsTemp = [];
                res.data.data?.length > 0 && res.data.data.map((buildingValue) => {
                    buildingOptionsTemp.push({label: buildingValue.name + " ( " + buildingValue.ownerName + " )", value: buildingValue.id})
                })
                setBuildingOptions(buildingOptionsTemp)
            } else if (res && res.status == 203) {
                remove_session();
                props.getLoggedInUser();
                navigate('/login');
            } else {
            }
        })
        getRoom(params.id).then(res => {
            if (res && res.status == 200) {
                if(res.data.data){
                    setBuildingId(res.data.data.buildingId);
                    setBuildingIdValue({label: res.data.data.buildingName + " ( " + res.data.data.ownerName + " )", value: res.data.data.buildingId});
                    setName(res.data.data.name);
                    setStatus(res.data.data.status);
                } else {
                    navigate('/admins');
                }
            } else if (res && res.status == 203) {
                remove_session();
                props.getLoggedInUser();
                navigate('/login');
            }
        })
    }, [])
    const onSubmit = (e) => {
        e.preventDefault()
        setResponse("");
        if (loading)
            return false;
        if (validate()) {
            setLoading(true);
            const room = {
                id: params.id,
                buildingId: buildingId,
                name: name,
                status: status
            }
            addEditRoom(room).then(res => {
                if (res && res.status == 203) {
                    remove_session();
                    props.getLoggedInUser();
                    navigate('/login');
                } else {
                    setResponse(get_response_or_errors(res))
                    setErrors(get_response_or_errors(res))
                    setLoading(false)
                }
            })
        }
    }
    const validate = () => {
        let errors = {
            ownerId: '',
            name: '',
            city: '',
            state: '',
            address: '',
            rent: '',
            rentType: '',
        };
        let isValid = true;
        if (!buildingId) {
            isValid = false;
            errors.buildingId = "Please select building.";
        }
        if (!name) {
            isValid = false;
            errors.name = "Please enter name.";
        }
        setErrors(errors);
        return isValid;
    }
    const handleBuildingChange = (selectedOption) => {
        setBuildingId(selectedOption.value)
        setBuildingIdValue(selectedOption)
    };
    return (
        <>
            <div className="col-lg-12">
                <div className="d-flex flex-wrap align-items-center justify-content-between mb-4">
                    <div>
                        <h4 className="mb-3">Edit Rooms</h4>
                    </div>
                </div>
            </div>
            <div className="col-sm-12">
                <div className="card">
                    <div className="bg-black-custom card-header d-flex justify-content-between rounded-top">
                        <div className="header-title">
                            <h4 className="card-title text-white">Edit New Room</h4>
                        </div>
                    </div>
                    <div className="card-body">
                        <form noValidate onSubmit={onSubmit}>
                            {response?.responseMessage &&
                            <div
                                className={`alert alert-${response?.responseFlag} fade show`}
                                role="alert">
                                {response?.responseMessage}
                            </div>
                            }
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Building/Villa/Camp *</label>
                                        <Select
                                            value={buildingIdValue}
                                            onChange={handleBuildingChange}
                                            options={buildingOptions}
                                        />
                                        <div className="text-warning font-size-12">{errors.buildingId}</div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Name *</label>
                                        <input type="text" className="form-control" placeholder="Enter Name"
                                               value={name} onChange={(e) => setName(e.target.value)}/>
                                        <div className="text-warning font-size-12">{errors.name}</div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Status *</label>
                                        <select className="form-control"
                                                value={status} onChange={(e) => setStatus(e.target.value)}>
                                            <option value="1">Active</option>
                                            <option value="2">Inactive</option>
                                        </select>
                                        <div className="text-warning font-size-12">{errors.state}</div>
                                    </div>
                                </div>
                            </div>
                            <button type="submit" className="btn btn-primary mr-2">Update</button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default EditRooms;
