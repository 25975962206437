
import React, { useState, useEffect } from "react";
import { getTransactionsReport,getBuildings, remove_session} from "../../../functions/general";
import { Link, useNavigate } from "react-router-dom";
import ModalImage from "react-modal-image";

function DepositChartDetails(props) {
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 10 }, (_, index) => currentYear - index);

  const months = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  const currentMonthIndex = new Date().getMonth();
  const currentMonth = months[currentMonthIndex];

  const navigate = useNavigate();
  const [transactions, setTransactions] = useState([]);
  const [buildings, setBuildings] = React.useState([]);
  const [buildingId, setBuildingId] = React.useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [date, setDate] = useState("");
  const [queryDate, setQueryDate] = useState("");
  const [year, setSelectedYear] = useState(currentYear);
  const [month, setSelectedMonth] = useState(currentMonthIndex+1);
  const [selectedMonthName, setSelectedMonthName] = useState(currentMonth);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(-1);
  const [reportType, setreportType] = useState('deposit');
  let totalTransactions =0;
  
  useEffect(() => {
    setQueryDate(`${selectedMonthName}, ${year}`);
    setStartDate("");
    setEndDate("");
    getBuildings().then(res => {
      if (res && res.status == 200) {
        setBuildings(res.data.data);
      } else if (res && res.status == 203) {
        remove_session();
        props.getLoggedInUser();
        navigate('/login');
      } else {
        // Handle other cases
      }
    });
    getTransactionsReport({reportType,year,month}).then((res) => {
      if (res && res.status === 200) {
        setTransactions(res.data.data);
      } else if (res && res.status === 203) {
        remove_session();
        props.getLoggedInUser();
        navigate("/login");
      }
    });
  }, [year, month]);

  const handleMonthChange = (monthIndex) => {
    setSelectedMonth(monthIndex+1);
    setSelectedMonthName(months[monthIndex]);
  };

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };
  const handleReportButtonClick = () => {
    setQueryDate(`${startDate} to ${endDate}`);
    //console.log(`Date set to: ${startDate} to ${endDate}`);
    getTransactionsReport({reportType,startDate,endDate}).then((res) => {
      if (res && res.status === 200) {
        setTransactions(res.data.data);
      } else if (res && res.status === 203) {
        remove_session();
        props.getLoggedInUser();
        navigate("/login");
      }
    });
  };
  const handlePrint = () => {
      window.print();
  };

   // Pagination
   const indexOfLastItem = currentPage * itemsPerPage;
   const indexOfFirstItem = indexOfLastItem - itemsPerPage;
   const currentTransactions = transactions?.slice(indexOfFirstItem, indexOfLastItem);
   
   const pageNumbers = Array.from(
     { length: Math.ceil(transactions?.length / itemsPerPage) },
     (_, i) => i + 1
   );
   
   const renderPageNumbers = pageNumbers.map((number) => {
     if (
       number == 1 ||
       number == currentPage ||
       number == currentPage - 1 ||
       number == currentPage + 1 ||
       number == pageNumbers?.length
     ) {
       return (
         <li
           key={number}
           className={`page-item ${currentPage == number ? "active" : ""}`}
         >
           <button className="page-link" onClick={() => paginate(number)}>
             {number}
           </button>
         </li>
       );
     }
     return null;
   });
   
   const paginate = (pageNumber) => {
     setCurrentPage(pageNumber);
   };
   
   const handleItemsPerPageChange = (e) => {
     const value = Number(e.target.value);
     if (value === -1) {
        setItemsPerPage(value);
         // Show all items
        //  setItemsPerPage(transactions.length);
     } else {
         // Set items per page to the selected value
         setItemsPerPage(value);
     }
     setCurrentPage(1); // Reset to first page when changing items per page
   };

  return (
    <>
              <div className="w-100 mt-1 px-3 graph">
                <div className="d-flex justify-content-between">
                    <h4>Deposit Report -
                      {queryDate &&(
                        <span>
                          &nbsp;{queryDate}
                        </span>
                      )}
                    </h4>
                    <button className="btn btn-primary  print-button" onClick={handlePrint}>
                        <i className="ri-printer-line mr-0"></i>
                    </button>
                </div>
                
            <div className="row align-items-end mt-4">
              <div className="col-lg-6">
              <div className="row mb-3 align-items-end">
                <div className="col-lg-3 col-md-4 col-sm-6">
                    <div className="form-group">
                      <label>Show per page:</label>
                      <select
                        className="form-control"
                        value={itemsPerPage === transactions.length ? -1 : itemsPerPage}
                        onChange={handleItemsPerPageChange}
                      >
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={30}>30</option>
                        <option value={-1}>Show All</option>
                      </select>
                  </div>
                </div>
                <div className="col-lg-3 mb-3">
                  <select
                    id="yearDropdown"
                    className="form-select form-control"
                    value={year}
                    onChange={(e) => setSelectedYear(e.target.value)}
                  >
                    {years.map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="col-lg-3 mb-3">
                  <select
                    id="monthDropdown"
                    className="form-select form-control"
                    value={selectedMonthName}
                    onChange={(e) => handleMonthChange(e.target.selectedIndex)}
                  >
                    {months.map((month,index) => (
                      <option key={index} value={month}>
                        {month}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6">
                  <div className="form-group">
                    <label>Building/Villa</label>
                    <select className="form-control" value={buildingId} onChange={(e) => setBuildingId(e.target.value)}>
                        <option value="">All Buildings</option>
                        { buildings?.length > 0 && buildings.map((building, index) => {
                            if(building.status == '1') {
                                return (
                                    <option key={index}
                                            value={building.id}>{building.name} ({building.ownerName})</option>
                                );
                            }
                        })}
                    </select>
                  </div>
                </div>
                </div>
              </div>
              
              <div className="col-lg-6">
              <div className="row mb-3 align-items-end justify-content-end">
          
                <div className="col-lg-3 mb-3 ">
                  <label htmlFor="startDate">Start Date</label>
                  <input
                    type="date"
                    id="startDate"
                    className="form-control"
                    value={startDate}
                    onChange={handleStartDateChange}
                  />
                </div>

                <div className="col-lg-3 mb-3">
                  <label htmlFor="endDate">End Date</label>
                  <input
                    type="date"
                    id="endDate"
                    className="form-control"
                    value={endDate}
                    onChange={handleEndDateChange}
                  />
                </div>

                <div className="col-lg-2 mb-3">
                  <button className="btn btn-primary w-100 py-2" onClick={handleReportButtonClick}>
                    Report
                  </button>
                </div>

              </div>
              </div>
            </div>
      </div>

        {/* <div className="col-lg-12 my-5">
          <span className="h6 text-dark">Date:&nbsp; </span>{startDate}<span>&nbsp;&nbsp; </span><span>{endDate}</span>
        </div> */}

      <div className="col-lg-12">
        <div className="table-responsive  rounded mb-3">
          <table className="data-table table mb-0 tbl-server-info ">
            <thead className="bg-black-custom text-uppercase">
              <tr className="text-white">
                <th>Transactions ID</th>
                <th>Reciept</th>
                <th>Amount</th>
                <th>Related To</th>
                <th>Name</th>
                <th>Description</th>
                <th>Type</th>
                <th>Added By</th>
                <th>Transaction Date</th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {currentTransactions.map((transaction, index) => {
                if (transaction.transactionType !== "4") {
                  if (date && date !== transaction.transactionDate) {
                    return null;
                  }
                  if(buildingId && transaction.buildingId != buildingId){
                    return null;
                  }
                  const tempString =
                    transaction.transactionAmount +
                    " " +
                    transaction.buildingName +
                    " " +
                    transaction.roomName +
                    " " +
                    transaction.transactionDescription;
                  if (
                    searchQuery &&
                    !tempString.match(new RegExp(searchQuery, "i"))
                  ) {
                    return null;
                  }
                  let amount = parseInt(transaction.transactionAmount);
                  parseInt(totalTransactions += amount);

                  return (
                    <tr key={index}>
                      <td>{transaction.id}</td>
                      <td>
                        {transaction.transactionReceipt && (
                          <div className="transactionTableImage">
                            <ModalImage
                              small={
                                process.env.REACT_APP_APIPATH +
                                "/api/images/" +
                                transaction.transactionReceipt
                              }
                              large={
                                process.env.REACT_APP_APIPATH +
                                "/api/images/" +
                                transaction.transactionReceipt
                              }
                            />
                          </div>
                        )}
                      </td>
                      <td>{transaction.transactionAmount.toLocaleString()}</td>
                      <td>
                        {transaction.rentId > 0
                        ?  'RENT'
                        : ''}
                    </td>
                      <td>
                        {transaction.buildingId > 0
                          ? transaction.buildingName +' ['+transaction.roomName +'] '
                          :  transaction.employeeName}
                      </td>
                      <td className="text-ellipses">
                        {transaction.transactionDescription}
                      </td>
                      <td>
                        <span className="badge badge-light">{transaction.transactionType == 1 && 'Rent'}</span>
                        <span className="badge badge-info">{transaction.transactionType == 2 && 'Dewa'}</span>
                        <span className="badge badge-dark">{transaction.transactionType == 3 && 'Maintenance'}</span>
                        <span className="badge badge-success">{transaction.transactionType == 4 && 'Salary'}</span>
                        <span className="badge badge-primary">{transaction.transactionType == 5 && 'Other Expense'}</span>
                        <span className="badge badge-warning">{transaction.transactionType == 6 && 'Security Refund'}</span>
                        <span className="badge badge-secondary">{transaction.transactionType == 7 && 'Security Deposit'}</span>
                        <span className="badge badge-danger">{transaction.transactionType == 8 && 'Advance Payment'}</span>
                        <span className="badge badge-light">{transaction.transactionType == 9 && 'Rent Received'}</span>
                        <span className="badge badge-light">{transaction.transactionType == 10 && 'Rent Adjustment'}</span>
                        <span className="badge badge-light">{transaction.transactionType == 11 && 'Rent Unpaid'}</span>
                        
                      </td>
                      <td>
                        {transaction.collectorName}
                      </td>
                      <td>
                        {transaction.transactionDate}
                      </td>
                    </tr>
                  );
                }
              })}
            </tbody>
            <thead className="bg-black-custom text-uppercase">
              <tr className="text-white">
                  <th></th>
                  <th></th>
                  <th>Total Amount: {parseInt(totalTransactions).toLocaleString()}</th>
                  <th colSpan={6}></th>
              </tr>
            </thead>
          </table>
        </div>
      </div>

      <div className="col-sm-6 text-xl-left text-sm-left text-center">
        <p>Total Number of Transactions: {transactions?.length}</p>
      </div>

        <div className="col-sm-6"> 
        <nav className="pagination justify-content-xl-end justify-content-sm-end justify-content-center">
          <ul className="pagination">
            <li
              className={`page-item ${
                currentPage == 1 ? "disabled" : ""
              }`}
            >
              <button
                className="page-link"
                onClick={() => paginate(currentPage - 1)}
              >
                Previous
              </button>
            </li>
            {renderPageNumbers}
            <li
              className={`page-item ${
                currentPage == Math.ceil(transactions?.length / itemsPerPage)
                  ? "disabled"
                  : ""
              }`}
            >
              <button
                className="page-link"
                onClick={() => paginate(currentPage + 1)}
              >
                Next
              </button>
            </li>
          </ul>
        </nav>
        </div>
    </>
  )
}

export default DepositChartDetails
