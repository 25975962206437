import React, {useEffect} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import {date_time_to_date_format, getOwners,cancelRentContract, getRent,getTransactions, getRoom, remove_session} from "../../../functions/general";
import ListTransactions from "../transactions/ListTransactions";
import ModalImage from "react-modal-image";

function RentDetails(props) {
    const currentUser = props.currentUser;
    const navigate = useNavigate();
    const params = useParams();
    const [room, setRoom] = React.useState([]);
    const [rent, setRent] = React.useState([]);
    const [transactions, setTransactions] = React.useState([]);
    const [rentId, setRentId] = React.useState(params.id);
    const [rentCollected, setRentCollected] = React.useState("");
    const [rentSecurityCollected, setRentSecurityCollected] = React.useState("");
    const [transactionFor, setTransactionFor] = React.useState("rent");
    const [cancelRent, setCancelRent] = React.useState(1);
    useEffect(() => {
        getRent(params.id).then(res => {
            if (res && res.status == 200) {
                if(res.data.data){
                    setRent(res.data.data);
                    setRentCollected(res.data.rentCollected);
                    setRentSecurityCollected(res.data.rentSecurityCollected);
                } else {
                    navigate('/admins');
                }
            } else if (res && res.status == 203) {
                remove_session();
                props.getLoggedInUser();
                navigate('/login');
            }
        });
        getTransactions({rentId, transactionFor}).then(res => {
            if (res && res.status == 200) {
              setTransactions(res.data.data);
            } else if (res && res.status == 203) {
              remove_session();
              props.getLoggedInUser();
              navigate('/login');
            } else {
              // Handle other cases if needed
            }
          });
    }, [])
    const deleteHandler = (e, id) => {
        e.preventDefault();
        if (window.confirm("Are you sure you want to cancel rent contract?")) {
          cancelRentContract({id,cancelRent}).then(res => {
            if (res && res.status == 200) {
                navigate('/rent');
              } else if (res && res.status == 405) {
              alert("Contract cannot be deleted.");
            } else if (res && res.status == 203) {
              remove_session();
              props.getLoggedInUser();
              navigate('/login');
            } else {
              // Handle other cases
            }
          });
        }
      }
    const monthDiff = (dateFrom, dateTo) => {
        var months = dateTo.getMonth() - dateFrom.getMonth()
            + (12 * (dateTo.getFullYear() - dateFrom.getFullYear()));

        if(dateTo.getDate() < dateFrom.getDate()){
            months--;
        }
        return months;
    }
    const yearDiff = (dateFrom, dateTo) => {
        var years = dateTo.getFullYear() - dateFrom.getFullYear();

        if(dateTo.getDate() < dateFrom.getDate()){
            years--;
        }
        return years;
    }
    const getRentTillNow = () => {
        let parsedDeposit = parseInt(rent.securityDeposit);
        if (isNaN(parsedDeposit)) {
            parsedDeposit = 0;
          }
       
        let rentAmount = 0;
        if(rent.startDate){
            if(rent.rentType == '1'){
                let months = monthDiff(new Date(rent.startDate), new Date()) + 1;
                rentAmount = rent.rent * months
            }else{
                let years = yearDiff(new Date(rent.startDate), new Date()) + 1;
                rentAmount = rent.rent * years
            }
        }
        return rentAmount + parseInt(rent.overDaysRent)+ parseInt(parsedDeposit);
    }
    const pendingBalance = getRentTillNow() - (rentCollected.amount ? rentCollected.amount : 0);
  return (
      <>
          <div className="col-lg-12">
              <div className="d-flex flex-wrap align-items-center justify-content-between mb-4">
                  <div>
                      <h4 className="mb-3">Rent Contract Detail</h4>
                    
                  </div>
                  <div className="text-end">
                  { pendingBalance == 0 && currentUser.isSuperAdmin == '1' &&
                  !rent.endDate && (getRentTillNow() <= rentCollected.amount ? (
                      <Link to={`/rent/checkout/${rent.id}`} className="btn btn-primary add-list mr-2">
                      Checkout</Link>
                  ) : (
                      <Link to={`/rent/checkout/${rent.id}`} className="btn btn-primary add-list mr-2">
                          Force/Early Checkout</Link>
                  ))}
                  
                  {!rent.endDate && (<>
                  {rent.rentType == '1' &&
                  <Link to={`/transactions/add?type=rent&id=${rent.id}`} className="btn btn-primary add-list mr-2"><i
                      className="las la-plus mr-3"></i>Add Transaction</Link>}
                  {rent.rentType == '2' &&
                  <Link to={`/transactions/add?type=rent&id=${rent.id}`} className="btn btn-primary add-list mr-2"><i
                      className="las la-plus mr-3"></i>Add Cheque</Link>}</>)}
                    {transactions.length<=1 && 
                        <a
                            onClick={(e) => deleteHandler(e, rent.id)}
                            className="btn btn-danger mr-2 add-list"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Delete"
                            data-original-title="Delete"
                            href="#"
                            >
                            Cancel Contract
                            </a>
                    }
                  </div>
                 
              </div>
          </div>
          <div className="col-sm-6">
              <div className="card">
                  <div className="bg-black-custom card-header d-flex justify-content-between rounded-top">
                      <div className="header-title">
                          <h4 className="card-title text-white">{rent.roomName} ({rent.customerName})</h4>
                      </div>
                  </div>
                  <div className="card-body">
                          <div className="row">
                              <div className="col-md-12">
                                  <div className="form-group">
                                      <label>Building: </label> {rent.buildingName}
                                      <br/>
                                      <label>Room: </label> {rent.roomName}
                                      <br/>
                                      <label>Tenant: </label> {rent.customerName}
                                      <br/>
                                      <label>Rent: </label> {rent.rent} / {rent.rentType == '1' ? 'Month' : 'Year'}
                                      {rent.contractedImage &&
                                      <div  className="transactionTableImage">
                                          <ModalImage
                                              small={process.env.REACT_APP_APIPATH + "/api/images/" + rent.contractedImage}
                                              large={process.env.REACT_APP_APIPATH + "/api/images/" + rent.contractedImage}
                                          />
                                      </div>}
                                      {rent.rentType == '2' && (<>
                                      <br/>
                                      <label>No of Cheques: </label> {rent.cheques}
                                      </>)}
                                      <br/>
                                      <label>Start Date: </label> {date_time_to_date_format(rent.startDate)}
                                      <br/>
                                      {rent.endDate && (<>
                                          <label>End Date: </label> {date_time_to_date_format(rent.endDate)}
                                          <br/>
                                      </>)}
                                      <label>Dewa: </label> {rent.dewa == '1' ? 'Included' : 'Not Included'} <br/>
                                      <label>Dewa Account : </label> {rent.dewaAccount? rent.dewaAccount : '--'}
                                      <br/>
                                      <label>Maintenance Charges: </label> {rent.maintenance == '1' ? 'Included' : 'Not Included'}
                                      <br/>
                                      <label>Security Deposit: </label> {rent.securityDeposit} | {rentSecurityCollected.amount ? rentSecurityCollected.amount+ ' - PAID' : 'PENDING '}
                                      <br/>
                                      <label>Over Days: </label> {rent.overDays}
                                      <br/>
                                      <label>Over Days Rent: </label> {rent.overDaysRent}
                                      {rent.rentType == '2' && (<>
                                          <br/>
                                          <label>Rent Start Date: </label> {date_time_to_date_format(rent.rentStartDate)}
                                          <br/>
                                          <label>Rent End Date: </label> {date_time_to_date_format(rent.rentEndDate)}
                                      </>)}
                                  </div>
                              </div>
                          </div>
                  </div>
              </div>
          </div>
          <div className="col-sm-6">
              <div className="card">
                  <div className="bg-black-custom card-header d-flex justify-content-between rounded-top">
                      <div className="header-title">
                          <h4 className="card-title text-white">Stats</h4>
                      </div>
                  </div>
                  <div className="card-body">
                          <div className="row">
                              <div className="col-md-12">
                                  <div className="form-group">
                                      <div className="checkbox">
                                          <label>Total Rent Till Now:</label> {getRentTillNow()}
                                          <br />
                                          
                                          <label>Total Rent Collected:</label> {Number(rentCollected?.amount ?? 0) + Number(rentSecurityCollected?.amount ?? 0)}
                                          <br />
                                          <label>Remaining:</label> {getRentTillNow() - (rentCollected.amount ? rentCollected.amount : 0)- (rentSecurityCollected.amount ? rentSecurityCollected.amount : 0)}
                                      </div>
                                  </div>
                              </div>
                          </div>
                  </div>
              </div>
          </div>
          <ListTransactions currentUser={currentUser} rentId={params.id} transactionType="9" transactionFor="rent"/>
          
      </>
  );
}

export default RentDetails;
