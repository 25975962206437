import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import PrivateRoute from "./common/PrivateRoute";
import React from "react";
import Login from "./pages/public/login";
import LoginRoute from "./common/PublicRoute";
import {current_user, is_logged_in,current_user_privilege} from "./functions/general";
import Loading from "./components/Loading";
import Sidebar from "./components/Sidebar";
import TopNavigation from "./components/TopNavigation";
import Footer from "./components/Footer";
import Profile from "./pages/private/Profile";
import Dashboard from "./pages/private/Dashboard";
import Reports from "./pages/private/reports/Reports";
import Account from './pages/private/accounts/Account';
import ListAdmins from "./pages/private/admins/ListAdmins";
import AddAdmins from "./pages/private/admins/AddAdmins";
import ListOwners from "./pages/private/owners/ListOwners";
import AddOwners from "./pages/private/owners/AddOwners";
import ListBuildingsVillas from "./pages/private/buildingsvillas/ListBuildingsVillas";
import AddBuildingsVillas from "./pages/private/buildingsvillas/AddBuildingsVillas";
import ListRooms from "./pages/private/rooms/ListRooms";
import AddRooms from "./pages/private/rooms/AddRooms";
import ListCustomers from "./pages/private/customers/ListCustomers";
import AddCustomers from "./pages/private/customers/AddCustomers";
import AddRent from "./pages/private/rent/AddRent";
import EditAdmins from "./pages/private/admins/EditAdmins";
import EditOwners from "./pages/private/owners/EditOwners";
import EditCustomers from "./pages/private/customers/EditCustomers";
import EditBuildingsVillas from "./pages/private/buildingsvillas/EditBuildingsVillas";
import EditRooms from "./pages/private/rooms/EditRooms";
import BuildingDetails from "./pages/private/buildingsvillas/BuildingDetails";
import AddTransaction from "./pages/private/transactions/AddTransaction";
import RoomDetails from "./pages/private/rooms/RoomDetails";
import ListRent from "./pages/private/rent/ListRent";
import RentDetails from "./pages/private/rent/RentDetails";
import Checkout from "./pages/private/rent/Checkout";
import ListEmployees from "./pages/private/employees/ListEmployees";
import AddEmployees from "./pages/private/employees/AddEmployees";
import EditEmployees from "./pages/private/employees/EditEmployees";
import ListAllTransactions from "./pages/private/transactions/ListAllTransactions";
import ListRentTransactions from "./pages/private/transactions/ListRentTransactions";
import PrintTransaction from './pages/private/transactions/PrintTransation';
import EmployeeDetails from "./pages/private/employees/EmployeeDetails";
import CustomerDetails from "./pages/private/customers/CustomerDetails";
import EditRent from "./pages/private/rent/EditRent";
import PrintRent from './pages/private/rent/PrintRent';
import OwnerDetails from './pages/private/owners/OwnerDetails';
import AdminDetails from './pages/private/admins/AdminDetails';
import PrintCustomer from './pages/private/customers/PrintCustomer';
import PrintFormCustomer from './pages/private/customers/PrintFormCustomer';
import PrintBuilding from './pages/private/buildingsvillas/PrintBuilding';
import DepositChart from './pages/private/reports/DepositChart';
import ExpenseChartDetails from './pages/private/reports/ExpenseChartDetails';
import DepositChartDetails from './pages/private/reports/DepositChartDetails';
import RevenueChartDetails from './pages/private/reports/RevenueChartDetails';
import AddAccounts from "./pages/private/accounts/AddAccount";
import EditAccounts from "./pages/private/accounts/EditAccount";
import AddAccountTransaction from "./pages/private/accounts/transactions/AddTransaction";
import AccountTransactions from "./pages/private/accounts/transactions/AccountTransactions";
import AccountStatement from "./pages/private/accounts/AccountStatement";
function App() {
    const [isLoggedIn, setIsLoggedIn] = React.useState(is_logged_in());
    const [currentUser, setCurrentUser] = React.useState(current_user());
    const [currentUserPrivilege, setCurrentUserPrivilege] = React.useState(current_user_privilege());
    const getLoggedInUser = () => {
      setIsLoggedIn(is_logged_in());
      setCurrentUser(current_user());
      setCurrentUserPrivilege(current_user_privilege());
    }
    return (
        <BrowserRouter>
            <div className="wrapper">
                <Loading />
                {isLoggedIn && (<>
                    <Sidebar currentUser={currentUser} currentUserPrivilege={currentUserPrivilege}/>
                    <TopNavigation getLoggedInUser={getLoggedInUser} currentUser={currentUser}/>
                </>)}
                <div className={isLoggedIn ? 'content-page' : ''}>
                    <div className="container-fluid">
                        <div className={isLoggedIn ? 'row' : ''}>
                            <Routes>
                                <Route path="/" element={
                                    <PrivateRoute isLoggedIn={isLoggedIn}>
                                        <Dashboard currentUser={currentUser} currentUserPrivilege={currentUserPrivilege}/>
                                    </PrivateRoute>
                                }/>
                                <Route path="/account" element={
                                    <PrivateRoute isLoggedIn={isLoggedIn}>
                                        <Account currentUser={currentUser} currentUserPrivilege={currentUserPrivilege}/>
                                    </PrivateRoute>
                                }/>
                                 <Route path="/reports" element={
                                    <PrivateRoute isLoggedIn={isLoggedIn}>
                                        <Reports currentUser={currentUser}/>
                                    </PrivateRoute>
                                }/>

                                <Route path="/report/expense-chart/" element={
                                    <PrivateRoute isLoggedIn={isLoggedIn}>
                                        <ExpenseChartDetails currentUser={currentUser}/>
                                    </PrivateRoute>
                                }/>

                                <Route path="/report/deposit-chart/" element={
                                    <PrivateRoute isLoggedIn={isLoggedIn}>
                                        <DepositChartDetails currentUser={currentUser}/>
                                    </PrivateRoute>
                                }/>

                                <Route path="/report/revenue-chart/" element={
                                    <PrivateRoute isLoggedIn={isLoggedIn}>
                                        <RevenueChartDetails currentUser={currentUser}/>
                                    </PrivateRoute>
                                }/>




                                <Route path="/profile" element={
                                    <PrivateRoute isLoggedIn={isLoggedIn}>
                                        <Profile />
                                    </PrivateRoute>
                                }/>
                                <Route path="/admins" element={
                                    <PrivateRoute isLoggedIn={isLoggedIn}>
                                        <ListAdmins getLoggedInUser={getLoggedInUser}/>
                                    </PrivateRoute>
                                }/>

                                <Route path="/admins/:id" element={
                                    <PrivateRoute isLoggedIn={isLoggedIn}>
                                        <AdminDetails/>
                                    </PrivateRoute>
                                }/> 

                                <Route path="/admins/add" element={
                                    <PrivateRoute isLoggedIn={isLoggedIn}>
                                        <AddAdmins getLoggedInUser={getLoggedInUser}/>
                                    </PrivateRoute>
                                }/>
                                <Route path="/admins/edit/:id" element={
                                    <PrivateRoute isLoggedIn={isLoggedIn}>
                                        <EditAdmins getLoggedInUser={getLoggedInUser}/>
                                    </PrivateRoute>
                                }/>
                                <Route path="/employees" element={
                                    <PrivateRoute isLoggedIn={isLoggedIn}>
                                        <ListEmployees currentUser={currentUser} getLoggedInUser={getLoggedInUser} currentUserPrivilege={currentUserPrivilege}/>
                                    </PrivateRoute>
                                }/>
                                <Route path="/employees/add" element={
                                    <PrivateRoute isLoggedIn={isLoggedIn}>
                                        <AddEmployees getLoggedInUser={getLoggedInUser}/>
                                    </PrivateRoute>
                                }/>
                                <Route path="/employees/edit/:id" element={
                                    <PrivateRoute isLoggedIn={isLoggedIn}>
                                        <EditEmployees getLoggedInUser={getLoggedInUser}/>
                                    </PrivateRoute>
                                }/>
                                <Route path="/employees/:id" element={
                                    <PrivateRoute isLoggedIn={isLoggedIn}>
                                        <EmployeeDetails currentUser={currentUser}  />
                                    </PrivateRoute>
                                }/>

                                
                                <Route path="/owners" element={
                                    <PrivateRoute isLoggedIn={isLoggedIn}>
                                        <ListOwners currentUser={currentUser} currentUserPrivilege={currentUserPrivilege} />
                                    </PrivateRoute>
                                }/>
                                <Route path="/owners/add" element={
                                    <PrivateRoute isLoggedIn={isLoggedIn}>
                                        <AddOwners />
                                    </PrivateRoute>
                                }/>
                                 <Route path="/owners/:id" element={
                                    <PrivateRoute isLoggedIn={isLoggedIn}>
                                        <OwnerDetails currentUser={currentUser} />
                                    </PrivateRoute>
                                }/>
                                <Route path="/owners/edit/:id" element={
                                    <PrivateRoute isLoggedIn={isLoggedIn}>
                                        <EditOwners getLoggedInUser={getLoggedInUser}/>
                                    </PrivateRoute>
                                }/>
                                <Route path="/customers" element={
                                    <PrivateRoute isLoggedIn={isLoggedIn}>
                                        <ListCustomers currentUser={currentUser} currentUserPrivilege={currentUserPrivilege}/>
                                    </PrivateRoute>
                                }/>
                                <Route path="/customers/add" element={
                                    <PrivateRoute isLoggedIn={isLoggedIn}>
                                        <AddCustomers />
                                    </PrivateRoute>
                                }/>
                                <Route path="/customers/:id" element={
                                    <PrivateRoute isLoggedIn={isLoggedIn}>
                                        <CustomerDetails currentUser={currentUser}  />
                                    </PrivateRoute>
                                }/>
                                <Route path="/customers/edit/:id" element={
                                    <PrivateRoute isLoggedIn={isLoggedIn}>
                                        <EditCustomers />
                                    </PrivateRoute>
                                }/>

                                    <Route path="/customers/print/:id" element={
                                    <PrivateRoute isLoggedIn={isLoggedIn}>
                                        <PrintCustomer/>
                                    </PrivateRoute>
                                }/> 

                                    <Route path="/customers/printform/:id" element={
                                    <PrivateRoute isLoggedIn={isLoggedIn}>
                                        <PrintFormCustomer/>
                                    </PrivateRoute>
                                }/> 


                                <Route path="/buildings-villas" element={
                                    <PrivateRoute isLoggedIn={isLoggedIn}>
                                        <ListBuildingsVillas currentUser={currentUser} currentUserPrivilege={currentUserPrivilege}/>
                                    </PrivateRoute>
                                }/>

                                 <Route path="/building/print/:id" element={
                                    <PrivateRoute isLoggedIn={isLoggedIn}>
                                        <PrintBuilding/>
                                    </PrivateRoute>
                                }/> 

                                <Route path="/buildings-villas/:id" element={
                                    <PrivateRoute isLoggedIn={isLoggedIn}>
                                        <BuildingDetails currentUser={currentUser} />
                                    </PrivateRoute>
                                }/>
                                <Route path="/buildings-villas/add" element={
                                    <PrivateRoute isLoggedIn={isLoggedIn}>
                                        <AddBuildingsVillas />
                                    </PrivateRoute>
                                }/>
                                <Route path="/buildings-villas/edit/:id" element={
                                    <PrivateRoute isLoggedIn={isLoggedIn}>
                                        <EditBuildingsVillas />
                                    </PrivateRoute>
                                }/>
                                <Route path="/rooms" element={
                                    <PrivateRoute isLoggedIn={isLoggedIn}>
                                        <ListRooms currentUser={currentUser} currentUserPrivilege={currentUserPrivilege}/>
                                    </PrivateRoute>
                                }/>
                                <Route path="/rooms/:id" element={
                                    <PrivateRoute isLoggedIn={isLoggedIn}>
                                        <RoomDetails currentUser={currentUser}  />
                                    </PrivateRoute>
                                }/>
                                <Route path="/rooms/add" element={
                                    <PrivateRoute isLoggedIn={isLoggedIn}>
                                        <AddRooms />
                                    </PrivateRoute>
                                }/>
                                <Route path="/rooms/edit/:id" element={
                                    <PrivateRoute isLoggedIn={isLoggedIn}>
                                        <EditRooms />
                                    </PrivateRoute>
                                }/>
                                <Route path="/new-rent" element={
                                    <PrivateRoute isLoggedIn={isLoggedIn}>
                                        <AddRent />
                                    </PrivateRoute>
                                }/>
                                <Route path="/transactions/add" element={
                                    <PrivateRoute isLoggedIn={isLoggedIn}>
                                        <AddTransaction currentUser={currentUser}  />
                                    </PrivateRoute>
                                }/>
                                <Route path="/rent" element={
                                    <PrivateRoute isLoggedIn={isLoggedIn} >
                                        <ListRent currentUser={currentUser} currentUserPrivilege={currentUserPrivilege}/>
                                    </PrivateRoute>
                                }/>
                                <Route path="/rent/:id" element={
                                    <PrivateRoute isLoggedIn={isLoggedIn}>
                                        <RentDetails currentUser={currentUser}  />
                                    </PrivateRoute>
                                }/>
                                 <Route path="/rent/print/:id" element={
                                    <PrivateRoute isLoggedIn={isLoggedIn}>
                                        <PrintRent  currentUser={currentUser}  />
                                    </PrivateRoute>
                                }/> 
                                <Route path="/rent/edit/:id" element={
                                    <PrivateRoute isLoggedIn={isLoggedIn}>
                                        <EditRent />
                                    </PrivateRoute>
                                }/>
                                <Route path="/rent/add" element={
                                    <PrivateRoute isLoggedIn={isLoggedIn}>
                                        <AddRent />
                                    </PrivateRoute>
                                }/>
                                <Route path="/rent/checkout/:id" element={
                                    <PrivateRoute isLoggedIn={isLoggedIn}>
                                        <Checkout />
                                    </PrivateRoute>
                                }/>
                                <Route path="/transactions" element={
                                    <PrivateRoute isLoggedIn={isLoggedIn}>
                                        <ListAllTransactions currentUser={currentUser} currentUserPrivilege={currentUserPrivilege} />
                                    </PrivateRoute>
                                }/>
                                <Route path="/transaction/print/:id" element={
                                    <PrivateRoute isLoggedIn={isLoggedIn}>
                                        <PrintTransaction  currentUser={currentUser}  />
                                    </PrivateRoute>
                                }/> 
                                <Route path="/transactions/rent" element={
                                    <PrivateRoute isLoggedIn={isLoggedIn}>
                                        <ListRentTransactions  currentUser={currentUser}  currentUserPrivilege={currentUserPrivilege} />
                                    </PrivateRoute>
                                }/>
                                <Route path="/login" element={
                                    <LoginRoute isLoggedIn={isLoggedIn}>
                                        <Login getLoggedInUser={getLoggedInUser}/>
                                    </LoginRoute>
                                }/>
                                <Route path="/accounts/add" element={
                                    <PrivateRoute isLoggedIn={isLoggedIn}>
                                        <AddAccounts getLoggedInUser={getLoggedInUser}/>
                                    </PrivateRoute>
                                }/>
                                <Route path="/accounts/edit/:id" element={
                                    <PrivateRoute isLoggedIn={isLoggedIn}>
                                        <EditAccounts getLoggedInUser={getLoggedInUser}/>
                                    </PrivateRoute>
                                }/>
                                <Route path="/account-statement/:id" element={
                                    <PrivateRoute isLoggedIn={isLoggedIn}>
                                        <AccountStatement getLoggedInUser={getLoggedInUser}/>
                                    </PrivateRoute>
                                }/>
                                <Route path="/account-transaction/add" element={
                                    <PrivateRoute isLoggedIn={isLoggedIn}>
                                        <AddAccountTransaction  getLoggedInUser={getLoggedInUser}/>
                                    </PrivateRoute>
                                }/>
                                <Route path="/account-transactions" element={
                                    <PrivateRoute isLoggedIn={isLoggedIn}>
                                        <AccountTransactions currentUser={currentUser} currentUserPrivilege={currentUserPrivilege} />
                                    </PrivateRoute>
                                }/>
                            </Routes>
                        </div>
                    </div>
                </div>
            </div>
            {isLoggedIn && (<>
                <Footer />
            </>)}
        </BrowserRouter>
    );
}

export default App;
