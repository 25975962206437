import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { date_time_to_date_format, getTransactions, remove_session,deleteTransaction } from "../../../functions/general";
import ModalImage from "react-modal-image";


function ListTransactions(props) {
  const currentUser = props.currentUser;
  
  const navigate = useNavigate();
  const [transactions, setTransactions] = React.useState([]);
  const [buildingId, setBuildingId] = React.useState(props.buildingId);
  const [roomId, setRoomId] = React.useState(props.roomId);
  const [rentId, setRentId] = React.useState(props.rentId);
  const [employeeId, setEmployeeId] = React.useState(props.employeeId);
  const [customerId, setCustomerId] = React.useState(props.customerId);
  const [transactionFor, setTransactionFor] = React.useState(props.transactionFor);
  const [transactionType, setTransactionType] = React.useState(props.transactionType);

  useEffect(() => {
    getTransactions({ customerId, employeeId, buildingId, roomId, rentId, transactionFor, transactionType }).then(res => {
      if (res && res.status == 200) {
        setTransactions(res.data.data);
      } else if (res && res.status == 203) {
        remove_session();
        props.getLoggedInUser();
        navigate('/login');
      } else {
        // Handle other cases if needed
      }
    });
  }, []);

  const handleEditClick = (transactionId) => {
    // Handle edit action, navigate to edit page or perform other actions
    navigate(`/transactions/edit/${transactionId}`);
  };

  const handleDeleteClick = (id) => {
    if (window.confirm("Are you sure you want to delete transaction?")) {
      deleteTransaction({id,customerId, employeeId, buildingId, roomId, rentId, transactionFor, transactionType }).then(res => {
        if (res && res.status == 200) {
          setTransactions(res.data.data);
        } else if (res && res.status == 405) {
          alert("Transaction cannot be deleted.");
        } else if (res && res.status == 203) {
          remove_session();
          props.getLoggedInUser();
          navigate('/login');
        } else {
          // Handle other cases
        }
      });
    }
  };

  return (
    <>
      <div className="col-lg-12">
        <div className="table-responsive rounded mb-3">
          <table className="data-table table mb-0 tbl-server-info">
            <thead className="bg-black-custom text-uppercase">
              <tr className="text-white">
                <th>Receipt</th>
                <th>Amount</th>
                {(transactionFor == "building") && transactionType > 1 && (
                  <th>Room</th>
                )}
                {(transactionFor == "customer") && transactionType == 1 && (
                  <th>Room</th>
                )}
                <th>Status</th>
                <th>Description</th>
                {transactionFor == "rent" && (
                  <th>Collected By</th>
                )}
                <th>Date</th>
                {currentUser.isSuperAdmin == '1' &&<th>Action</th> }{/* New Action Column */}
              </tr>
            </thead>
            <tbody className="bg-white">
              {transactions?.length > 0 && transactions.map((transaction, index) => {
                return (
                  <tr key={index}>
                    <td>
                      {transaction.transactionReceipt && (
                        <div className="transactionTableImage">
                          <ModalImage
                            small={process.env.REACT_APP_APIPATH + "/api/images/" + transaction.transactionReceipt}
                            large={process.env.REACT_APP_APIPATH + "/api/images/" + transaction.transactionReceipt}
                          />
                        </div>
                      )}
                    </td>
                    <td>{transaction.transactionAmount}</td>
                    {transactionFor == "building" && transactionType > 1 && (
                      <td>{transaction.roomId > 0 ? transaction.roomName : ''}</td>
                    )}
                    {(transactionFor == "customer") && transactionType == 1 && (
                      <td>{transaction.roomId > 0 ? transaction.roomName : ''}</td>
                    )}
                    <td className="text-center">
                        <span className="badge badge-light">{transaction.transactionType == 1 && 'Rent'}</span>
                        <span className="badge badge-info">{transaction.transactionType == 2 && 'Dewa'}</span>
                        <span className="badge badge-dark">{transaction.transactionType == 3 && 'Maintenance'}</span>
                        <span className="badge badge-success">{transaction.transactionType == 4 && 'Salary'}</span>
                        <span className="badge badge-primary">{transaction.transactionType == 5 && 'Other Expense'}</span>
                        <span className="badge badge-warning">{transaction.transactionType == 6 && 'Security Refund'}</span>
                        <span className="badge badge-secondary">{transaction.transactionType == 7 && 'Security Deposit'}</span>
                        <span className="badge badge-danger">{transaction.transactionType == 8 && 'Advance Payment'}</span>
                        <span className="badge badge-light">{transaction.transactionType == 9 && 'Rent Received'}</span>
                        <span className="badge badge-light">{transaction.transactionType == 10 && 'Rent Adjustment'}</span>
                        <span className="badge badge-light">{transaction.transactionType == 11 && 'Rent Unpaid'}</span>
                        
                      </td>
                    <td className="text-ellipses">{transaction.transactionDescription}</td>
                    {transactionFor == "rent" && (
                      <td>{transaction.collectorName}</td>
                    )}
                    <td>{date_time_to_date_format(transaction.transactionDate)}</td>
                    {currentUser.isSuperAdmin == '1' &&
                    <td>
                      <div className="d-flex align-items-center list-action">
                        {/* <Link
                          className="badge bg-primary mr-2"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Edit"
                          to={'#'}
                          onClick={() => handleEditClick(transaction.id)}
                        >
                          <i className="ri-pencil-line mr-0"></i>
                        </Link> */}
                        
                        <span
                          className="badge bg-primary"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Delete"
                          onClick={() => handleDeleteClick(transaction.id)}
                          style={{ cursor: 'pointer' }}
                        >
                          <i className="ri-delete-bin-line mr-0"></i>
                        </span>
                      </div>
                    </td>}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default ListTransactions;
