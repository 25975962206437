import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { deleteBuilding, deleteRoom, getBuildings, getRooms, remove_session } from "../../../functions/general";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Dropdown from 'react-bootstrap/Dropdown';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

function ListRooms(props) {
  const currentUser = props.currentUser;
  const userType = (currentUser && currentUser.isSuperAdmin == 1)? 'superadmin': 'admin';
  const navigate = useNavigate();
  const [rooms, setRooms] = useState([]);
  const [buildings, setBuildings] = useState([]);
  const [buildingId, setBuildingId] = useState("");
  const [roomStatus, setRoomStatus] = useState("");
  const [activeStatus, setActiveStatus] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [showFilters, setShowFilters] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(-1);
  const [loading, setLoading] = useState(true); 
  const tableRef = useRef();
  let count = 0;
  let activeCount = 0;
  let inactiveCount = 0;

  useEffect(() => {
    getBuildings().then(res => {
      if (res && res.status === 200) {
        setBuildings(res.data.data);
      } else if (res && res.status === 203) {
        remove_session();
        props.getLoggedInUser();
        navigate('/login');
      } else {
        // Handle other cases
      }
    });

    getRooms().then(res => {
      if (res && res.status === 200) {
        setLoading(false); 
        const selectedRoomsOptionsTemp = [];
        if(userType =='admin'){
          if(props.currentUserPrivilege){
            const parsedBuildingIds = JSON.parse(props.currentUserPrivilege.building_ids);
            res.data.data?.length > 0 && res.data.data.forEach((buildingValue) => {
                const value = buildingValue.buildingId;
                if(parsedBuildingIds && parsedBuildingIds.includes(value)){ 
                    selectedRoomsOptionsTemp.push(buildingValue);
                } 
            });
            setRooms(selectedRoomsOptionsTemp);
            setItemsPerPage(selectedRoomsOptionsTemp.length);
          }
        }else{
          setRooms(res.data.data);
          setItemsPerPage(res.data.data.length);
        }
      
      } else if (res && res.status === 203) {
        remove_session();
        props.getLoggedInUser();
        navigate('/login');
      } else {
        // Handle other cases
      }
    });
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    // Handle form submission if needed
  }

  const deleteHandler = (e, id) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want to delete room?")) {
      deleteRoom(id).then(res => {
        if (res && res.status === 200) {
          setRooms(res.data.data);
        } else if (res && res.status === 405) {
          alert("Building cannot be deleted.");
        } else if (res && res.status === 203) {
          remove_session();
          props.getLoggedInUser();
          navigate('/login');
        } else {
          // Handle other cases
        }
      });
    }
  }

  const applyFilters = (rooms) => {
    return rooms.filter(room  => {
      // Filter by room status and building ID
      if ((!roomStatus || (roomStatus == 1 ? roomStatus == room.isOnRent : '1' != room.isOnRent)) &&
      (!buildingId || buildingId == room.buildingId)) {

      // Create a concatenated string of relevant fields
      const tempString = `${room.buildingName} ${room.ownerName} ${room.name}`;

      // Filter by search query
      if (searchQuery && !tempString.match(new RegExp(searchQuery, 'i'))) {
        return false;
      }

      // Filter by active status
      if (activeStatus && activeStatus != room.status) {
        return false;
      }

      return true;
    }

    return false;
    });
  };

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentRooms = rooms?.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const pageNumbers = Array.from(
    { length: Math.ceil(rooms?.length / itemsPerPage) },
    (_, i) => i + 1
  );
  
  const renderPageNumbers = pageNumbers.map((number) => {
    if (
      number === 1 ||
      number === currentPage ||
      number === currentPage - 1 ||
      number === currentPage + 1 ||
      number === pageNumbers?.length
    ) {
      return (
        <li
          key={number}
          className={`page-item ${currentPage === number ? "active" : ""}`}
        >
          <button className="page-link" onClick={() => paginate(number)}>
            {number}
          </button>
        </li>
      );
    }
    return null;
  });
  const handleItemsPerPageChange = (e) => {
      const value = Number(e.target.value);
      if (value === -1) {
          // Show all items
          setItemsPerPage(rooms.length);
      } else {
          // Set items per page to the selected value
          setItemsPerPage(value);
      }
      setCurrentPage(1); // Reset to first page when changing items per page
  };


  const exportToCSV = () => {
    tableRef.current.link.click();
  };

  const exportToExcel = () => {
    const filteredRooms = applyFilters(currentRooms);
    const ws = XLSX.utils.json_to_sheet(filteredRooms);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Rooms List");
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" });
    saveAs(data, "rooms-list.xlsx");
  };

  const exportToPDF = () => {
    const filteredRooms = applyFilters(currentRooms);
    const unit = "pt";
    const size = "A4";
    const orientation = "landscape";

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);
    const title = "Rooms List";
    const headers = [
      ["Room No","Building Name", "Owner Name", "Rent Status", "State"],
    ];

    const data = filteredRooms.map((room) => [
      room.name,
      room.buildingName, 
      room.ownerName,
      room.isOnRent === 1 ? "Occupied" : "Free",
      room.status === 1 ? "Inactive" : "Active",
    ]);

    let content = {
      startY: 50,
      head: headers,
      body: data,
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("rooms-list.pdf");
  };


  return (
    <>
      <div className="col-lg-12">
        <div className="d-flex flex-wrap align-items-center justify-content-between mb-4">
          <div>
            <h4 className="mb-3">Rooms List</h4>
          </div>
          <div>
          {currentUser && currentUser.isSuperAdmin == "1" && (
          <Link to="/rooms/add" className="btn btn-primary add-list mx-3">
            <i className="las la-plus mr-3"></i>Add Room
          </Link>
          )}
          </div>
        </div>
      </div>



      <div className="col-lg-12">
      <div className="row  align-items-end px-3">
          <div className="col-lg-2 col-md-3 col-sm-6">
          <div className="form-group">
                <label>Show per page:</label>
                <select
                  className="form-control"
                  value={itemsPerPage === rooms.length ? -1 : itemsPerPage}
                  onChange={handleItemsPerPageChange}
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={30}>30</option>
                  <option value={-1}>Show All</option>
                </select>
              </div>
          </div>

          <div className="col-lg-2 col-md-3 col-sm-6">

          <div className="form-group">
          <label>Export</label>
          <Dropdown className="w-100">
              <Dropdown.Toggle variant="light" id="dropdown-basic" className="w-100">
                Select Export Type
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item href="#/action-1" onClick={exportToCSV}>Export to CSV</Dropdown.Item>
                <Dropdown.Item href="#/action-2" onClick={exportToExcel}>Export to Excel</Dropdown.Item>
                <Dropdown.Item href="#/action-3" onClick={exportToPDF}>Export to PDF</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          </div>
          {/* <div className="col-md-3">
          <div className="form-group">
                        <label>Search</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder=""
                          value={searchQuery}
                          onChange={(e) => setSearchQuery(e.target.value)}
                        />
                      </div>
          </div>

          <div className="col-md-3">
          <div className="form-group">
                        <label>Building/Villa *</label>
                        <select
                          className="form-control"
                          value={buildingId}
                          onChange={(e) => setBuildingId(e.target.value)}
                        >
                          <option value="">All Buildings</option>
                          {buildings?.length > 0 &&
                            buildings.map((building, index) => {
                              if (building.status === '1') {
                                return (
                                  <option key={index} value={building.id}>
                                    {building.name} ({building.ownerName})
                                  </option>
                                );
                              }
                            })}
                        </select>
                      </div>
            </div> */}


          <div className="text-end col-sm-12">
            <div className="d-flex flex-wrap align-items-center justify-content-end mb-4 ">
            {/* <Link to="/rooms/add" className="btn btn-outline-primary add-list mx-3">
            <i className="las la-plus mr-3"></i>Add Room
          </Link> */}
            <button onClick={() => setShowFilters(!showFilters)} className="btn btn-primary add-list ">
              Filters
            </button>
          </div>
          </div>
        </div> 
      </div>

      {showFilters && (
        <>
          <div className="col-sm-12">
            <div className="card">
              <div className="bg-black-custom card-header d-flex justify-content-between rounded-top">
                <div className="header-title">
                  <h4 className="card-title text-white">Filters</h4>
                </div>
              </div>
              <div className="card-body">
                <form noValidate onSubmit={onSubmit}>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Search</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder=""
                          value={searchQuery}
                          onChange={(e) => setSearchQuery(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>Building/Villa *</label>
                        <select
                          className="form-control"
                          value={buildingId}
                          onChange={(e) => setBuildingId(e.target.value)}
                        >
                          <option value="">All Buildings</option>
                          {buildings?.length > 0 &&
                            buildings.map((building, index) => {
                              if (building.status === '1') {
                                return (
                                  <option key={index} value={building.id}>
                                    {building.name} ({building.ownerName})
                                  </option>
                                );
                              }
                            })}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>Room Status *</label>
                        <select
                          className="form-control"
                          value={roomStatus}
                          onChange={(e) => setRoomStatus(e.target.value)}
                        >
                          <option value="">All Rooms</option>
                          <option value="1">Occupied</option>
                          <option value="2">Free</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                                <label>Status</label>
                                <select
                                  className="form-control"
                                  value={activeStatus}
                                  onChange={(e) => setActiveStatus(e.target.value)}
                                >
                                  <option value="">All</option>
                                  <option value="1">Active</option>
                                  <option value="2">Inactive</option>
                                </select>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </>
      )}
    
      <div className="col-lg-12">
        <div className="table-responsive rounded mb-3">
          <table className="data-table table mb-0 tbl-server-info">
            <thead className="bg-black-custom text-uppercase">
              <tr className="text-white">
                <th>Building/Villa/Camp</th>
                <th>Name</th>
                <th>Rent&nbsp;Status</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {currentRooms?.length > 0 &&
                currentRooms.map((room, index) => {
                  if (
                    (!roomStatus ||
                      (roomStatus == 1
                        ? roomStatus == room.isOnRent
                        : '1' != room.isOnRent)) &&
                    (!buildingId || buildingId == room.buildingId)
                  ) {
                    const tempString =
                      room.buildingName +
                      ' ' +
                      room.ownerName +
                      ' ' +
                      room.name;
                    if (
                      searchQuery &&
                      !tempString.match(new RegExp(searchQuery, 'i'))
                    ) {
                      return null;
                    }
                    if (activeStatus && activeStatus != room.status) {
                      return null;
                    }
                    if(room.status == 1){
                      parseInt(activeCount +=  1)
                    }  else{
                      parseInt(inactiveCount +=  1)
                    }
                    parseInt(count++);
                    return (
                      <tr key={index}>
                        <td>{room.buildingName} ({room.ownerName})</td>
                        <td>
                          <Link to={`/rooms/${room.id}`}>{room.name}</Link>
                        </td>
                        <td>
                          {room.isOnRent == 1 ? (
                            <div className="text-danger font-weight-bold">Occupied</div>
                          ) : (
                            <div className="text-success font-weight-bold">Free</div>
                          )}
                        </td>
                        <td>
                          {room.status == 1 ?  (
                            
                            <div className="text-success font-weight-bold">Active</div>
                          ) :   (
                            <div className="text-danger font-weight-bold">Inactive</div>
                          )}
                        </td>
                        <td>
                        {currentUser &&  currentUser.isSuperAdmin == '1' && (
                          <div className="d-flex align-items-center list-action">
                            <Link
                              className="badge bg-primary mr-2"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Edit"
                              data-original-title="Edit"
                              to={`/rooms/edit/${room.id}`}
                            >
                              <i className="ri-pencil-line mr-0"></i>
                            </Link>
                          
                                <a
                                  onClick={(e) => deleteHandler(e, room.id)}
                                  className="badge bg-primary mr-2"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Delete"
                                  data-original-title="Delete"
                                  href="#"
                                >
                                  <i className="ri-delete-bin-line mr-0"></i>
                                </a>
                            
                          </div>
                        )}
                        </td>
                      </tr>
                    );
                  }
                })}
            </tbody>
          </table>

          <CSVLink
            data={applyFilters(currentRooms)}
            headers={[
              { label: "Building Name", key: "buildingName" },
              { label: "Owner Name", key: "ownerName" },
              { label: "Name", key: "name" },
              { label: "Rent Status", key: "isOnRent"},
              { label: "Status", key: "status" },
            ]}
            filename={"rooms-list.csv"}
            className="hidden"
            ref={tableRef}
          />
        </div>
      </div>
    

      <div className="col-lg-12">
              <div className="row">
              <div className="col-sm-6  text-xl-left text-sm-left text-center">
              {loading ? (
                <div className="text-muted"><p>Loading...</p> </div>
            ) : (
                <div className="col-lg-12">
                <p>Total Number of Rooms: {count}</p>
                <p>Active Rooms: {parseInt(activeCount)}</p>
                <p>InActive Rooms: {parseInt(inactiveCount)}</p>
                </div>
            )}
       
      </div>
      <div className="col-sm-6">
        <nav className="pagination justify-content-xl-end justify-content-sm-end justify-content-center">
          <ul className="pagination">
          <li
            className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
          >
            <button
              className="page-link"
              onClick={() => paginate(currentPage - 1)}
            >
              Previous
            </button>
          </li>
          {renderPageNumbers}
          <li
            className={`page-item ${
              currentPage === Math.ceil(rooms?.length / itemsPerPage)
                ? "disabled"
                : ""
            }`}
          >
            <button
              className="page-link"
              onClick={() => paginate(currentPage + 1)}
            >
              Next
            </button>
          </li>
        </ul>
      </nav>
    </div>
    </div>
    </div>
  </>
);
}

export default ListRooms;
