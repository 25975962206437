import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { getAccounts,deleteAccountTransaction, getAccountTransactions, remove_session, date_time_to_date_format } from "../../../../functions/general";
import ModalImage from "react-modal-image";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Dropdown from 'react-bootstrap/Dropdown';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

function ListAllTransactions(props) {
  const currentUserPrivilege = props.currentUserPrivilege;
  const navigate = useNavigate();
  const [accounts, setAccounts] = useState([]);
  const [accountId, setAccountId] = React.useState("");
  const [transactions, setTransactions] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchStatus, setSearchStatus] = useState("");
  const [showFilters, setShowFilters] = useState(false);
  const [date, setDate] = useState("");
  const [loading, setLoading] = useState(true); 
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(-1);
  let totalTransactions =0;
  const tableRef = useRef();

  useEffect(() => {
    getAccountTransactions().then((res) => {
      if (res && res.status == 200) {
        setLoading(false); 
        setTransactions(res.data.data);
        // console.log(res.data.data.length);
        setItemsPerPage(res.data.data.length);
      } else if (res && res.status == 203) {
        remove_session();
        props.getLoggedInUser();
        navigate("/login");
      }
    });
    getAccounts().then((res) => {
      if (res && res.status == 200) {
       setLoading(false); 
        setAccounts(res.data.data);
      } else if (res && res.status == 203) {
        remove_session();
        props.getLoggedInUser();
        navigate("/login");
      }
    });
    
 }, []);
 
  const onSubmit = (e) => {
    e.preventDefault();
  };
  const deleteHandler = (e, id) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want to delete transaction?")) {
      deleteAccountTransaction(id).then(res => {
        if (res && res.status == 200) {
          setLoading(false); 
        setTransactions(res.data.data);
        setItemsPerPage(res.data.data.length);
        } else if (res && res.status == 405) {
          alert("Transaction cannot be deleted.");
        } else if (res && res.status == 203) {
          remove_session();
          props.getLoggedInUser();
          navigate('/login');
        } else {
          // Handle other cases
        }
      });
    }
  }
  const applyFilters = (transactions) => {
    return transactions.filter(transaction => {
      
      if (accountId && !(accountId == transaction.accountId)) {
        return false;
      }
  
      if (searchStatus && !(searchStatus == transaction.transactionType)) {
        return false;
      }
  
      const tempString = `${transaction.transactionAmount} ${transaction.buildingName} ${transaction.roomName}`;
      if (searchQuery && !tempString.match(new RegExp(searchQuery, 'i'))) {
        return false;
      }
  
      return true;
    });
  };
  
  const handleAccountChange = (selectedOption) => {
    setAccountId(selectedOption);
  };
  // Pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentTransactions = transactions.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const pageNumbers = Array.from(
    { length: Math.ceil(transactions?.length / itemsPerPage) },
    (_, i) => i + 1
  );

  const renderPageNumbers = pageNumbers.map((number) => {
    if (
      number === 1 ||
      number === currentPage ||
      number === currentPage - 1 ||
      number === currentPage + 1 ||
      number === pageNumbers?.length
    ) {
      return (
        <li
          key={number}
          className={`page-item ${currentPage === number ? "active" : ""}`}
        >
          <button className="page-link" onClick={() => paginate(number)}>
            {number}
          </button>
        </li>
      );
    }
    return null;
  });

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleItemsPerPageChange = (e) => {
    const value = Number(e.target.value);
    if (value === -1) {
        // Show all items
        setItemsPerPage(value);
    } else {
        // Set items per page to the selected value
        setItemsPerPage(value);
    }
    setCurrentPage(1); // Reset to first page when changing items per page
};

  const exportToCSV = () => {
    tableRef.current.link.click();
  };

  const exportToExcel = () => {
    const filteredTransactions = applyFilters(currentTransactions);
    const ws = XLSX.utils.json_to_sheet(filteredTransactions);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Transactions List");
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" });
    saveAs(data, "bank-transactions-list.xlsx");
  };

  const exportToPDF = () => {
    const filteredTransactions = applyFilters(currentTransactions);
    const unit = "pt";
    const size = "A4";
    const orientation = "landscape";

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);
    const title = "Bank Transactions List";
    const headers = [
      ["Receipt", "Amount", "Account", "Description", "Transaction Date"],
    ];

    const data = filteredTransactions.map((transaction) => [
      transaction.transactionReceipt,
      transaction.transactionAmount,
      transaction.accountTitle,
      transaction.transactionDescription,
      transaction.transactionDate,
    ]);
    
    // Calculate total amount
    const totalAmount = filteredTransactions.reduce(
      (total, transaction) => total + parseFloat(transaction.transactionAmount),
      0
    );

    // Add the total amount row
    const totalRow = [
      "Total",
      totalAmount.toLocaleString(),
      "",
      "",
      "",
      ""
    ];

    // Append the total row to the data
    data.push(totalRow);
    let content = {
      startY: 50,
      head: headers,
      body: data,
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("bank-transactions-list.pdf");
  };


  return (
    <>
   
      <div className="col-lg-12">
        <div className="d-flex flex-wrap align-items-center justify-content-between mb-4">
          <div>
            <h4 className="mb-3">Transactions List</h4>
          </div>
         
        </div>
      </div>
      
      <div className="col-lg-12">
<div className="row  align-items-end px-3">
    <div className="col-lg-2 col-md-3 col-sm-6">
    <div className="form-group">
          <label>Show per page:</label>
          <select
            className="form-control"
            value={itemsPerPage === transactions.length ? -1 : itemsPerPage}
            onChange={handleItemsPerPageChange}
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={30}>30</option>
            <option value={-1}>Show All</option>
          </select>
        </div>
    </div>
    <div className="col-lg-2 col-md-3 col-sm-6">

<div className="form-group">
<label>Export</label>
<Dropdown className="w-100">
    <Dropdown.Toggle variant="light" id="dropdown-basic" className="w-100">
      Select Export Type
    </Dropdown.Toggle>

    <Dropdown.Menu>
      <Dropdown.Item href="#/action-1" onClick={exportToCSV}>Export to CSV</Dropdown.Item>
      <Dropdown.Item href="#/action-2" onClick={exportToExcel}>Export to Excel</Dropdown.Item>
      <Dropdown.Item href="#/action-3" onClick={exportToPDF}>Export to PDF</Dropdown.Item>
    </Dropdown.Menu>
  </Dropdown>
</div>
</div>
        <div className="col-md-2 col-sm-6">
            <div className="form-group">
            <label>Transaction Type</label>
            <select
                className="form-control"
                value={searchStatus}
                onChange={(e) => setSearchStatus(e.target.value)} >
                <option value="">All</option>
                <option value="DEPOSIT">DEPOSIT</option>
                <option value="WITHDRAW">WITHDRAW</option>
            </select>
            </div>
        </div>
        <div className="col-lg-2 col-md-4 col-sm-6">
            <div className="form-group">
              <label>Accounts</label>
              <select className="form-control" value={accountId} onChange={(e) =>handleAccountChange(e.target.value)}>
                  <option value="">All Accounts</option>
                  { accounts?.length > 0 && accounts.map((account, index) => {
                    return (
                              <option key={index}
                                      value={account.id}>{account.account_no}</option>
                          );
                  })}
              </select>
            </div>
          </div>
  </div> 
</div>

      
      <div className="col-lg-12">
        <div className="table-responsive rounded mb-3">
          <table className="data-table table mb-0 tbl-server-info">
            <thead className="bg-black-custom text-uppercase">
              <tr className="text-white">
                <th>No</th>
                <th>Receipt</th>
                <th>Amount</th>
                <th>Account</th>
                <th>Description</th>
                <th className="text-center">Transaction</th>
                <th className="text-center">Type</th>
                <th>Transaction Date</th>
                <th>Action </th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {currentTransactions.map((transaction, index) => {
                if (accountId && !(accountId == transaction.accountId)) {
                  return;}
                if (searchStatus && !(searchStatus == transaction.transactionType)) {
                  return;}
                    const tempString =
                    transaction.transactionAmount + " " + transaction.buildingName + " " + transaction.roomName;
                  if (searchQuery && !tempString.match(new RegExp(searchQuery, 'i'))) {
                    return null;
                  }
                  if (
                    searchQuery &&
                    !tempString.match(new RegExp(searchQuery, "i"))
                  ) {
                    return null;
                  }
                    let amount = parseInt(transaction.transactionAmount);
                    parseInt(totalTransactions += amount);
                  return (
                    <tr key={index}>
                      <td>{transaction.id}</td>
                      <td>
                        {transaction.transactionReceipt && (
                          <div className="transactionTableImage">
                            <ModalImage
                              small={
                                process.env.REACT_APP_APIPATH +
                                "/api/images/" +
                                transaction.transactionReceipt
                              }
                              large={
                                process.env.REACT_APP_APIPATH +
                                "/api/images/" +
                                transaction.transactionReceipt
                              }
                            />
                          </div>
                        )}
                      </td>
                      <td>{transaction.transactionAmount.toLocaleString()}</td>
                      <td>
                        {transaction.accountTitle}
                      </td>
                    
                      <td className="text-ellipses">
                        {transaction.transactionDescription}
                      </td>
                      <td className="text-center">
                      
                          <span className={`badge ${transaction.transactionBy == 'Local' ? 'badge-dark' : 'light'}`}>
                                {transaction.transactionBy}
                          </span>
                        
                      </td>
                      <td className="text-center">
                      
                            <span className={`badge ${transaction.transactionType == 'DEPOSIT' ? 'badge-success' : 'badge-info'}`}>
                                {transaction.transactionType}
                            </span> <br />
                           <i><small>{transaction.transactionSubType}</small></i> 
                        
                      </td>
                     
                      <td>
                        {date_time_to_date_format(
                          transaction.transactionDate
                        )}
                      </td>
                      <td>
                      <a
                        onClick={(e) => deleteHandler(e, transaction.id)}
                        className="badge bg-primary mr-2 mb-2"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Delete"
                        data-original-title="Delete"
                        href="#"
                      >
                        <i className="ri-delete-bin-line mr-0"></i>
                      </a>
                      </td>
                    </tr>
                  );
                
                
              })}
            </tbody>
            <thead className="bg-black-custom text-uppercase">
              <tr className="text-white">
                  <th></th>
                  <th></th>
                  <th>Total Amount: {parseInt(totalTransactions).toLocaleString()}</th>
                  <th colSpan={6}></th>
              </tr>
            </thead>
          </table>

          <CSVLink
            data={applyFilters(transactions)}
            headers={[
              { label: "Receipt", key: "transactionReceipt" },
              { label: "Amount", key: "transactionAmount" },
              { label: "Account", key: "accountTitle" },
              { label: "Description", key: "transactionDescription" },
              { label: "Transaction Date", key: "transactionDate" },
            ]}
            filename={"bank-transaction-list.csv"}
            className="hidden"
            ref={tableRef}
          />
        </div>
      </div>
      

      <div className="col-lg-6 text-left">
        {loading ? (
                <div className="text-muted"><h3>Loading...</h3> </div>
            ) : (
                <div className="col-lg-12">
                </div>
            )}
        <p>Total Number of Transactions: {applyFilters(transactions)?.length}</p>
      </div>
      <div className="col-lg-6">
        <nav className="pagination justify-content-end">
          <ul className="pagination">
            <li
              className={`page-item ${
                currentPage === 1 ? "disabled" : ""
              }`}
            >
              <button
                className="page-link"
                onClick={() => paginate(currentPage - 1)}
              >
                Previous
              </button>
            </li>
            {renderPageNumbers}
            <li
              className={`page-item ${
                currentPage === Math.ceil(transactions?.length / itemsPerPage)
                  ? "disabled"
                  : ""
              }`}
            >
              <button
                className="page-link"
                onClick={() => paginate(currentPage + 1)}
              >
                Next
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
}

export default ListAllTransactions;
