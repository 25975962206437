import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import DepositChart from "./DepositChart";
import ExpenseChart from "./ExpenseChart";
import RevenueChart from "./RevenueChart";

function Report() {
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 10 }, (_, index) => currentYear - index);
  const [selectedYear, setSelectedYear] = useState(currentYear);

  useEffect(() => {
  }, [selectedYear]);


  const handleReportButtonClick = () => {
    console.log(`Fetching data for ${selectedYear}`);
  };
  return (
      <div className="py-5 w-100">
        <div className="col-lg-12">
          <div className="card card-transparent card-block card-stretch card-height border-none">
            <div className="card-body p-0 mt-lg-2 mt-0">
              <h3 className="mb-3">Reports</h3>
            </div>
          </div>
        </div>

          <div className="container-fluid">
              <div className="row gx-5">
                  <div className="col-md-4 mb-2 ">
                    <div className="bg-white p-3 rounded shadow-sm  d-flex align-items-center">
                      <div><i class="ri-line-chart-fill bg-primary p-2 rounded report-icons"></i></div>
                      <div>
                        <h6>Expense Report</h6>
                        <Link
                          className="text-dark font-weight-bold"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="View"
                          data-original-title="view"
                          to={"/report/expense-chart/"}
                        >
                          <small>View</small>
                        </Link>
                      </div>

                    </div>
                  </div>

                  <div className="col-md-4 mb-2">
                    <div className="bg-white p-3 rounded shadow-sm  d-flex align-items-center">
                      <div>
                      <i class="ri-luggage-deposit-fill bg-primary p-2 rounded report-icons"></i>
                      </div>
                      <div>
                        <h6>Deposit Report</h6>
                        <Link
                          className="text-dark font-weight-bold"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="View"
                          data-original-title="view"
                          to={"/report/deposit-chart/"}
                        >
                          <small>View</small>
                        </Link>
                      </div>

                    </div>
                  </div>

                  <div className="col-md-4 mb-2">
                    <div className="bg-white p-3 rounded shadow-sm  d-flex align-items-center">
                      <div >
                      <i class="ri-bar-chart-2-fill bg-primary p-2 rounded report-icons"></i>
                      </div>
                      <div >
                        <h6> Revenue Report</h6>
                        <Link
                          className="text-dark font-weight-bold"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="View"
                          data-original-title="view"
                          to={"/report/revenue-chart/"}
                        >
                          <small>View</small>
                        </Link>
                      </div>

                    </div>
                  </div>
              </div>
          </div>

          <div className="w-100 mt-5 px-3 graph">
            <div className="row align-items-end mt-5">
              <div className="col-md-6">
              <div className="row mb-3 align-items-end">
                <div className="col-3">
                  <select
                    id="yearDropdown"
                    className="form-select form-control"
                    value={selectedYear}
                    onChange={(e) => setSelectedYear(e.target.value)}
                  >
                    {years.map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </select>
                </div>
                </div>
              </div>
             
            </div>
       
          
        <div className="row">
          <div className="col-lg-4 col-md-6 mb-2">
            <div className="pt-4 px-3 bg-white rounded shadow-sm">
              <h5>Expense Chart {selectedYear}</h5>
              <ExpenseChart  year={selectedYear} />
            </div>
          </div>

          <div className="col-lg-4 col-md-6 mb-2">
            <div className="pt-4 px-3 bg-white rounded shadow-sm">
              <h5>Deposit Report {selectedYear}</h5>
              <DepositChart year={selectedYear}  />
            </div>
          </div>

          <div className="col-lg-4 col-md-6 mb-2">
            <div className="pt-4 px-3 bg-white rounded shadow-sm">
              <h5>Revenue Report {selectedYear}</h5>
              <RevenueChart year={selectedYear}  />
            </div>
          </div>
        </div>
      </div>
         
      </div>
  );
}

export default Report;


