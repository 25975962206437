import React, {useEffect} from "react";
import {addEditEmployee, get_response_or_errors, getEmployee, remove_session} from "../../../functions/general";
import {useNavigate, useSearchParams, useParams} from "react-router-dom";
import Select from "react-select";
import countryList from 'react-select-country-list'

function EditEmployees(props) {
    const navigate = useNavigate();
    const params = useParams();
    const [loading, setLoading] = React.useState(false);
    const [response, setResponse] = React.useState("");
    const [name, setName] = React.useState("");
    const [phone, setPhone] = React.useState("");
    const [salary, setSalary] = React.useState("");
    const [startDate, setStartDate] = React.useState("");
    const [emiratesId, setEmiratesId] = React.useState("");
    const [country, setCountry] = React.useState("");
    const [countryValue, setCountryValue] = React.useState("");
    const [city, setCity] = React.useState("");
    const [state, setState] = React.useState("");
    const [address, setAddress] = React.useState("");
    const [status, setStatus] = React.useState(1);
    const [errors, setErrors] = React.useState("");
    const countries = React.useMemo(() => countryList().getData(), []);
    const countriesOptions = []
    countries?.length > 0 && countries.map((contry) => {
        countriesOptions.push({label: contry.label, value: contry.label})
    })
    useEffect(() => {
        getEmployee(params.id).then(res => {
            if (res && res.status == 200) {
                if(res.data.data){
                    setName(res.data.data.name);
                    setPhone(res.data.data.phone);
                    setSalary(res.data.data.salary);
                    setStartDate(res.data.data.startDate);
                    setEmiratesId(res.data.data.emiratesId);
                    setCountry(res.data.data.country);
                    setCountryValue({label: res.data.data.country, value: res.data.data.country});
                    setCity(res.data.data.city);
                    setState(res.data.data.state);
                    setAddress(res.data.data.address);
                    setStatus(res.data.data.status);
                } else {
                    navigate('/employees');
                }
            } else if (res && res.status == 203) {
                remove_session();
                props.getLoggedInUser();
                navigate('/login');
            }
        })
    }, [])
    const onSubmit = (e) => {
        e.preventDefault()
        setResponse("");
        if (loading)
            return false;
        if (validate()) {
            setLoading(true);
            const employee = {
                id: params.id,
                name: name,
                phone: phone,
                salary: salary,
                startDate: startDate,
                emiratesId: emiratesId,
                country: country,
                city: city,
                state: state,
                address: address,
                status: status
            }
            addEditEmployee(employee).then(res => {
                if (res && res.status == 203) {
                    remove_session();
                    props.getLoggedInUser();
                    navigate('/login');
                } else {
                    setResponse(get_response_or_errors(res))
                    setErrors(get_response_or_errors(res))
                    setLoading(false)
                }
            })
        }
    }
    const validate = () => {
        let errors = {
            name: '',
            phone: '',
            salary: '',
            startDate: '',
            emiratesId: '',
            country: '',
            city: '',
            state: '',
            address: '',
        };
        let isValid = true;
        if (!name) {
            isValid = false;
            errors.name = "Please enter name.";
        }
        if (!phone) {
            isValid = false;
            errors.phone = "Please enter phone.";
        }
        if (!salary) {
            isValid = false;
            errors.salary = "Please enter salary.";
        }
        if (salary && (parseInt(salary) != salary || parseInt(salary) <= 0)) {
            isValid = false;
            errors.salary = "Please enter valid salary.";
        }
        if (!startDate) {
            isValid = false;
            errors.startDate = "Please enter start date.";
        }
        if (!emiratesId) {
            isValid = false;
            errors.emiratesId = "Please enter emirates id.";
        }
        if (!country) {
            isValid = false;
            errors.country = "Please select country.";
        }
        if (!city) {
            isValid = false;
            errors.city = "Please enter city.";
        }
        if (!state) {
            isValid = false;
            errors.state = "Please enter state.";
        }
        if (!address) {
            isValid = false;
            errors.address = "Please enter address.";
        }
        setErrors(errors);
        return isValid;
    }
    const handleCountryChange = (selectedOption) => {
        setCountry(selectedOption.value)
        setCountryValue(selectedOption)
    };
    return (
        <>
        <div className="col-lg-12">
          <div className="d-flex flex-wrap align-items-center justify-content-between mb-4">
              <div>
                  <h4 className="mb-3">Edit Employees</h4>
              </div>
          </div>
        </div>
        <div className="col-sm-12">
          <div className="card">
              <div className="bg-black-custom card-header d-flex justify-content-between rounded-top">
                  <div className="header-title">
                      <h4 className="card-title text-white">Edit Employee</h4>
                  </div>
              </div>
              <div className="card-body">
                  <form noValidate onSubmit={onSubmit}>
                      {response?.responseMessage &&
                      <div
                          className={`alert alert-${response?.responseFlag} fade show`}
                          role="alert">
                          {response?.responseMessage}
                      </div>
                      }
                      <div className="row">
                          <div className="col-md-6">
                              <div className="form-group">
                                  <label>Name *</label>
                                  <input type="text" className="form-control" placeholder="Enter Name"
                                         value={name} onChange={(e) => setName(e.target.value)}/>
                                      <div className="text-warning font-size-12">{errors.name}</div>
                              </div>
                          </div>
                          <div className="col-md-6">
                              <div className="form-group">
                                  <label>Phone Number *</label>
                                  <input type="text" className="form-control" placeholder="Enter Phone Number"
                                         value={phone} onChange={(e) => setPhone(e.target.value)}/>
                                      <div className="text-warning font-size-12">{errors.phone}</div>
                              </div>
                          </div>
                          <div className="col-md-6">
                              <div className="form-group">
                                  <label>Emirates ID/Passport *</label>
                                  <input type="text" className="form-control" placeholder="Enter Emirates Id"
                                         value={emiratesId} onChange={(e) => setEmiratesId(e.target.value)}/>
                                  <div className="text-warning font-size-12">{errors.emiratesId}</div>
                              </div>
                          </div>
                          <div className="col-md-6">
                              <div className="form-group">
                                  <label>Salary *</label>
                                  <input type="text" className="form-control" placeholder="Enter Salary"
                                         value={salary} onChange={(e) => setSalary(e.target.value)}/>
                                  <div className="text-warning font-size-12">{errors.salary}</div>
                              </div>
                          </div>
                          <div className="col-md-6">
                              <div className="form-group">
                                  <label>Start Date *</label>
                                  <input type="date" className="form-control" placeholder="Enter Start Date"
                                         value={startDate} onChange={(e) => setStartDate(e.target.value)}/>
                                  <div className="text-warning font-size-12">{errors.startDate}</div>
                              </div>
                          </div>
                          <div className="col-md-6">
                              <div className="form-group">
                                  <label>Select Country *</label>
                                  <Select
                                      value={countryValue}
                                      onChange={handleCountryChange}
                                      options={countriesOptions}
                                  />
                                  <div className="text-warning font-size-12">{errors.country}</div>
                              </div>
                          </div>
                          <div className="col-md-6">
                              <div className="form-group">
                                  <label>City *</label>
                                  <input type="text" className="form-control" placeholder="Enter City"
                                         value={city} onChange={(e) => setCity(e.target.value)}/>
                                  <div className="text-warning font-size-12">{errors.city}</div>
                              </div>
                          </div>
                          <div className="col-md-6">
                              <div className="form-group">
                                  <label>State *</label>
                                  <input type="text" className="form-control" placeholder="Enter State"
                                         value={state} onChange={(e) => setState(e.target.value)}/>
                                  <div className="text-warning font-size-12">{errors.state}</div>
                              </div>
                          </div>
                          <div className="col-md-12">
                              <div className="form-group">
                                  <label>Address *</label>
                                  <textarea className="form-control" rows="4" value={address}
                                            onChange={(e) => setAddress(e.target.value)}></textarea>
                                  <div className="text-warning font-size-12">{errors.address}</div>
                              </div>
                          </div>
                          <div className="col-md-12">
                              <div className="form-group">
                                  <label>Status *</label>
                                  <select className="form-control"
                                         value={status} onChange={(e) => setStatus(e.target.value)}>
                                      <option value="1">Active</option>
                                      <option value="2">Inactive</option>
                                  </select>
                                  <div className="text-warning font-size-12">{errors.state}</div>
                              </div>
                          </div>
                      </div>
                      <button type="submit" className="btn btn-primary mr-2">Update</button>
                  </form>
              </div>
          </div>
        </div>
        </>
    );
}

export default EditEmployees;
