import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {
    addEditRent,
    get_response_or_errors,
    getBuildings, getCustomers, getRooms,
    remove_session
} from "../../../functions/general";
import Select from 'react-select';

function AddRent(props) {
    Date.prototype.toDateInputValue = (function() {
        var local = new Date(this);
        local.setMinutes(this.getMinutes() - this.getTimezoneOffset());
        return local.toJSON().slice(0,10);
    });
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState(false);
    const [response, setResponse] = React.useState("");
    const [buildings, setBuildings] = React.useState([]);
    const [rooms, setRooms] = React.useState([]);
    const [customers, setCustomers] = React.useState([]);
    const [buildingId, setBuildingId] = React.useState("");
    const [buildingOptions, setBuildingOptions] = React.useState([]);
    const [buildingIdValue, setBuildingIdValue] = React.useState("");
    const [roomId, setRoomId] = React.useState("");
    const [roomOptions, setRoomOptions] = React.useState([]);
    const [roomIdValue, setRoomIdValue] = React.useState("");
    const [customerId, setCustomerId] = React.useState("");
    const [customerOptions, setCustomerOptions] = React.useState([]);
    const [customerIdValue, setCustomerIdValue] = React.useState("");
    const [rent, setRent] = React.useState("");
    const [cheques, setCheques] = React.useState("");
    const [gracePeriod, setGracePeriod] = React.useState(0);
    const [securityDeposit, setSecurityDeposit] = React.useState(0);
    const [rentType, setRentType] = React.useState("");
    const [contractedImage, setContractedImage] = React.useState("");
    const [startDate, setStartDate] = React.useState(new Date().toDateInputValue());
    const [rentStartDate, setRentStartDate] = React.useState(new Date().toDateInputValue());
    const [rentEndDate, setRentEndDate] = React.useState(new Date().toDateInputValue());
    const [overDays, setOverDays] = React.useState(0);
    const [overDaysRent, setOverDaysRent] = React.useState(0);
    const [dewa, setDewa] = React.useState(false);
    const [dewaAccount, setDewaAccount] = React.useState("");
    const [maintenance, setMaintenance] = React.useState(false);
    const [internet, setInternet] = React.useState(false);
    const [internetCharges, setInternetCharges] = React.useState("");
    const [selectedType, setSelectedType] = React.useState("");
    const [detail, setRoomDetail] = React.useState("");
    const [selectedTenancyType, setTenancyType] = React.useState("");
    const [errors, setErrors] = React.useState("");
    useEffect(() => {
        getBuildings().then(res => {
            if (res && res.status == 200) {
                setBuildings(res.data.data);
                const buildingOptionsTemp = [];
                res.data.data?.length > 0 && res.data.data.map((buildingValue) => {
                    buildingOptionsTemp.push({label: buildingValue.name + " ( " + buildingValue.ownerName + " )", value: buildingValue.id})
                })
                setBuildingOptions(buildingOptionsTemp)
            } else if (res && res.status == 203) {
                remove_session();
                props.getLoggedInUser();
                navigate('/login');
            } else {
            }
        })
        getRooms().then(res => {
            if (res && res.status == 200) {
                setRooms(res.data.data);
            } else if (res && res.status == 203) {
                remove_session();
                props.getLoggedInUser();
                navigate('/login');
            } else {
            }
        })
        getCustomers().then(res => {
            if (res && res.status == 200) {
                setCustomers(res.data.data);
                const customerOptionsTemp = [{ label: 'Select Customer', value: '' }];
                res.data.data?.length > 0 && res.data.data.map((customerValue) => {
                    customerOptionsTemp.push({label: customerValue.name + " ( " + customerValue.phone + " )", value: customerValue.id})
                })
                setCustomerOptions(customerOptionsTemp)
            } else if (res && res.status == 203) {
                remove_session();
                props.getLoggedInUser();
                navigate('/login');
            } else {
            }
        })
       
    }, [])
    const onSubmit = (e) => {
        e.preventDefault()
        setResponse("");
        if (loading)
            return false;
        if (validate()) {
            setLoading(true);
            let contractedImageValue = '';
            // let securityDepositValue = 0;
            let securityDepositValue = securityDeposit;
            if(rentType == 2){
                contractedImageValue = contractedImage
                // securityDepositValue = securityDeposit
            }
            const newRent = {
                buildingId: buildingId,
                roomId: roomId,
                customerId: customerId,
                rent: rent,
                cheques: rentType == '2' ? cheques : 0,
                securityDeposit: securityDepositValue,
                gracePeriod:gracePeriod,
                rentType: rentType,
                contractedImage: contractedImageValue,
                startDate: startDate,
                rentStartDate: rentStartDate,
                rentEndDate: rentEndDate,
                overDays: overDays,
                overDaysRent: overDaysRent,
                dewa: dewa,
                dewaAccount:dewaAccount,
                internet:internet,
                internetCharges:internetCharges,
                maintenance: maintenance,
                roomType: selectedType,
                roomDetail:detail,
                tenancyType:selectedTenancyType
            }
            addEditRent(newRent).then(res => {
                if (res && res.status == 200) {
                    navigate("/rent");
                } else if (res && res.status == 203) {
                    remove_session();
                    props.getLoggedInUser();
                    navigate('/login');
                } else {
                    setResponse(get_response_or_errors(res))
                    setErrors(get_response_or_errors(res))
                    setLoading(false)
                }
            })
        }
    }
    const validate = () => {
        let errors = {
            buildingId: '',
            roomId: '',
            customerId: '',
            rent: '',
            cheques: '',
            rentType: '',
            startDate: '',
            overDays: '',
            overDaysRent: '',
            securityDeposit: '',
            gracePeriod: '',
            tenancyType:'',
            selectedType:'',
            detail: '',
            dewaAccount:'',
            internetCharges:'',
        };
        let isValid = true;
        if (!buildingId) {
            isValid = false;
            errors.buildingId = "Please select building.";
        }
        if (!roomId) {
            isValid = false;
            errors.roomId = "Please select room.";
        }
        if (!customerId) {
            isValid = false;
            errors.customerId = "Please select tenant.";
        }
        if (!rent) {
            isValid = false;
            errors.rent = "Please enter rent.";
        }
        if (rent && (parseInt(rent) != rent || parseInt(rent) <= 0)) {
            isValid = false;
            errors.rent = "Please enter valid rent.";
        }
        if (!rentType) {
            isValid = false;
            errors.rentType = "Please select rent type.";
        }
        if (!selectedTenancyType) {
            isValid = false;
            errors.tenancyType = "Please select tenancy type.";
        }
        if (rentType == '2' && !cheques) {
            isValid = false;
            errors.cheques = "Please add no of cheques.";
        }
        if (rentType == '2' && cheques && (parseInt(cheques) != cheques || parseInt(cheques) <= 0)) {
            isValid = false;
            errors.cheques = "Please enter valid cheques.";
        }
        if (rentType == '2' && securityDeposit && (parseInt(securityDeposit) != securityDeposit || parseInt(securityDeposit) <= 0)) {
            isValid = false;
            errors.securityDeposit = "Please enter valid security deposit.";
        }
        if (!startDate) {
            isValid = false;
            errors.startDate = "Please enter start date.";
        }
        if (!selectedType) {
            isValid = false;
            errors.selectedType = "Please enter Room Type.";
        }
        if((selectedType && selectedType == "furnished") && !detail){
            isValid = false;
            errors.detail = "Please enter Room Detail.";
        }
        if(dewa && !dewaAccount){
            isValid = false;
            errors.dewaAccount = "Please enter Dewa Account.";
        }
        if(internet && !internetCharges){
            isValid = false;
            errors.internetCharges = "Please enter Internet Charges.";
        }
        if ((parseInt(overDays) != overDays || parseInt(overDays) < 0)) {
            isValid = false;
            errors.overDays = "Please enter valid value.";
        }
        if ((parseInt(overDaysRent) != overDaysRent || parseInt(overDaysRent) < 0)) {
            isValid = false;
            errors.overDaysRent = "Please enter valid value.";
        }
        setErrors(errors);
        return isValid;
    }
    const imageUploadHandler = (e) => {
        var file = e.target.files[0];
        console.log("file",file)
        var reader = new FileReader();
        var url = reader.readAsDataURL(file);

        reader.onloadend = function (e) {
            setContractedImage(reader.result)

        }.bind(this);
    }
    const handleBuildingChange = (selectedOption) => {
        setBuildingId(selectedOption.value)
        setBuildingIdValue(selectedOption)
        setRoomId('')
        setRoomIdValue('')
        const roomOptionsTemp = [];
        rooms?.length > 0 && rooms.map((roomValue) => {
            if(roomValue.status == '1' && roomValue.buildingId == selectedOption.value && roomValue.isOnRent == 0) {
                roomOptionsTemp.push({label: roomValue.name, value: roomValue.id})
            }
        })
        setRoomOptions(roomOptionsTemp)
    };
    const handleRoomChange = (selectedOption) => {
        setRoomId(selectedOption.value)
        setRoomIdValue(selectedOption)
    };
    const handleCustomerChange = (selectedOption) => {
        setCustomerId(selectedOption.value)
        setCustomerIdValue(selectedOption)
        
       
    };
    const handleTypeSelectChange = (e) => {
        setSelectedType(e.target.value);
        setRoomDetail('');
    };
    const handleDewaStatusChange = (e) => {
    };
    return (
        <>
            <div className="col-lg-12">
                <div className="d-flex flex-wrap align-items-center justify-content-between mb-4">
                    <div>
                        <h4 className="mb-3">Add Rent Contract</h4>
                    </div>
                </div>
            </div>
            <div className="col-sm-12">
                <div className="card">
                    <div className="bg-black-custom card-header d-flex justify-content-between rounded-top">
                        <div className="header-title">
                            <h4 className="card-title text-white">Add New Rent Contract</h4>
                        </div>
                    </div>
                    <div className="card-body">
                        <form noValidate onSubmit={onSubmit}>
                            {response?.responseMessage &&
                            <div
                                className={`alert alert-${response?.responseFlag} fade show`}
                                role="alert">
                                {response?.responseMessage}
                            </div>
                            }
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Building/Villa/Camp *</label>
                                        <Select
                                            value={buildingIdValue}
                                            onChange={handleBuildingChange}
                                            options={buildingOptions}
                                        />
                                        <div className="text-warning font-size-12">{errors.buildingId}</div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Room *</label>
                                        <Select
                                            value={roomIdValue}
                                            onChange={handleRoomChange}
                                            options={roomOptions}
                                        />
                                        <div className="text-warning font-size-12">{errors.roomId}</div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Tenant *</label>
                                        <Select
                                            value={customerIdValue}
                                            onChange={handleCustomerChange}
                                            options={customerOptions}
                                        />
                                        <div className="text-warning font-size-12">{errors.customerId}</div>
                                    </div>
                                </div>
                                {/* <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Building *</label>
                                        <input type="text" className="form-control" placeholder="Enter Building"
                                               value={buildingIdValue}  readOnly/>
                                    </div>
                                    <div className="text-warning font-size-12">{errors.buildingId}</div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Room *</label>
                                        <input type="text" className="form-control" placeholder="Enter Room"
                                               value={roomIdValue}  readOnly/>
                                     
                                    </div>
                                    <div className="text-warning font-size-12">{errors.roomId}</div>
                                </div> */}
                                  {/* Type */}
                                <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="room_type">Room Type *:</label>
                                            <select id="room_type" value={selectedType} className="form-control" onChange={handleTypeSelectChange}>
                                                <option value="" >Select</option>
                                                <option value="furnished">Furnished</option>
                                                <option value="unfurnished">Unfurnished</option>
                                            </select>
                                            <div className="text-warning font-size-12">{errors.selectedType}</div>
                                        </div>
                                </div>
                                {/* if type is company */}
                                {selectedType == "furnished" && (
                                <div className="col-md-6">
                                    <div className="form-group">
                                 
                                        <div>
                                            <label htmlFor="furnished">Details *:</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                id="furnished"
                                                value={detail} onChange={(e) => setRoomDetail(e.target.value)}/>
                                            
                                            <div className="text-warning font-size-12">{errors.detail}</div>
                                        </div>
                                   
                                    </div>
                                </div>  )}
                                <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="tenency_type">Tenancy Type *:</label>
                                            <select id="tenency_type" value={selectedTenancyType} className="form-control" onChange={(e) => setTenancyType(e.target.value)}>
                                                <option value="" >Select</option>
                                                <option value="Full Space">Full Space</option>
                                                <option value="Bed Space">Bed Space</option>
                                            </select>
                                            <div className="text-warning font-size-12">{errors.tenancyType}</div>
                                        </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Rent Type *</label>
                                        <select className="form-control" value={rentType} onChange={(e) => setRentType(e.target.value)}>
                                            <option value="">Select Rent Type</option>
                                            <option value="1">Monthly</option>
                                            <option value="2">Yearly</option>
                                        </select>
                                        <div className="text-warning font-size-12">{errors.rentType}</div>
                                    </div>
                                </div>                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Rent *</label>
                                        <input type="text" className="form-control" placeholder="Enter Rent"
                                               value={rent} onChange={(e) => setRent(e.target.value)}/>
                                        <div className="text-warning font-size-12">{errors.rent}</div>
                                    </div>
                                </div>
                               
                                {rentType == "2" && (<>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>Contracted Image</label>
                                            <div className="custom-file">
                                                <input type="file" className="custom-file-input" id="receipt" onChange={e => imageUploadHandler(e)}  accept="image/*"/>
                                                <label className="custom-file-label" htmlFor="receipt">Choose
                                                    file</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        {contractedImage && (
                                            <img className="border rounded" style={{maxHeight: '100px'}}
                                                 src={contractedImage}
                                                 alt=""/>
                                        )}
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>No of cheques *</label>
                                            <input type="text" className="form-control" placeholder="Enter Rent"
                                                   value={cheques} onChange={(e) => setCheques(e.target.value)}/>
                                            <div className="text-warning font-size-12">{errors.cheques}</div>
                                        </div>
                                    </div>
                                </>)}
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Start Date *</label>
                                        <input type="date" className="form-control" placeholder="Enter Start Date"
                                               value={startDate} onChange={(e) => setStartDate(e.target.value)}/>
                                        <div className="text-warning font-size-12">{errors.startDate}</div>
                                    </div>
                                </div>
                                {rentType == "2" && (<>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Rent Start Date</label>
                                        <input type="date" className="form-control" placeholder="Enter Start Date"
                                               value={rentStartDate} onChange={(e) => setRentStartDate(e.target.value)}/>
                                        <div className="text-warning font-size-12">{errors.rentStartDate}</div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Rent End Date</label>
                                        <input type="date" className="form-control" placeholder="Enter Start Date"
                                               value={rentEndDate} onChange={(e) => setRentEndDate(e.target.value)}/>
                                        <div className="text-warning font-size-12">{errors.rentEndDate}</div>
                                    </div>
                                </div>
                                </>)}

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Over Days</label>
                                        <input type="text" className="form-control" placeholder="Enter Over Days"
                                               value={overDays} onChange={(e) => setOverDays(e.target.value)}/>
                                        <div className="text-warning font-size-12">{errors.overDays}</div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Over Days Rent</label>
                                        <input type="text" className="form-control" placeholder="Enter Over Days Rent"
                                               value={overDaysRent} onChange={(e) => setOverDaysRent(e.target.value)}/>
                                        <div className="text-warning font-size-12">{errors.overDaysRent}</div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Grace period (days)</label>
                                        <input type="number" className="form-control" placeholder="Enter Grace period (days)"
                                            value={gracePeriod} onChange={(e) => setGracePeriod(e.target.value)}/>
                                        <div className="text-warning font-size-12">{errors.gracePeriod}</div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Security Deposit</label>
                                        <input type="number" className="form-control" placeholder="Enter Security Deposit"
                                            value={securityDeposit} onChange={(e) => setSecurityDeposit(e.target.value)}/>
                                        <div className="text-warning font-size-12">{errors.securityDeposit}</div>
                                    </div>
                                </div>

                                
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <div className="checkbox">
                                            <label>Dewa Included?</label>
                                            <input type="checkbox" className="form-control" checked={dewa}
                                                   onChange={(e) => setDewa(!dewa)} />
                                        </div>
                                    </div>
                                </div>
                                { dewa && (
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Dewa Account</label>
                                            <input type="text" className="form-control" placeholder="Enter Dewa Account"
                                             value={dewaAccount} onChange={(e) => setDewaAccount(e.target.value)} />
                                            <div className="text-warning font-size-12">{errors.dewaAccount}</div>
                                        </div>
                                    </div>
                                )}
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <div className="checkbox">
                                            <label>Maintenance Charges Included?</label>
                                            <input type="checkbox" className="form-control" checked={maintenance}
                                                   onChange={(e) => setMaintenance(!maintenance)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <div className="checkbox">
                                            <label>Internet Included?</label>
                                            <input type="checkbox" className="form-control" checked={internet}
                                                   onChange={(e) => setInternet(!internet)} />
                                        </div>
                                    </div>
                                </div>
                                { internet && (
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Internet Charges</label>
                                            <input type="text" className="form-control" placeholder="Enter Internet Charges"
                                             value={internetCharges} onChange={(e) => setInternetCharges(e.target.value)} />
                                            <div className="text-warning font-size-12">{errors.internetCharges}</div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <button type="submit" className="btn btn-primary mr-2">Add</button>
                        </form>
                    </div>
                </div>
            </div>
        </> 
    );
}

export default AddRent;
