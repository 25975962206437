import React, { useEffect, useState, useRef } from "react";
import {Link, useNavigate} from "react-router-dom";
import {
    collectedTransaction,
    date_time_to_date_format,
    deleteAdmin,
    deleteTransaction,
    getTransactions,
    remove_session
} from "../../../functions/general";
import ModalImage from "react-modal-image";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Dropdown from 'react-bootstrap/Dropdown';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

function ListAllTransactions(props) {
    const currentUser = props.currentUser;
    const userType = (currentUser && currentUser.isSuperAdmin == 1)? 'superadmin': 'admin';
    const navigate = useNavigate();
    const [transactions, setTransactions] = React.useState([]);
    const [showFilters, setShowFilters] = React.useState(false);
    const [rentCollected, setRentCollected] = React.useState("");
    const [collectedBy, setCollectedBy] = React.useState("");
    const [collecters, setCollecters] = React.useState("");
    const [date, setDate] = React.useState("");
    const [endDate, setEndDate] = React.useState("");
    const [searchQuery, setSearchQuery] = React.useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(-1);
    const tableRef = useRef();
    let collectedBys = [];
    let collectedIds = [];
    useEffect(() => {
        getTransactions().then(res => {
            if (res && res.status == 200) {
                collectedBys = [];
                collectedIds = [];
                if(res.data.data && res.data.data?.length){
                    res.data.data.forEach(value => {
                        if(value.collectorStatus == 1 && !collectedIds.includes(value.collectorId)){
                            collectedIds.push(value.collectorId)
                            collectedBys.push({"collectorId": value.collectorId, "collectorName": value.collectorName})
                        }
                    })
                }
                setCollecters(collectedBys);
                setTransactions(res.data.data);
                setItemsPerPage(res.data.data.length);
                setCollectedBy("");
            } else if (res && res.status == 203) {
                remove_session();
                props.getLoggedInUser();
                navigate('/login');
            } else {
            }
        })
    }, [])
    const onSubmit = (e) => {
        e.preventDefault()
    }
    const collectedHandler = (e, id) => {
        e.preventDefault()
        if (window.confirm("Are you sure you want collected rent?") == true) {
            collectedTransaction(id).then(res => {
                if (res && res.status == 200) {
                    getTransactions().then(res => {
                        if (res && res.status == 200) {
                            setTransactions(res.data.data);
                        } else if (res && res.status == 203) {
                            remove_session();
                            props.getLoggedInUser();
                            navigate('/login');
                        } else {
                        }
                    })
                } else if (res && res.status == 203) {
                    remove_session();
                    props.getLoggedInUser();
                    navigate('/login');
                }
            })
        }
    }
    const handleDeleteClick = (id) => {
        if (window.confirm("Are you sure you want to delete transaction?")) {
          deleteTransaction({id}).then(res => {
            if (res && res.status === 200)  if (res && res.status == 200) {
                collectedBys = [];
                collectedIds = [];
                if(res.data.data && res.data.data?.length){
                    res.data.data.forEach(value => {
                        if(!collectedIds.includes(value.collectorId)){
                            collectedIds.push(value.collectorId)
                            collectedBys.push({"collectorId": value.collectorId, "collectorName": value.collectorName})
                        }
                    })
                }
                setCollecters(collectedBys);
                setTransactions(res.data.data);
                setCollectedBy("");
            }  else if (res && res.status === 405) {
              alert("Transaction cannot be deleted.");
            } else if (res && res.status === 203) {
              remove_session();
              props.getLoggedInUser();
              navigate('/login');
            } else {
              // Handle other cases
            }
          });
        }
      };
    
   // Pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentTransactions = transactions.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const pageNumbers = Array.from(
    { length: Math.ceil(transactions?.length / itemsPerPage) },
    (_, i) => i + 1
  );

  const renderPageNumbers = pageNumbers.map((number) => {
    if (
      number === 1 ||
      number === currentPage ||
      number === currentPage - 1 ||
      number === currentPage + 1 ||
      number === pageNumbers?.length
    ) {
      return (
        <li
          key={number}
          className={`page-item ${currentPage === number ? "active" : ""}`}
        >
          <button className="page-link" onClick={() => paginate(number)}>
            {number}
          </button>
        </li>
      );
    }
    return null;
  });

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleItemsPerPageChange = (e) => {
    const value = Number(e.target.value);
    if (value === -1) {
        // Show all items
        setItemsPerPage(value);
    } else {
        // Set items per page to the selected value
        setItemsPerPage(value);
    }
    setCurrentPage(1); // Reset to first page when changing items per page
};

const applyFilters = (transactions) => {
    return transactions.filter(transaction => {
      if (transaction.roomId > 0 && (transaction.transactionType == 1 || transaction.transactionType == 9|| transaction.transactionType == 10 || transaction.transactionType == 11)) {
        if (date) {
          const d1 = new Date(date);
          const d2 = new Date(transaction.transactionDate);
          if (d2.getTime() < d1.getTime()) {
            return false;
          }
        }
        if (endDate) {
          const d1 = new Date(endDate);
          const d2 = new Date(transaction.transactionDate);
          if (d2.getTime() > d1.getTime()) {
            return false;
          }
        }
        if (rentCollected && rentCollected != transaction.transactionCollected) {
          return false;
        }
        if (collectedBy && collectedBy != transaction.collectorId) {
          return false;
        }
        const tempString = transaction.transactionAmount + " " + transaction.buildingName + " " + transaction.roomName + " " +
          transaction.transactionDescription + " " + transaction.collectorName;
        if (searchQuery && !tempString.match(new RegExp(searchQuery, 'i'))) {
          return false;
        }
        return true;
      } else {
        return false;
      }
    });
  };
  
  const exportToCSV = () => {
    tableRef.current.link.click();
  };

  const exportToExcel = () => {
    const filteredTransactions = applyFilters(currentTransactions);
    const ws = XLSX.utils.json_to_sheet(filteredTransactions);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Transactions List");
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" });
    saveAs(data, "transactions-list.xlsx");
  };

  const exportToPDF = () => {
    const filteredTransactions = applyFilters(currentTransactions);
    const unit = "pt";
    const size = "A4";
    const orientation = "landscape";

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);
    const title = "Transactions List";
    const headers = [
      ["Receipt", "Amount", "Building", "Room", "Description", "Date","Approved","CollectedBy"],
    ];

    const data = filteredTransactions.map((transaction) => [
      transaction.transactionReceipt,
      transaction.transactionAmount,
      transaction.buildingName,
      transaction.roomName,
      transaction.transactionDescription,
      transaction.transactionDate,
      (transaction.transactionCollected == 1) ? "Yes" : "No",
      transaction.collectorName
    ]);

    // Calculate total amount
    const totalAmount = filteredTransactions.reduce(
      (total, transaction) => total + parseFloat(transaction.transactionAmount),
      0
    );

    // Add the total amount row
    const totalRow = [
      "Total",
      totalAmount.toLocaleString(),
      "",
      "",
      "",
      ""
    ];

    // Append the total row to the data
    data.push(totalRow);
    let content = {
      startY: 50,
      head: headers,
      body: data,
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("renttransactions-list.pdf");
  };

  return (
      <>
          <div className="col-lg-12">
              <div className="d-flex flex-wrap align-items-center justify-content-between mb-4">
                  <div>
                      <h4 className="mb-3">Rent Transactions List</h4>
                  </div>
              </div>
          </div>
          <div className="col-lg-12">
              <div className="d-flex flex-wrap align-items-center justify-content-end mb-4">
                  <button onClick={() => setShowFilters(!showFilters)} className="btn btn-primary add-list">Filters</button>
              </div>
          </div>

          { showFilters && (
              <>
                  <div className="col-sm-12">
                      <div className="card">
                          <div className="bg-black-custom card-header d-flex justify-content-between rounded-top">
                              <div className="header-title">
                                  <h4 className="card-title text-white">Filters</h4>
                              </div>
                          </div>
                          <div className="card-body">
                              <form noValidate onSubmit={onSubmit}>
                                  <div className="row">
                                      <div className="col-md-12">
                                          <div className="form-group">
                                              <label>Search</label>
                                              <input type="text" className="form-control" placeholder=""
                                                     value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)}/>
                                          </div>
                                      </div>
                                      <div className="col-md-6">
                                          <div className="form-group">
                                              <label>Start Date</label>
                                              <input type="date" className="form-control" placeholder="Enter Date"
                                                     value={date} onChange={(e) => setDate(e.target.value)}/>
                                          </div>
                                      </div>
                                      <div className="col-md-6">
                                          <div className="form-group">
                                              <label>End Date</label>
                                              <input type="date" className="form-control" placeholder="Enter Date"
                                                     value={endDate} onChange={(e) => setEndDate(e.target.value)}/>
                                          </div>
                                      </div>
                                      <div className="col-md-6">
                                          <div className="form-group">
                                              <label>Approved</label>
                                              <select className="form-control" value={rentCollected} onChange={(e) => setRentCollected(e.target.value)}>
                                                  <option value="">All</option>
                                                  <option value="1">Yes</option>
                                                  <option value="0">No</option>
                                              </select>
                                          </div>
                                      </div>
                                      <div className="col-md-6">
                                          <div className="form-group">
                                              <label>Collected By</label>
                                              <select className="form-control" value={collectedBy} onChange={(e) => setCollectedBy(e.target.value)}>
                                                  <option value="">All</option>
                                                  { collecters?.length > 0 && collecters.map((value, index) => {
                                                      return (<option key={index} value={value.collectorId}>{value.collectorName}</option>)
                                                  })}
                                              </select>
                                          </div>
                                      </div>
                                  </div>
                              </form>
                          </div>
                      </div>
                  </div>
              </>
          )}
            <div className="col-lg-12">
                <div className="row  align-items-end px-3">
                    <div className="col-lg-2 col-md-3 col-sm-6">
                        <div className="form-group">
                        <label>Show per page:</label>
                        <select
                            className="form-control"
                            value={itemsPerPage == currentTransactions.length ? -1 : itemsPerPage}
                            onChange={handleItemsPerPageChange}
                        >
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                            <option value={30}>30</option>
                            <option value={-1}>Show All</option>
                        </select>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-3 col-sm-6">
                        <div className="form-group">
                        <label>Export</label>
                        <Dropdown className="w-100">
                            <Dropdown.Toggle variant="light" id="dropdown-basic" className="w-100">
                            Select Export Type
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                            <Dropdown.Item href="#/action-1" onClick={exportToCSV}>Export to CSV</Dropdown.Item>
                            <Dropdown.Item href="#/action-2" onClick={exportToExcel}>Export to Excel</Dropdown.Item>
                            <Dropdown.Item href="#/action-3" onClick={exportToPDF}>Export to PDF</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        </div>
                    </div>
                </div> 
            </div>
          <div className="col-lg-12">
              <div className="table-responsive rounded mb-3">
                  <table className="data-table table mb-0 tbl-server-info">
                      <thead className="bg-black-custom text-uppercase">
                      <tr className="text-white">
                          <th>Receipt</th>
                          <th>Amount</th>
                          <th>Building</th>
                          <th>Tenant Name</th>
                          <th>Room</th>
                          <th>Description</th>
                          <th>Date</th>
                          <th>Approved</th>
                          <th className="text-center">Type</th>
                          <th>Collected By</th>
                          <th>Action</th>
                      </tr>
                      </thead>
                      <tbody className="bg-white">
                      {currentTransactions?.length > 0 && currentTransactions.map((transaction, index) => {
                
                          if(transaction.roomId > 0 && (transaction.transactionType == 1 || transaction.transactionType == 9|| transaction.transactionType == 10 || transaction.transactionType == 11)) {
                              if(date){
                                  const d = new Date();
                                  var d1 = new Date(date);
                                  var d2 = new Date(transaction.transactionDate);
                                  if(d2.getTime() < d1.getTime()) {
                                      return;
                                  }
                              }
                              if(endDate){
                                  const d = new Date();
                                  var d1 = new Date(endDate);
                                  var d2 = new Date(transaction.transactionDate);
                                  if(d2.getTime() > d1.getTime()) {
                                      return;
                                  }
                              }
                              if(rentCollected && rentCollected != transaction.transactionCollected){
                                  return;
                              }
                              if(collectedBy && collectedBy != transaction.collectorId){
                                  return;
                              }
                              const tempString =
                                  transaction.transactionAmount + " " + transaction.buildingName + " " + transaction.roomName + " " +
                                  transaction.transactionDescription + " " + transaction.collectorName
                              if(searchQuery && !tempString.match(new RegExp(searchQuery, 'i'))){
                                  return;
                              }
                              return (
                                  <tr key={index}>
                                      <td>
                                          {transaction.transactionReceipt &&
                                          <div className="transactionTableImage">
                                              <ModalImage
                                                  small={process.env.REACT_APP_APIPATH + "/api/images/" + transaction.transactionReceipt}
                                                  large={process.env.REACT_APP_APIPATH + "/api/images/" + transaction.transactionReceipt}
                                              />
                                          </div>
                                          }
                                          {/*<img className="transactionTableImage" src={process.env.REACT_APP_APIPATH + "/api/images/" + transaction.transactionReceipt}/>*/}
                                      </td>
                                      <td>{transaction.transactionAmount}</td>
                                      <td>{transaction.buildingId > 0 ? transaction.buildingName : ''}</td>
                                      <td>{transaction.customerName }</td>
                                      <td>{transaction.roomId > 0 ? transaction.roomName : ''}</td>
                                      <td className="text-ellipses">{transaction.transactionDescription}</td>
                                      <td>{date_time_to_date_format(transaction.transactionDate)}</td>
                                      <td>{transaction.transactionCollected == 1 ? "Yes" : "No"}</td>
                                      <td className="text-center">
                                        <span className="badge badge-light">{transaction.transactionType == 1 && 'Rent'}</span>
                                        <span className="badge badge-info">{transaction.transactionType == 2 && 'Dewa'}</span>
                                        <span className="badge badge-dark">{transaction.transactionType == 3 && 'Maintenance'}</span>
                                        <span className="badge badge-success">{transaction.transactionType == 4 && 'Salary'}</span>
                                        <span className="badge badge-primary">{transaction.transactionType == 5 && 'Other Expense'}</span>
                                        <span className="badge badge-warning">{transaction.transactionType == 6 && 'Security Refund'}</span>
                                        <span className="badge badge-secondary">{transaction.transactionType == 7 && 'Security Deposit'}</span>
                                        <span className="badge badge-danger">{transaction.transactionType == 8 && 'Advance Payment'}</span>
                                        <span className="badge badge-light">{transaction.transactionType == 9 && 'Rent Received'}</span>
                                        <span className="badge badge-light">{transaction.transactionType == 10 && 'Rent Adjustment'}</span>
                                        <span className="badge badge-light">{transaction.transactionType == 11 && 'Rent Unpaid'}</span>
                                        
                                      </td>
                                      <td>{transaction.collectorName}</td>
                                      <td>
                                      <div className="d-flex align-items-center list-action">
                                          {transaction.transactionCollected != 1 &&
                                              <a onClick={e => collectedHandler(e, transaction.id)}
                                                 className="badge bg-success mr-2" data-toggle="tooltip"
                                                 data-placement="top"
                                                 title="Collect Rent" data-original-title="Delete"
                                                 href="#"><i className="ri-pencil-line mr-0"></i></a>
                                          }
                                          { userType == 'superadmin' && 
                                          <span
                                            className="badge bg-primary"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title="Delete"
                                            onClick={() => handleDeleteClick(transaction.id)}
                                            style={{ cursor: 'pointer' }}
                                            >
                                            <i className="ri-delete-bin-line mr-0"></i>
                                            </span>
                                          }
                                         
                                            </div>

                                      </td>
                                  </tr>
                              );
                          }
                      })}
                      </tbody>
                      <thead className="bg-black-custom text-uppercase">
                      <tr className="text-white">
                          <th></th>
                          <th>{currentTransactions.reduce((accumulator, object) => {
                            if(object.roomId > 0 && (object.transactionType == 1 || object.transactionType == 9|| object.transactionType == 10 || object.transactionType == 11)) {
                                  if (date) {
                                      var d1 = new Date(date);
                                      var d2 = new Date(object.transactionDate);
                                      if (d2.getTime() < d1.getTime()) {
                                          return accumulator + 0;
                                      }
                                  }
                                  if (endDate) {
                                      var d1 = new Date(endDate);
                                      var d2 = new Date(object.transactionDate);
                                      if (d2.getTime() > d1.getTime()) {
                                          return accumulator + 0;
                                      }
                                  }
                                  if (rentCollected && rentCollected != object.transactionCollected) {
                                      return accumulator + 0;
                                  }
                                  if (collectedBy && collectedBy != object.collectorId) {
                                      return accumulator + 0;
                                  }
                                  return accumulator + parseInt(object.transactionAmount);
                              }else{
                                  return accumulator + 0;
                              }
                              }, 0)}</th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                      </tr>
                      </thead>
                  </table>
                  <CSVLink
            data={applyFilters(currentTransactions)}
            headers={[
              { label: "Receipt", key: "transactionReceipt" },
              { label: "Ammount", key: "transactionAmount" },
              { label: "Building", key: "buildingName" },
              { label: "Room", key: "roomName" },
              { label: "Description", key: "transactionDescription" },
              { label: "Start Date", key: "transactionDate" },
            ]}
            filename={"renttransaction-list.csv"}
            className="hidden"
            ref={tableRef}
          />
              </div>
          </div>
          <div className="col-lg-6 text-left">
        <p>Total Number of Transactions: {applyFilters(currentTransactions)?.length}</p>
      </div>
      <div className="col-lg-6">
        <nav className="pagination justify-content-end">
          <ul className="pagination">
            <li
              className={`page-item ${
                currentPage === 1 ? "disabled" : ""
              }`}
            >
              <button
                className="page-link"
                onClick={() => paginate(currentPage - 1)}
              >
                Previous
              </button>
            </li>
            {renderPageNumbers}
            <li
              className={`page-item ${
                currentPage === Math.ceil(transactions?.length / itemsPerPage)
                  ? "disabled"
                  : ""
              }`}
            >
              <button
                className="page-link"
                onClick={() => paginate(currentPage + 1)}
              >
                Next
              </button>
            </li>
          </ul>
        </nav>
      </div>
      </>
  );
}

export default ListAllTransactions;
