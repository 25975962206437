import React, {useEffect, useRef} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {
    addEditBuilding,
    get_response_or_errors, getBuilding,
    getOwners,
    remove_session
} from "../../../functions/general";
import Select from 'react-select';

function EditBuildingsVillas(props) {
    Date.prototype.toDateInputValue = (function() {
        var local = new Date(this);
        local.setMinutes(this.getMinutes() - this.getTimezoneOffset());
        return local.toJSON().slice(0,10);
    });
    const navigate = useNavigate();
    const params = useParams();
    const idCounter = useRef(0);
    const [loading, setLoading] = React.useState(false);
    const [response, setResponse] = React.useState("");
    const [owners, setOwners] = React.useState([]);
    const [ownerId, setOwnerId] = React.useState("");
    const [ownersOptions, setOwnersOptions] = React.useState([]);
    const [ownerIdValue, setOwnerIdValue] = React.useState("");
    const [name, setName] = React.useState("");
    const [city, setCity] = React.useState("");
    const [state, setState] = React.useState("");
    const [address, setAddress] = React.useState("");
    const [rent, setRent] = React.useState("");
    const [cheques, setCheques] = React.useState("");
    const [accountNumbers, setAccountNumbers] = React.useState([]);
    const [gracePeriod, setGracePeriod] = React.useState(0);
    const [securityDeposit, setSecurityDeposit] = React.useState(0);
    const [rentType, setRentType] = React.useState("");
    const [contractedImage, setContractedImage] = React.useState("");
    const [startDate, setStartDate] = React.useState(new Date().toDateInputValue());
    const [endDate, setEndDate] = React.useState(new Date().toDateInputValue());
    const [dewa, setDewa] = React.useState(false);
    const [numDewaAccounts, setNumDewaAccounts] = React.useState(0);
    const [dewaAccounts, setDewaAccounts] = React.useState([]);
    const [maintenance, setMaintenance] = React.useState(false);
    const [status, setStatus] = React.useState(1);
    const [errors, setErrors] = React.useState("");
    useEffect(() => {
        getOwners().then(res => {
            if (res && res.status == 200) {
                setOwners(res.data.data);
                const ownersOptionsTemp = [];
                res.data.data?.length > 0 && res.data.data.map((ownerValue) => {
                    ownersOptionsTemp.push({label: ownerValue.name + " " + ownerValue.phone, value: ownerValue.id})
                })
                setOwnersOptions(ownersOptionsTemp)
            } else if (res && res.status == 203) {
                remove_session();
                props.getLoggedInUser();
                navigate('/login');
            } else {
            }
        })
        getBuilding(params.id).then(res => {
            if (res && res.status == 200) {
                if(res.data.data){
                    setOwnerId(res.data.data.ownerId);
                    setOwnerIdValue({label: res.data.data.ownerName + " " + res.data.data.phone, value: res.data.data.ownerId});
                    setName(res.data.data.name);
                    setCity(res.data.data.city);
                    setState(res.data.data.state);
                    setAddress(res.data.data.address);
                    setRent(res.data.data.rent);
                    setRentType(res.data.data.rentType);
                    setCheques(res.data.data.cheques);
                    const fetchedAccountNumbers = JSON.parse(res.data.data.accountNumbers);
                    setAccountNumbers(fetchedAccountNumbers);

                    // Update the idCounter to continue from the last ID used
                    if (fetchedAccountNumbers.length > 0) {
                
                        idCounter.current = Math.max(...fetchedAccountNumbers.map(account => account.id)) + 1;
                    }else{
                        setAccountNumbers([{ id: idCounter.current++, value: '' }]);
                    }
                   
                    setSecurityDeposit(res.data.data.securityDeposit);
                    setGracePeriod(res.data.data.gracePeriod);
                    // console.log(res.data.data.contractedImage);
                    if(res.data.data.contractedImage) {
                       
                        setContractedImage(process.env.REACT_APP_APIPATH + "/api/images/" + res.data.data.contractedImage);
                    }
                    setDewa(res.data.data.dewa);
                    if (res.data.dewaAccounts && res.data.dewaAccounts.length > 0) {
                        setNumDewaAccounts(res.data.dewaAccounts.length);
                        setDewaAccounts(res.data.dewaAccounts.map(account => account.dewa_account));
                    }
                    setMaintenance(res.data.data.maintenance);
                    setStartDate(res.data.data.startDate);
                    setEndDate(res.data.data.endDate);
                    setStatus(res.data.data.status);
                } else {
                    navigate('/admins');
                }
            } else if (res && res.status == 203) {
                remove_session();
                props.getLoggedInUser();
                navigate('/login');
            }
        })
    }, [])
    const onSubmit = (e) => {
        e.preventDefault()
        setResponse("");
        if (loading)
            return false;
        if (validate()) {
            setLoading(true);
            let contractedImageValue = '';
            if(rentType == 2){
                contractedImageValue = contractedImage
            }
            const building = {
                id: params.id,
                ownerId: ownerId,
                name: name,
                city: city,
                state: state,
                address: address,
                rent: rent,
                rentType: rentType,
                cheques: cheques,
                accountNumbers: JSON.stringify(accountNumbers),
                securityDeposit: securityDeposit,
                gracePeriod:gracePeriod,
                contractedImage: contractedImageValue,
                dewa: dewa,
                dewaAccounts: dewaAccounts,
                maintenance: maintenance,
                startDate: startDate ? startDate : null,
                endDate: endDate ? endDate : null,
                status: status
            }
            addEditBuilding(building).then(res => {
                if (res && res.status == 203) {
                    remove_session();
                    props.getLoggedInUser();
                    navigate('/login');
                } else {
                    setResponse(get_response_or_errors(res))
                    setErrors(get_response_or_errors(res))
                    setLoading(false)
                }
            })
        }
    }
    const validate = () => {
        let errors = {
            ownerId: '',
            name: '',
            city: '',
            state: '',
            address: '',
            rent: '',
            rentType: '',
            cheques: '',
            securityDeposit: '',
            gracePeriod: '',
        };
        let isValid = true;
        if (!ownerId) {
            isValid = false;
            errors.ownerId = "Please select owner.";
        }
        if (!name) {
            isValid = false;
            errors.name = "Please enter name.";
        }
        if (!city) {
            isValid = false;
            errors.city = "Please enter city.";
        }
        if (!state) {
            isValid = false;
            errors.state = "Please enter state.";
        }
        if (!address) {
            isValid = false;
            errors.address = "Please enter address.";
        }
        if (!rent) {
            isValid = false;
            errors.rent = "Please enter rent.";
        }
        if (rentType === '2' && !cheques) {
            isValid = false;
            errors.cheques = "Please enter cheques.";
        }
        if (rent && (parseInt(rent) != rent || parseInt(rent) <= 0)) {
            isValid = false;
            errors.rent = "Please enter valid rent.";
        }
        if (rentType === '2' && cheques && (parseInt(cheques) != cheques || parseInt(cheques) <= 0)) {
            isValid = false;
            errors.cheques = "Please enter valid cheques.";
        }
        if (securityDeposit && (parseInt(securityDeposit) != securityDeposit || parseInt(securityDeposit) <= 0)) {
            isValid = false;
            errors.securityDeposit = "Please enter valid security deposit.";
        }
        if (gracePeriod && (parseInt(gracePeriod) != gracePeriod || parseInt(gracePeriod) <= 0)) {
            isValid = false;
            errors.gracePeriod = "Please enter valid grace period.";
        }
        if (!rentType) {
            isValid = false;
            errors.rentType = "Please select rent type.";
        }
        setErrors(errors);
        return isValid;
    }
    const imageUploadHandler = (e) => {
        var file = e.target.files[0];
        console.log("file",file)
        var reader = new FileReader();
        var url = reader.readAsDataURL(file);

        reader.onloadend = function (e) {
            setContractedImage(reader.result)

        }.bind(this);
    }
    const handleOwnerChange = (selectedOption) => {
        setOwnerId(selectedOption.value)
        setOwnerIdValue(selectedOption)
    };
    const handleDewaChange = (e) => {
        setDewa(e.target.checked);
        if (!e.target.checked) {
            setNumDewaAccounts(0);
            setDewaAccounts([]);
        }
    };
    const handleNumDewaAccountsChange = (e) => {
        const numAccounts = parseInt(e.target.value);
        setNumDewaAccounts(numAccounts);
    
        // Preserve existing values and add/remove values as needed
        setDewaAccounts(prevAccounts => {
            const newAccounts = [...prevAccounts];
            if (newAccounts.length < numAccounts) {
                // Add new empty values for additional accounts
                for (let i = newAccounts.length; i < numAccounts; i++) {
                    newAccounts.push('');
                }
            } else if (newAccounts.length > numAccounts) {
                // Remove extra values for fewer accounts
                newAccounts.splice(numAccounts);
            }
            return newAccounts;
        });
    };

    const handleDewaAccountChange = (index, value) => {
        const newDewaAccounts = [...dewaAccounts];
        newDewaAccounts[index] = value;
        setDewaAccounts(newDewaAccounts);
    };

    const handleAccountNumberChange = (id, value) => {
        const updatedAccountNumbers = accountNumbers.map(account =>
            account.id === id ? { ...account, value } : account
        );
        setAccountNumbers(updatedAccountNumbers);
       
    };

    const addAccountNumber = () => {
        const updatedAccountNumbers = [...accountNumbers, { id: idCounter.current++, value: '' }];
        setAccountNumbers(updatedAccountNumbers);
       
    };

    const removeAccountNumber = (id) => {
        const updatedAccountNumbers = accountNumbers.filter(account => account.id !== id);
        setAccountNumbers(updatedAccountNumbers);
       
    };
    return (
        <>
            <div className="col-lg-12">
                <div className="d-flex flex-wrap align-items-center justify-content-between mb-4">
                    <div>
                        <h4 className="mb-3">Edit Building/Villa/Camp</h4>
                    </div>
                </div>
            </div>
            <div className="col-sm-12">
                <div className="card">
                    <div className="bg-black-custom card-header d-flex justify-content-between rounded-top">
                        <div className="header-title">
                            <h4 className="card-title text-white">Edit New Building/Villa/Camp</h4>
                        </div>
                    </div>
                    <div className="card-body">
                        <form noValidate onSubmit={onSubmit}>
                            {response?.responseMessage &&
                            <div
                                className={`alert alert-${response?.responseFlag} fade show`}
                                role="alert">
                                {response?.responseMessage}
                            </div>
                            }
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Owner *</label>
                                        <Select
                                            value={ownerIdValue}
                                            onChange={handleOwnerChange}
                                            options={ownersOptions}
                                        />
                                        <div className="text-warning font-size-12">{errors.ownerId}</div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Name *</label>
                                        <input type="text" className="form-control" placeholder="Enter Name"
                                               value={name} onChange={(e) => setName(e.target.value)}/>
                                        <div className="text-warning font-size-12">{errors.name}</div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>City *</label>
                                        <input type="text" className="form-control" placeholder="Enter City"
                                               value={city} onChange={(e) => setCity(e.target.value)}/>
                                        <div className="text-warning font-size-12">{errors.city}</div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>State *</label>
                                        <input type="text" className="form-control" placeholder="Enter State"
                                               value={state} onChange={(e) => setState(e.target.value)}/>
                                        <div className="text-warning font-size-12">{errors.state}</div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Address *</label>
                                        <textarea className="form-control" rows="4" value={address}
                                                  onChange={(e) => setAddress(e.target.value)}></textarea>
                                        <div className="text-warning font-size-12">{errors.address}</div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Rent *</label>
                                        <input type="text" className="form-control" placeholder="Enter Rent"
                                               value={rent} onChange={(e) => setRent(e.target.value)}/>
                                        <div className="text-warning font-size-12">{errors.rent}</div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Rent Type *</label>
                                        <select className="form-control" value={rentType} onChange={(e) => setRentType(e.target.value)}>
                                            <option value="">Select Rent Type</option>
                                            <option value="1">Monthly</option>
                                            <option value="2">Yearly</option>
                                        </select>
                                        <div className="text-warning font-size-12">{errors.rentType}</div>
                                    </div>
                                </div>
                                {rentType == "2" && (<>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Contracted Image</label>
                                            <div className="custom-file">
                                                <input type="file" className="custom-file-input" id="receipt" onChange={e => imageUploadHandler(e)}  accept="image/*"/>
                                                <label className="custom-file-label" htmlFor="receipt">Choose
                                                    file</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6"></div>
                                    <div className="col-md-6 mb-3">
                                        {contractedImage && (
                                            <img className="border rounded" style={{maxHeight: '100px'}}
                                                 src={contractedImage}
                                                 alt=""/>
                                        )}
                                    </div>
                                </>)}
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Security Deposit</label>
                                        <input type="text" className="form-control" placeholder="Enter Security Deposit"
                                                value={securityDeposit} onChange={(e) => setSecurityDeposit(e.target.value)}/>
                                        <div className="text-warning font-size-12">{errors.securityDeposit}</div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Grace period (days)</label>
                                        <input type="number" className="form-control" placeholder="Enter Grace period (days)"
                                            value={gracePeriod} onChange={(e) => setGracePeriod(e.target.value)}/>
                                        <div className="text-warning font-size-12">{errors.gracePeriod}</div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>No of cheques *</label>
                                        <input type="text" className="form-control" placeholder="Enter Rent"
                                               value={cheques} onChange={(e) => setCheques(e.target.value)}/>
                                        <div className="text-warning font-size-12">{errors.cheques}</div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                        <div className="row">
                                            {accountNumbers.map((account) => (
                                                <div className="col-md-6" key={account.id}>
                                                    <div className="row">
                                                        <div className="col-md-10 form-group">
                                                            <label>Account Number</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Enter Account Number"
                                                                value={account.value}
                                                                onChange={(e) => handleAccountNumberChange(account.id, e.target.value)}
                                                            />
                                                        </div>
                                                        <div className="col-md-2 mt-md-5 mb-md-5 p-0">
                                                            {accountNumbers.length > 1 && (
                                                                <button type="button" className="btn btn-danger" onClick={() => removeAccountNumber(account.id)}>
                                                                    Remove
                                                                </button>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                            <div className="m-4">
                                                <button type="button" className="btn btn-primary" onClick={addAccountNumber}>+ Add Account</button>
                                            </div>
                                        </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Start Date</label>
                                        <input type="date" className="form-control" placeholder="Enter Start Date"
                                               value={startDate} onChange={(e) => setStartDate(e.target.value)}/>
                                        <div className="text-warning font-size-12">{errors.startDate}</div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>End Date</label>
                                        <input type="date" className="form-control" placeholder="Enter End Date"
                                               value={endDate} onChange={(e) => setEndDate(e.target.value)}/>
                                        <div className="text-warning font-size-12">{errors.endDate}</div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <div className="checkbox">
                                            <label>Dewa Included?</label>
                                            <input type="checkbox" className="form-control" checked={dewa}
                                                  onChange={handleDewaChange} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <div className="checkbox">
                                            <label>Maintenance Charges Included?</label>
                                            <input type="checkbox" className="form-control" checked={maintenance}
                                                   onChange={(e) => setMaintenance(!maintenance)} />
                                        </div>
                                    </div>
                                </div>
                                {dewa && (
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Number of Dewa Accounts:</label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                value={numDewaAccounts}
                                                onChange={handleNumDewaAccountsChange}
                                            />
                                        </div>
                                    </div>
                                )}
                                {dewaAccounts.map((account, index) => (
                                    <div className="col-md-6">
                                        <div className="form-group" key={index}>
                                            <label>Dewa Account {index + 1}:</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={account}
                                                onChange={(e) => handleDewaAccountChange(index, e.target.value)}
                                                required={dewa}
                                            />
                                        </div>
                                    </div>
                                ))}
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Status *</label>
                                        <select className="form-control"
                                                value={status} onChange={(e) => setStatus(e.target.value)}>
                                            <option value="1">Active</option>
                                            <option value="2">Inactive</option>
                                        </select>
                                        <div className="text-warning font-size-12">{errors.state}</div>
                                    </div>
                                </div>
                            </div>
                            <button type="submit" className="btn btn-primary mr-2">Update</button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default EditBuildingsVillas;
