import React, { useEffect } from "react";
import { Link, useNavigate , useLocation} from "react-router-dom";
import { date_time_to_date_format, getBuildingRooms,  deleteRent, getBuildings, getRents, remove_session } from "../../../functions/general";
import ModalImage from "react-modal-image";
import ExportToExcel from "react-html-table-to-excel";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Dropdown from 'react-bootstrap/Dropdown';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

function ListRent(props) {
  const location = useLocation();
  const currentUser = props.currentUser;
  const userType = (currentUser && currentUser.isSuperAdmin == 1)? 'superadmin': 'admin';
  const navigate = useNavigate();
  const [rent, setRent] = React.useState([]);
  const [rooms, setRooms] = React.useState([]);
  const [buildings, setBuildings] = React.useState([]);
  const [buildingId, setBuildingId] = React.useState("");
  const [roomId, setRoomId] = React.useState("");
  const [rentType, setRentType] = React.useState("");
  const [dewa, setDewa] = React.useState("");
  const [maintenanceFilter, setMaintenanceFilter] = React.useState("");
  const [rentPending, setRentPending] = React.useState("");
  const [searchQuery, setSearchQuery] = React.useState("");
  const [showFilters, setShowFilters] = React.useState(false);
  const [expiryDate, setExpiryDate] = React.useState("");
  const [currentPage, setCurrentPage] = React.useState(1);
  const [loading, setLoading] = React.useState(true); 
  const [itemsPerPage, setItemsPerPage] = React.useState(-1);
  let totalRent =0;
  let totalPending = 0;
  const currentDate = new Date();
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const rentPendingValue = searchParams.get("rentPending") || "";
    const buildingIdValue = searchParams.get("buildingId") || "";
    setRentPending(rentPendingValue);
    setBuildingId(buildingIdValue);
    getBuildings().then(res => {
      if (res && res.status == 200) {
        setBuildings(res.data.data);
       
      } else if (res && res.status == 203) {
        remove_session();
        props.getLoggedInUser();
        navigate('/login');
      } else {
        // Handle other cases
      }
    });

    
    getRents().then(res => {
      if (res && res.status == 200) {
        setLoading(false);
        const selectedRoomsOptionsTemp = [];
        if(userType =='admin'){
          if(props.currentUserPrivilege){
          
            const parsedBuildingIds = JSON.parse(props.currentUserPrivilege.building_ids);
            res.data.data?.length > 0 && res.data.data.forEach((buildingValue) => {
                const value = buildingValue.buildingId;
                if(parsedBuildingIds && parsedBuildingIds.includes(value)){ 
                    selectedRoomsOptionsTemp.push(buildingValue);
                } 
            });
            setRent(selectedRoomsOptionsTemp);
            setItemsPerPage(selectedRoomsOptionsTemp.length);
          }
        }else{
      
          setRent(res.data.data);
          setItemsPerPage(res.data.data.length);
        }
        
      } else if (res && res.status == 203) {
        remove_session();
        props.getLoggedInUser();
        navigate('/login');
      } else {
        // Handle other cases
      }
    });
  }, [location.search]);

  const onSubmit = (e) => {
    e.preventDefault()
}
const monthDiff = (dateFrom, dateTo) => {
    var months = dateTo.getMonth() - dateFrom.getMonth()
        + (12 * (dateTo.getFullYear() - dateFrom.getFullYear()));

    if(dateTo.getDate() < dateFrom.getDate()){
        months--;
    }
    return months;
}
const yearDiff = (dateFrom, dateTo) => {
    var years = dateTo.getFullYear() - dateFrom.getFullYear();

    if(dateTo.getDate() < dateFrom.getDate()){
        years--;
    }
    return years;
}
const getRentTillNow = (currentRent) => {
  let parsedDeposit = parseInt(rent.securityDeposit);
  if (isNaN(parsedDeposit)) {
      parsedDeposit = 0;
    }
    let rentAmount = 0;
    if(currentRent.startDate){
        if(currentRent.rentType == '1'){
            let months = monthDiff(new Date(currentRent.startDate), new Date()) + 1;
            rentAmount = currentRent.rent * months
        }else{
            let years = yearDiff(new Date(currentRent.startDate), new Date()) + 1;
            rentAmount = currentRent.rent * years
        }
    }
    return rentAmount + parseInt(currentRent.overDaysRent)+ parseInt(parsedDeposit);
}

const deleteHandler = (e, id) => {
  e.preventDefault();
  if (window.confirm("Are you sure you want to delete rent?")) {
    deleteRent(id).then(res => {
      if (res && res.status == 200) {
        setLoading(false);
        const selectedRoomsOptionsTemp = [];
        if(userType =='admin'){
          if(props.currentUserPrivilege){
          
            const parsedBuildingIds = JSON.parse(props.currentUserPrivilege.building_ids);
            res.data.data?.length > 0 && res.data.data.forEach((buildingValue) => {
                const value = buildingValue.buildingId;
                if(parsedBuildingIds && parsedBuildingIds.includes(value)){ 
                    selectedRoomsOptionsTemp.push(buildingValue);
                } 
            });
            setRent(selectedRoomsOptionsTemp);
            setItemsPerPage(selectedRoomsOptionsTemp.length);
          }
        }else{
      
          setRent(res.data.data);
          setItemsPerPage(res.data.data.length);
        }
      } else if (res && res.status == 405) {
        alert("Building cannot be deleted.");
      } else if (res && res.status == 203) {
        remove_session();
        props.getLoggedInUser();
        navigate('/login');
      } else {
        // Handle other cases
      }
    });
  }
}

const rentPendingFilter = (val) => {
  const searchParams = new URLSearchParams(location.search);
  
  if (val === "") {
    searchParams.delete("rentPending"); // Remove the query param if "All" is selected
  } else {
    searchParams.set("rentPending", val); // Update the query param
  }

  navigate({
    pathname: location.pathname,
    search: searchParams.toString(),
  });

  setRentPending(val);
}
function formatDate(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

const handleBuildingChange = (selectedOption) => {
  const searchParams = new URLSearchParams(location.search);
  setRooms([]);
  var building_id = selectedOption;
  setBuildingId(selectedOption);
  setRoomId(''); // Reset room selection when building changes

  if(building_id == ""){
    setRooms([]);
    searchParams.delete("buildingId");
  }else{
    searchParams.set("buildingId", selectedOption);
    getBuildingRooms(building_id).then(res => {
      if (res && res.status == 200) {
          setRooms(res.data.data);
      }
    })
  }
  navigate({
    pathname: location.pathname,
    search: searchParams.toString(),
  });
 
};
// const formattedDate = formatDate(currentDate);
const formattedDate = new Date();
formattedDate.setHours(0, 0, 0, 0);

const getNextMonthFirstDate = () => {
  const now = new Date();
  return new Date(now.getFullYear(), now.getMonth() + 1, 1);
};
//   const indexOfLastItem = currentPage * itemsPerPage;
//   const indexOfFirstItem = indexOfLastItem - itemsPerPage;
//   const currentRents = rent.slice(indexOfFirstItem, indexOfLastItem);

//   const handlePageChange = (pageNumber) => {
//     setCurrentPage(pageNumber);
//   };

//   const handleItemsPerPageChange = (e) => {
//     setItemsPerPage(Number(e.target.value));
//     setCurrentPage(1); // Reset to first page when changing items per page
//   };

//   const renderPageNumbers = () => {
//     const pageNumbers = [];
//     for (let i = Math.max(1, currentPage - 2); i <= Math.min(currentPage + 2, Math.ceil(rent.length / itemsPerPage)); i++) {
//       pageNumbers.push(
//         <li key={i} className={`page-item ${i == currentPage ? 'active' : ''}`}>
//           <button className="page-link" onClick={() => handlePageChange(i)}>
//             {i}
//           </button>
//         </li>
//       );
//     }
//     return pageNumbers;
//   };

const applyFilters = (rents) => {
  return rents.filter(singleRent  => {
    // Calculate the pending balance
    const pendingBalance = getRentTillNow(singleRent) - (singleRent.rentCollected.amount ? singleRent.rentCollected.amount : 0);

    // Filter by rent pending status
    if (rentPending && rentPending == '1' && pendingBalance <= 0) {
      return false;
    }
    if (rentPending && rentPending == '0' && pendingBalance > 0) {
      return false;
    }

    // Filter by rent type, building ID, and room ID
    if ((!rentType || rentType == singleRent.rentType) &&
        (!buildingId || buildingId == singleRent.buildingId) &&
        (!roomId || roomId == singleRent.roomId || !buildingId)) {

      // Create a concatenated string of relevant fields
      const tempString = `${singleRent.buildingName} ${singleRent.roomName} ${singleRent.customerName}`;

      // Filter by search query
      if (searchQuery && !tempString.match(new RegExp(searchQuery, 'i'))) {
        return false;
      }

      // Filter by dewa filter
      if ((maintenanceFilter && singleRent.maintenance != '1' && maintenanceFilter == '1') || 
          (maintenanceFilter && singleRent.maintenance == '1' && maintenanceFilter == '2')) {
        return false;
      }

      // Filter by dewa
      if ((dewa && singleRent.dewa != '1' && dewa == '1') || 
          (dewa && singleRent.dewa == '1' && dewa == '2')) {
        return false;
      }

      // Filter by expiry date
      if (expiryDate && expiryDate != singleRent.endDate) {
        return false;
      }

      return true;
    }

    return false;
  });
};

const indexOfLastItem = currentPage * itemsPerPage;
const indexOfFirstItem = indexOfLastItem - itemsPerPage;
const currentRents = rent?.slice(indexOfFirstItem, indexOfLastItem);

const pageNumbers = Array.from(
  { length: Math.ceil(rent?.length / itemsPerPage) },
  (_, i) => i + 1
);

const renderPageNumbers = pageNumbers.map((number) => {
  if (
    number == 1 ||
    number == currentPage ||
    number == currentPage - 1 ||
    number == currentPage + 1 ||
    number == pageNumbers?.length
  ) {
    return (
      <li
        key={number}
        className={`page-item ${currentPage == number ? "active" : ""}`}
      >
        <button className="page-link" onClick={() => paginate(number)}>
          {number}
        </button>
      </li>
    );
  }
  return null;
});

const paginate = (pageNumber) => {
  setCurrentPage(pageNumber);
};

const handleItemsPerPageChange = (e) => {
  const value = Number(e.target.value);
  if (value === -1) {
      // Show all items
      setItemsPerPage(rent.length);
  } else {
      // Set items per page to the selected value
      setItemsPerPage(value);
  }
  setCurrentPage(1); // Reset to first page when changing items per page
};
  
const exportToExcel = () => {
  const filteredRents = applyFilters(currentRents);
  const ws = XLSX.utils.json_to_sheet(filteredRents);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Rents List");
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" });
  saveAs(data, "rent-list.xlsx");
};

const exportToCSV = () => {
  const filteredRents = applyFilters(currentRents);
  const csvData = [];
  // Add headers
  const headers = ["Building", "Room", "Tenant", "Rent", "Pending", "Start Date", "Dewa", "Maintenance"];
  csvData.push(headers);

  // Add all data
  filteredRents.forEach((singleRent) => {
    const rowData = [
      singleRent.buildingName,
      singleRent.roomName,
      singleRent.customerName,
      `${singleRent.rent} / ${singleRent.rentType == '1' ? "Month" : "Year"}`,
      (getRentTillNow(singleRent) - (singleRent.rentCollected.amount ? singleRent.rentCollected.amount : 0)) > 0 ? (getRentTillNow(singleRent) - (singleRent.rentCollected.amount ? singleRent.rentCollected.amount : 0)) : 0,
      date_time_to_date_format(singleRent.startDate),
      singleRent.dewa == '1' ? "Yes" : "No",
      singleRent.maintenance == '1' ? "Yes" : "No"
    ];
    csvData.push(rowData);
  });



  // Create CSV file
  const csvReport = {
    data: csvData,
    headers: headers,
    filename: "rent_data.csv"
  };

  return (
    <CSVLink {...csvReport}>
     <p className="text-dark">Export to CSV</p> 
    </CSVLink>
  );
};

const exportToPDF = () => {
  const filteredRents = applyFilters(currentRents);
  const unit = "pt";
  const size = "A4"; // Use A1, A2, A3, or A4
  const orientation = "landscape";

  const doc = new jsPDF(orientation, unit, size);

  doc.text("Rent Data", 14, 16);

  const headers = ["Building", "Room", "Tenant", "Rent", "Pending", "Start Date", "Dewa", "Maintenance"];
  const data = filteredRents.map((singleRent) => [
    singleRent.buildingName,
    singleRent.roomName,
    singleRent.customerName,
    `${singleRent.rent} / ${singleRent.rentType == '1' ? "Month" : "Year"}`,
    (getRentTillNow(singleRent) - (singleRent.rentCollected.amount ? singleRent.rentCollected.amount : 0)) > 0 ? (getRentTillNow(singleRent) - (singleRent.rentCollected.amount ? singleRent.rentCollected.amount : 0)) : 0,
    date_time_to_date_format(singleRent.startDate),
    singleRent.dewa == '1' ? "Yes" : "No",
    singleRent.maintenance == '1' ? "Yes" : "No"
  ]);

  // Calculate total amount
  const totalAmount = filteredRents.reduce(
    (total, rent) => total + parseFloat(rent.rent),
    0
  );
  const rentTotalAmount = filteredRents.reduce(
    (total, rent) => total +Math.max(getRentTillNow(rent) - (rent.rentCollected.amount ? rent.rentCollected.amount : 0), 0),
    0
  );
  
  // Add the total amount row
  const totalRow = [
    "Total Rent",
    totalAmount.toLocaleString(),
    "Pending Amount",
    rentTotalAmount.toLocaleString(),
    "",
    "",
    ""
  ];

  // Append the total row to the data
  data.push(totalRow);
  let content = {
    startY: 50,
    head: [headers],
    body: data
  };

  doc.autoTable(content);
  doc.save("rent_data.pdf");
};
  return (
    <>
      <div className="col-lg-12">
        <div className="d-flex flex-wrap align-items-center justify-content-between mb-4">
          <div>
            <h4 className="mb-3">Rent Contracts List</h4>
          </div>
         <div>
         {currentUser && currentUser.isSuperAdmin == "1" && (
         <Link to="/rent/add" className="btn btn-primary mx-3 add-list">
            <i className="las la-plus mr-3"></i>Add Rent Contract
          </Link>
         )}
         </div>
        </div>
      </div>

      <div className="col-lg-12">
        <div className="row justify-content-between align-items-end px-3">
          <div className="col-lg-2 col-md-4 col-sm-6">
              <div className="form-group">
                <label>Show per page:</label>
                <select
                  className="form-control"
                  value={itemsPerPage === rent.length ? -1 : itemsPerPage}
                  onChange={handleItemsPerPageChange}
                >
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={30}>30</option>
                  <option value={-1}>Show All</option>
                </select>
            </div>
          </div>

          <div className="col-lg-2 col-md-4 col-sm-6">
            <div className="form-group">
              <label>Export</label>
              <Dropdown className="w-100">
                  <Dropdown.Toggle variant="light" id="dropdown-basic" className="w-100">
                    Select Export Type
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1"  onClick={exportToExcel}>Export to Excel</Dropdown.Item>
                    <Dropdown.Item href="#/action-2" className="pt-2 pb-0 mb-0 " >{exportToCSV()}</Dropdown.Item>
                    <Dropdown.Item href="#/action-3" className="mt-0 " onClick={exportToPDF}>Export to PDF</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
            </div>
          </div>

          <div className="col-lg-2 col-md-4 col-sm-6">
            <div className="form-group">
              <label>Building/Villa/Camp</label>
              <select className="form-control" value={buildingId} onChange={(e) =>handleBuildingChange(e.target.value)}>
                  <option value="">All Buildings</option>
                  { buildings?.length > 0 && buildings.map((building, index) => {
                      if(building.status == '1') {
                          return (
                              <option key={index}
                                      value={building.id}>{building.name} ({building.ownerName})</option>
                          );
                      }
                  })}
              </select>
            </div>
          </div>
          <div className="col-lg-2 col-md-4 col-sm-6">
              <div className="form-group">
                <label>Room</label>
                <select className="form-control" onChange={(e) =>setRoomId(e.target.value)}>
                  <option value="">All Rooms</option>
                  {rooms?.length > 0 && rooms.map((room, index) => (
                    <option key={index} value={room.id}>{room.name}</option>
                  ))}
                </select>
              </div>
            </div>
          <div className="col-lg-2 col-md-4 col-sm-6">
            <div className="form-group">
              <label>Rent Type</label>
                <select className="form-control" value={rentType} onChange={(e) => setRentType(e.target.value)}>
                    <option value="">All Rent Types</option>
                    <option value="1">Monthly</option>
                    <option value="2">Yearly</option>
                </select>
            </div>
          </div>
          <div className="col-lg-2 col-md-4 col-sm-6">
            <div className="form-group">
              <label>Dewa</label>
                <select className="form-control" value={dewa} onChange={(e) => setDewa(e.target.value)}>
                    <option value="">Select</option>
                    <option value="1">Yes</option>
                    <option value="2">No</option>
                </select>
            </div>
          </div>
          <div className="col-lg-2 col-md-4 col-sm-6">
            <div className="form-group">
              <label>Rent Pending</label>
              <select className="form-control" value={rentPending} onChange={(e) => rentPendingFilter(e.target.value)}>
                  <option value="">All</option>
                  <option value="1">Yes</option>
                  <option value="0">No</option>
              </select>
            </div>
          </div>
          <div className="col-lg-2 col-md-4 col-sm-6">
            <div className="form-group">
              <label>Maintenance</label>
              <select className="form-control" value={maintenanceFilter} onChange={(e) => setMaintenanceFilter(e.target.value)}>
                  <option value="">Select</option>
                  <option value="1">Yes</option>
                  <option value="2">No</option>
              </select>
            </div>
          </div>
          <div className="col-md-3 col-sm-6">
              <div className="form-group">
                        <label>Expiry Date</label>
                        <input  className="form-control"   value={expiryDate} type="date"   onChange={(e) => setExpiryDate(e.target.value)} />
              </div>
            </div>
          <div className="col-lg-2 col-md-4 col-sm-6">
            <div className="form-group">
                <label>Search</label>
                <input type="text" className="form-control" placeholder=""
                      value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)}/>
            </div>
          </div>
        </div> 
      </div>

  
      {/* {showFilters && (
        <>
          <div className="col-sm-12">
            <div className="card">
              <div className="bg-black-custom card-header d-flex justify-content-between rounded-top">
                <div className="header-title">
                  <h4 className="card-title text-white">Filters</h4>
                </div>
                <div>

                </div>
              </div>
              <div className="card-body">
              <form noValidate onSubmit={onSubmit}>
                              <div className="row">
                                  <div className="col-md-6">
                                      <div className="form-group">
                                          <label>Search</label>
                                          <input type="text" className="form-control" placeholder=""
                                                 value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)}/>
                                      </div>
                                  </div>
                                  <div className="col-md-6">
                                      <div className="form-group">
                                          <label>Building/Villa</label>
                                          <select className="form-control" value={buildingId} onChange={(e) => setBuildingId(e.target.value)}>
                                              <option value="">All Buildings</option>
                                              { buildings.length > 0 && buildings.map((building, index) => {
                                                  if(building.status == '1') {
                                                      return (
                                                          <option key={index}
                                                                  value={building.id}>{building.name} ({building.ownerName})</option>
                                                      );
                                                  }
                                              })}
                                          </select>
                                      </div>
                                  </div>
                                  <div className="col-md-6">
                                      <div className="form-group">
                                          <label>Rent Type</label>
                                          <select className="form-control" value={rentType} onChange={(e) => setRentType(e.target.value)}>
                                              <option value="">All Rent Types</option>
                                              <option value="1">Monthly</option>
                                              <option value="2">Yearly</option>
                                          </select>
                                      </div>
                                  </div>
                                  <div className="col-md-6">
                                      <div className="form-group">
                                          <label>Rent Pending</label>
                                          <select className="form-control" value={rentPending} onChange={(e) => setRentPending(e.target.value)}>
                                              <option value="">All</option>
                                              <option value="1">Yes</option>
                                              <option value="0">No</option>
                                          </select>
                                      </div>
                                  </div>
                              </div>
                          </form>
              </div>
            </div>
          </div>
        </>
      )} */}
      <div className="col-lg-12">
        <div className="table-responsive rounded mb-3">
          <table className="data-table table mb-0 tbl-server-info">
            <thead className="bg-black-custom text-uppercase">
              <tr className="text-white">
                          <th>Building/Villa/Camp</th>
                          <th>Room</th>
                          <th>Tenant</th>
                          <th>Rent</th>
                          <th>Pending</th>
                          <th>Start Date</th>
                          <th>End Date</th>
                          <th>Dewa</th>
                          <th>Maintenance</th>
                          <th>Action</th>
                      </tr>
            </thead>
            <tbody className="bg-white">
              {currentRents?.length > 0 && currentRents.map((singleRent, index) => {
              
                if (rentPending && rentPending == '1' && (getRentTillNow(singleRent) - (singleRent.rentCollected.amount ? singleRent.rentCollected.amount : 0)) <= 0) {
                  return null;
                }
                if (rentPending && rentPending == '0' && (getRentTillNow(singleRent) - (singleRent.rentCollected.amount ? singleRent.rentCollected.amount : 0)) > 0) {
                  return null;
                }
                
                if ((!rentType || rentType == singleRent.rentType) && (!buildingId || buildingId == singleRent.buildingId) && (!roomId || roomId == singleRent.roomId || !buildingId)) {
                  const tempString =
                    singleRent.buildingName + " " + singleRent.roomName + " " + singleRent.customerName;
                  if (searchQuery && !tempString.match(new RegExp(searchQuery, 'i'))) {
                    return null;
                  }
                  if((maintenanceFilter && singleRent.maintenance != '1' && maintenanceFilter == '1') || (maintenanceFilter && singleRent.maintenance == '1' && maintenanceFilter == '2') ){
                    return null;
                  }
                  if ((dewa && singleRent.dewa != '1' && dewa == '1') || (dewa && singleRent.dewa == '1' && dewa == '2')) {
                    return null;
                  }
                  if(expiryDate && expiryDate != singleRent.endDate ){
                    return null;
                  }
                  let rentAmount = parseInt(singleRent.rent);
                let pendingAmount = Math.max(getRentTillNow(singleRent) - (singleRent.rentCollected.amount ? singleRent.rentCollected.amount : 0)- (singleRent.rentSecurityCollected.amount ? singleRent.rentSecurityCollected.amount : 0), 0);
                parseInt(totalRent += rentAmount);
                parseInt(totalPending += pendingAmount);
                const nextMonthFirstDate = getNextMonthFirstDate();
                const pendingBalance = getRentTillNow(singleRent) - (singleRent.rentCollected.amount ? singleRent.rentCollected.amount : 0)- (singleRent.rentSecurityCollected.amount ? singleRent.rentSecurityCollected.amount : 0);
                  return (
                   
                    <tr key={index} className={pendingBalance > 0 && formattedDate.getDate() > 1 ? 'bg-red' : ''}>
                                      <td>{singleRent.buildingName}</td>
                                      <td>{singleRent.roomName}</td>
                                      <td>{singleRent.customerName}</td>
                                      <td>
                                          {singleRent.rent.toLocaleString()} / {singleRent.rentType == '1' ? "Month" : "Year"}
                                          {singleRent.contractedImage &&
                                          <div  className="transactionTableImage">
                                              <ModalImage
                                                  small={process.env.REACT_APP_APIPATH + "/api/images/" + singleRent.contractedImage}
                                                  large={process.env.REACT_APP_APIPATH + "/api/images/" + singleRent.contractedImage}
                                              />
                                          </div>}
                                      </td>
                                      <td>{(getRentTillNow(singleRent) - (singleRent.rentCollected.amount ? singleRent.rentCollected.amount : 0)) > 0 ? (getRentTillNow(singleRent) - (singleRent.rentCollected.amount ? singleRent.rentCollected.amount : 0)- (singleRent.rentSecurityCollected.amount ? singleRent.rentSecurityCollected.amount : 0)).toLocaleString() : 0}</td>
                                      <td>{date_time_to_date_format(singleRent.startDate)}</td>
                                      <td>
                                        {(singleRent.rentType == '2' && singleRent.rentEndDate)?date_time_to_date_format(singleRent.rentEndDate):'--'}
                                      </td>
                                      <td>
                                          {singleRent.dewa == '1' ?
                                            
                                              (<div className="text-success font-weight-bold">Yes</div>) :  
                                              (<div className="text-danger font-weight-bold">No&nbsp;</div>)}
                                      </td>
                                      <td>
                                          {singleRent.maintenance == '1' ?
                                              (<div className="text-success font-weight-bold">Yes</div>) :
                                              (<div className="text-danger font-weight-bold">No&nbsp;</div>)}
                                      </td>
                                      <td>
                                          <div className="d-flex align-items-center list-action">
                                            
                                                  <Link className="badge bg-primary mr-2 mb-2" data-toggle="tooltip"
                                                      data-placement="top"
                                                      title="Transaction" data-original-title="Transaction"
                                                      to={`/transactions/add?type=rent&id=${singleRent.id}&returnUrl=${encodeURIComponent(location.pathname + location.search)}`}><i
                                                    className="ri-money-dollar-circle-line mr-0"></i></Link>
                                                  
                                              
                                                    <Link className="badge bg-primary mr-2 mb-2" data-toggle="tooltip"
                                                    data-placement="top"
                                                    title="Detail" data-original-title="Detail"
                                                    to={'/rent/' + singleRent.id}><i
                                                  className="ri-eye-line mr-0"></i></Link>
                                                {currentUser && currentUser.isSuperAdmin == "1" && (
                                                  <div>
                                                  {pendingBalance <= 0 && currentUser.isSuperAdmin == '1' &&
                                                    <Link to={`/rent/checkout/${singleRent.id}`} className="badge bg-primary mr-2 mb-2" 
                                                    data-toggle="tooltip"
                                                    data-placement="top"
                                                    title="Early Checkout" data-original-title="Early Checkout">
                                                      <i className="ri-shield-check-fill mr-0"></i></Link>}

                                                  <Link className="badge bg-primary mr-2 mb-2" data-toggle="tooltip"
                                                    data-placement="top"
                                                    title="Edit" data-original-title="Edit"
                                                    to={'/rent/edit/' + singleRent.id}><i
                                                  className="ri-pencil-line mr-0"></i></Link>
                                                    <a
                                                    onClick={(e) => deleteHandler(e, singleRent.id)}
                                                    className="badge bg-primary mr-2 mb-2"
                                                    data-toggle="tooltip"
                                                    data-placement="top"
                                                    title="Delete"
                                                    data-original-title="Delete"
                                                    href="#"
                                                  >
                                                    <i className="ri-delete-bin-line mr-0"></i>
                                                  </a>
                                                  <Link
                                                    className="badge bg-primary mr-2"
                                                    data-toggle="tooltip"
                                                    data-placement="top"
                                                    title="Print"
                                                    data-original-title="Print"
                                                    // onClick={() => handlePrintModal(customer)}
                                                    to={"/rent/print/" + singleRent.id}
                                                  >
                                                    <i className="ri-printer-line mr-0"></i>
                                                  </Link>
                                                  </div>
                                                )}
                                          </div>
                                      </td>
                                  </tr>
                  );
                }
                return null;
              })}
            </tbody>
            <thead className="bg-black-custom text-uppercase">
              <tr className="text-white">
                  <th></th>
                  <th></th>
                  <th></th>
                  <th>Total Rent Amount: {parseInt(totalRent).toLocaleString()}</th>
                  <th>Total Pending Amount: {parseInt(totalPending).toLocaleString()}</th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
              </tr>
            </thead>
          </table>
        </div>
   


<div className="row">
<div className="col-sm-6  text-xl-left text-sm-left text-center">
     
     {loading ? (
                <div className="text-muted"><p>Loading...</p> </div>
            ) : (
                <div className="col-lg-12">
                {/* <i><p class="text-muted"><i class="fa fa-info-circle"></i><span class="p-2">Amount showed in the table footer is total of all {rent?.length} rents listed above</span> </p></i>  */}
                <p>Total Number of Rents: {applyFilters(rent)?.length}</p>
                </div>
            )}
      </div>

      <div className="col-sm-6 text-left">
        <nav className="pagination justify-content-xl-end justify-content-sm-end justify-content-center">
          <ul className="pagination">
            <li
              className={`page-item ${
                currentPage == 1 ? "disabled" : ""
              }`}
            >
              <button
                className="page-link"
                onClick={() => paginate(currentPage - 1)}
              >
                Previous
              </button>
            </li>
            {renderPageNumbers}
            <li
              className={`page-item ${
                currentPage == Math.ceil(rent?.length / itemsPerPage)
                  ? "disabled"
                  : ""
              }`}
            >
              <button
                className="page-link"
                onClick={() => paginate(currentPage + 1)}
              >
                Next
              </button>
            </li>
          </ul>
        </nav>
      </div>
      </div>
      </div>
    </>
  );
}

export default ListRent;




