import React from "react";

function Footer() {
  return (
      <footer className="iq-footer">
          {/* <div className="container-fluid">
              <div className="card">
                  <div className="card-body">
                      <div className="row">
                          <div className="col-lg-12 text-center">
                              Designed with
                              <span className="heart-color text-secondary"> ❤ </span>
                              by<b>
                              <a target="_blank" rel="noreferrer" className="itinitial-color" href="https://itinitial.com"> ITINITIAL</a></b>
                          </div>
                      </div>
                  </div>
              </div>
          </div> */}
      </footer>
  );
}

export default Footer;
