import React from "react";
import {useNavigate} from "react-router-dom";
import {addEditAccount, get_response_or_errors, remove_session} from "../../../functions/general";
import Select from 'react-select';

function AddAccounts(props) {
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState(false);
    const [response, setResponse] = React.useState("");
    const [bankName, setBankName] = React.useState("");
    const [accountNo, setAccountNo] = React.useState("");
    const [accountTitle, setAccountTitle] = React.useState("");
    const [errors, setErrors] = React.useState("");
  
    const onSubmit = (e) => {
        e.preventDefault()
        setResponse("");
        if (loading)
            return false;
        if (validate()) {
            setLoading(true);
            const account = {
                bankName: bankName,
                accountNo: accountNo,
                accountTitle: accountTitle,
            }
            addEditAccount(account).then(res => {
                if (res && res.status == 200) {
                    navigate("/account");
                } else if (res && res.status == 203) {
                    remove_session();
                    props.getLoggedInUser();
                    navigate('/login');
                } else {
                    setResponse(get_response_or_errors(res))
                    setErrors(get_response_or_errors(res))
                    setLoading(false)
                }
            })
        }
    }
    const validate = () => {
        let errors = {
            bankName: '',
            accountNo: '',
            accountTitle: '',
        };
        let isValid = true;
        if (!bankName) {
            isValid = false;
            errors.bankName = "Please enter Bank Name.";
        }
        if (!accountNo) {
            isValid = false;
            errors.accountNo = "Please enter Account No.";
        }
        if (!accountTitle) {
            isValid = false;
            errors.accountTitle = "Please enter Account Title.";
        }
        setErrors(errors);
        return isValid;
    }
    
    return (
        <>
            <div className="col-lg-12">
                <div className="d-flex flex-wrap align-items-center justify-content-between mb-4">
                    <div>
                        <h4 className="mb-3">Add Account</h4>
                    </div>
                </div>
            </div>
            <div className="col-sm-12">
                <div className="card">
                    <div className="bg-black-custom card-header d-flex justify-content-between rounded-top">
                        <div className="header-title">
                            <h4 className="card-title text-white">Add New Account</h4>
                        </div>
                    </div>
                    <div className="card-body">
                        <form noValidate onSubmit={onSubmit}>
                            {response?.responseMessage &&
                            <div
                                className={`alert alert-${response?.responseFlag} fade show`}
                                role="alert">
                                {response?.responseMessage}
                            </div>
                            }
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Bank Name *</label>
                                        <input type="text" className="form-control" placeholder="Enter Bank Name"
                                               value={bankName} onChange={(e) => setBankName(e.target.value)}/>
                                        <div className="text-warning font-size-12">{errors.bankName}</div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                              <div className="form-group">
                                  <label>Account No *</label>
                                  <input type="text" className="form-control" placeholder="Enter Account No"
                                         value={accountNo} onChange={(e) => setAccountNo(e.target.value)}/>
                                  <div className="text-warning font-size-12">{errors.accountNo}</div>
                              </div>
                          </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Account Title *</label>
                                        <input type="text" className="form-control" placeholder="Enter Account Title"
                                               value={accountTitle} onChange={(e) => setAccountTitle(e.target.value)}/>
                                        <div className="text-warning font-size-12">{errors.accountTitle}</div>
                                    </div>
                                </div>
                            </div>
                            <button type="submit" className="btn btn-primary mr-2">Add</button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AddAccounts;
