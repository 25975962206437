import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getAccount,getAccountTransactions, remove_session, date_time_to_date_format } from "../../../functions/general";
import ModalImage from "react-modal-image";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Dropdown from 'react-bootstrap/Dropdown';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

function ListAllTransactions(props) {
  const navigate = useNavigate();
  const params = useParams();
  const [account, setAccount] = React.useState("");
  const [transactions, setTransactions] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchStatus, setSearchStatus] = useState("");
  const [showFilters, setShowFilters] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [loading, setLoading] = useState(true); 
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(-1);
  const [balance, setBalance] = useState(0); 
  let totalTransactions =0;
  let count =0;
  const tableRef = useRef();

  useEffect(() => {

    let tempBalance = 0;
    getAccount(params.id).then((res) => {
      if (res && res.status == 200) {
        setLoading(false); 
        setAccount(res.data.data);
        setItemsPerPage(res.data.data.length);
      } else if (res && res.status == 203) {
        remove_session();
        props.getLoggedInUser();
        navigate("/login");
      }
    });
    getAccountTransactions(params.id).then((res) => {
        if (res && res.status == 200) {
          setLoading(false); 
          setTransactions(res.data.data);
          res.data.data.forEach((transaction) => {
            const transAmount = parseInt(transaction.transactionAmount);
            if (transaction.transactionType == 'DEPOSIT') {
              tempBalance += transAmount;
            } else if (transaction.transactionType == 'WITHDRAW') {
              tempBalance -= transAmount;
            }
         
          });
         
          setBalance(tempBalance);
          setItemsPerPage(res.data.data.length);
        } else if (res && res.status == 203) {
          remove_session();
          props.getLoggedInUser();
          navigate("/login");
        }
      });
    
 }, []);
 
  const onSubmit = (e) => {
    e.preventDefault();
  };

  const applyFilters = (transactions) => {
    return transactions.filter(transaction => {
      const transactionDate = new Date(transaction.transactionDate); 
      const start = new Date(startDate);
      const end = new Date(endDate);

      // Check if transaction date falls within the start and end dates
      const isWithinDateRange = 
        (!startDate || transactionDate >= start) &&
        (!endDate || transactionDate <= end);
      
      if (!isWithinDateRange) {
        return false;
      }

      // Check if transaction status matches the search status
      if (searchStatus && !(searchStatus == transaction.transactionType)) {
        return false;
      }

      // Check if search query matches any relevant fields
      const tempString = `${transaction.transactionAmount} ${transaction.buildingName} ${transaction.roomName}`;
      if (searchQuery && !tempString.match(new RegExp(searchQuery, 'i'))) {
        return false;
      }

      return true;
    });
  };

  // Pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentTransactions = transactions.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const pageNumbers = Array.from(
    { length: Math.ceil(transactions?.length / itemsPerPage) },
    (_, i) => i + 1
  );

  const renderPageNumbers = pageNumbers.map((number) => {
    if (
      number == 1 ||
      number == currentPage ||
      number == currentPage - 1 ||
      number == currentPage + 1 ||
      number == pageNumbers?.length
    ) {
      return (
        <li
          key={number}
          className={`page-item ${currentPage == number ? "active" : ""}`}
        >
          <button className="page-link" onClick={() => paginate(number)}>
            {number}
          </button>
        </li>
      );
    }
    return null;
  });

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleItemsPerPageChange = (e) => {
    const value = Number(e.target.value);
    if (value == -1) {
        // Show all items
        setItemsPerPage(value);
    } else {
        // Set items per page to the selected value
        setItemsPerPage(value);
    }
    setCurrentPage(1); // Reset to first page when changing items per page
};

  const exportToCSV = () => {
    tableRef.current.link.click();
  };

  const exportToExcel = () => {
    const filteredAccounts = applyFilters(currentTransactions);
    const ws = XLSX.utils.json_to_sheet(filteredAccounts);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Transactions List");
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" });
    saveAs(data, "transactions-list.xlsx");
  };

  const exportToPDF = () => {
    const filteredAccounts = applyFilters(currentTransactions);
    const unit = "pt";
    const size = "A4";
    const orientation = "landscape";

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);
    const title = "Account Statement";
    const headers = [
      ["Receipt", "Amount", "Account", "Description", "Transaction Date"],
    ];

    const data = filteredAccounts.map((transaction) => [
      transaction.transactionReceipt,
      transaction.transactionAmount,
      transaction.accountTitle,
      transaction.transactionDescription,
      transaction.transactionDate,
    ]);

    let content = {
      startY: 50,
      head: headers,
      body: data,
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("account-statement.pdf");
  };


  return (
    <>
   
      <div className="col-lg-12">
        <div className="d-flex flex-wrap align-items-center justify-content-between mb-4">
          <div>
            <h4 className="mb-3">{account.account_title}'s Bank Statement</h4>
            
          </div>
         
        </div>
      </div>
      
      <div className="col-lg-12">
<div className="row  align-items-end px-3">
    <div className="col-lg-2 col-md-3 col-sm-6">
    <div className="form-group">
          <label>Show per page:</label>
          <select
            className="form-control"
            value={itemsPerPage == transactions.length ? -1 : itemsPerPage}
            onChange={handleItemsPerPageChange}
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={30}>30</option>
            <option value={-1}>Show All</option>
          </select>
        </div>
    </div>
    <div className="col-lg-2 col-md-3 col-sm-6">

<div className="form-group">
<label>Export</label>
<Dropdown className="w-100">
    <Dropdown.Toggle variant="light" id="dropdown-basic" className="w-100">
      Select Export Type
    </Dropdown.Toggle>

    <Dropdown.Menu>
      <Dropdown.Item href="#/action-1" onClick={exportToCSV}>Export to CSV</Dropdown.Item>
      <Dropdown.Item href="#/action-2" onClick={exportToExcel}>Export to Excel</Dropdown.Item>
      <Dropdown.Item href="#/action-3" onClick={exportToPDF}>Export to PDF</Dropdown.Item>
    </Dropdown.Menu>
  </Dropdown>
</div>
</div>
        <div className="col-md-2 col-sm-6">
            <div className="form-group">
            <label>Type</label>
            <select
                className="form-control"
                value={searchStatus}
                onChange={(e) => setSearchStatus(e.target.value)} >
                <option value="">All</option>
                <option value="DEPOSIT">DEPOSIT</option>
                <option value="WITHDRAW">WITHDRAW</option>
            </select>
            </div>
        </div>
        <div className="col-lg-2 col-md-3 col-sm-6">
            <div className="form-group">
                <label>Start Date</label>
                <input
                    type="date"
                    className="form-control"
                    placeholder="Enter Date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                />
            </div>
        </div>
        <div className="col-lg-2 col-md-3 col-sm-6">
            <div className="form-group">
                <label>End Date</label>
                <input
                    type="date"
                    className="form-control"
                    placeholder="Enter Date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                />
            </div>
        </div>
  </div> 
</div>

      
      <div className="col-lg-12">
        <div className="table-responsive rounded mb-3">
          <table className="data-table table mb-0 tbl-server-info">
            <thead className="bg-black-custom text-uppercase">
              <tr className="text-white">
                <th>No</th>
                <th>Receipt</th>
                <th>Amount</th>
                <th>Description</th>
                <th>Transaction</th>
                <th className="text-center">Type</th>
                <th>Transaction Date</th>
                
              </tr>
            </thead>
            <tbody className="bg-white">
              {currentTransactions.map((transaction, index) => {
                const transactionDate = new Date(transaction.transactionDate); // Assuming 'date' is the field for transaction date
                const start = new Date(startDate);
                const end = new Date(endDate);

                // Check if transaction date falls within the start and end dates
                const isWithinDateRange = 
                    (!startDate || transactionDate >= start) &&
                    (!endDate || transactionDate <= end);
                    
                if(!isWithinDateRange){
                    return;
                }    
                if (searchStatus && !(searchStatus == transaction.transactionType)) {
                  return;}
                    const tempString =
                    transaction.transactionAmount + " " + transaction.buildingName + " " + transaction.roomName;
                  if (searchQuery && !tempString.match(new RegExp(searchQuery, 'i'))) {
                    return null;
                  }
                  if (
                    searchQuery &&
                    !tempString.match(new RegExp(searchQuery, "i"))
                  ) {
                    return null;
                  }
                    let amount = parseInt(transaction.transactionAmount);
                    parseInt(totalTransactions += amount);
                    parseInt(count++);
                  return (
                    <tr key={index}>
                      <td>{transaction.id}</td>
                      <td>
                        {transaction.transactionReceipt && (
                          <div className="transactionTableImage">
                            <ModalImage
                              small={
                                process.env.REACT_APP_APIPATH +
                                "/api/images/" +
                                transaction.transactionReceipt
                              }
                              large={
                                process.env.REACT_APP_APIPATH +
                                "/api/images/" +
                                transaction.transactionReceipt
                              }
                            />
                          </div>
                        )}
                      </td>
                      <td>{transaction.transactionAmount.toLocaleString()}</td>
                      <td className="text-ellipses">
                        {transaction.transactionDescription}
                      </td>
                      <td className="text-center">
                          
                          <span className={`badge ${transaction.transactionBy == 'Local' ? 'badge-dark' : 'light'}`}>
                                {transaction.transactionBy}
                          </span>
                        
                      </td>
                      <td className="text-center">
                      
                            <span className={`badge ${transaction.transactionType == 'DEPOSIT' ? 'badge-success' : 'badge-info'}`}>
                                {transaction.transactionType}<br />
                                <i><small>{transaction.transactionSubType}</small></i> 
                            </span>
                        
                      </td>
                      <td>
                        {date_time_to_date_format(
                          transaction.transactionDate
                        )}
                      </td>
                    </tr>
                  );
                
                
              })}
              
            </tbody>
            <thead className="bg-black-custom text-uppercase">
              <tr className="text-white">
                  <th></th>
                  <th></th>
                  <th>Total Amount: {parseInt(totalTransactions).toLocaleString()}</th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
              </tr>
            </thead>
          </table>

          <CSVLink
            data={applyFilters(transactions)}
            headers={[
              { label: "Receipt", key: "transactionReceipt" },
              { label: "Amount", key: "transactionAmount" },
              { label: "Account", key: "accountTitle" },
              { label: "Description", key: "transactionDescription" },
              { label: "Transaction Date", key: "transactionDate" },
            ]}
            filename={"transaction-list.csv"}
            className="hidden"
            ref={tableRef}
          />
        </div>
      </div>
      

      <div className="col-lg-6 text-left">
        {loading ? (
                <div className="text-muted"><h3>Loading...</h3> </div>
            ) : (
                <div className="col-lg-12">
                </div>
            )}
            <p>Balance: {balance}</p>
        <p>Total Number of Transactions: {count?.toLocaleString()}</p>
      </div>
      <div className="col-lg-6">
        <nav className="pagination justify-content-end">
          <ul className="pagination">
            <li
              className={`page-item ${
                currentPage == 1 ? "disabled" : ""
              }`}
            >
              <button
                className="page-link"
                onClick={() => paginate(currentPage - 1)}
              >
                Previous
              </button>
            </li>
            {renderPageNumbers}
            <li
              className={`page-item ${
                currentPage == Math.ceil(transactions?.length / itemsPerPage)
                  ? "disabled"
                  : ""
              }`}
            >
              <button
                className="page-link"
                onClick={() => paginate(currentPage + 1)}
              >
                Next
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
}

export default ListAllTransactions;
