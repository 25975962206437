import React, {useEffect} from "react";
import {
    addEditCustomer,
    get_response_or_errors, getCustomer,getBuildings, getRooms,
    remove_session
} from "../../../functions/general";
import {useNavigate, useParams} from "react-router-dom";
import Select from "react-select";
import countryList from 'react-select-country-list'

function EditCustomers(props) {
    const navigate = useNavigate();
    const params = useParams();
    const [loading, setLoading] = React.useState(false);
    const [response, setResponse] = React.useState("");
    const [name, setName] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [phone, setPhone] = React.useState("");
    // const [passport, setPassport] = React.useState("");
    const [emiratesId, setEmiratesId] = React.useState("");
    const [emiratesIdImage, setEmiratesIdImage] = React.useState("");
    const [country, setCountry] = React.useState("");
    const [countryValue, setCountryValue] = React.useState("");
    const [city, setCity] = React.useState("");
    const [state, setState] = React.useState("");
    const [address, setAddress] = React.useState("");
    const [status, setStatus] = React.useState(1);
    const [errors, setErrors] = React.useState("");
    const [gracePeriod, setGracePeriod] = React.useState("");
    const [selectedOption, setSelectedOption] = React.useState("");
    const [members, setMembers] = React.useState("");
    const [selectedType, setSelectedType] = React.useState("");
    const [trade, setTrade] = React.useState("");
    // const [buildings, setBuildings] = React.useState([]);
    // const [rooms, setRooms] = React.useState([]);
    // const [buildingId, setBuildingId] = React.useState("");
    // const [buildingOptions, setBuildingOptions] = React.useState([]);
    // const [buildingIdValue, setBuildingIdValue] = React.useState("");
    // const [roomId2, setRoomId2] = React.useState("");
    // const [roomId, setRoomId] = React.useState("");
    // const [roomOptions, setRoomOptions] = React.useState([]);
    // const [roomIdValue, setRoomIdValue] = React.useState("");

    const countries = React.useMemo(() => countryList().getData(), []);
    const countriesOptions = []
    countries?.length > 0 && countries.map((contry) => {
        countriesOptions.push({label: contry.label, value: contry.label})
    })
    useEffect(() => {
        getCustomer(params.id).then(res => {
            if (res && res.status == 200) {
                if(res.data.data){
                    setName(res.data.data.name);
                    setEmail(res.data.data.email);
                    setPhone(res.data.data.phone);
                    // setPassport(res.data.data.passport);
                    setEmiratesId(res.data.data.emiratesId);
                    if(res.data.data.emiratesIdImage) {
                        setEmiratesIdImage(process.env.REACT_APP_APIPATH + "/api/images/" + res.data.data.emiratesIdImage);
                    }
                    setCountry(res.data.data.country);
                    setCountryValue({label: res.data.data.country, value: res.data.data.country});
                    setCity(res.data.data.city);
                    setState(res.data.data.state);
                    setAddress(res.data.data.address);
                    setStatus(res.data.data.status);
                    setGracePeriod(res.data.data.gracePeriod);
                    setSelectedOption(res.data.data.category);
                    setMembers(res.data.data.no_of_members);
                    setSelectedType(res.data.data.type);
                    setTrade(res.data.data.trade_liscence);
                } else {
                    navigate('/customers');
                }
            } else if (res && res.status == 203) {
                remove_session();
                props.getLoggedInUser();
                navigate('/login');
            }
        })
        // getBuildings().then(res => {
        //     if (res && res.status == 200) {
        //         setBuildings(res.data.data);
        //         const buildingOptionsTemp = [];
        //         res.data.data?.length > 0 && res.data.data.map((buildingValue) => {
        //             buildingOptionsTemp.push({label: buildingValue.name + " ( " + buildingValue.ownerName + " )", value: buildingValue.id})
        //         })
        //         setBuildingOptions(buildingOptionsTemp)
        //     } else if (res && res.status == 203) {
        //         remove_session();
        //         props.getLoggedInUser();
        //         navigate('/login');
        //     } else {
        //     }
        // })
        // getRooms().then(res => {
        //     if (res && res.status == 200) {
        //         setRooms(res.data.data);
        //     } else if (res && res.status == 203) {
        //         remove_session();
        //         props.getLoggedInUser();
        //         navigate('/login');
        //     } else {
        //     }
        // })
    }, [])
    const onSubmit = (e) => {
        e.preventDefault()
        setResponse("");
        if (loading)
            return false;
        if (validate()) {
            setLoading(true);
            const customer = {
                id: params.id,
                name: name,
                email: email,
                phone: phone,
                // passport: passport,
                emiratesId: emiratesId,
                emiratesIdImage: emiratesIdImage,
                country: country,
                city: city,
                state: state,
                address: address,
                // buildingId: buildingId,
                // roomId: roomId,
                gracePeriod:gracePeriod,
                status: status,
                category: selectedOption,
                no_of_members: members,
                type: selectedType,
                trade_liscence:trade
            }
            addEditCustomer(customer).then(res => {
                if (res && res.status == 203) {
                    remove_session();
                    props.getLoggedInUser();
                    navigate('/login');
                } else {
                    setResponse(get_response_or_errors(res))
                    setErrors(get_response_or_errors(res))
                    setLoading(false)
                }
            })
        }
    }
    const validate = () => {
        let errors = {
            name: '',
            email:'',
            // buildingId: '',
            // roomId: '',
            phone: '',
            emiratesId: '',
            country: '',
            city: '',
            state: '',
            address: '',
            selectedOption: '',
            selectedType:'',
            members: '',
            trade:''
        };
        let isValid = true;
        if (!name) {
            isValid = false;
            errors.name = "Please enter name.";
        }

        if (!email) {
            isValid = false;
            errors.email = "Please enter email.";
        }
        if (!phone) {
            isValid = false;
            errors.phone = "Please enter phone.";
        }
        if (!emiratesId) {
            isValid = false;
            errors.emiratesId = "Please enter emirates id.";
        }
        if (!country) {
            isValid = false;
            errors.country = "Please enter nationality.";
        }
        if (!city) {
            isValid = false;
            errors.city = "Please enter city.";
        }
        if (!state) {
            isValid = false;
            errors.state = "Please enter state.";
        }
        if (!address) {
            isValid = false;
            errors.address = "Please enter address.";
        }
        // if (!buildingId) {
        //     isValid = false;
        //     errors.buildingId = "Please select building.";
        // }
        // if (!roomId) {
        //     isValid = false;
        //     errors.roomId = "Please select room.";
        // }
        if (!selectedOption) {
            isValid = false;
            errors.selectedOption = "Please enter Category.";
           
        }
        if (!selectedType) {
            isValid = false;
            errors.selectedType = "Please enter Type.";
        }
        if((selectedOption && selectedOption == "family") && !members){
            isValid = false;
            errors.members = "Please enter Members.";
        }
        if((selectedType && selectedType == "company") && !trade){
            isValid = false;
            errors.trade = "Please enter Trade Liscence.";
        }
        setErrors(errors);
        return isValid;
    }
    const imageUploadHandler = (e) => {
        var file = e.target.files[0];
        console.log("file",file)
        var reader = new FileReader();
        var url = reader.readAsDataURL(file);

        reader.onloadend = function (e) {
            setEmiratesIdImage(reader.result)

        }.bind(this);
    }
    const handleCountryChange = (selectedOption) => {
        setCountry(selectedOption.value)
        setCountryValue(selectedOption)
    };
    const handleSelectChange = (e) => {
        setSelectedOption(e.target.value);
        // Reset members field when the user changes the selection
        setMembers('');
      };

    const handleTypeSelectChange = (e) => {
        setSelectedType(e.target.value);
        setTrade('');
    };
    
      const handleMembersChange = (e) => {
        setMembers(e.target.value);
      };
    //   const handleBuildingChange = (selectedOption) => {
    //     setBuildingId(selectedOption.value)
    //     setBuildingIdValue(selectedOption)
    //     setRoomId('')
    //     setRoomIdValue('')
    //     setBuildingIdValue(selectedOption)
    //     const roomOptionsTemp = [];
    //     rooms?.length > 0 && rooms.map((roomValue) => {
    //         if(roomValue.status == '1' && roomValue.buildingId == selectedOption.value && (roomValue.isOnRent == 0 || roomValue.id == roomId2)) {
    //             roomOptionsTemp.push({label: roomValue.name, value: roomValue.id})
    //         }
    //     })
    //     setRoomOptions(roomOptionsTemp)
    // };
    // const handleRoomChange = (selectedOption) => {
    //     setRoomId(selectedOption.value)
    //     setRoomIdValue(selectedOption)
    // };
    return (
        <>
        <div className="col-lg-12">
          <div className="d-flex flex-wrap align-items-center justify-content-between mb-4">
              <div>
                  <h4 className="mb-3">Edit Tenants</h4>
              </div>
          </div>
        </div>
        <div className="col-sm-12">
          <div className="card">
              <div className="bg-black-custom card-header d-flex justify-content-between rounded-top">
                  <div className="header-title">
                      <h4 className="card-title text-white">Edit Tenant</h4>
                  </div>
              </div>
              <div className="card-body">
                  <form noValidate onSubmit={onSubmit}>
                      {response?.responseMessage &&
                      <div
                          className={`alert alert-${response?.responseFlag} fade show`}
                          role="alert">
                          {response?.responseMessage}
                      </div>
                      }
                      <div className="row">
                        {/* <div div className="col-md-6">
                            <div className="form-group">
                                <label>Building/Villa/Camp *</label>
                                <Select
                                    value={buildingIdValue}
                                    onChange={handleBuildingChange}
                                    options={buildingOptions}
                                />
                                <div className="text-warning font-size-12">{errors.buildingId}</div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Room *</label>
                                <Select
                                    value={roomIdValue}
                                    onChange={handleRoomChange}
                                    options={roomOptions}
                                />
                                <div className="text-warning font-size-12">{errors.roomId}</div>
                            </div>
                        </div> */}
                          <div className="col-md-6">
                              <div className="form-group">
                                  <label>Name *</label>
                                  <input type="text" className="form-control" placeholder="Enter Name"
                                         value={name} onChange={(e) => setName(e.target.value)}/>
                                  <div className="text-warning font-size-12">{errors.name}</div>
                              </div>
                          </div>

                          <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Email </label>
                                        <input type="text" className="form-control" placeholder="Enter Email"
                                                value={email} onChange={(e) => setEmail(e.target.value)}/>
                                        <div className="text-warning font-size-12">{errors.email}</div>
                                    </div>
                                </div>

                          <div className="col-md-6">
                              <div className="form-group">
                                  <label>Phone Number *</label>
                                  <input type="text" className="form-control" placeholder="Enter Phone Number"
                                         value={phone} onChange={(e) => setPhone(e.target.value)}/>
                                  <div className="text-warning font-size-12">{errors.phone}</div>
                              </div>
                          </div>
                          {/* <div className="col-md-6">
                              <div className="form-group">
                                  <label>Passport</label>
                                  <input type="text" className="form-control" placeholder="Enter Emirates Id"
                                         value={passport} onChange={(e) => setPassport(e.target.value)}/>
                                  <div className="text-warning font-size-12">{errors.passport}</div>
                              </div>
                          </div> */}
                          <div className="col-md-6">
                              <div className="form-group">
                                  <label>Emirates ID/Passport</label>
                                  <input type="text" className="form-control" placeholder="Enter Emirates Id"
                                         value={emiratesId} onChange={(e) => setEmiratesId(e.target.value)}/>
                                  <div className="text-warning font-size-12">{errors.emiratesId}</div>
                              </div>
                          </div>
                          <div className="col-md-12">
                              <div className="form-group">
                                  <label>Emirates Id Image</label>
                                  <div className="custom-file">
                                      <input type="file" className="custom-file-input" id="receipt" onChange={e => imageUploadHandler(e)}  accept="image/*"/>
                                      <label className="custom-file-label" htmlFor="receipt">Choose
                                          file</label>
                                  </div>
                              </div>
                          </div>
                          <div className="col-md-12 mb-3">
                              {emiratesIdImage && (
                                  <img className="border rounded" style={{maxHeight: '100px'}}
                                       src={emiratesIdImage}
                                       alt=""/>
                              )}
                          </div>
                            <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="mySelect">Select Category *:</label>
                                        <select id="mySelect" value={selectedOption} className="form-control" onChange={handleSelectChange}>
                                            <option value="" >Select</option>
                                            <option value="family">Family</option>
                                            <option value="bachelor">Bachelor</option>
                                        </select>
                                        <div className="text-warning font-size-12">{errors.selectedOption}</div>
                                    </div>
                            </div>
                            {selectedOption == "family" && (
                            <div className="col-md-6">
                                <div className="form-group">
                                    <div>
                                        <label htmlFor="members">Members *:</label>
                                        <input
                                            className="form-control"
                                            type="number"
                                            id="members"
                                            value={members}
                                            onChange={handleMembersChange}
                                        />
                                            <div className="text-warning font-size-12">{errors.members}</div>
                                    </div>
                               
                                </div>
                            </div> )}
                             {/* Type */}
                            <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="tenant">Tenant Type *:</label>
                                        <select id="tenant" value={selectedType} className="form-control" onChange={handleTypeSelectChange}>
                                            <option value="" >Select</option>
                                            <option value="indvidual">Indvidual</option>
                                            <option value="company">Company</option>
                                        </select>
                                        <div className="text-warning font-size-12">{errors.selectedType}</div>
                                    </div>
                            </div>
                            {/* if type is company */}
                            {selectedType == "company" && (
                            <div className="col-md-6">
                                <div className="form-group">
                                    <div>
                                        <label htmlFor="trade">Trade Liscence *:</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            id="trade"
                                            value={trade} onChange={(e) => setTrade(e.target.value)}/>
                                        
                                        <div className="text-warning font-size-12">{errors.trade}</div>
                                    </div>
                              
                                </div>
                            </div>  )}
                          <div className="col-md-6">
                              <div className="form-group">
                                  <label>Country *</label>
                                  <Select
                                      value={countryValue}
                                      onChange={handleCountryChange}
                                      options={countriesOptions}
                                  />
                                  <div className="text-warning font-size-12">{errors.country}</div>
                              </div>
                          </div>
                          <div className="col-md-6">
                              <div className="form-group">
                                  <label>City *</label>
                                  <input type="text" className="form-control" placeholder="Enter City"
                                         value={city} onChange={(e) => setCity(e.target.value)}/>
                                  <div className="text-warning font-size-12">{errors.city}</div>
                              </div>
                          </div>
                          <div className="col-md-6">
                              <div className="form-group">
                                  <label>State *</label>
                                  <input type="text" className="form-control" placeholder="Enter State"
                                         value={state} onChange={(e) => setState(e.target.value)}/>
                                  <div className="text-warning font-size-12">{errors.state}</div>
                              </div>
                          </div>
                          <div className="col-md-12">
                              <div className="form-group">
                                  <label>Address *</label>
                                  <textarea className="form-control" rows="4" value={address}
                                            onChange={(e) => setAddress(e.target.value)}></textarea>
                                  <div className="text-warning font-size-12">{errors.address}</div>
                              </div>
                          </div>
                          <div className="col-md-6">
                              <div className="form-group">
                                  <label>Status *</label>
                                  <select className="form-control"
                                          value={status} onChange={(e) => setStatus(e.target.value)}>
                                      <option value="1">Active</option>
                                      <option value="2">Inactive</option>
                                  </select>
                                  <div className="text-warning font-size-12">{errors.state}</div>
                              </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                                <label>Add Grace period (days)</label>
                                <input type="number" className="form-control" placeholder="Enter Grace period (days)"
                                        value={gracePeriod} onChange={(e) => setGracePeriod(e.target.value)}/>
                                <div className="text-warning font-size-12">{errors.gracePeriod}</div>
                            </div>
                        </div>
                      </div>
                      <button type="submit" className="btn btn-primary mr-2">Update</button>
                  </form>
              </div>
          </div>
        </div>
        </>
    );
}

export default EditCustomers;
