import React, {useEffect} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import { getOwner,deleteOwnerDocuments,addOwnerDocuments,get_response_or_errors, remove_session} from "../../../functions/general";
import ModalImage from "react-modal-image";

function OwnerDetails(props) {
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState(false);
    const [response, setResponse] = React.useState("");
    const params = useParams();
    const [documents, setDocuments] = React.useState("");
    const [name, setName] = React.useState("");
    const [previewdocuments, setOwnerDocs] = React.useState([]);
    const [owner, setOwner] = React.useState([]);
    const [errors, setErrors] = React.useState("");
    const onSubmit = (e) => {
        e.preventDefault()
        setResponse("");
        if (loading)
            return false;
        if (validate()) {
            setLoading(true);
            const owner = {
                id: params.id,
                name:name,
                documents:documents,
            }
            addOwnerDocuments(owner).then(res => {
                if (res && res.status == 200) {
                    setOwnerDocs(res.data.documents);
                } else if (res && res.status == 203) {
                    remove_session();
                    props.getLoggedInUser();
                    navigate('/login');
                } else {
                    setResponse(get_response_or_errors(res))
                    setLoading(false)
                }
            })
        }
    }
    const validate = () => {
        let errors = {
            documents: [],
            name: '',
        };
        let isValid = true;
        if (!documents) {
            isValid = false;
            errors.documents = "Please Choose Document.";
        }
        if (!name) {
            isValid = false;
            errors.name = "Please Enter name.";
        }
        setErrors(errors);
        return isValid;
    }
    const deleteHandler = (e, docId,path) => {
        e.preventDefault();
        const owner = {
            id: params.id,
            doc_id: docId,
            path: path,
        }
        if (window.confirm("Are you sure you want to delete document?") === true) {
            deleteOwnerDocuments(owner).then((res) => {
                if (res && res.status === 200) {
                    setOwnerDocs(res.data.documents);
                } else if (res && res.status === 203) {
                    remove_session();
                    props.getLoggedInUser();
                    navigate("/login");
                }
            });
        }
      };
    const docUploadHandler = (e) => {
        const files = e.target.files;
    
        // Ensure there are files to process
        if (files?.length > 0) {
            const newDocs = [];
    
            // Loop through each selected file
            for (let i = 0; i < files?.length; i++) {
                const file = files[i];
                const reader = new FileReader();
    
                reader.onloadend = function () {
                    // Append the base64 data of the file to the array
                    newDocs.push({
                        name: file.name,
                        type: file.type,
                        size: file.size,
                        data: reader.result,
                    });
    
                    // If all files are processed, update the state with the array of documents
                    if (i === files?.length - 1) {
                    //    console.log(newDocs);
                       setDocuments(newDocs);
                    }
                };
    
                // Read the file as data URL
                reader.readAsDataURL(file);
            }
        }
    }
    useEffect(() => {
        getOwner(params.id).then(res => {
            if (res && res.status == 200) {
                if(res.data.data){
                    setOwner(res.data.data);
                    setOwnerDocs(res.data.documents);
                } else {
                    navigate('/owners');
                }
            } else if (res && res.status == 203) {
                remove_session();
                props.getLoggedInUser();
                navigate('/login');
            }
        })
    }, [])
  return (
    <>
        <div className="col-lg-12 my-4">
            <div className="d-flex flex-wrap align-items-center justify-content-between mb-4">
                <div>
                    <h4 className="mb-3">Owner Detail</h4>
                </div>
            </div>
        </div>
        <div className="col-sm-6">
            <div className="card">
                <div className="bg-black-custom card-header d-flex justify-content-between rounded-top">
                    <div className="header-title">
                        <h4 className="card-title text-white">{owner.name}</h4>
                    </div>
                </div>
                <div className="card-body">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="row justify-content-between form-group">
                                <div className="col-md-6">
                                    <label>Owner: </label> {owner.name}
                                </div>
                                <div className="col-md-6">
                                    <label>Email: </label> {owner.email !== '' ? owner.email : '--'} &nbsp;&nbsp;&nbsp;&nbsp;
                                </div>
                                <div className="col-md-6">
                                    <label>Phone: </label> {owner.phone} &nbsp;&nbsp;&nbsp;&nbsp;
                                </div>
                                <div className="col-md-6">
                                    <label>City: </label> {owner.city} &nbsp;&nbsp;&nbsp;&nbsp;
                                </div>
                                <div className="col-md-6">
                                    <label>State: </label> {owner.state}
                                </div>
                                <div className="col-md-6">
                                    <label>Address: </label> {owner.address}
                                </div>
                                <div className="col-md-6">
                                    <label>Emirates Id: </label> {owner.id} 
                                    {owner.emiratesIdImage && (
                                        <div className="transactionTableImage">
                                        <ModalImage
                                            small={
                                            process.env.REACT_APP_APIPATH +
                                            "/api/images/" +
                                            owner.emiratesIdImage
                                            }
                                            large={
                                            process.env.REACT_APP_APIPATH +
                                            "/api/images/" +
                                            owner.emiratesIdImage
                                            }
                                        />
                                        </div>
                                    )}
                                </div>
                                <div className="col-md-6"> 
                                <label>Status: </label> {owner.status == 1 ? 
                                    (<span className="text-success font-weight-bold">Active</span>) :
                                    (<span className="text-danger font-weight-bold">Inactive</span>)}
                                </div>
                                {/* <div className="col-md-6">
                                    <label>No of Cheques: </label> {building.cheques}
                                </div>

                                <div className="col-md-6">
                                    <label>Account Number: </label> {building.accountNumber}
                                </div>

                                <div className="col-md-6">
                                    <label>Dewa: </label> {building.dewa === '1' ? 'Included' : 'Not Included'} 
                                </div>

                                <div className="col-md-6">
                                <label>Maintenance Charges: </label> {building.maintenance === '1' ? 'Included' : 'Not Included'}
                                </div>

                                <div className="col-md-6">
                                <label>Security Deposit: </label> {building.securityDeposit}
                                </div>
                                <div className="col-md-6">
                                <label>Total Rooms: </label> {roomsCount}
                                </div>

                                <div className="col-md-6">
                                {building.startDate &&
                                        <>
                                            <label>Start Date: </label> {building.startDate}
                                        </>}
                                </div>
                                <div className="col-md-6">
                                {building.endDate &&
                                    <>
                                        <label>End Date: </label> {building.endDate}
                                    </>}
                                    </div>*/}

                                </div> 
                            </div>
                        </div>
                </div>
            </div>
        </div>
        <div className="col-12">
        <h4 className="card-title text-dark">Documents</h4>
                                <div className="row">
                                
                                    <div className="text-end col-12">
                                        <div className="col-12">
                                            <form className="row " noValidate onSubmit={onSubmit}>
                                                <div className="col-12">
                                                    {response?.responseMessage &&
                                                    <div
                                                        className={`alert alert-${response?.responseFlag} fade show`}
                                                        role="alert">
                                                        {response?.responseMessage}
                                                    </div>
                                                    }
                                                </div>
                                                <div className="col-md-8">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <input type="text" className="form-control" placeholder="Enter Name"
                                                                        value={name} onChange={(e) => setName(e.target.value)}/>
                                                                <div className="text-warning font-size-12">{errors.name}</div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <div className="custom-file">
                                                                    <input type="file" className="custom-file-input" id="doc" onChange={e => docUploadHandler(e)} multiple/>
                                                                    <label className="custom-file-label" htmlFor="receipt">Choose
                                                                        file</label>
                                                                    <div className="text-warning font-size-12">{errors.documents}</div>
                                                                    {documents && (documents?.length +' Files')} 
                                                                </div>
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                                <div className="col-lg-1 col-md-1">
                                                    <button type="submit" className="btn btn-primary mr-2 w-100">Add</button> 
                                                </div>
                                                {/* <div className="col-lg-1 col-md-1">
                                                    
                                                </div> */}
                                            </form>
                                        </div>  
                                        <div className="col-md-12">
                                            <div className="table-responsive rounded mb-3">
                                            <table className="data-table table mb-0 tbl-server-info">
                                                <thead className="bg-black-custom text-uppercase">
                                                <tr className="text-white">
                                                    <th>No</th>
                                                    <th>Document</th>
                                                    <th>Action</th>
                                                </tr>
                                                </thead>
                                                <tbody className="bg-white">
                                                    { previewdocuments?.length > 0 && previewdocuments.map((document, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>{index+1}</td>
                                                                <td>
                                                                    <h4> <a href={process.env.REACT_APP_APIPATH +"/api/uploads/" +document.path} target="_blank" > <i className="ri-download-fill mr-0"></i> {document.name}</a></h4> 
                                                                </td>
                                                                <td>
                                                                    <a onClick={(e) => deleteHandler(e, document.id,document.path)}
                                                                        className="badge bg-primary mr-2"
                                                                        data-toggle="tooltip"
                                                                        data-placement="top"
                                                                        title=""
                                                                        data-original-title="Delete"
                                                                        href="#" >
                                                                        <i className="ri-delete-bin-line mr-0"></i>
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                            
                                                </tbody>
                                            </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                    </div>
    </>
  )
}

export default OwnerDetails


