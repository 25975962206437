import React from "react";
import {get_response_or_errors, remove_session, updatePassword} from "../../functions/general";
import {useNavigate} from "react-router-dom";

function Profile(props) {
  const navigate = useNavigate();
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [response, setResponse] = React.useState("");
  const [errors, setErrors] = React.useState({});
  const onSubmit = (e) => {
    e.preventDefault()
    setResponse("");
    if (loading)
      return false;
    if (validate()) {
      setLoading(true);
      const user = {
        password: password,
      }
      updatePassword(user).then(res => {
        if (res && res.status == 203) {
          remove_session();
          props.getLoggedInUser();
          navigate('/login');
        } else {
          setResponse(get_response_or_errors(res))
          setErrors(get_response_or_errors(res))
          setLoading(false)
        }
      })
    }
  }
  const validate = () => {
    let errors = {
      password: '',
      confirmPassword: ''
    };
    let isValid = true;
    if (!password) {
      isValid = false;
      errors.password = "Please enter password.";
    }
    if (!confirmPassword) {
      isValid = false;
      errors.confirmPassword = "Please enter confirm password.";
    }
    if(password && confirmPassword && confirmPassword != password){
      isValid = false;
      errors.confirmPassword = "Password does not match.";
    }
    setErrors(errors);
    return isValid;
  }
  return (
      <>
        <div className="col-lg-12">
          <div className="d-flex flex-wrap align-items-center justify-content-between mb-4">
            <div>
              <h4 className="mb-3">Profile</h4>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-lg-6">
          <div className="card">
            <div className="card-header d-flex justify-content-between">
              <div className="header-title text-center">
                <h4 className="card-title text-center">Change Password</h4>
              </div>
            </div>
            <div className="card-body">
              <form noValidate onSubmit={onSubmit}>

                {response?.responseMessage &&
                <div
                    className={`alert alert-${response?.responseFlag} fade show`}
                    role="alert">
                  {response?.responseMessage}
                </div>
                }
                  <div className="form-group">
                    <label>Password<span className="text-danger">*</span></label>
                    <input type="password" className="form-control" placeholder="Enter Password"
                           value={password} onChange={(e) => setPassword(e.target.value)}/>
                    <div className="text-warning font-size-12">{errors.password}</div>
                  </div>

                  <div className="form-group">
                    <label>Confirm Password<span className="text-danger">*</span></label>
                    <input type="password" className="form-control" placeholder="Enter Password"
                           value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)}/>
                    <div className="text-warning font-size-12">{errors.confirmPassword}</div>
                  </div>
                  <div className="text-center">
                      <button type="submit" className="btn btn-primary">Submit</button>
                  </div>
                
              </form>
            </div>
          </div>
        </div>
      </>
  );
}

export default Profile;
