import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { deleteOwner, getOwners, remove_session } from "../../../functions/general";
import ModalImage from "react-modal-image";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Dropdown from 'react-bootstrap/Dropdown';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';


function ListOwners(props) {
  const currentUser = props.currentUser;
  const navigate = useNavigate();
  const [owners, setOwners] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchStatus, setSearchStatus] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(-1);
  const tableRef = useRef();

  useEffect(() => {
    getOwners().then((res) => {
      if (res && res.status == 200) {
        setOwners(res.data.data);
        setItemsPerPage(res.data.data.length);
      } else if (res && res.status == 203) {
        remove_session();
        props.getLoggedInUser();
        navigate("/login");
      }
    });
  }, []);

  const deleteHandler = (e, id) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want to delete owner?") == true) {
      deleteOwner(id).then((res) => {
        if (res && res.status == 200) {
          setOwners(res.data.data);
        } else if (res && res.status == 203) {
          remove_session();
          props.getLoggedInUser();
          navigate("/login");
        }
      });
    }
  };

  const applyFilters = (owners) => {
    return owners.filter(owner => {
      const status = (owner.status == 2) ? 2 : 1;
    
      // Check if the status matches the search status
      if (searchStatus && searchStatus == status) {
        return false;
      }
  
      // Create a concatenated string of relevant fields
      const tempString = `${owner.name} ${owner.emiratesId} ${owner.phone} ${owner.email} ${owner.emiratesIdImage} ${owner.address}`;
  
      // Check if the search query matches any of the relevant fields
      if (searchQuery && !tempString.match(new RegExp(searchQuery, 'i'))) {
        return false;
      }
  
      return true;
    });
};

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentOwners = owners?.slice(indexOfFirstItem, indexOfLastItem);

  const pageNumbers = Array.from(
    { length: Math.ceil(owners?.length / itemsPerPage) },
    (_, i) => i + 1
  );

  const renderPageNumbers = pageNumbers.map((number) => {
    if (
      number == 1 ||
      number == currentPage ||
      number == currentPage - 1 ||
      number == currentPage + 1 ||
      number == pageNumbers?.length
    ) {
      return (
        <li
          key={number}
          className={`page-item ${currentPage == number ? "active" : ""}`}
        >
          <button className="page-link" onClick={() => paginate(number)}>
            {number}
          </button>
        </li>
      );
    }
    return null;
  });

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleItemsPerPageChange = (e) => {
      const value = Number(e.target.value);
      if (value == -1) {
          // Show all items
          setItemsPerPage(owners.length);
      } else {
          // Set items per page to the selected value
          setItemsPerPage(value);
      }
      setCurrentPage(1); // Reset to first page when changing items per page
  };

  const exportToCSV = () => {
    tableRef.current.link.click();
  };

  const exportToExcel = () => {
    const filteredOwners = applyFilters(currentOwners);
    const ws = XLSX.utils.json_to_sheet(filteredOwners);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Owners List");
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" });
    saveAs(data, "owners-list.xlsx");
  };

  const exportToPDF = () => {
    const filteredOwners = applyFilters(currentOwners);
    const unit = "pt";
    const size = "A4";
    const orientation = "landscape";

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);
    const title = "Owners List";
    const headers = [
      ["Name", "Phone", "Emirates Id", "City", "State", "Address", "Status"],
    ];

    const data = filteredOwners.map((owner) => [
      owner.name,
      owner.phone,
      owner.emiratesId,
      owner.city,
      owner.state,
      owner.address,
      owner.status == 1 ? "Active" : "Inactive",
    ]);

    let content = {
      startY: 50,
      head: headers,
      body: data,
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("owners-list.pdf");
  };



  return (
    <>
      <div className="col-lg-12">
        <div className="d-flex flex-wrap align-items-center justify-content-between mb-4">
          <div>
            <h4 className="mb-3">Owners List</h4>
          </div>
          <div>
          {currentUser && currentUser.isSuperAdmin == "1" && (
          <Link to="/owners/add" className="btn btn-primary add-list mx-2 ">
                    <i className="las la-plus mr-3"></i>Add Owner
                  </Link>
           )}
          </div>
        
        </div>
      </div>

      <div className="col-lg-12">
      <div className="row  align-items-end px-3">
          <div className="col-lg-2 col-md-3 col-sm-6">
            <div className="form-group w-100 ">
              <label>Show per page:</label>
              <select
                className="form-control"
                value={itemsPerPage === owners.length ? -1 : itemsPerPage}
                onChange={handleItemsPerPageChange}
              >
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={30}>30</option>
                <option value={-1}>Show All</option>
              </select>
            </div>
          </div>

            <div className="col-lg-2 col-md-3 col-sm-6">

          <div className="form-group">
          <label>Export</label>
          <Dropdown className="w-100">
              <Dropdown.Toggle variant="light" id="dropdown-basic" className="w-100">
                Select Export Type
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item href="#/action-1" onClick={exportToCSV}>Export to CSV</Dropdown.Item>
                <Dropdown.Item href="#/action-2" onClick={exportToExcel}>Export to Excel</Dropdown.Item>
                <Dropdown.Item href="#/action-3" onClick={exportToPDF}>Export to PDF</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          </div>


          <div className="col-md-3 col-sm-6">
            <div className="form-group">
              <label>Search</label>
              <input
                type="text"
                className="form-control"
                placeholder=""
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
          </div>

          <div className="col-md-3 col-sm-6">
            <div className="form-group">
              <label>Status</label>
              <select
                className="form-control"
                value={searchStatus}
                onChange={(e) => setSearchStatus(e.target.value)}
              >
                <option value="">All</option>
                <option value="2">Active</option>
                <option value="1">Inactive</option>
              </select>
            </div>
          </div>
        
          {/* <div className=" col-md-4 text-end">
              <div className="">
                  <Link to="/owners/add" className="btn btn-primary add-list mx-2 ">
                    <i className="las la-plus mr-3"></i>Add Owner
                  </Link>
                  <button
                    onClick={() => setShowFilters(!showFilters)}
                    className="btn btn-primary add-list mx-2"
                  >
                    Filters
                  </button>


                </div>
          </div> */}
        </div> 
      </div>

      {/* <div className="col-lg-12">
        <div className="d-flex flex-wrap align-items-center justify-content-between mb-4">

          <div>
            <button
              className="btn btn-primary"
              onClick={exportToCSV}
            >
              Export to CSV
            </button>
            <button
              className="btn btn-primary mx-2"
              onClick={exportToExcel}
            >
              Export to Excel
            </button>
            <button
              className="btn btn-primary"
              onClick={exportToPDF}
            >
              Export to PDF
            </button>

            <FaFilePdf size={30} color="#e44d26" />
      <FaFileCsv size={30} color="#008CBA" />
      <FaFileExcel size={30} color="#207245" />
          </div>
        </div>
      </div> */}

      <div className="col-lg-12">
        <div className="table-responsive rounded mb-3">
        <table className="data-table table mb-0 tbl-server-info">
        <thead className="bg-black-custom text-uppercase">
          <tr className="text-white">
            <th>Name</th>
            <th>Emirates&nbsp;Id</th>
            <th>Phone</th>
            <th>Email</th>
            <th>Emirates Id Image</th>
            {/* <th>City</th>
            <th>State</th> */}
            <th>Address</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody className="bg-white">
          {currentOwners.map((owner, index) => {
            const status = (owner.status == 2)? 2:1;
            if (searchStatus && searchStatus == status) {
              return null;
            }
            const tempString =
              owner.name +
              " " +
              owner.emiratesId +
              " " +
              owner.phone +
              " " +
              owner.email +
              " " +
              owner.emiratesIdImage +
              " " +
              owner.address;
            if (
              searchQuery &&
              !tempString.match(new RegExp(searchQuery, "i"))
            ) {
              return null;
            }
            return (
              <tr key={index}>
                <td>
                  <Link to={`/owners/${owner.id}`}>
                    {owner.name}
                  </Link>
                </td>
                <td>
                  {owner.emiratesId}
                 
                </td>
                <td>{owner.phone}</td>
                <td>{owner.email !== '' ? owner.email : '--'}</td>
                <td>
                {owner.emiratesIdImage && (
                    <div className="transactionTableImage">
                      <ModalImage
                        small={
                          process.env.REACT_APP_APIPATH +
                          "/api/images/" +
                          owner.emiratesIdImage
                        }
                        large={
                          process.env.REACT_APP_APIPATH +
                          "/api/images/" +
                          owner.emiratesIdImage
                        }
                      />
                    </div>
                  )}
                </td>
                <td>{owner.address} <br/> {owner.city}, {owner.state}</td>
                <td>
                  {owner.status == 1 ? 
                        (<div className="text-success font-weight-bold">Active</div>) :
                        (<div className="text-danger font-weight-bold">Inactive</div>)}
                </td>
                <td>
                {currentUser && currentUser.isSuperAdmin == "1" && (
                  <div className="d-flex align-items-center list-action">
                    <Link
                      className="badge bg-primary mr-2"
                      data-toggle="tooltip"
                      data-placement="top"
                      title=""
                      data-original-title="Edit"
                      to={"/owners/edit/" + owner.id}
                    >
                      <i className="ri-pencil-line mr-0"></i>
                    </Link>
                    <a onClick={(e) => deleteHandler(e, owner.id)} className="badge bg-primary mr-2" data-toggle="tooltip" data-placement="top"
                          title="" data-original-title="Delete"
                          href="#"><i className="ri-delete-bin-line mr-0"></i></a>
                  </div>
                )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
          {/* CSVLink for exporting to CSV */}
          <CSVLink
            data={applyFilters(currentOwners)}
            headers={[
              { label: "Name", key: "name" },
              { label: "Phone", key: "phone" },
              { label: "Emirates Id", key: "emiratesId" },
              { label: "City", key: "city" },
              { label: "State", key: "state" },
              { label: "Address", key: "address" },
              { label: "Status", key: "status" },
            ]}
            filename={"owners-list.csv"}
            className="hidden"
            ref={tableRef}
          />
          {/* ReactHTMLTableToExcel for exporting to Excel */}
          {/* <ReactHTMLTableToExcel
            id="test-table-xls-button"
            className="hidden"
            table="owners-list"
            filename="owners-list"
            sheet="sheet 1"
            buttonText="Export to Excel"
          /> */}
        </div>
      </div>
      <div className="col-lg-12">
              <div className="row">
              <div className="col-sm-6  text-xl-left text-sm-left text-center">
        <p>Total Number of Owners: {applyFilters(currentOwners)?.length}</p>
      </div>
      <div className="col-sm-6 text-sm-center">
        <nav className="pagination justify-content-xl-end justify-content-sm-end justify-content-center">
          <ul className="pagination">
            <li
              className={`page-item ${
                currentPage == 1 ? "disabled" : ""
              }`}
            >
              <button
                className="page-link"
                onClick={() => paginate(currentPage - 1)}
              >
                Previous
              </button>
            </li>
            {renderPageNumbers}
            <li
              className={`page-item ${
                currentPage == Math.ceil(owners?.length / itemsPerPage)
                  ? "disabled"
                  : ""
              }`}
            >
              <button
                className="page-link"
                onClick={() => paginate(currentPage + 1)}
              >
                Next
              </button>
            </li>
          </ul>
        </nav>
      </div>
      </div>
      </div>
    </>
  );
}

export default ListOwners;




