import React, {useEffect} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import { getAdmin,deleteAdminDocuments,addAdminDocuments,get_response_or_errors, remove_session} from "../../../functions/general";

function AdminDetails(props) {
    const currentUser = props.currentUser;
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState(false);
    const [response, setResponse] = React.useState("");
    const params = useParams();
    const [documents, setDocuments] = React.useState("");
    const [previewdocuments, setAdminDocs] = React.useState([]);
    const [admin, setAdmin] = React.useState([]);
    const onSubmit = (e) => {
        e.preventDefault()
        setResponse("");
        if (loading)
            return false;
        setLoading(true);
        const admin = {
            id: params.id,
            documents:documents,
        }
        addAdminDocuments(admin).then(res => {
            if (res && res.status == 200) {
                setAdminDocs(res.data.documents);
            } else if (res && res.status == 203) {
                remove_session();
                props.getLoggedInUser();
                navigate('/login');
            } else {
                setResponse(get_response_or_errors(res))
                setLoading(false)
            }
        })
    }
    const deleteHandler = (e, docId,path) => {
        e.preventDefault();
        const admin = {
            id: params.id,
            doc_id: docId,
            path: path,
        }
        if (window.confirm("Are you sure you want to delete document?") === true) {
            deleteAdminDocuments(admin).then((res) => {
                if (res && res.status === 200) {
                    setAdminDocs(res.data.documents);
                } else if (res && res.status === 203) {
                    remove_session();
                    props.getLoggedInUser();
                    navigate("/login");
                }
            });
        }
      };
    const docUploadHandler = (e) => {
        const files = e.target.files;
    
        // Ensure there are files to process
        if (files?.length > 0) {
            const newCustomerDocs = [];
    
            // Loop through each selected file
            for (let i = 0; i < files?.length; i++) {
                const file = files[i];
                const reader = new FileReader();
    
                reader.onloadend = function () {
                    // Append the base64 data of the file to the array
                    newCustomerDocs.push({
                        name: file.name,
                        type: file.type,
                        size: file.size,
                        data: reader.result,
                    });
    
                    // If all files are processed, update the state with the array of documents
                    if (i === files?.length - 1) {
                       console.log(newCustomerDocs);
                       setDocuments(newCustomerDocs);
                    }
                };
    
                // Read the file as data URL
                reader.readAsDataURL(file);
            }
        }
    }
    useEffect(() => {
        getAdmin(params.id).then(res => {
            if (res && res.status == 200) {
                if(res.data.data){
                    setAdmin(res.data.data);
                    setAdminDocs(res.data.documents);
                } else {
                    navigate('/admins');
                }
            } else if (res && res.status == 203) {
                remove_session();
                props.getLoggedInUser();
                navigate('/login');
            }
        })
    }, [])
  return (
    <>
        <div className="col-12">
        <h4 className="card-title text-dark my-4">Documents</h4>
                                <div className="row">
                                    <div className="text-end col-12">
                                        <form className="row justify-content-end" noValidate onSubmit={onSubmit}>
                                            <div className="col-12">
                                                {response?.responseMessage &&
                                                <div
                                                    className={`alert alert-${response?.responseFlag} fade show`}
                                                    role="alert">
                                                    {response?.responseMessage}
                                                </div>
                                                }
                                            </div>
                                            <div className="col-lg-4 col-md-4">
                                                <div className="form-group">
                                                
                                                    <div className="custom-file">
                                                        <input type="file" className="custom-file-input" id="doc" onChange={e => docUploadHandler(e)} multiple/>
                                                        <label className="custom-file-label" htmlFor="receipt">Choose
                                                            file</label>
                                                    </div>
                                                </div>
                                                {documents && (documents?.length +' Files')} 
                                            </div>
                                            <div className="col-lg-1 col-md-1">
                                                <button type="submit" className="btn btn-primary mr-2 w-100">Add</button> 
                                            </div>
                                        </form>
                                    </div>  
                                    <div className="col-md-12">
                                        <div className="table-responsive rounded mb-3">
                                        <table className="data-table table mb-0 tbl-server-info">
                                            <thead className="bg-black-custom text-uppercase">
                                            <tr className="text-white">
                                                <th>No</th>
                                                <th>Document</th>
                                                <th>Action</th>
                                            </tr>
                                            </thead>
                                            <tbody className="bg-white">
                                                { previewdocuments?.length > 0 && previewdocuments.map((document, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{index+1}</td>
                                                            <td>
                                                                <h4> <a href={process.env.REACT_APP_APIPATH +"/api/uploads/" +document.path} target="_blank" > <i className="ri-download-fill mr-0"></i></a></h4> 
                                                            </td>
                                                            <td>
                                                                <a onClick={(e) => deleteHandler(e, document.id,document.path)}
                                                                    className="badge bg-primary mr-2"
                                                                    data-toggle="tooltip"
                                                                    data-placement="top"
                                                                    title=""
                                                                    data-original-title="Delete"
                                                                    href="#" >
                                                                    <i className="ri-delete-bin-line mr-0"></i>
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                        
                                            </tbody>
                                        </table>
                                        </div>
                                    </div>
                                </div>
                      
        </div>
    </>
  )
}

export default AdminDetails


