import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { deleteCustomer,getBuildings, getCustomers, remove_session } from "../../../functions/general";
import ModalImage from "react-modal-image";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Dropdown from 'react-bootstrap/Dropdown';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

function ListCustomers(props) {
  const currentUser = props.currentUser;
  const userType = (currentUser && currentUser.isSuperAdmin == 1)? 'superadmin': 'admin';
 
  const navigate = useNavigate();
  const [customers, setCustomers] = useState([]);
  const [name, setName] = useState("");
  const [buildings, setBuildings] = useState([]);
  const [buildingId, setBuildingId] = useState("");
  const [showFilters, setShowFilters] = useState(false);
  const [customerStatus, setCustomerStatus] = useState("");
  const [customerCategory, setCustomerCategory] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(-1);
  const [showPrintModal, setShowPrintModal] = useState(false);
const [printModalData, setPrintModalData] = useState(null);

  const tableRef = useRef();


  useEffect(() => {
    // getBuildings().then(res => {
    //   if (res && res.status === 200) {
    //     const selectedBuildingOptionsTemp = [];
    //     if(userType =='admin'){
    //       if(props.currentUserPrivilege){
    //         const parsedBuildingIds = JSON.parse(props.currentUserPrivilege.building_ids);
    //         res.data.data?.length > 0 && res.data.data.forEach((buildingValue) => {
    //             const value = buildingValue.id;
    //             if(parsedBuildingIds && parsedBuildingIds.includes(value)){ 
    //                 selectedBuildingOptionsTemp.push(buildingValue);
    //             } 
    //         });
    //         setBuildings(selectedBuildingOptionsTemp);
    //       }
         
    //     }else{
    //       setBuildings(res.data.data);
    //     }
    //   } else if (res && res.status === 203) {
    //     remove_session();
    //     props.getLoggedInUser();
    //     navigate('/login');
    //   } else {
    //     // Handle other cases
    //   }
    // });
    getCustomers().then((res) => {
      if (res && res.status === 200) {
        setCustomers(res.data.data);
        setItemsPerPage(res.data.data.length);
      } else if (res && res.status === 203) {
        remove_session();
        props.getLoggedInUser();
        navigate("/login");
      } else {
        // Handle other cases
      }
    });
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
  };

  const deleteHandler = (e, id) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want to delete customer?") === true) {
      deleteCustomer(id).then((res) => {
        if (res && res.status === 200) {
          setCustomers(res.data.data);
        } else if (res && res.status === 203) {
          remove_session();
          props.getLoggedInUser();
          navigate("/login");
        }
      });
    }
  };

  const applyFilters = (customers) => {
      return customers.filter(customer => {
        // Check if the customer status matches the search status
        if (customerStatus && customerStatus !== customer.status) {
          return false;
        }
    
        // Check if the customer category matches the search category
        if (customerCategory && customerCategory !== customer.category) {
          return false;
        }
    
        // Check if the search query matches any of the relevant fields
        const tempString = `${customer.name} ${customer.passport} ${customer.emiratesId} ${customer.phone} ${customer.email} ${customer.country} ${customer.city} ${customer.state} ${customer.address}`;
        if (name && !tempString.match(new RegExp(name, 'i'))) {
          return false;
        }
    
        return true;
      });
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentCustomers = customers?.slice(indexOfFirstItem, indexOfLastItem);

  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = Math.max(1, currentPage - 2); i <= Math.min(currentPage + 2, Math.ceil(customers?.length / itemsPerPage)); i++) {
      pageNumbers.push(
        <li key={i} className={`page-item ${i === currentPage ? 'active' : ''}`}>
          <button className="page-link" onClick={() => setCurrentPage(i)}>
            {i}
          </button>
        </li>
      );
    }
    return pageNumbers;
  };

  const handleItemsPerPageChange = (e) => {
    const value = Number(e.target.value);
    if (value === -1) {
        // Show all items
        setItemsPerPage(customers.length);
    } else {
        // Set items per page to the selected value
        setItemsPerPage(value);
    }
    setCurrentPage(1); // Reset to first page when changing items per page
};

  const exportToCSV = () => {
    tableRef.current.link.click();
  };

  const exportToExcel = () => {
    const filteredCustomers = applyFilters(currentCustomers);
    const ws = XLSX.utils.json_to_sheet(filteredCustomers);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Customerss List");
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" });
    saveAs(data, "customers-list.xlsx");
  };

  const exportToPDF = () => {
    const filteredCustomers = applyFilters(currentCustomers);
    const unit = "pt";
    const size = "A4";
    const orientation = "landscape";

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);
    const title = "Customers List";
    const headers = [
      ["Name", "Phone", "Emirates Id","Country", "City", "State", "Address", "Status"],
    ];

    const data = filteredCustomers.map((owner) => [
      owner.name,
      owner.phone,
      // owner.passport,
      owner.emiratesId,
      owner.country,
      owner.city,
      owner.state,
      owner.address,
      owner.status === 1 ? "Inactive" : "Active",
    ]);

    let content = {
      startY: 50,
      head: headers,
      body: data,
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("customers-list.pdf");
  };
  const handlePrintModal = (customer) => {
    setPrintModalData(customer);
    setShowPrintModal(true);
    printModal(); // Call printModal function when the modal is shown
  };

  const printModal = () => {
    window.print();
  };
  
  return (
    <>
      <div className="col-lg-12">
        <div className="d-flex flex-wrap align-items-center justify-content-between mb-4">
          <div>
            <h4 className="mb-3">Tenants List</h4>
          </div>
        <div>
        {currentUser && currentUser.isSuperAdmin == "1" && (
        <Link to="/customers/add" className="btn btn-primary add-list">
            <i className="las la-plus mr-3"></i>Add Tenant
          </Link>
        )}
        </div>
        </div>
      </div>
      {/* {customers?.length > itemsPerPage && ( */}
        <>
        <div className="col-lg-12">
          <div className="row  align-items-end px-3">
              <div className="col-lg-2 col-md-3 col-sm-6">
              <div className="form-group">
                    <label>Show per page:</label>
                    <select
                      className="form-control"
                      value={itemsPerPage === customers.length ? -1 : itemsPerPage}
                      onChange={handleItemsPerPageChange}
                    >
                      <option value={5}>5</option>
                      <option value={10}>10</option>
                      <option value={20}>20</option>
                      <option value={30}>30</option>
                      <option value={-1}>Show All</option>
                    </select>
                  </div>
              </div>

              <div className="col-lg-2 col-md-3 col-sm-6">

          <div className="form-group">
          <label>Export</label>
          <Dropdown className="w-100">
              <Dropdown.Toggle variant="light" id="dropdown-basic" className="w-100">
                Select Export Type
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item href="#/action-1" onClick={exportToCSV}>Export to CSV</Dropdown.Item>
                <Dropdown.Item href="#/action-2" onClick={exportToExcel}>Export to Excel</Dropdown.Item>
                <Dropdown.Item href="#/action-3" onClick={exportToPDF}>Export to PDF</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          </div>
              <div className="col-md-3 col-sm-6">
              <div className="form-group">
                <label>Search</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder=""
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              </div>
              <div className="col-md-3 col-sm-6">
                <div className="form-group">
                    <label>Status</label>
                    <select
                      className="form-control"
                      value={customerStatus}
                      onChange={(e) => setCustomerStatus(e.target.value)}
                    >
                      <option value="">All</option>
                      <option value="1">Active</option>
                      <option value="2">Inactive</option>
                    </select>
                  </div>
                </div>
                {/* <div className="col-md-3">
                  <div className="form-group">
                    <label>Building/Villa *</label>
                    <select
                      className="form-control"
                      value={buildingId}
                      onChange={(e) => setBuildingId(e.target.value)}
                    >
                      <option value="">All Buildings</option>
                      {buildings?.length > 0 &&
                        buildings.map((building, index) => {
                          if (building.status === '1') {
                            return (
                              <option key={index} value={building.id}>
                                {building.name} ({building.ownerName})
                              </option>
                            );
                          }
                        })}
                    </select>
                  </div>
                </div> */}
                <div className="col-md-3 col-sm-6">
                  <div className="form-group">
                      <label>Category</label>
                      <select
                        className="form-control"
                        value={customerCategory}
                        onChange={(e) => setCustomerCategory(e.target.value)}>
                          <option value="" >All</option>
                          <option value="family">Family</option>
                          <option value="bachelor">Bachelor</option>
                      </select>
                    </div>
                </div>
            </div> 
      </div>
      
          
        </>
      {/* )} */}
      <div className="col-lg-12">
        <div className="table-responsive rounded mb-3">
          <table className="data-table table mb-0 tbl-server-info">
            <thead className="bg-black-custom text-uppercase">
              <tr className="text-white">
                <th>No</th>
                <th>Name</th>
                <th>Emirates&nbsp;Id/ Passport</th>
                <th>Phone No.</th>
                <th>Email</th>
                <th>Country</th>
                <th>City</th>
                <th>State</th>
                <th>Category</th>
                <th>Address</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {currentCustomers.map((customer, index) => {
                if (customerStatus && customerStatus !== customer.status) {
                  return null;
                }
                if (customerCategory && customerCategory !== customer.category) {
                  return null;
                }
                const tempString =
                  customer.name +
                  " " +
                  customer.passport +
                  " " +
                  customer.emiratesId +
                  " " +
                  customer.phone +
                  " " +
                  customer.email +
                  " " +
                  customer.country +
                  " " +
                  customer.city +
                  " " +
                  customer.state +
                  " " +
                  customer.address;
                if (name && !tempString.match(new RegExp(name, "i"))) {
                  return null;
                }
                return (
                  <tr key={index}>
                    <td>{customer.id}</td>
                    <td>
                      <Link to={`/customers/${customer.id}`}>{customer.name}</Link>
                    </td>
                    <td>
                      {(customer.emiratesId)?customer.emiratesId:customer.passport}
                      {customer.emiratesIdImage && (
                        <div className="transactionTableImage">
                          <ModalImage
                            small={process.env.REACT_APP_APIPATH + "/api/images/" + customer.emiratesIdImage}
                            large={process.env.REACT_APP_APIPATH + "/api/images/" + customer.emiratesIdImage}
                          />
                        </div>
                      )}
                    </td>
                    <td>{customer.phone}</td>
                    <td>{customer.email !== '' ? customer.email : '--'}</td>
                    <td>{customer.country}</td>
                    <td>{customer.city}</td>
                    <td>{customer.state}</td>
                    <td><div  className="font-weight-bold">{customer.category} {(customer.category == "family") && ' [Members: '+customer.no_of_members+']'}</div> </td>
                    <td>{customer.address}</td>
                    <td>
                      {customer.status == 1 ? (
                        <div className="text-success font-weight-bold">Active</div>
                      ) : (
                        <div className="text-danger font-weight-bold">Inactive</div>
                      )}
                    </td>
                    <td>
                    {currentUser && currentUser.isSuperAdmin == "1" && (
                      <div className="d-flex align-items-center list-action">
                        <Link
                          className="badge bg-primary mr-2"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Edit"
                          data-original-title="Edit"
                          to={"/customers/edit/" + customer.id}
                        >
                          <i className="ri-pencil-line mr-0"></i>
                        </Link>
                        {/* Uncomment the lines below if delete functionality is needed */}
                        <a
                          onClick={(e) => deleteHandler(e, customer.id)}
                          className="badge bg-primary mr-2"
                          data-toggle="tooltip"
                          data-placement="top"
                          title=""
                          data-original-title="Delete"
                          href="#"
                        >
                          <i className="ri-delete-bin-line mr-0"></i>
                        </a>
                        {/* <Link
                          className="badge bg-primary mr-2"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Print"
                          data-original-title="Print"
                          // onClick={() => handlePrintModal(customer)}
                          to={"/customers/print/" + customer.id}
                        >
                          <i className="ri-printer-line mr-0"></i>
                        </Link> */}

                        <Link
                          className="badge bg-primary mr-2"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Print"
                          data-original-title="Print"
                          // onClick={() => handlePrintModal(customer)}
                          to={"/customers/printform/" + customer.id}
                        >
                          <i className="ri-printer-line mr-0"></i>
                        </Link>
                      </div>
                    )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <CSVLink
            data={applyFilters(customers)}
            headers={[
              { label: "Name", key: "name" },
              { label: "Phone", key: "phone" },
              { label: "Passport", key: "passport" },
              { label: "Emirates Id", key: "emiratesId" },
              { label: "Country", key: "country" },
              { label: "City", key: "city" },
              { label: "State", key: "state" },
              { label: "Address", key: "address" },
              { label: "Status", key: "status" },
            ]}
            filename={"customers-list.csv"}
            className="hidden"
            ref={tableRef}
          />
        </div>

        <div className="col-lg-12">
              <div className="row">
              <div className="col-sm-6 text-xl-left text-sm-left text-center">
        <p>Total Number of Tenants: {applyFilters(customers)?.length}</p>
      </div>
      <div className="col-sm-6">
        <nav className="pagination justify-content-xl-end justify-content-sm-end justify-content-center">
          <ul className="pagination">
        {/* {customers?.length > itemsPerPage && ( */}
      
              <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
                <button className="page-link" onClick={() => setCurrentPage(currentPage - 1)}>
                  Previous
                </button>
              </li>
              {renderPageNumbers()}
              <li className={`page-item ${currentPage === Math.ceil(customers?.length / itemsPerPage) ? "disabled" : ""}`}>
                <button className="page-link" onClick={() => setCurrentPage(currentPage + 1)}>
                  Next
                </button>
              </li>
            </ul>
          </nav>
          </div>
              </div>
        </div>

        
        {/* )} */}
      </div>
    </>
  );
}

export default ListCustomers;
