import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/backend-plugin.min.css'
import './styles/backend.css'
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Helmet} from "react-helmet";
import 'remixicon/fonts/remixicon.css'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <App />
        <Helmet>
            <script src="/assets/js/table-treeview.js"></script>
            {/*<script src="/assets/js/customizer.js"></script>*/}
            <script src="/assets/js/app.js"></script>
        </Helmet>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
