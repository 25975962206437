import React, {useEffect} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import {getBuilding, getBuildings, getOwners, getRoom, remove_session} from "../../../functions/general";
import ListTransactions from "../transactions/ListTransactions";

function RoomDetails(props) {
    const currentUser = props.currentUser;
    const navigate = useNavigate();
    const params = useParams();
    const [room, setRoom] = React.useState([]);
    const [owners, setOwners] = React.useState([]);
    const [showRent, setShowRent] = React.useState(1);
    const [showDewa, setShowDewa] = React.useState(0);
    const [showMaintenance, setShowMaintenance] = React.useState(0);
    const [showSecurityRefund, setShowSecurityRefund] = React.useState(0);
    const [showOtherExpense, setShowOtherExpense] = React.useState(0);
    const [rentCollected, setRentCollected] = React.useState("");
    const [lastTenant, setLastTenant] = React.useState("");
    const [dewaPaid, setDewaPaid] = React.useState("");
    const [maintenancePaid, setMaintenancePaid] = React.useState("");
    useEffect(() => {
        getOwners().then(res => {
            if (res && res.status == 200) {
                setOwners(res.data.data);
            } else if (res && res.status == 203) {
                remove_session();
                props.getLoggedInUser();
                navigate('/login');
            } else {
            }
        })
        getRoom(params.id).then(res => {
            if (res && res.status == 200) {
                if(res.data.data){
                    setRoom(res.data.data);
                    setRentCollected(res.data.rentCollected);
                    if(res.data.lastTransaction){
                        setLastTenant(res.data.lastTransaction[0]);
                    }
                    setDewaPaid(res.data.dewaPaid);
                    setMaintenancePaid(res.data.maintenancePaid);
                } else {
                    navigate('/admins');
                }
            } else if (res && res.status == 203) {
                remove_session();
                props.getLoggedInUser();
                navigate('/login');
            }
        })
    }, [])
    const getRentCollectedAmount = () => {
        return rentCollected.amount ? parseInt(rentCollected.amount) : 0;
    }
    const getDewaPaidAmount = () => {
        return dewaPaid.amount ? parseInt(dewaPaid.amount) : 0;
    }
    const getMaintenancePaidAmount = () => {
        return maintenancePaid.amount ? parseInt(maintenancePaid.amount) : 0;
    }
    const totalExpense = () => {
        return getDewaPaidAmount() + getMaintenancePaidAmount();
    }
  return (
      <>
          <div className="col-lg-12">
              <div className="d-flex flex-wrap align-items-center justify-content-between mb-4">
                  <div>
                      <h4 className="mb-3">Room Detail</h4>
                  </div>
                  <Link to={`/transactions/add?type=room&id=${room.id}`} className="btn btn-primary add-list"><i
                      className="las la-plus mr-3"></i>Add Transaction</Link>
              </div>
          </div>
          <div className="col-sm-6">
              <div className="card">
                  <div className="bg-black-custom card-header d-flex justify-content-between rounded-top">
                      <div className="header-title">
                          <h4 className="card-title text-white">{room.name}</h4>
                      </div>
                  </div>
                  <div className="card-body">
                          <div className="row">
                              <div className="col-md-6">
                                  <div className="form-group">
                                      <label>Building: </label> {room.buildingName}
                                  </div>
                              </div>
                              <div className="col-md-6">
                                  <div className="form-group">
                                      <label>Tenant name: </label> {room.ownerName}
                                  </div>
                              </div>

                              {/* <div className="col-md-6">
                                  <div className="form-group">
                                      <label>Show Total Amount: </label> {room.amount}
                                  </div>
                              </div> */}
                              {/* <div className="col-md-6">
                                  <div className="form-group">
                                      <label>Total paid: </label> {room.buildingName}
                                  </div>
                              </div> */}
                              {/* <div className="col-md-12">
                                  <div className="form-group">
                                      <label>Balance: </label> {room.buildingName}
                                  </div>
                              </div> */}
                              <div className="col-md-12">
                                  <div className="form-group">
                                                <label>Room Status: </label> {room.isOnRent == 1 ? (
                                        <span className="text-danger font-weight-bold">Occupied</span>
                                    ) : (
                                        <span className="text-success font-weight-bold">Free</span>
                                    )}
                                  </div>
                              </div>
                          </div>
                  </div>
              </div>
          </div>
          <div className="col-sm-6">
              <div className="card">
                  <div className="bg-black-custom card-header d-flex justify-content-between rounded-top">
                      <div className="header-title">
                          <h4 className="card-title text-white">Stats</h4>
                      </div>
                  </div>
                  <div className="card-body">
                          <div className="row">
                              <div className="col-md-12">
                                  <div className="form-group">
                                      <div className="checkbox">
                                          <label>Total Rent Collected:</label> {getRentCollectedAmount()}
                                          <br/>
                                          <label>Dewa Charges Paid:</label> {getDewaPaidAmount()}
                                          <br/>
                                          <label>Maintenance Charges Paid:</label> {getMaintenancePaidAmount()}
                                          <br/>
                                          <label>Total Expense:</label> {totalExpense()}
                                          <br />
                                          <label>Last Tenant </label> {(lastTenant)?lastTenant.customerName- lastTenant.customerPhone:'--'}
                                      
                                      </div>
                                  </div>
                              </div>
                          </div>
                  </div>
              </div>
          </div>
          {/* <div className="col-lg-12">
              <div className="row mb-4">
                  <div className="col-md-4 text-center">
                      <button onClick={() => { setShowRent(1); setShowDewa(0); setShowMaintenance(0)}} className={`btn add-list ${showRent == 1 ? 'btn-primary' : 'btn-outline-primary'}`}>
                          Show Rent</button>
                  </div>
                  <div className="col-md-4 text-center">
                      <button onClick={() => { setShowRent(0); setShowDewa(1); setShowMaintenance(0)}} className={`btn add-list ${showDewa == 1 ? 'btn-primary' : 'btn-outline-primary'}`}>
                          Show Dewa</button>
                  </div>
                  <div className="col-md-4 text-center">
                      <button onClick={() => { setShowRent(0); setShowDewa(0); setShowMaintenance(1)}} className={`btn add-list ${showMaintenance == 1 ? 'btn-primary' : 'btn-outline-primary'}`}>
                          Show Maintenance</button>
                  </div>
              </div>
          </div> */}
            <div className="col-lg-12">
              <div className="row justify-content-end mb-4">
                  <div className="">
                         <button onClick={() => { setShowRent(1); setShowDewa(0); setShowMaintenance(0); setShowOtherExpense(0); setShowSecurityRefund(0)}} className={`btn add-list ${showRent == 1 ? 'btn-primary' : 'btn-outline-primary'}`}>
                          Cheques
                        </button>
                  </div>
                  <div className=" mx-2">
                      <button onClick={() => { setShowRent(0); setShowDewa(1); setShowMaintenance(0); setShowOtherExpense(0); setShowSecurityRefund(0)}} className={`btn add-list ${showDewa == 1 ? 'btn-primary' : 'btn-outline-primary'}`}>
                          Show Dewa Bill Payment</button>
                  </div>
                  <div className="mx-2">
                      <button onClick={() => { setShowRent(0); setShowDewa(0); setShowMaintenance(1); setShowOtherExpense(0); setShowSecurityRefund(0)}} className={`btn add-list ${showMaintenance == 1 ? 'btn-primary' : 'btn-outline-primary'}`}>
                          Show Maintenance Payment</button>
                  </div>
                  <div className="mx-2">
                      <button onClick={() => { setShowRent(0); setShowDewa(0); setShowMaintenance(0); setShowOtherExpense(0); setShowSecurityRefund(1)}} className={`btn add-list ${showSecurityRefund == 1 ? 'btn-primary' : 'btn-outline-primary'}`}>
                          Show Security Refund</button>
                  </div>
                   <div className="">
                      <button onClick={() => { setShowRent(0); setShowDewa(0); setShowMaintenance(0); setShowOtherExpense(1); setShowSecurityRefund(0)}} className={`btn add-list ${showOtherExpense == 1 ? 'btn-primary' : 'btn-outline-primary'}`}>
                          Show Other Expense Payment</button>
                  </div>
                  
              </div>
          </div>
          {showRent == 1 && (
              <ListTransactions currentUser={currentUser} roomId={params.id} transactionType="9" transactionFor="room"/>
          )}
          {showDewa == 1 && (
              <ListTransactions currentUser={currentUser} roomId={params.id} transactionType="2" transactionFor="room"/>
          )}
          {showMaintenance == 1 && (
              <ListTransactions currentUser={currentUser} roomId={params.id} transactionType="3" transactionFor="room"/>
          )}
          {showSecurityRefund == 1 && (
              <ListTransactions currentUser={currentUser} roomId={params.id} transactionType="5" transactionFor="room"/>
          )}
          {showOtherExpense == 1 && (
              <ListTransactions currentUser={currentUser} roomId={params.id} transactionType="4" transactionFor="room"/>
          )}
      </>
  );
}

export default RoomDetails;
