import React, {useEffect} from "react";
import App from "../../App";
import {Helmet} from "react-helmet";
import {
    getAdmins,
    getBuildings,
    getCustomers,
    getEmployees,
    getOwners, getCurrentRents,
    getRooms,
    remove_session
} from "../../functions/general";
import {useNavigate} from "react-router-dom";

function Dashboard(props) {
    const navigate = useNavigate();
    const currentUser = props.currentUser;
    const [loading, setLoading] = React.useState(true);
    const [employees, setEmployees] = React.useState([]);
    const [activeEmployees, setActiveEmployees] = React.useState([]);
    const [inactiveEmployees, setInactiveEmployees] = React.useState([]);
    const [admins, setAdmins] = React.useState([]);
    const [activeAdmins, setActiveAdmins] = React.useState([]);
    const [inactiveAdmins, setInactiveAdmins] = React.useState([]);
    const [owners, setOwners] = React.useState([]);
    const [activeOwners, setActiveOwners] = React.useState([]);
    const [inactiveOwners, setInactiveOwners] = React.useState([]);
    const [customers, setCustomers] = React.useState([]);
    const [activeCustomers, setActiveCustomers] = React.useState([]);
    const [inactiveCustomers, setInactiveCustomers] = React.useState([]);
    const [buildings, setBuildings] = React.useState([]);
    const [activeBuildings, setActiveBuildings] = React.useState([]);
    const [inactiveBuildings, setInactiveBuildings] = React.useState([]);
    const [rent, setRent] = React.useState([]);
    const [rooms, setRooms] = React.useState([]);
    const [activeRooms, setActiveRooms] = React.useState([]);
    const [inactiveRooms, setInactiveRooms] = React.useState([]);
    const rentType = "onRent";
    useEffect(() => {
        Promise.all([
            getAdmins(),
            getOwners(),
            getCustomers(),
            getBuildings(),
            getRooms(),
            getCurrentRents({rentType}),
            getEmployees()
        ]).then(([adminsRes, ownersRes, customersRes, buildingsRes, roomsRes, rentsRes, employeesRes]) => {
            if (adminsRes.status == 200){
                setAdmins(adminsRes.data.data);
                setActiveAdmins(adminsRes.data.data.filter(admin => admin.status == 1));
                setInactiveAdmins(adminsRes.data.data.filter(admin => admin.status == 2));
            } 
            if (ownersRes.status == 200){
                setOwners(ownersRes.data.data);
                setActiveOwners(ownersRes.data.data.filter(owner => owner.status == 1));
                setInactiveOwners(ownersRes.data.data.filter(owner => owner.status == 2));
            } 
            if (customersRes.status == 200){
                setCustomers(customersRes.data.data);
                setActiveCustomers(customersRes.data.data.filter(customer => customer.status == 1));
                setInactiveCustomers(customersRes.data.data.filter(customer => customer.status == 2));
            } 
            if (buildingsRes.status == 200){
                setBuildings(buildingsRes.data.data);
                setActiveBuildings(buildingsRes.data.data.filter(building => building.status == 1));
                setInactiveBuildings(buildingsRes.data.data.filter(building => building.status == 2));
            } 
            if (roomsRes.status == 200){
                if(currentUser && currentUser.isSuperAdmin == '1'){
                    setActiveRooms(roomsRes.data.active);
                    setInactiveRooms(roomsRes.data.inactive);
                }
                setRooms(roomsRes.data.data);
            } 
            if (rentsRes.status == 200) setRent(rentsRes.data.data);
            if (employeesRes.status == 200){
                setEmployees(employeesRes.data.data);
                setActiveEmployees(employeesRes.data.data.filter(employee => employee.status == 1));
                setInactiveEmployees(employeesRes.data.data.filter(employee => employee.status == 2));
            } 
            setLoading(false);
        }).catch(error => {
            if (error.response && error.response.status == 203) {
                remove_session();
                props.getLoggedInUser();
                navigate('/login');
            }
            setLoading(false);
        });
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }
  return (
      <>
        <div className="col-lg-12">
          <div className="card card-transparent card-block card-stretch card-height border-none">
            <div className="card-body p-0 mt-lg-2 mt-0">
              <h3 className="mb-3">Dashboard</h3>
            </div>
          </div>
        </div>

          <div className="container-fluid">
              <div className="row">
                  <div className="col-lg-12">
                      <div className="row">
                          {currentUser && currentUser.isSuperAdmin == '1' &&
                          <div className="col-lg-4 col-md-4">
                              <div className="bg-light-custom card card-block card-stretch card-height border-0 shadow-sm">
                                  <div className="card-body">
                                    <div className="d-flex align-items-center mb-4 card-total-sale justify-content-between">
                                        <div>
                                            <p className="mb-2 text-dark">Total Admins</p>
                                            <h4 className="text-dark">{activeAdmins?.length.toLocaleString()}</h4>
                                            {currentUser && currentUser.isSuperAdmin == '1' &&
                                            <small>Active: {activeAdmins?.length.toLocaleString()} Inactive: {inactiveAdmins?.length.toLocaleString()}</small>
                                            }
                                        </div>
                                        <div >
                                        <i class="ri-admin-fill"></i>
                                        </div>
                                    </div>
                                  </div>
                              </div>
                          </div>}
                          <div className="col-lg-4 col-md-4">
                              <div className="bg-light-custom card card-block card-stretch card-height border-0 shadow-sm">
                                  <div className="card-body">
                                      <div className="d-flex align-items-center mb-4 card-total-sale justify-content-between">
                                          <div>
                                              <p className="mb-2 text-dark">Total Owners</p>
                                              <h4 className="text-dark">{activeOwners?.length.toLocaleString()}</h4>
                                              {currentUser && currentUser.isSuperAdmin == '1' &&
                                              <small>Active: {activeOwners?.length.toLocaleString()} Inactive: {inactiveOwners?.length.toLocaleString()}</small>
                                              }
                                          </div>
                                          <div>
                                          <i class="ri-team-fill"></i>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div className="col-lg-4 col-md-4">
                              <div className="bg-light-custom card card-block card-stretch card-height border-0 shadow-sm">
                                  <div className="card-body">
                                      <div className="d-flex align-items-center mb-4 card-total-sale justify-content-between">
                                          <div>
                                              <p className="mb-2 text-dark">Total Tenants</p>
                                              <h4 className="text-dark">{activeCustomers?.length.toLocaleString()}</h4>
                                              {currentUser && currentUser.isSuperAdmin == '1' &&
                                              <small>Active: {activeCustomers?.length.toLocaleString()} Inactive: {inactiveCustomers?.length.toLocaleString()}</small>
                                              }
                                              
                                          </div>
                                          <div>
                                          <i class="ri-group-fill"></i>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div className="col-lg-4 col-md-4">
                              <div className="bg-light-custom card card-block card-stretch card-height border-0 shadow-sm">
                                  <div className="card-body">
                                      <div className="d-flex align-items-center mb-4 card-total-sale justify-content-between">
                                          <div>
                                              <p className="mb-2 text-dark">Total Buildings</p>
                                              <h4 className="text-dark">{activeBuildings?.length.toLocaleString()}</h4>
                                              {currentUser && currentUser.isSuperAdmin == '1' &&
                                              <small>Active: {activeBuildings?.length.toLocaleString()} Inactive: {inactiveBuildings?.length.toLocaleString()}</small>
                                              }
                                          </div>
                                          <div>
                                          <i class="ri-building-fill"></i>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div className="col-lg-4 col-md-4">
                              <div className="bg-light-custom card card-block card-stretch card-height border-0 shadow-sm">
                                  <div className="card-body">
                                      <div className="d-flex align-items-center mb-4 card-total-sale justify-content-between">
                                          <div>
                                              <p className="mb-2 text-dark">Total Rooms</p>
                                              <h4 className="text-dark">{activeRooms?.length.toLocaleString()}</h4>
                                              {currentUser && currentUser.isSuperAdmin == '1' &&
                                              <small>Active: {activeRooms?.length.toLocaleString()} Inactive: {inactiveRooms?.length.toLocaleString()}</small>
                                              }
                                          </div>
                                          <div>
                                          <i class="ri-home-7-fill"></i>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div className="col-lg-4 col-md-4">
                              <div className="bg-light-custom card card-block card-stretch card-height border-0 shadow-sm">
                                  <div className="card-body">
                                      <div className="d-flex align-items-center justify-content-between mb-4 card-total-sale">
                                          <div>
                                              <p className="mb-2 text-dark">Total Rented Rooms</p>
                                              <h4 className="text-dark">{rent?.length.toLocaleString()}</h4>
                                          </div>
                                          <div>
                                          <i class="ri-building-4-fill"></i>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div className="col-lg-4 col-md-4">
                              <div className="bg-light-custom card card-block card-stretch card-height border-0 shadow-sm">
                                  <div className="card-body">
                                      <div className="d-flex align-items-center justify-content-between mb-4 card-total-sale">
                                          <div>
                                              <p className="mb-2 text-dark">Total Empty Rooms</p>
                                              <h4 className="text-dark">{rooms?.length - rent?.length.toLocaleString()}</h4>
                                          </div>
                                          <div>
                                          <i class="ri-community-fill"></i>
                                          </div>
                                         
                                      </div>
                                  </div>
                              </div>
                          </div>
                          {currentUser && currentUser.isSuperAdmin == '1' &&
                          <div className="col-lg-4 col-md-4">
                              <div className="bg-light-custom card card-block card-stretch card-height border-0 shadow-sm">
                                  <div className="card-body">
                                      <div className="d-flex align-items-center mb-4 card-total-sale justify-content-between">
                                          <div>
                                              <p className="mb-2 text-dark">Total Employees</p>
                                              <h4 className="text-dark">{activeEmployees?.length.toLocaleString()}</h4>
                                              {currentUser && currentUser.isSuperAdmin == '1' &&
                                              <small>Active: {activeEmployees?.length.toLocaleString()} Inactive: {inactiveEmployees?.length.toLocaleString()}</small>
                                              }
                                          </div>
                                          <div className="text-end">
                                          <i class="ri-admin-fill"></i>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>}
                      </div>
                  </div>
              </div>
          </div>
          <Helmet>
              <script src="/assets/js/chart-custom.js"></script>
          </Helmet>
      </>
  );
}

export default Dashboard;
