import React, {useEffect} from "react";
import ListAccount from "./AccountList";

function Account(props) {
   
  return (
      <>
        <div className="col-lg-12">
          
        </div>

          <div className="container-fluid">
              <div className="row">
                  <div className="col-lg-12">
                     <ListAccount/>
                  </div>
              </div>
          </div>
         
      </>
  );
}

export default Account;
