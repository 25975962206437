import React, {useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {
    addEditRent,
    get_response_or_errors, getRent,
    remove_session
} from "../../../functions/general";

function Checkout(props) {
    Date.prototype.toDateInputValue = (function() {
        var local = new Date(this);
        local.setMinutes(this.getMinutes() - this.getTimezoneOffset());
        return local.toJSON().slice(0,10);
    });
    const navigate = useNavigate();
    const params = useParams();
    const [loading, setLoading] = React.useState(false);
    const [response, setResponse] = React.useState("");
    const [rent, setRent] = React.useState([]);
    const [tenantStatus, setTenantStatus] = React.useState(1);
    const [roomStatus, setRoomStatus] = React.useState(1);
    const [endDate, setEndDate] = React.useState(new Date().toDateInputValue());
    const [errors, setErrors] = React.useState("");
    useEffect(() => {
        getRent(params.id).then(res => {
            if (res && res.status == 200) {
                if(res.data.data){
                    setRent(res.data.data);
                } else {
                    navigate('/admins');
                }
            } else if (res && res.status == 203) {
                remove_session();
                props.getLoggedInUser();
                navigate('/login');
            }
        })
    }, [])
    const onSubmit = (e) => {
        e.preventDefault()
        setResponse("");
        if (loading)
            return false;
        if (validate()) {
            setLoading(true);
            const checkoutRent = {
                id: params.id,
                endDate: endDate,
                roomId: rent.roomId,
                tenantStatus: tenantStatus,
                roomStatus: roomStatus,
            }
            addEditRent(checkoutRent).then(res => {
                if (res && res.status == 200) {
                    navigate('/rent/' + params.id);
                } else if (res && res.status == 203) {
                    remove_session();
                    props.getLoggedInUser();
                    navigate('/login');
                } else {
                    setResponse(get_response_or_errors(res))
                    setErrors(get_response_or_errors(res))
                    setLoading(false)
                }
            })
        }
    }
    const validate = () => {
        let errors = {
            endDate: '',
        };
        let isValid = true;
        if (!endDate) {
            isValid = false;
            errors.endDate = "Please enter end date.";
        }
        setErrors(errors);
        return isValid;
    }
    return (
        <>
            <div className="col-lg-12">
                <div className="d-flex flex-wrap align-items-center justify-content-between mb-4">
                    <div>
                        <h4 className="mb-3">Checkout</h4>
                    </div>
                </div>
            </div>
            <div className="col-sm-12">
                <div className="card">
                    <div className="bg-black-custom card-header d-flex justify-content-between rounded-top">
                        <div className="header-title">
                            <h4 className="card-title text-white">Checkout ({rent.roomName} - {rent.customerName})</h4>
                        </div>
                    </div>
                    <div className="card-body">
                        <form noValidate onSubmit={onSubmit}>
                            {response?.responseMessage &&
                            <div
                                className={`alert alert-${response?.responseFlag} fade show`}
                                role="alert">
                                {response?.responseMessage}
                            </div>
                            }
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Checkout Date *</label>
                                        <input type="date" className="form-control" placeholder="Enter Date"
                                               value={endDate} onChange={(e) => setEndDate(e.target.value)}/>
                                        <div className="text-warning font-size-12">{errors.endDate}</div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Tenant Status *</label>
                                        <select className="form-control"
                                                value={tenantStatus} onChange={(e) => setTenantStatus(e.target.value)}>
                                            <option value="1">Active</option>
                                            <option value="2">Inactive</option>
                                        </select>
                                        <div className="text-warning font-size-12">{errors.state}</div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Room Status *</label>
                                        <select className="form-control"
                                                value={roomStatus} onChange={(e) => setRoomStatus(e.target.value)}>
                                            <option value="1">Active</option>
                                            <option value="2">Inactive</option>
                                        </select>
                                        <div className="text-warning font-size-12">{errors.state}</div>
                                    </div>
                                </div>
                            </div>
                            <button type="submit" className="btn btn-primary mr-2">Checkout</button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Checkout;
