import React from "react";
import {useNavigate} from "react-router-dom";
import {addEditOwner, get_response_or_errors, remove_session} from "../../../functions/general";
import Select from 'react-select';
import countryList from 'react-select-country-list'

function AddOwners(props) {
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState(false);
    const [response, setResponse] = React.useState("");
    const [name, setName] = React.useState("");
    const [email, setEmail] = React.useState(null);
    const [phone, setPhone] = React.useState("");
    const [emiratesId, setEmiratesId] = React.useState("");
    const [emiratesIdImage, setEmiratesIdImage] = React.useState("");
    const [documents, setOwnerDocs] = React.useState("");
    const [country, setCountry] = React.useState("");
    const [countryValue, setCountryValue] = React.useState("");
    const [city, setCity] = React.useState("");
    const [state, setState] = React.useState("");
    const [address, setAddress] = React.useState("");
    const [status, setStatus] = React.useState(1);
    const [errors, setErrors] = React.useState("");
    const countries = React.useMemo(() => countryList().getData(), []);
    const countriesOptions = []
    countries?.length > 0 && countries.map((contry) => {
        countriesOptions.push({label: contry.label, value: contry.label})
    })
    const onSubmit = (e) => {
        e.preventDefault()
        setResponse("");
        if (loading)
            return false;
        if (validate()) {
            setLoading(true);
            const owner = {
                name: name,
                phone: phone,
                email: email,
                emiratesId: emiratesId,
                emiratesIdImage: emiratesIdImage,
                documents:documents,
                country: country,
                city: city,
                state: state,
                address: address,
                status: status
            }
            addEditOwner(owner).then(res => {
                if (res && res.status == 200) {
                    navigate("/owners");
                } else if (res && res.status == 203) {
                    remove_session();
                    props.getLoggedInUser();
                    navigate('/login');
                } else {
                    setResponse(get_response_or_errors(res))
                    setErrors(get_response_or_errors(res))
                    setLoading(false)
                }
            })
        }
    }
    const validate = () => {
        let errors = {
            name: '',
            phone: '',
            emiratesId: '',
            country: '',
            city: '',
            state: '',
            address: '',
        };
        let isValid = true;
        if (!name) {
            isValid = false;
            errors.name = "Please enter name.";
        }
        if (!phone) {
            isValid = false;
            errors.phone = "Please enter phone.";
        }
        if (!emiratesId) {
            isValid = false;
            errors.emiratesId = "Please enter emirates id.";
        }
        if (!country) {
            isValid = false;
            errors.country = "Please enter nationality.";
        }
        if (!city) {
            isValid = false;
            errors.city = "Please enter city.";
        }
        if (!state) {
            isValid = false;
            errors.state = "Please enter state.";
        }
        if (!address) {
            isValid = false;
            errors.address = "Please enter address.";
        }
        setErrors(errors);
        return isValid;
    }
    const imageUploadHandler = (e) => {
        var file = e.target.files[0];
        console.log("file",file)
        var reader = new FileReader();
        var url = reader.readAsDataURL(file);

        reader.onloadend = function (e) {
           
            setEmiratesIdImage(reader.result)

        }.bind(this);
    }
    const docUploadHandler = (e) => {
        const files = e.target.files;
    
        // Ensure there are files to process
        if (files?.length > 0) {
            const newOwnerDocs = [];
    
            // Loop through each selected file
            for (let i = 0; i < files?.length; i++) {
                const file = files[i];
                const reader = new FileReader();
    
                reader.onloadend = function () {
                    // Append the base64 data of the file to the array
                    newOwnerDocs.push({
                        name: file.name,
                        type: file.type,
                        size: file.size,
                        data: reader.result,
                    });
    
                    // If all files are processed, update the state with the array of documents
                    if (i === files?.length - 1) {
                       console.log(newOwnerDocs);
                        setOwnerDocs(newOwnerDocs);
                    }
                };
    
                // Read the file as data URL
                reader.readAsDataURL(file);
            }
        }
    }
    const handleCountryChange = (selectedOption) => {
        setCountry(selectedOption.value)
        setCountryValue(selectedOption)
    };
    
    return (
        <>
            <div className="col-lg-12">
                <div className="d-flex flex-wrap align-items-center justify-content-between mb-4">
                    <div>
                        <h4 className="mb-3">Add Owners</h4>
                    </div>
                </div>
            </div>
            <div className="col-sm-12">
                <div className="card">
                    <div className="bg-black-custom card-header d-flex justify-content-between rounded-top">
                        <div className="header-title">
                            <h4 className="card-title text-white">Add New Owner</h4>
                        </div>
                    </div>
                    <div className="card-body">
                        <form noValidate onSubmit={onSubmit}>
                            {response?.responseMessage &&
                            <div
                                className={`alert alert-${response?.responseFlag} fade show`}
                                role="alert">
                                {response?.responseMessage}
                            </div>
                            }
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Name *</label>
                                        <input type="text" className="form-control" placeholder="Enter Name"
                                               value={name} onChange={(e) => setName(e.target.value)}/>
                                        <div className="text-warning font-size-12">{errors.name}</div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Emirates ID/Passport *</label>
                                        <input type="text" className="form-control" placeholder="Enter Emirates Id"
                                               value={emiratesId} onChange={(e) => setEmiratesId(e.target.value)}/>
                                        <div className="text-warning font-size-12">{errors.emiratesId}</div>
                                    </div>
                                </div>
                               
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Phone Number *</label>
                                        <input type="text" className="form-control" placeholder="Enter Phone Number"
                                               value={phone} onChange={(e) => setPhone(e.target.value)}/>
                                        <div className="text-warning font-size-12">{errors.phone}</div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Email</label>
                                        <input type="text" className="form-control" placeholder="Enter Email"
                                                value={email} onChange={(e) => setEmail(e.target.value)}/>
                                        <div className="text-warning font-size-12">{errors.email}</div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Emirates Id Image</label>
                                        <div className="custom-file">
                                            <input type="file" className="custom-file-input" id="receipt" onChange={e => imageUploadHandler(e)}  accept="image/*"/>
                                            <label className="custom-file-label" htmlFor="receipt">Choose
                                                file</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 mb-3">
                                    {emiratesIdImage && (
                                        <img className="border rounded" style={{maxHeight: '100px'}}
                                             src={emiratesIdImage}
                                             alt=""/>
                                    )}
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Select Country *</label>
                                        <Select
                                            value={countryValue}
                                            onChange={handleCountryChange}
                                            options={countriesOptions}
                                        />
                                        <div className="text-warning font-size-12">{errors.country}</div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>City *</label>
                                        <input type="text" className="form-control" placeholder="Enter City"
                                               value={city} onChange={(e) => setCity(e.target.value)}/>
                                        <div className="text-warning font-size-12">{errors.city}</div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>State *</label>
                                        <input type="text" className="form-control" placeholder="Enter State"
                                               value={state} onChange={(e) => setState(e.target.value)}/>
                                        <div className="text-warning font-size-12">{errors.state}</div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Address *</label>
                                        <textarea className="form-control" rows="4" value={address}
                                                  onChange={(e) => setAddress(e.target.value)}></textarea>
                                        <div className="text-warning font-size-12">{errors.address}</div>
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Attach Documents</label>
                                        <div className="custom-file">
                                            <input type="file" className="custom-file-input" id="doc" onChange={e => docUploadHandler(e)} multiple/>
                                            <label className="custom-file-label" htmlFor="doc">Choose
                                                file</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 mb-3">
                                    {documents && (documents?.length +' Files')} 
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Status *</label>
                                        <select className="form-control"
                                                value={status} onChange={(e) => setStatus(e.target.value)}>
                                            <option value="1">Active</option>
                                            <option value="2">Inactive</option>
                                        </select>
                                        <div className="text-warning font-size-12">{errors.state}</div>
                                    </div>
                                </div>
                            </div>
                            <button type="submit" className="btn btn-primary mr-2">Add</button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AddOwners;
