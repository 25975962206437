import React, {useEffect} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import { getEmployee,deleteEmployeeDocuments,addEmployeeDocuments,get_response_or_errors, remove_session} from "../../../functions/general";
import ListTransactions from "../transactions/ListTransactions";

function EmployeeDetails(props) {
    const currentUser = props.currentUser;
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState(false);
    const [response, setResponse] = React.useState("");
    const params = useParams();
    const [documents, setDocuments] = React.useState("");
    const [name, setName] = React.useState("");
    const [previewdocuments, setEmployeeDocs] = React.useState([]);
    const [employee, setEmployee] = React.useState([]);
    const [salaryPaid, setSalaryPaid] = React.useState("");
    const [errors, setErrors] = React.useState("");
    const onSubmit = (e) => {
        e.preventDefault()
        setResponse("");
        if (loading)
            return false;
        if (validate()) {
            setLoading(true);
            const employee = {
                id: params.id,
                name:name,
                documents:documents,
            }
            addEmployeeDocuments(employee).then(res => {
                if (res && res.status == 200) {
                    setEmployeeDocs(res.data.documents);
                } else if (res && res.status == 203) {
                    remove_session();
                    props.getLoggedInUser();
                    navigate('/login');
                } else {
                    setResponse(get_response_or_errors(res))
                    setLoading(false)
                }
            })
        }
    }
    const validate = () => {
        let errors = {
            documents: [],
            name: '',
        };
        let isValid = true;
        if (!documents) {
            isValid = false;
            errors.documents = "Please Choose Document.";
        }
        if (!name) {
            isValid = false;
            errors.name = "Please Enter name.";
        }
        setErrors(errors);
        return isValid;
    }
    const deleteHandler = (e, docId,path) => {
        e.preventDefault();
        const employee = {
            id: params.id,
            doc_id: docId,
            path: path,
        }
        if (window.confirm("Are you sure you want to delete document?") === true) {
            deleteEmployeeDocuments(employee).then((res) => {
                if (res && res.status === 200) {
                    setEmployeeDocs(res.data.documents);
                } else if (res && res.status === 203) {
                    remove_session();
                    props.getLoggedInUser();
                    navigate("/login");
                }
            });
        }
      };
    const docUploadHandler = (e) => {
        const files = e.target.files;
    
        // Ensure there are files to process
        if (files?.length > 0) {
            const newDocs = [];
    
            // Loop through each selected file
            for (let i = 0; i < files?.length; i++) {
                const file = files[i];
                const reader = new FileReader();
    
                reader.onloadend = function () {
                    // Append the base64 data of the file to the array
                    newDocs.push({
                        name: file.name,
                        type: file.type,
                        size: file.size,
                        data: reader.result,
                    });
    
                    // If all files are processed, update the state with the array of documents
                    if (i === files?.length - 1) {
                       setDocuments(newDocs);
                    }
                };
    
                // Read the file as data URL
                reader.readAsDataURL(file);
            }
        }
    }
    useEffect(() => {
        getEmployee(params.id).then(res => {
            if (res && res.status == 200) {
                if(res.data.data){
                    setEmployee(res.data.data);
                    setSalaryPaid(res.data.salaryPaid);
                    setEmployeeDocs(res.data.documents);
                } else {
                    navigate('/admins');
                }
            } else if (res && res.status == 203) {
                remove_session();
                props.getLoggedInUser();
                navigate('/login');
            }
        })
    }, [])
    const monthDiff = (dateFrom, dateTo) => {
        var months = dateTo.getMonth() - dateFrom.getMonth()
            + (12 * (dateTo.getFullYear() - dateFrom.getFullYear()));

        if(dateTo.getDate() < dateFrom.getDate()){
            months--;
        }
        return months;
    }
    const getSalaryTillNow = () => {
        let salaryAmount = 0;
        if(employee.startDate){
            let months = monthDiff(new Date(employee.startDate), new Date()) + 1;
            salaryAmount = employee.salary * months
        }
        return salaryAmount;
    }
  return (
      <>
          <div className="col-lg-12">
              <div className="d-flex flex-wrap align-items-center justify-content-between mb-4">
                  <div>
                      <h4 className="mb-3">Employee Detail</h4>
                  </div>
                  <Link to={`/transactions/add?type=employee&id=${employee.id}`} className="btn btn-primary add-list"><i
                      className="las la-plus mr-3"></i>Add Transaction</Link>
              </div>
          </div>
          <div className="col-sm-6">
              <div className="card">
                  <div className="bg-black-custom card-header d-flex justify-content-between rounded-top">
                      <div className="header-title">
                          <h4 className="card-title text-white">{employee.name}</h4>
                      </div>
                  </div>
                  <div className="card-body">
                      <div className="row">
                          <div className="col-md-12">
                              <div className="form-group">
                                  <label>Name: </label> {employee.name}
                                  <br />
                                  <label>Salary: </label> {employee.salary}
                                  <br />
                                  <label>Start Date: </label> {employee.startDate}
                                  <br />
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div className="col-sm-6">
              <div className="card">
                  <div className="bg-black-custom card-header d-flex justify-content-between rounded-top">
                      <div className="header-title">
                          <h4 className="card-title text-white">Stats</h4>
                      </div>
                  </div>
                  <div className="card-body">
                          <div className="row">
                              <div className="col-md-12">
                                  <div className="form-group">
                                      <div className="checkbox">
                                          <label>Salary Balance:</label> {getSalaryTillNow() - (salaryPaid.amount ? salaryPaid.amount : 0)}
                                      </div>
                                  </div>
                              </div>
                          </div>
                  </div>
              </div>
          </div>
          <div className="col-12">
          <h4 className="card-title text-dark">Documents</h4>
                        <div className="row">
                            <div className="col-12">
                                <form className="row justify-content-between" noValidate onSubmit={onSubmit}>
                                    <div className="col-12">
                                        {response?.responseMessage &&
                                        <div
                                            className={`alert alert-${response?.responseFlag} fade show`}
                                            role="alert">
                                            {response?.responseMessage}
                                        </div>
                                        }
                                    </div>
                                    <div className="col-md-8">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <input type="text" className="form-control" placeholder="Enter Name"
                                                            value={name} onChange={(e) => setName(e.target.value)}/>
                                                    <div className="text-warning font-size-12">{errors.name}</div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <div className="custom-file">
                                                        <input type="file" className="custom-file-input" id="doc" onChange={e => docUploadHandler(e)} multiple/>
                                                        <label className="custom-file-label" htmlFor="receipt">Choose
                                                            file</label>
                                                        <div className="text-warning font-size-12">{errors.documents}</div>
                                                        {documents && (documents?.length +' Files')} 
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div className="col-lg-1 col-md-1">
                                        <button type="submit" className="btn btn-primary mr-2 w-100">Add</button> 
                                    </div>
                                </form>
                            </div>  
                            <div className="col-md-12">
                                <div className="table-responsive rounded mb-3">
                                <table className="data-table table mb-0 tbl-server-info">
                                    <thead className="bg-black-custom text-uppercase">
                                    <tr className="text-white">
                                        <th>No</th>
                                        <th>Document</th>
                                        <th>Action</th>
                                    </tr>
                                    </thead>
                                    <tbody className="bg-white">
                                        { previewdocuments?.length > 0 && previewdocuments.map((document, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{index+1}</td>
                                                    <td>
                                                        <h4> <a href={process.env.REACT_APP_APIPATH +"/api/uploads/" +document.path} target="_blank" > <i className="ri-download-fill mr-0"></i>{document.name}</a></h4> 
                                                    </td>
                                                    <td>
                                                        <a onClick={(e) => deleteHandler(e, document.id,document.path)}
                                                            className="badge bg-primary mr-2"
                                                            data-toggle="tooltip"
                                                            data-placement="top"
                                                            title=""
                                                            data-original-title="Delete"
                                                            href="#" >
                                                            <i className="ri-delete-bin-line mr-0"></i>
                                                        </a>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                
                                    </tbody>
                                </table>
                                </div>
                            </div>
                        </div>
                  </div>

          <ListTransactions currentUser={currentUser}  sactions employeeId={params.id} transactionType="4" transactionFor="employee"/>
      </>
  );
}

export default EmployeeDetails;
