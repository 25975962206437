import React, { useState, useEffect } from "react";
import { getTransactionsReport, remove_session} from "../../../functions/general";
import ReactApexChart from "react-apexcharts";

const RevenueChart = (props) => {
  const currentYear = new Date().getFullYear();

  const [transactions, setExpenseData] = useState([]);
  const [year, setSelectedYear] = useState(currentYear);
  const [chartType, setchartType] = useState('revenue');
  useEffect(() => {
    setSelectedYear(props.year);
    getTransactionsReport({chartType,year}).then((res) => {
      if (res && res.status === 200) {
        setExpenseData(res.data.data);
      } else if (res && res.status === 203) {
        remove_session();
      }
    });
  }, [props,year]);

  const chartState ={
    series: [
      {
        name: 'SUM',
        data:  transactions,// [2.3, 3.1, 5.0, 6.1, 4.0, 3.6, 3.2, 2.3, 1.4, 0.8, 0.5, 0.2],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: 'bar',
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          dataLabels: {
            position: 'top',
          },
        },
      },
      dataLabels: {
        enabled: true,
        formatter: (val) => val,
        offsetY: -20,
        style: {
          fontSize: '12px',
          colors: ["#304758"],
        },
      },
      xaxis: {
        categories: [
          "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ],
        position: 'bottom',
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        crosshairs: {
          fill: {
            type: 'gradient',
            gradient: {
              colorFrom: '#D8E3F0',
              colorTo: '#BED1E6',
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            },
          },
        },
        tooltip: {
          enabled: true,
        },
      },
      yaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          formatter: (val) => val,
        },
      },
    //   title: {
    //     text: 'Monthly Inflation in Argentina, 2002',
    //     floating: true,
    //     offsetY: 330,
    //     align: 'center',
    //     style: {
    //       color: '#444',
    //     },
    //   },
    },
  };
  return (
    <div>
      <div id="chart">
        <ReactApexChart
          options={chartState.options}
          series={chartState.series}
          type="bar"
          height={350}
        />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};

export default RevenueChart;
