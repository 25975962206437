import React from "react";

function Loading() {
  return (
      <div id="loading">
        <div id="loading-center">
        </div>
      </div>
  );
}

export default Loading;
