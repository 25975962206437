import React, {useEffect} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import {getBuilding, deleteBuildingDocuments,addBuildingDocuments,get_response_or_errors, getOwners, remove_session} from "../../../functions/general";
import ListTransactions from "../transactions/ListTransactions";
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import ModalImage from "react-modal-image";


function ListBuildingsVillas(props) {
    const currentUser = props.currentUser;
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState(false);
    const [response, setResponse] = React.useState("");
    const params = useParams();
    const [building, setBuilding] = React.useState([]);
    const [roomsCount, setRoomsCount] = React.useState([]);
    const [owners, setOwners] = React.useState([]);
    const [showRent, setShowRent] = React.useState(1);
    const [showRentRecieved, setShowRentRecived] = React.useState(0);
    const [showDewa, setShowDewa] = React.useState(0);
    const [showMaintenance, setShowMaintenance] = React.useState(0);
    const [showOtherExpense, setShowOtherExpense] = React.useState(0);
    const [showSecurityDeposit, setShowSecurityDeposit] = React.useState(0);
    const [showAdvancePayment, setShowAdvancePayment] = React.useState(0);
    const [rentCollected, setRentCollected] = React.useState("");
    const [rentPaid, setRentPaid] = React.useState("");
    const [dewaPaid, setDewaPaid] = React.useState("");
    const [expensesGraphData, setExpensesGraphData] = React.useState([]);
    const [accountNumbers, setAccountNumbers] = React.useState([]);
    const [documents, setDocuments] = React.useState("");
    const [name, setName] = React.useState("");
    const [previewdocuments, setBuildingDocs] = React.useState([]);
    const [incomeGraphData, setIncomeGraphData] = React.useState([]);
    const [maintenancePaid, setMaintenancePaid] = React.useState("");
    const [errors, setErrors] = React.useState("");
    const onSubmit = (e) => {
        e.preventDefault()
        setResponse("");
        if (loading)
            return false;
        if (validate()) {
            setLoading(true);
            const building = {
                id: params.id,
                documents:documents,
                name:name,
            }
            addBuildingDocuments(building).then(res => {
                if (res && res.status == 200) {
                    setBuildingDocs(res.data.documents);
                } else if (res && res.status == 203) {
                    remove_session();
                    props.getLoggedInUser();
                    navigate('/login');
                } else {
                    setResponse(get_response_or_errors(res))
                    setLoading(false)
                }
            })
        }
    }
    const validate = () => {
        let errors = {
            documents: [],
            name: '',
        };
        let isValid = true;
        if (!documents) {
            isValid = false;
            errors.documents = "Please Choose Document.";
        }
        if (!name) {
            isValid = false;
            errors.name = "Please Enter name.";
        }
        setErrors(errors);
        return isValid;
    }
    const deleteHandler = (e, docId,path) => {
        e.preventDefault();
        const admin = {
            id: params.id,
            doc_id: docId,
            path: path,
        }
        if (window.confirm("Are you sure you want to delete document?") === true) {
            deleteBuildingDocuments(admin).then((res) => {
                if (res && res.status === 200) {
                    setBuildingDocs(res.data.documents);
                } else if (res && res.status === 203) {
                    remove_session();
                    props.getLoggedInUser();
                    navigate("/login");
                }
            });
        }
      };
    const docUploadHandler = (e) => {
        const files = e.target.files;
    
        // Ensure there are files to process
        if (files?.length > 0) {
            const newDocs = [];
    
            // Loop through each selected file
            for (let i = 0; i < files?.length; i++) {
                const file = files[i];
                const reader = new FileReader();
    
                reader.onloadend = function () {
                    // Append the base64 data of the file to the array
                    newDocs.push({
                        name: file.name,
                        type: file.type,
                        size: file.size,
                        data: reader.result,
                    });
    
                    // If all files are processed, update the state with the array of documents
                    if (i === files?.length - 1) {
                       setDocuments(newDocs);
                    }
                };
    
                // Read the file as data URL
                reader.readAsDataURL(file);
            }
        }
    }
    useEffect(() => {
        getOwners().then(res => {
            if (res && res.status == 200) {
                setOwners(res.data.data);
            } else if (res && res.status == 203) {
                remove_session();
                props.getLoggedInUser();
                navigate('/login');
            } else {
            }
        })
        getBuilding(params.id).then(res => {
            if (res && res.status == 200) {
                if(res.data.data){
                    setBuilding(res.data.data);  
                    setBuildingDocs(res.data.documents);
                    setRoomsCount(res.data.roomsCount);
                    setRentCollected(res.data.rentCollected);
                    setRentPaid(res.data.rentPaid);
                    setDewaPaid(res.data.dewaPaid);
                    setMaintenancePaid(res.data.maintenancePaid);
                    const accountNumbers = Array.isArray(res.data.data.accountNumber) ? res.data.data.accountNumber : [res.data.data.accountNumber];
                    setAccountNumbers(accountNumbers);
                } else {
                    navigate('/admins');
                }
            } else if (res && res.status == 203) {
                remove_session();
                props.getLoggedInUser();
                navigate('/login');
            }
        })
    }, [])
    useEffect(() => {
        setGraphExpenseData();
        setGraphIncomeData();
    }, [rentPaid])
    const getRentCollectedAmount = () => {
        if(rentCollected?.length) {
            return rentCollected.reduce((accumulator, object) => {
                return accumulator + parseInt(object.transactionAmount);
            }, 0);
        } else {
            return 0;
        }
    }
    const getRentPaidAmount = () => {
        if (rentPaid?.length) {
            return rentPaid.reduce((accumulator, object) => {
                // Get the current date and set the time to midnight
                const d = new Date();
                const d1 = new Date(d.getFullYear(), d.getMonth(), d.getDate());
        
                // Parse the transaction date and set the time to midnight
                const d2 = new Date(object.transactionDate);
                const d2Midnight = new Date(d2.getFullYear(), d2.getMonth(), d2.getDate());
        
                // Compare the dates
                if (d2Midnight.getTime() <= d1.getTime()) {
                    return accumulator + parseInt(object.transactionAmount, 10);
                } else {
                    return accumulator;
                }
            }, 0);
        } else {
            return 0;
        }
    }
    const setGraphExpenseData = () => {

        let resultArray = [];
        let totalExpenseAmount = 0;
        if(rentPaid?.length) {
            for(let i = 0; i < rentPaid?.length; i++){
                let splitDate = rentPaid[i].transactionDate.split('-')
                const d = new Date();
                var d1 = new Date(d.getFullYear() + '-' + (d.getMonth()+1 < 10 ? ('0'+(d.getMonth()+1)) : d.getMonth()+1) + '-' + d.getDate());
                var d2 = new Date(rentPaid[i].transactionDate);
                if(d2.getTime() <= d1.getTime()) {
                    totalExpenseAmount += parseInt(rentPaid[i].transactionAmount);
                }
                // resultArray.push([Date.UTC(parseInt(splitDate[0]), parseInt(splitDate[1])-1, parseInt(splitDate[2])), totalExpenseAmount]);
            }
        }
        if(dewaPaid?.length) {
            for(let i = 0; i < dewaPaid?.length; i++){
                let splitDate = dewaPaid[i].transactionDate.split('-')
                totalExpenseAmount += parseInt(dewaPaid[i].transactionAmount);
                // resultArray.push([Date.UTC(parseInt(splitDate[0]), parseInt(splitDate[1])-1, parseInt(splitDate[2])), totalExpenseAmount]);
            }
        }
        if(maintenancePaid?.length) {
            for(let i = 0; i < maintenancePaid?.length; i++){
                let splitDate = maintenancePaid[i].transactionDate.split('-')
                totalExpenseAmount += parseInt(maintenancePaid[i].transactionAmount);
                // resultArray.push([Date.UTC(parseInt(splitDate[0]), parseInt(splitDate[1])-1, parseInt(splitDate[2])), totalExpenseAmount]);
            }
        }
        let totalIncomeAmount = 0;
        if(rentCollected?.length) {
            for(let i = 0; i < rentCollected?.length; i++){
                let splitDate = rentCollected[i].transactionDate.split('-')
                totalIncomeAmount += parseInt(rentCollected[i].transactionAmount);
                resultArray.push([Date.UTC(parseInt(splitDate[0]), parseInt(splitDate[1])-1, parseInt(splitDate[2])), totalIncomeAmount]);
            }
        }
        setExpensesGraphData([totalExpenseAmount])
    }
    const setGraphIncomeData = () => {
        let resultArray = [];
        let totalIncomeAmount = 0;
        if(rentCollected?.length) {
            for(let i = 0; i < rentCollected?.length; i++){
                let splitDate = rentCollected[i].transactionDate.split('-')
                totalIncomeAmount += parseInt(rentCollected[i].transactionAmount);
                resultArray.push([Date.UTC(parseInt(splitDate[0]), parseInt(splitDate[1])-1, parseInt(splitDate[2])), totalIncomeAmount]);
            }
        }
        setIncomeGraphData([totalIncomeAmount])
    }
    const getDewaPaidAmount = () => {
        if(dewaPaid?.length) {
            return dewaPaid.reduce((accumulator, object) => {
                return accumulator + parseInt(object.transactionAmount);
            }, 0);
        } else {
            return 0;
        }
    }
    const getMaintenancePaidAmount = () => {
        if(maintenancePaid?.length) {
            return maintenancePaid.reduce((accumulator, object) => {
                return accumulator + parseInt(object.transactionAmount);
            }, 0);
        } else {
            return 0;
        }
    }
    const totalExpense = () => {
        return getRentPaidAmount() + getDewaPaidAmount() + getMaintenancePaidAmount();
    }

    const options = {
        title: {
            text: 'Graph'
        },
        series: [{
            type: "bar",
            name: "Income",
            data: [incomeGraphData]
        },{
            type: "bar",
            name: "Expense",
            data: [expensesGraphData]
        }]
    }

    
    const handleAddAccount = () => {
        setAccountNumbers([...accountNumbers, '']); // Add an empty string for a new account
    };
    const handleRemoveAccount = (index) => {
        const newAccountNumbers = [...accountNumbers];
        newAccountNumbers.splice(index, 1); // Remove the account at the specified index
        setAccountNumbers(newAccountNumbers);
    };

    const handleAccountChange = (index, value) => {
        const newAccountNumbers = [...accountNumbers];
        newAccountNumbers[index] = value; // Update the account number at the specified index
        setAccountNumbers(newAccountNumbers);
    };
  return (
      <>
        <div className="col-lg-12">
            <div className="d-flex flex-wrap align-items-center justify-content-between mb-4">
                <div>
                    <h4 className="mb-3">Building/Villa/Camp Detail</h4>
                </div>
                <Link to={`/transactions/add?type=building&id=${building.id}`} className="btn btn-primary add-list"><i
                    className="las la-plus mr-3"></i>Add Transaction</Link>
                {/* <Link to={`/transactions/add?type=building&cheque=1&id=${building.id}`} className="btn btn-primary add-list"><i
                    className="las la-plus mr-3"></i>Add Cheque</Link> */}
            </div>
        </div>
        <div className="col-sm-6">
            <div className="card">
                <div className="bg-black-custom card-header d-flex justify-content-between rounded-top">
                    <div className="header-title">
                        <h4 className="card-title text-white">{building.name}</h4>
                    </div>
                </div>
                <div className="card-body">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="row justify-content-between form-group">
                                <div className="col-md-6">
                                    <label>Owner: </label> {owners && owners.find(owner => owner.id === building.ownerId)?.name}&nbsp;
                                    ({owners && owners.find(owner => owner.id === building.ownerId)?.phone})
                                </div>
                                <div className="col-md-6">
                                    <label>City: </label> {building.city} &nbsp;&nbsp;&nbsp;&nbsp;
                                </div>
                                <div className="col-md-6">
                                    <label>State: </label> {building.state}
                                </div>
                                <div className="col-md-6">
                                    <label>Address: </label> {building.address}
                                </div>
                                <div className="col-md-6">
                                    <label>Rent: </label> {building.rent} / {building.rentType === '1' ? 'Month' : 'Year'}
                                        {building.contractedImage &&
                                        <div  className="transactionTableImage">
                                            <ModalImage
                                                small={process.env.REACT_APP_APIPATH + "/api/images/" + building.contractedImage}
                                                large={process.env.REACT_APP_APIPATH + "/api/images/" + building.contractedImage}
                                            />
                                        </div>}
                                </div>
                                <div className="col-md-6">
                                    <label>No of Cheques: </label> {building.cheques}
                                </div>

                                <div className="col-md-6">
                                    <label>Account Number: </label> {building.accountNumber}
                                </div>

                                <div className="col-md-6">
                                    <label>Dewa: </label> {building.dewa === '1' ? 'Included' : 'Not Included'} 
                                </div>

                                <div className="col-md-6">
                                <label>Maintenance Charges: </label> {building.maintenance === '1' ? 'Included' : 'Not Included'}
                                </div>

                                <div className="col-md-6">
                                <label>Security Deposit: </label> {building.securityDeposit}
                                </div>
                                <div className="col-md-6">
                                <label>Total Rooms: </label> {roomsCount}
                                </div>

                                <div className="col-md-6">
                                {building.startDate &&
                                        <>
                                            <label>Start Date: </label> {building.startDate}
                                        </>}
                                </div>
                                <div className="col-md-6">
                                {building.endDate &&
                                    <>
                                        <label>End Date: </label> {building.endDate}
                                    </>}
                                    </div>

                                </div>
                            </div>
                        </div>
                </div>
            </div>
        </div>
        <div className="col-sm-6">
            <div className="card">
                <div className="bg-black-custom card-header d-flex justify-content-between rounded-top">
                    <div className="header-title">
                        <h4 className="card-title text-white">Stats</h4>
                    </div>
                </div>
                <div className="card-body">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <div className="checkbox">
                                        <label>Total Rent Paid:</label> {getRentPaidAmount()}
                                        <br/>
                                        <label>Remaining Rent:</label> {building.rent - getRentPaidAmount()}
                                        <br/>
                                        <label>Dewa Charges Paid:</label> {getDewaPaidAmount()}
                                        <br/>
                                        <label>Maintenance Charges Paid:</label> {getMaintenancePaidAmount()}
                                        <br/>
                                        <label>Total Expense:</label> {totalExpense()}
                                        <br/>
                                        <label>Total Rent Collected:</label> {getRentCollectedAmount()}
                                        <br/>
                                        <label>Profit/Loss Status:</label>
                                        {getRentCollectedAmount() > totalExpense() && "Profit"}
                                        {getRentCollectedAmount() < totalExpense() && "Loss"}
                                        {getRentCollectedAmount() === totalExpense() && "No Profit No Loss"}
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
        </div>
        {/* <div className="col-12">
            <div className="card">
            <div className="row card-body">
                <div className="col-12 d-flex justify-content-between">
                    <label>Account Number</label>
                    <button className="btn btn-primary mr-2"> Add Now </button>
                </div>
                {accountNumbers.map((accountNumber, index) => (
                    <div key={index} className="col-md-3">
                        <div className="form-group">
                     
                            <div className="input-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Account Number"
                                    value={accountNumber}
                                    onChange={(e) => handleAccountChange(index, e.target.value)}
                                />
                                <div className="input-group-append">
                                    <button className="btn btn-danger" onClick={() => handleRemoveAccount(index)}>Remove</button>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
                <div className="col-md-3">
                    <button className="btn btn-primary" onClick={handleAddAccount}><i className="las la-plus"></i></button>
                </div>
            </div>
               
            </div>
        </div> */}
        <div className="col-12 my-5">
            <h4 className="card-title text-dark">Documents</h4>
            <div className="row">
                <div className="col-12">
                    <form className="row justify-content-end" noValidate onSubmit={onSubmit}>
                        <div className="col-12">
                            {response?.responseMessage &&
                            <div
                                className={`alert alert-${response?.responseFlag} fade show`}
                                role="alert">
                                {response?.responseMessage}
                            </div>
                            }
                        </div>
                        <div className="col-md-8">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <input type="text" className="form-control" placeholder="Enter Name"
                                                value={name} onChange={(e) => setName(e.target.value)}/>
                                        <div className="text-warning font-size-12">{errors.name}</div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <div className="custom-file">
                                            <input type="file" className="custom-file-input" id="doc" onChange={e => docUploadHandler(e)} multiple/>
                                            <label className="custom-file-label" htmlFor="receipt">Choose
                                                file</label>
                                            <div className="text-warning font-size-12">{errors.documents}</div>
                                            {documents && (documents?.length +' Files')} 
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                        <div className="col-lg-1 col-md-1">
                            <button type="submit" className="btn btn-primary mr-2 w-100">Add</button> 
                        </div>
                    </form>
                </div>  
                <div className="col-md-12">
                    <div className="table-responsive rounded mb-3">
                    <table className="data-table table mb-0 tbl-server-info">
                        <thead className="bg-black-custom text-uppercase">
                        <tr className="text-white">
                            <th>No</th>
                            <th>Document</th>
                            <th>Action</th>
                        </tr>
                        </thead>
                        <tbody className="bg-white">
                            { previewdocuments?.length > 0 && previewdocuments.map((document, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{index+1}</td>
                                        <td>
                                            <h4> <a href={process.env.REACT_APP_APIPATH +"/api/uploads/" +document.path} target="_blank" > <i className="ri-download-fill mr-0"></i>{document.name}</a></h4> 
                                        </td>
                                        <td>
                                            <a onClick={(e) => deleteHandler(e, document.id,document.path)}
                                                className="badge bg-primary mr-2"
                                                data-toggle="tooltip"
                                                data-placement="top"
                                                title=""
                                                data-original-title="Delete"
                                                href="#" >
                                                <i className="ri-delete-bin-line mr-0"></i>
                                            </a>
                                        </td>
                                    </tr>
                                );
                            })}
                    
                        </tbody>
                    </table>
                    </div>
                </div>
            </div>
        </div>
        <div className="col-lg-12">
            <div className="row justify-content-end mb-4">
                <div className="">
                        <button onClick={() => { setShowRent(1); setShowRentRecived(0); setShowDewa(0); setShowMaintenance(0);setShowOtherExpense(0);setShowSecurityDeposit(0); setShowAdvancePayment(0);}} className={`btn add-list ${showRent === 1 ? 'btn-primary' : 'btn-outline-primary'}`}>
                        Rent Payment
                        </button>
                </div>
                <div className="mx-2">
                    <button onClick={() => { setShowRent(0); setShowRentRecived(1); setShowDewa(0); setShowMaintenance(0); setShowOtherExpense(0);setShowSecurityDeposit(0); setShowAdvancePayment(0);}} className={`btn add-list ${showAdvancePayment === 1 ? 'btn-primary' : 'btn-outline-primary'}`}>
                        Show Rent Received</button>
                </div>
                <div className=" mx-2">
                    <button onClick={() => { setShowRent(0); setShowRentRecived(0); setShowDewa(1); setShowMaintenance(0); setShowOtherExpense(0);setShowSecurityDeposit(0); setShowAdvancePayment(0);}} className={`btn add-list ${showDewa === 1 ? 'btn-primary' : 'btn-outline-primary'}`}>
                        Show Dewa Bill Payment</button>
                </div>
                <div className="mx-2">
                    <button onClick={() => { setShowRent(0); setShowRentRecived(0); setShowDewa(0); setShowMaintenance(1); setShowOtherExpense(0);setShowSecurityDeposit(0); setShowAdvancePayment(0);}} className={`btn add-list ${showMaintenance === 1 ? 'btn-primary' : 'btn-outline-primary'}`}>
                        Show Maintenance Payment</button>
                </div>
                <div className="mx-2">
                      <button onClick={() => { setShowRent(0);  setShowRentRecived(0);setShowDewa(0); setShowMaintenance(0); setShowOtherExpense(1);setShowSecurityDeposit(0); setShowAdvancePayment(0);}} className={`btn add-list ${showOtherExpense === 1 ? 'btn-primary' : 'btn-outline-primary'}`}>
                          Show Other Expense Payment</button>
                </div>
                <div className="mx-2">
                    <button onClick={() => { setShowRent(0); setShowRentRecived(0); setShowDewa(0); setShowMaintenance(0); setShowOtherExpense(0);setShowSecurityDeposit(1); setShowAdvancePayment(0);}} className={`btn add-list ${showSecurityDeposit === 1 ? 'btn-primary' : 'btn-outline-primary'}`}>
                        Show Security Deposit Refund</button>
                </div>
                <div className="mx-2">
                    <button onClick={() => { setShowRent(0); setShowRentRecived(0); setShowDewa(0); setShowMaintenance(0); setShowOtherExpense(0);setShowSecurityDeposit(0); setShowAdvancePayment(1);}} className={`btn add-list ${showAdvancePayment === 1 ? 'btn-primary' : 'btn-outline-primary'}`}>
                        Show Advance Payment</button>
                </div>
               
            </div>
        </div>
          {showRent === 1 && (
              <ListTransactions  currentUser={currentUser} buildingId={params.id} transactionType="1" transactionFor="building"/>
          )}
          {showRentRecieved === 1 && (
              <ListTransactions  currentUser={currentUser} buildingId={params.id} transactionType="9" transactionFor="building"/>
          )}
          {showDewa === 1 && (
              <ListTransactions currentUser={currentUser} buildingId={params.id} transactionType="2" transactionFor="building"/>
          )}
          {showMaintenance === 1 && (
              <ListTransactions currentUser={currentUser} buildingId={params.id} transactionType="3" transactionFor="building"/>
          )}
          {showOtherExpense === 1 && (
              <ListTransactions currentUser={currentUser} buildingId={params.id} transactionType="5" transactionFor="building"/>
          )}
          {showSecurityDeposit === 1 && (
              <ListTransactions currentUser={currentUser} buildingId={params.id} transactionType="7" transactionFor="building"/>
          )}

          {showAdvancePayment === 1 && (
              <ListTransactions currentUser={currentUser} buildingId={params.id} transactionType="8" transactionFor="building"/>
          )}


            {expensesGraphData?.length > 0 && (
            <div className="col-sm-12 mb-4 text-center rounded-3">
                <HighchartsReact className=""
                    highcharts={Highcharts}
                    options={options}
                />
            </div>
            )}
      </>
  );
}

export default ListBuildingsVillas;
