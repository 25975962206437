import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getAccounts,deleteAccount, remove_session,date_time_to_date_format } from "../../../functions/general";
import ModalImage from "react-modal-image";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Dropdown from 'react-bootstrap/Dropdown';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

function ListAccount(props) {
  const navigate = useNavigate();
  const [accounts, setAccounts] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [showFilters, setShowFilters] = useState(false);
  const [searchStatus, setSearchStatus] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(-1);
  const [date, setDate] = useState("");
  const tableRef = useRef();
  const [loading, setLoading] = useState(true); 
  const onSubmit = (e) => {
    e.preventDefault();
  };


  useEffect(() => {
    getAccounts().then((res) => {
      if (res && res.status === 200) {
       setLoading(false); 
        setAccounts(res.data.data);
        setItemsPerPage(res.data.data.length);
      } else if (res && res.status === 203) {
        remove_session();
        props.getLoggedInUser();
        navigate("/login");
      }
    });
  }, []);
  const deleteHandler = (e, id) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want to delete account?")) {
      deleteAccount(id).then(res => {
        if (res && res.status == 200) {
          setLoading(false); 
          setAccounts(res.data.data);
          setItemsPerPage(res.data.data.length);
        } else if (res && res.status == 405) {
          alert("Account cannot be deleted.");
        } else if (res && res.status == 203) {
          remove_session();
          props.getLoggedInUser();
          navigate('/login');
        } else {
          // Handle other cases
        }
      });
    }
  }
  const applyFilters = (accounts) => {
    return accounts.filter(account => {
      const tempString = `${account.id} ${account.bank_name} ${account.account_no} ${account.account_title}`;
      if (searchQuery && !tempString.match(new RegExp(searchQuery, 'i'))) {
        return false;
      }
  
      return true;
    });
  };

  // Pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentAccounts = accounts?.slice(indexOfFirstItem, indexOfLastItem);

  const pageNumbers = Math.ceil(accounts?.length / itemsPerPage);

  const renderPageNumbers = () => {
    const pageNumbersArray = Array.from({ length: pageNumbers }, (_, i) => i + 1);
    return pageNumbersArray.map((number) => {
      if (
        number === 1 ||
        number === currentPage ||
        number === currentPage - 1 ||
        number === currentPage + 1 ||
        number === pageNumbersArray?.length
      ) {
        return (
          <li
            key={number}
            className={`page-item ${currentPage === number ? "active" : ""}`}
          >
            <button className="page-link" onClick={() => paginate(number)}>
              {number}
            </button>
          </li>
        );
      }
      return null;
    });
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleItemsPerPageChange = (e) => {
      const value = Number(e.target.value);
      if (value === -1) {
          // Show all items
          setItemsPerPage(accounts.length);
      } else {
          // Set items per page to the selected value
          setItemsPerPage(value);
      }
      setCurrentPage(1); // Reset to first page when changing items per page
  };

  const exportToCSV = () => {
    tableRef.current.link.click();
  };

  const exportToExcel = () => {
    const filteredAccounts = applyFilters(accounts);
    const ws = XLSX.utils.json_to_sheet(filteredAccounts);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "accounts List");
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" });
    saveAs(data, "Account-list.xlsx");
  };

  const exportToPDF = () => {
    const filteredAccounts = applyFilters(accounts);
    const unit = "pt";
    const size = "A4";
    const orientation = "landscape";

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);
    const title = "Account List";
    const headers = [
      ["No", "Bank Name", "Account No","Account Title"],
    ];

    const data = filteredAccounts.map((account) => [
      account.id,
      account.bank_name,
      account.account_no,
      account.account_title
      
    ]);

    let content = {
      startY: 50,
      head: headers,
      body: data,
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("Account-list.pdf");
  };

  

  return (
    <>
      <div className="col-lg-12">
        <div className="d-flex flex-wrap align-items-center justify-content-between mb-4">
          <div>
            <h4 className="mb-3">Account List</h4>
          </div>
          <div>
              <Link className="btn btn-primary mr-2" data-toggle="tooltip" data-placement="top"
                title="Edit" data-original-title="Edit"
                to={'/accounts/add'}><i className="las la-plus mr-3"></i> Add Account</Link>
          </div>
        </div>
      </div>
    


<div className="col-lg-12">
<div className="row  align-items-end px-3">

    <div className="col-lg-2 col-md-3 col-sm-6">
    <div className="form-group">
            <label>Show per page</label>
            <select
              className="form-control"
              value={itemsPerPage == accounts.length ? -1 : itemsPerPage}
              onChange={handleItemsPerPageChange}
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={30}>30</option>
              <option value={-1}>Show All</option>
            </select>
          </div>
    </div>
    <div className="col-lg-2 col-md-3 col-sm-6">

          <div className="form-group">
          <label>Export</label>
          <Dropdown className="w-100">
              <Dropdown.Toggle variant="light" id="dropdown-basic" className="w-100">
                Select Export Type
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item href="#/action-1" onClick={exportToCSV}>Export to CSV</Dropdown.Item>
                <Dropdown.Item href="#/action-2" onClick={exportToExcel}>Export to Excel</Dropdown.Item>
                <Dropdown.Item href="#/action-3" onClick={exportToPDF}>Export to PDF</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          </div>
    <div className="col-md-2 col-sm-6">
    <div className="form-group">
                      <label>Search</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                      />
                    </div>
    </div>
    
   
  </div> 
</div>
      
      <div className="col-lg-12">
        <div className="table-responsive  rounded mb-3">
          <table className="data-table table mb-0 tbl-server-info ">
            <thead className="bg-black-custom text-uppercase">
              <tr className="text-white">
              <th>No</th>
                <th>Bank Name</th>
                <th>Account No</th>
                <th>Account Title</th>
                <th>Action </th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {currentAccounts.map((account, index) => {
{/*                 
                if (searchStatus && (searchStatus == '2') && !(transaction.employeeId > 0 || transaction.buildingId > 0 )) {
                  return;
                }else if(searchStatus && (searchStatus == '1') && !(transaction.rentId > 0 && !transaction.buildingId > 0)){
                  return;
                } */}
                const tempString =
                  account.id +
                  " " +
                  account.bank_name +
                  " " +
                  account.account_no +
                  " " +
                  account.account_title;
                if (
                  searchQuery &&
                  !tempString.match(new RegExp(searchQuery, "i"))
                ) {
                  return null;
                }
                return (
                  <tr key={index}>
                    <td>{account.id}</td>
                    
                    <td className="text-ellipses">
                    {account.bank_name}
                    </td>
                    <td>
                    {account.account_no}
                    </td>
                    <td>
                    {account.account_title}
                    </td>
                    <td>
                      <div className="d-flex align-items-center list-action">
                          <Link className="badge bg-primary mr-2" data-toggle="tooltip" data-placement="top"
                              title="Edit" data-original-title="Edit"
                              to={'/accounts/edit/' + account.id}><i className="ri-pencil-line mr-0"></i></Link>
                            <Link to={`/account-transaction/add?type=DEPOSIT&id=${account.id}`} className="badge bg-primary mr-2" 
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Deposit" data-original-title="Deposit">
                                 <i class="ri-luggage-deposit-fill"></i></Link>
                            <Link to={`/account-transaction/add?type=WITHDRAW&id=${account.id}`} className="badge bg-primary mr-2" 
                                data-toggle="tooltip"
                                data-placement="top"
                                title="WithDraw" data-original-title="WithDraw">
                                 <i class="ri-wallet-3-fill"></i></Link>
                            <Link to={'/account-statement/'+account.id} className="badge bg-primary mr-2" 
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Account Statement" data-original-title="Account Statement">
                                 <i class="ri-eye-line"></i></Link>
                            <a
                                onClick={(e) => deleteHandler(e, account.id)}
                                className="badge bg-primary mr-2 mb-2"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Delete"
                                data-original-title="Delete"
                                href="#"
                              >
                                <i className="ri-delete-bin-line mr-0"></i>
                              </a>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <div className="row justify-content-between">

    

      <div className="col-sm-6 text-xl-left text-sm-left text-center">
      {loading ? (
                <div className="text-muted"><p>Loading...</p> </div>
            ) : (
                <div className="col-lg-12">
                </div>
            )}
        <p>Total Number of Account: {applyFilters(accounts)?.length}</p>
      </div>

        <div className="col-sm-6"> 
        <nav className="pagination justify-content-xl-end justify-content-sm-end justify-content-center">
          <ul className="pagination">
              <li
                className={`page-item ${
                  currentPage === 1 ? "disabled" : ""
                }`}
              >
                <button
                  className="page-link"
                  onClick={() => paginate(currentPage - 1)}
                >
                  Previous
                </button>
              </li>
              {renderPageNumbers()}
              <li
                className={`page-item ${
                  currentPage === pageNumbers ? "disabled" : ""
                }`}
              >
                <button
                  className="page-link"
                  onClick={() => paginate(currentPage + 1)}
                >
                  Next
                </button>
              </li>
            </ul>
          </nav>
        </div>
        </div>
    </>
  );
}

export default ListAccount;
