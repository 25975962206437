import React, { useEffect } from "react";
import {Link, useNavigate} from "react-router-dom";
import {deleteAdmin, getAdmins, remove_session} from "../../../functions/general";

function ListAdmins(props) {
    const navigate = useNavigate();
    const [admins, setAdmins] = React.useState([]);
    const [searchQuery, setSearchQuery] = React.useState("");
    const [showFilters, setShowFilters] = React.useState(false);
    const [searchStatus, setSearchStatus] = React.useState("");
    const onSubmit = (e) => {
        e.preventDefault()
    }
    useEffect(() => {
        getAdmins().then(res => {
            if (res && res.status == 200) {
                setAdmins(res.data.data);
            } else if (res && res.status == 203) {
                remove_session();
                props.getLoggedInUser();
                navigate('/login');
            } else {
            }
        })
        }, [])
    const deleteHandler = (e, id) => {
        e.preventDefault()
        if (window.confirm("Are you sure you want to delete admin?") == true) {
            deleteAdmin(id).then(res => {
                if (res && res.status == 200) {
                    setAdmins(res.data.data);
                } else if (res && res.status == 203) {
                    remove_session();
                    props.getLoggedInUser();
                    navigate('/login');
                }
            })
        }
    }
  return (
      <>
          <div className="col-lg-12">
              <div className="d-flex flex-wrap align-items-center justify-content-between mb-4">
                  <div>
                      <h4 className="mb-3">Admins List</h4>
                  </div>
                  <div>
                    <div>
                    <Link to="/admins/add" className="btn btn-primary mx-3 add-list"><i
                      className="las la-plus mr-3"></i>Add Admin</Link>
                    </div>
                  </div>
                 
              </div>
          </div>
       


                    <div className="col-lg-12">
            <div className="row  align-items-end px-3">
                <div className="col-md-3">
                <div className="form-group">
                                              <label>Search</label>
                                              <input type="text" className="form-control" placeholder=""
                                                     value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)}/>
                                          </div>
                </div>
                <div className="col-md-3">
                <div className="form-group">
                                              <label>Status</label>
                                              <select className="form-control" value={searchStatus} onChange={(e) => setSearchStatus(e.target.value)}>
                                                  <option value="">All</option>
                                                  <option value="1">Active</option>
                                                  <option value="2">Inactive</option>
                                              </select>
                                          </div>
                </div>
                
                {/* <div className="text-end col-sm-6">
                <div className="d-flex flex-wrap align-items-center justify-content-end mb-4">
                <Link to="/admins/add" className="btn btn-outline-primary mx-3 add-list"><i
                      className="las la-plus mr-3"></i>Add Admin</Link>
                  <button onClick={() => setShowFilters(!showFilters)} className="btn btn-primary add-list">Filters</button>
              </div>
                </div> */}
            </div> 
            </div>
          {/* { showFilters && (
              <>
                  <div className="col-sm-12">
                      <div className="card">
                          <div className="bg-black-custom card-header d-flex justify-content-between rounded-top">
                              <div className="header-title">
                                  <h4 className="card-title text-white">Filters</h4>
                              </div>
                          </div>
                          <div className="card-body">
                              <form noValidate onSubmit={onSubmit}>
                                  <div className="row">
                                      <div className="col-md-6">
                                          <div className="form-group">
                                              <label>Search</label>
                                              <input type="text" className="form-control" placeholder=""
                                                     value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)}/>
                                          </div>
                                      </div>
                                      <div className="col-md-6">
                                          <div className="form-group">
                                              <label>Status</label>
                                              <select className="form-control" value={searchStatus} onChange={(e) => setSearchStatus(e.target.value)}>
                                                  <option value="">All</option>
                                                  <option value="1">Active</option>
                                                  <option value="2">Inactive</option>
                                              </select>
                                          </div>
                                      </div>
                                  </div>
                              </form>
                          </div>
                      </div>
                  </div>
              </>
          )} */}
          <div className="col-lg-12">
              <div className="table-responsive rounded mb-3">
                  <table className="data-table table mb-0 tbl-server-info">
                      <thead className="bg-black-custom text-uppercase">
                      <tr className="text-white">
                          <th>Name</th>
                          <th>Emirates&nbsp;Id</th>
                          <th>Phone</th>
                          <th>Email</th>
                          <th>City</th>
                          <th>State</th>
                          <th>Address</th>
                          <th>Status</th>
                          <th>Action</th>
                      </tr>
                      </thead>
                      <tbody className="bg-white">
                      { admins?.length > 0 && admins.map((admin, index) => {
                          if(searchStatus && searchStatus !== admin.status){
                              return;
                          }
                          const tempString =
                              admin.name + " " + admin.phone + " " + admin.email + " " + admin.emiratesId + " " +
                              admin.city + " " + admin.state + " " + admin.address
                          if(searchQuery && !tempString.match(new RegExp(searchQuery, 'i'))){
                              return;
                          }
                          return (
                              <tr key={index}>
                                  <td>
                                  <Link to={`/admins/${admin.id}`}>
                                        {admin.name}
                                    </Link>
                                    </td>
                                  <td>{admin.emiratesId}</td>
                                  <td>{admin.phone}</td>
                                  <td>{admin.email}</td>
                                
                                  <td>{admin.city}</td>
                                  <td>{admin.state}</td>
                                  <td>{admin.address}</td>
                                  <td>
                                      {admin.status == 1 ?
                                          (<div className="text-success font-weight-bold">Active</div>) :
                                          (<div className="text-danger font-weight-bold">Inactive</div>)}
                                  </td>
                                  <td>
                                      <div className="d-flex align-items-center list-action">
                                          <Link className="badge bg-primary mr-2" data-toggle="tooltip" data-placement="top"
                                             title="Edit" data-original-title="Edit"
                                             to={'/admins/edit/' + admin.id}><i className="ri-pencil-line mr-0"></i></Link>
                                          <a onClick={e => deleteHandler(e, admin.id)} className="badge bg-primary mr-2" data-toggle="tooltip" data-placement="top"
                                             title="Delete" data-original-title="Delete"
                                             href="#"><i className="ri-delete-bin-line mr-0"></i></a>
                                      </div>
                                  </td>
                              </tr>
                          );
                      })}
                      </tbody>
                  </table>
              </div>
          </div>
      </>
  );
}

export default ListAdmins;
