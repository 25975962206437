import React from 'react';
import loginImage from './images/login.png';
import {login, get_response_or_errors} from "../../../functions/general";
import {useNavigate} from "react-router-dom";

function Login(props) {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [response, setResponse] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const onSubmit = (e) => {
    e.preventDefault()
    setResponse("");
    if (loading)
      return false;
    if (validate()) {
      setLoading(true);
      const user = {
        email: email,
        password: password
      }
      login(user).then(res => {
        if (res && res.status == 200) {
          props.getLoggedInUser();
          navigate("/");
        } else {
          setResponse(get_response_or_errors(res))
          setLoading(false)
        }
      })
    }
  }

  const validate = () => {
    let isValid = true;
    if (!email) {
      isValid = false;
    }
    if (!password) {
      isValid = false;
    }
    return isValid;
  }

  return (
      <div className="wrapper">
        <section className="login-content">
          <div className="container">
            <div className="row align-items-center justify-content-center height-self-center">
              <div className="col-lg-8">
                <div className="card auth-card">
                  <div className="card-body p-0">
                    <div className="d-flex align-items-center auth-content">
                      <div className="col-lg-7 align-self-center">
                        <div className="p-3">
                          {response?.responseMessage &&
                          <div
                              className={`alert alert-${response?.responseFlag} fade show`}
                              role="alert">
                            {response?.responseMessage}
                          </div>
                          }
                          <h2 className="mb-2">Sign In</h2>
                          <form onSubmit={onSubmit}>
                            <div className="row">
                              <div className="col-lg-12">
                                <div className="floating-label form-group">
                                  <input className="floating-input form-control" type="email" placeholder=" "
                                         value={email} required
                                         onChange={(e) => setEmail(e.target.value)}/>
                                    <label>Email</label>
                                </div>
                              </div>
                              <div className="col-lg-12">
                                <div className="floating-label form-group">
                                  <input className="floating-input form-control" type="password" placeholder=" "
                                         value={password} required
                                         onChange={(e) => setPassword(e.target.value)}/>
                                    <label>Password</label>
                                </div>
                              </div>
                            </div>
                            <button type="submit" className="btn btn-primary">Sign In</button>
                          </form>
                        </div>
                      </div>
                      <div className="col-lg-5 content-right">
                        <img src={loginImage}
                             className="img-fluid image-right" alt=""/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
  );
}

export default Login;
