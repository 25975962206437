import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { deleteEmployee, getEmployees, remove_session } from "../../../functions/general";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Dropdown from 'react-bootstrap/Dropdown';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

function ListEmployees(props) {
  const currentUser = props.currentUser;
  const navigate = useNavigate();
  const [employees, setEmployees] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [showFilters, setShowFilters] = useState(false);
  const [searchStatus, setSearchStatus] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(-1);
  const tableRef = useRef();

  const onSubmit = (e) => {
    e.preventDefault();
  };


  useEffect(() => {
    getEmployees().then((res) => {
      if (res && res.status === 200) {
        setEmployees(res.data.data);
        setItemsPerPage(res.data.data.length);
      } else if (res && res.status === 203) {
        remove_session();
        props.getLoggedInUser();
        navigate("/login");
      }
    });
  }, []);

  const deleteHandler = (e, id) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want to delete employee?")) {
      deleteEmployee(id).then((res) => {
        if (res && res.status === 200) {
          setEmployees(res.data.data);
        } else if (res && res.status === 203) {
          remove_session();
          props.getLoggedInUser();
          navigate("/login");
        }
      });
    }
  };

  const applyFilters = (employees) => {
    return employees.filter(employee => {
        // Check if the employee status matches the search status
        if (searchStatus && searchStatus !== employee.status) {
          return false;
        }
    
        // Check if the search query matches any of the relevant fields
        const tempString = `${employee.name} ${employee.emiratesId} ${employee.phone} ${employee.salary} ${employee.country} ${employee.city} ${employee.state} ${employee.address}`;
        if (searchQuery && !tempString.match(new RegExp(searchQuery, 'i'))) {
          return false;
        }
    
        return true;
      });
};

  // Pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentEmployees = employees?.slice(indexOfFirstItem, indexOfLastItem);

  const pageNumbers = Math.ceil(employees?.length / itemsPerPage);

  const renderPageNumbers = () => {
    const pageNumbersArray = Array.from({ length: pageNumbers }, (_, i) => i + 1);
    return pageNumbersArray.map((number) => {
      if (
        number === 1 ||
        number === currentPage ||
        number === currentPage - 1 ||
        number === currentPage + 1 ||
        number === pageNumbersArray?.length
      ) {
        return (
          <li
            key={number}
            className={`page-item ${currentPage === number ? "active" : ""}`}
          >
            <button className="page-link" onClick={() => paginate(number)}>
              {number}
            </button>
          </li>
        );
      }
      return null;
    });
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleItemsPerPageChange = (e) => {
      const value = Number(e.target.value);
      if (value === -1) {
          // Show all items
          setItemsPerPage(value);
      } else {
          // Set items per page to the selected value
          setItemsPerPage(value);
      }
      setCurrentPage(1); // Reset to first page when changing items per page
  };

  const exportToCSV = () => {
    tableRef.current.link.click();
  };

  const exportToExcel = () => {
    const filteredEmployees = applyFilters(currentEmployees);
    const ws = XLSX.utils.json_to_sheet(currentEmployees);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Employees List");
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" });
    saveAs(data, "employess-list.xlsx");
  };

  const exportToPDF = () => {
    const filteredEmployees = applyFilters(currentEmployees);
    const unit = "pt";
    const size = "A4";
    const orientation = "landscape";

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);
    const title = "Employess List";
    const headers = [
      ["Name", "Phone", "Emirates Id","Salary", "Country", "City", "State", "Address", "Status"],
    ];

    const data = filteredEmployees.map((employee) => [
      employee.name,
      employee.phone,
      employee.emiratesId,
      employee.salary,
      employee.country,
      employee.city,
      employee.state,
      employee.address,
      employee.status === 1 ? "Active" : "Inactive",
    ]);

    let content = {
      startY: 50,
      head: headers,
      body: data,
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("employees-list.pdf");
  };

  return (
    <>
      <div className="col-lg-12">
        <div className="d-flex flex-wrap align-items-center justify-content-between mb-4">
          <div>
            <h4 className="mb-3">Employees List</h4>
          </div>
          <div>
          {currentUser && currentUser.isSuperAdmin == "1" && (
          <Link to="/employees/add" className="btn btn-primary mx-3 add-list">
            <i className="las la-plus mr-3"></i>Add Employee
          </Link>
          )}
          </div>
        </div>
      </div>
    


<div className="col-lg-12">
<div className="row  align-items-end px-3">
    <div className="col-lg-2 col-md-3 col-sm-6">
    <div className="form-group">
            <label>Show per page:</label>
            <select
              className="form-control"
              value={itemsPerPage === employees.length ? -1 : itemsPerPage}
              onChange={handleItemsPerPageChange}
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={30}>30</option>
              <option value={-1}>Show All</option>
            </select>
          </div>
    </div>
    <div className="col-lg-2 col-md-3 col-sm-6">

          <div className="form-group">
          <label>Export</label>
          <Dropdown className="w-100">
              <Dropdown.Toggle variant="light" id="dropdown-basic" className="w-100">
                Select Export Type
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item href="#/action-1" onClick={exportToCSV}>Export to CSV</Dropdown.Item>
                <Dropdown.Item href="#/action-2" onClick={exportToExcel}>Export to Excel</Dropdown.Item>
                <Dropdown.Item href="#/action-3" onClick={exportToPDF}>Export to PDF</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          </div>
    <div className="col-md-3 col-sm-6">
    <div className="form-group">
                      <label>Search</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                      />
                    </div>
    </div>
    <div className="col-md-3 col-sm-6">
    <div className="form-group">
                      <label>Status</label>
                      <select
                        className="form-control"
                        value={searchStatus}
                        onChange={(e) => setSearchStatus(e.target.value)}
                      >
                        <option value="">All</option>
                        <option value="1">Active</option>
                        <option value="2">Inactive</option>
                      </select>
                    </div>
    </div>
    {/* <div className="text-end col-sm-6">
    <div className="d-flex flex-wrap align-items-center justify-content-end mb-4">
    <Link to="/employees/add" className="btn btn-outline-primary mx-3 add-list">
            <i className="las la-plus mr-3"></i>Add Employee
          </Link>
            <button
              onClick={() => setShowFilters(!showFilters)}
              className="btn btn-primary add-list"
            >
              Filters
            </button>
          </div>
    </div> */}
  </div> 
</div>
        
        
    
      {showFilters && (
        <div className="col-sm-12">
          <div className="card">
            <div className="bg-black-custom card-header d-flex justify-content-between rounded-top">
              <div className="header-title">
                <h4 className="card-title text-white">Filters</h4>
              </div>
            </div>
            <div className="card-body">
              <form noValidate onSubmit={onSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Search</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Status</label>
                      <select
                        className="form-control"
                        value={searchStatus}
                        onChange={(e) => setSearchStatus(e.target.value)}
                      >
                        <option value="">All</option>
                        <option value="1">Active</option>
                        <option value="2">Inactive</option>
                      </select>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
      
      <div className="col-lg-12">
        <div className="table-responsive  rounded mb-3">
          <table className="data-table table mb-0 tbl-server-info ">
            <thead className="bg-black-custom text-uppercase">
              <tr className="text-white">
              <th>no</th>
                <th>Name</th>
                <th>Emirates&nbsp;Id</th>
                <th>Phone</th>
                <th>Salary</th>
                <th>Country</th>
                <th>City</th>
                <th>State</th>
                <th>Address</th>
                <th>Status</th>
                <th className="text-center">Action</th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {currentEmployees.map((employee, index) => {
                if (searchStatus && searchStatus !== employee.status) {
                  return null;
                }
                const tempString =
                  employee.name +
                  " " +
                  employee.emiratesId +
                  " " +
                  employee.phone +
                
                  " " +
                  employee.salary +
                  " " +
                  employee.country +
                  " " +
                  employee.city +
                  " " +
                  employee.state +
                  " " +
                  employee.address;
                if (
                  searchQuery &&
                  !tempString.match(new RegExp(searchQuery, "i"))
                ) {
                  return null;
                }
                return (
                  <tr key={index}>
                    <td>{employee.id}</td>
                    <td>
                      <Link to={`/employees/${employee.id}`}>
                        {employee.name}
                      </Link>
                    </td>
                    <td>{employee.emiratesId}</td>
                    <td>{employee.phone}</td>
                    <td>{employee.salary}</td>
                    <td>{employee.country}</td>
                    <td>{employee.city}</td>
                    <td>{employee.state}</td>
                    <td>{employee.address}</td>
                    <td>
                      {employee.status == 1 ? (
                        <div className="text-success font-weight-bold">Active</div>
                      ) : (
                        <div className="text-danger font-weight-bold">Inactive</div>
                      )}
                    </td>
                    <td>
                    {currentUser && currentUser.isSuperAdmin == "1" && (
                      <div className="d-flex align-items-center list-action">
                        <Link
                          className="badge bg-primary mr-2"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Edit"
                          data-original-title="Edit"
                          to={"/employees/edit/" + employee.id}
                        >
                          <i className="ri-pencil-line mr-0"></i>
                        </Link>
                        <a
                          onClick={(e) => deleteHandler(e, employee.id)}
                          className="badge bg-primary mr-2"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Delete"
                          data-original-title="Delete"
                          href="#"
                        >
                          <i className="ri-delete-bin-line mr-0"></i>
                        </a>
                      </div>
                    )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      <div className="col-sm-6 text-xl-left text-sm-left text-center">
        <p>Total Number of Employees: {applyFilters(employees)?.length}</p>
      </div>

        <div className="col-sm-6"> 
        <nav className="pagination justify-content-xl-end justify-content-sm-end justify-content-center">
          <ul className="pagination">
              <li
                className={`page-item ${
                  currentPage === 1 ? "disabled" : ""
                }`}
              >
                <button
                  className="page-link"
                  onClick={() => paginate(currentPage - 1)}
                >
                  Previous
                </button>
              </li>
              {renderPageNumbers()}
              <li
                className={`page-item ${
                  currentPage === pageNumbers ? "disabled" : ""
                }`}
              >
                <button
                  className="page-link"
                  onClick={() => paginate(currentPage + 1)}
                >
                  Next
                </button>
              </li>
            </ul>
          </nav>
        </div>
    </>
  );
}

export default ListEmployees;
