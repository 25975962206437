import React, {useEffect} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import {
    addEditAccountTransaction,
    get_response_or_errors, getAccount,
    remove_session
} from "../../../../functions/general";

function AddTransaction(props) {
    Date.prototype.toDateInputValue = (function() {
        var local = new Date(this);
        local.setMinutes(this.getMinutes() - this.getTimezoneOffset());
        return local.toJSON().slice(0,10);
    });
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [loading, setLoading] = React.useState(false);
    const [response, setResponse] = React.useState("");
    const [account, setAccount] = React.useState([]);
    const [transactionType, setTransactionType] = React.useState(searchParams.get('type'));
    const [transactionSubType, setTransactionSubType] = React.useState("");
    const [accountId, setAccountId] = React.useState(null);
    const [transactionDate, setTransactionDate] = React.useState(new Date().toDateInputValue());
    const [transactionAmount, setTransactionAmount] = React.useState(0);
    const [transactionDescription, setTransactionDescription] = React.useState("");
    const [transactionReceipt, setTransactionReceipt] = React.useState("");
    const [transactionBy, setTransactionBy] = React.useState("");
    const [errors, setErrors] = React.useState("");
    const depositSubTypes = ['Cash Deposit', 'Cheque Deposit'];
    const withdrawSubTypes = ['Cash Withdraw', 'Cheque Withdraw'];

    const getSubTypes = () => {
        if (transactionType == 'DEPOSIT') {
            return depositSubTypes;
        } else if (transactionType == 'WITHDRAW') {
            return withdrawSubTypes;
        }
        return [];
    };

    useEffect(() => {
        getAccount(searchParams.get('id')).then(res => {
            if (res && res.status == 200) {
                if (res.data.data) {
                    setAccount(res.data.data);
                    setAccountId(searchParams.get('id'));
                } else {
                    navigate('/admins');
                }
            } else if (res && res.status == 203) {
                remove_session();
                props.getLoggedInUser();
                navigate('/login');
            }
        })
    }, [])
    const onSubmit = (e) => {
        e.preventDefault()
        setResponse("");
        if (loading)
            return false;
        if (validate()) {
            setLoading(true);
            const transaction = {
                accountId: accountId,
                transactionType: transactionType,
                transactionSubType: transactionSubType,
                transactionBy: transactionBy,
                transactionDate: transactionDate,
                transactionAmount: transactionAmount,
                transactionDescription: transactionDescription,
                transactionReceipt: transactionReceipt
            }
            addEditAccountTransaction(transaction).then(res => {
                if (res && res.status == 200) {
                   navigate('/account-transactions');
                } else if (res && res.status == 203) {
                    remove_session();
                    props.getLoggedInUser();
                    navigate('/login');
                } else {
                    setResponse(get_response_or_errors(res))
                    setErrors(get_response_or_errors(res))
                    setLoading(false)
                }
            })
        }
    }
    const validate = () => {
        let errors = {
            transactionDate: '',
            transactionReceipt: '',
            transactionAmount: '',
            transactionBy: '',
            transactionSubType: '',
        };
        let isValid = true;
        if (!transactionDate) {
            isValid = false;
            errors.date = "Please enter transaction date.";
        }
        // if (!transactionReceipt) {
        //     isValid = false;
        //     errors.receipt = "Please enter transaction receipt.";
        // }
        if (!transactionAmount) {
            isValid = false;
            errors.transactionAmount = "Please enter transaction amount.";
        }
        if (!transactionSubType) {
            isValid = false;
            errors.transactionSubType = "Please enter transaction Type.";
        }
        if (!transactionBy) {
            isValid = false;
            errors.transactionBy = "Please enter required field.";
        }
        if (transactionAmount && (parseInt(transactionAmount) != transactionAmount || parseInt(transactionAmount) <= 0)) {
            isValid = false;
            errors.transactionAmount = "Please enter valid transaction amount.";
        }
        setErrors(errors);
        return isValid;
    }
    const imageUploadHandler = (e) => {
        var file = e.target.files[0];
        console.log("file",file)
        var reader = new FileReader();
        var url = reader.readAsDataURL(file);

        reader.onloadend = function (e) {
            setTransactionReceipt(reader.result)

        }.bind(this);
    }
    return (
        <>
            <div className="col-lg-12">
                <div className="d-flex flex-wrap align-items-center justify-content-between mb-4">
                    <div>
                        <h4 className="mb-3">Add Transactions</h4>
                    </div>
                </div>
            </div>
            <div className="col-md-4">
                <div className="card">
                    <div className="bg-black-custom card-header d-flex justify-content-between rounded-top">
                        <div className="header-title">
                           <h4 className="card-title text-white">Account Detail</h4>
                               
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="form-group">
                            <p> <span className="text-muted">Account Title:</span>  {account.account_title}</p>
                            <p> <span className="text-muted">Account Number:</span>  {account.account_no}</p>
                            <p> <span className="text-muted">Bank Name:</span>  {account.bank_name}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-8">
                <div className="card">
                    <div className="bg-black-custom card-header d-flex justify-content-between rounded-top">
                        <div className="header-title">
                           <h4 className="card-title text-white">Add New Transaction - {transactionType}</h4>
                               
                        </div>
                    </div>
                    <div className="card-body">
                        <form noValidate onSubmit={onSubmit}>
                            {response?.responseMessage &&
                            <div
                                className={`alert alert-${response?.responseFlag} fade show`}
                                role="alert">
                                {response?.responseMessage}
                            </div>
                            }
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Transaction Date *</label>
                                        <input type="date" className="form-control" placeholder="Enter Date"
                                               value={transactionDate} onChange={(e) => setTransactionDate(e.target.value)}/>
                                        <div className="text-warning font-size-12">{errors.date}</div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Transaction Amount *</label>
                                        <input type="text" className="form-control" placeholder="Enter Amount"
                                               value={transactionAmount} onChange={(e) => setTransactionAmount(e.target.value)}/>
                                        <div className="text-warning font-size-12">{errors.transactionAmount}</div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Transaction *</label>
                                        <select className="form-control"
                                                value={transactionBy} onChange={(e) => setTransactionBy(e.target.value)}>
                                               <option value="">Select </option>
                                            <option value="local">Local</option>
                                            <option value="international">International</option>
                                        </select>
                                        <div className="text-warning font-size-12">{errors.transactionBy}</div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Transaction Type*</label>
                                        <select
                                            className="form-control"
                                            value={transactionSubType}
                                            onChange={(e) => setTransactionSubType(e.target.value)}
                                            disabled={!transactionType}
                                        >
                                            <option value="">Select Type</option>
                                            {getSubTypes().map((subType) => (
                                                <option key={subType} value={subType}>
                                                    {subType}
                                                </option>
                                            ))}
                                        </select>
                                        <div className="text-warning font-size-12">{errors.transactionSubType}</div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Transaction Receipt</label>
                                        <div className="custom-file">
                                            <input type="file" className="custom-file-input" id="receipt" onChange={e => imageUploadHandler(e)}  accept="image/*"/>
                                                <label className="custom-file-label" htmlFor="receipt">Choose
                                                    file</label>
                                        </div>
                                        <div className="text-warning font-size-12">{errors.receipt}</div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                </div>
                                <div className="col-md-6 mb-3">
                                    {transactionReceipt && (
                                    <img className="border rounded" style={{maxHeight: '100px'}}
                                         src={transactionReceipt}
                                         alt=""/>
                                    )}
                                </div>
                               
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Description</label>
                                        <textarea type="text" className="form-control" placeholder="Enter Description"
                                                  onChange={(e) => setTransactionDescription(e.target.value)}>{transactionDescription}</textarea>
                                    </div>
                                </div>
                            </div>
                            <button type="submit" className="btn btn-primary mr-2">Add</button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AddTransaction;
