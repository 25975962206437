import axios from 'axios'
import FormData from 'form-data'

export const login = user => {
    return axios
        .post(
            process.env.REACT_APP_APIPATH + '/api/login.php',
            user
            ,
            {
                headers: {'Content-Type': 'application/json'}
            }
        )
        .then(response => {
            add_session(response.data.token)
            set_user(response.data.user)
            set_privileges(response.data.privileges)
            return response
        })
        .catch(err => {
            return err.response;
        })
}

export const addEditAdmin = admin => {
    return axios
        .post(
            process.env.REACT_APP_APIPATH + '/api/addEditAdmin.php',
            admin,
            {
                headers: {'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
            }
        )
        .then(response => {
            return response
        })
        .catch(err => {
            return err.response;
        })
}

export const updatePassword = user => {
    return axios
        .post(
            process.env.REACT_APP_APIPATH + '/api/updatePassword.php',
            user,
            {
                headers: {'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
            }
        )
        .then(response => {
            return response
        })
        .catch(err => {
            return err.response;
        })
}

export const deleteAdmin = id => {
    return axios
        .post(
            process.env.REACT_APP_APIPATH + '/api/addEditAdmin.php',
            {id, delete:true},
            {
                headers: {'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
            }
        )
        .then(response => {
            return response
        })
        .catch(err => {
            return err.response;
        })
}

export const getAdmins = () => {
    return axios
        .post(
            process.env.REACT_APP_APIPATH + '/api/admin.php',{},
            {
                headers: {'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
            }
        )
        .then(response => {
            return response
        })
        .catch(err => {
            return err.response;
        })
}

export const getAdmin = (id) => {
    return axios
        .post(
            process.env.REACT_APP_APIPATH + '/api/admin.php',{ id },
            {
                headers: {'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
            }
        )
        .then(response => {
            return response
        })
        .catch(err => {
            return err.response;
        })
}

export const addAdminDocuments = admin => {
    return axios
        .post(
            process.env.REACT_APP_APIPATH + '/api/adminDocuments.php',
            admin,
            {
                headers: {'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
            }
        )
        .then(response => {
            return response
        })
        .catch(err => {
            return err.response;
        })
}

export const addBuildingPrivileges = admin => {
    return axios
        .post(
            process.env.REACT_APP_APIPATH + '/api/adminBuildingPrivileges.php',
            admin,
            {
                headers: {'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
            }
        )
        .then(response => {
            return response
        })
        .catch(err => {
            return err.response;
        })
}

export const deleteAdminDocuments = admin => {
    return axios
        .post(
            process.env.REACT_APP_APIPATH + '/api/adminDocuments.php',
            {admin, delete:true},
            {
                headers: {'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
            }
        )
        .then(response => {
            return response
        })
        .catch(err => {
            return err.response;
        })
}

export const addEditEmployee = admin => {
    return axios
        .post(
            process.env.REACT_APP_APIPATH + '/api/addEditEmployee.php',
            admin,
            {
                headers: {'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
            }
        )
        .then(response => {
            return response
        })
        .catch(err => {
            return err.response;
        })
}

export const deleteEmployee = id => {
    return axios
        .post(
            process.env.REACT_APP_APIPATH + '/api/addEditEmployee.php',
            {id, delete:true},
            {
                headers: {'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
            }
        )
        .then(response => {
            return response
        })
        .catch(err => {
            return err.response;
        })
}

export const getEmployees = () => {
    return axios
        .post(
            process.env.REACT_APP_APIPATH + '/api/employee.php',{},
            {
                headers: {'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
            }
        )
        .then(response => {
            return response
        })
        .catch(err => {
            return err.response;
        })
}

export const getEmployee = (id) => {
    return axios
        .post(
            process.env.REACT_APP_APIPATH + '/api/employee.php',{ id },
            {
                headers: {'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
            }
        )
        .then(response => {
            return response
        })
        .catch(err => {
            return err.response;
        })
}
export const addEmployeeDocuments = employee => {
    return axios
        .post(
            process.env.REACT_APP_APIPATH + '/api/employeeDocuments.php',
            employee,
            {
                headers: {'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
            }
        )
        .then(response => {
            return response
        })
        .catch(err => {
            return err.response;
        })
}
export const deleteEmployeeDocuments = employee => {
    return axios
        .post(
            process.env.REACT_APP_APIPATH + '/api/employeeDocuments.php',
            {employee, delete:true},
            {
                headers: {'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
            }
        )
        .then(response => {
            return response
        })
        .catch(err => {
            return err.response;
        })
}
export const addEditOwner = owner => {
    return axios
        .post(
            process.env.REACT_APP_APIPATH + '/api/addEditOwner.php',
            owner,
            {
                headers: {'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
            }
        )
        .then(response => {
            return response
        })
        .catch(err => {
            return err.response;
        })
}
export const deleteOwner = id => {
    return axios
        .post(
            process.env.REACT_APP_APIPATH + '/api/addEditOwner.php',
            {id, delete:true},
            {
                headers: {'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
            }
        )
        .then(response => {
            return response
        })
        .catch(err => {
            return err.response;
        })
}

export const getOwners = () => {
    return axios
        .post(
            process.env.REACT_APP_APIPATH + '/api/owner.php',{},
            {
                headers: {'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
            }
        )
        .then(response => {
            return response
        })
        .catch(err => {
            return err.response;
        })
}

export const getOwner = (id) => {
    return axios
        .post(
            process.env.REACT_APP_APIPATH + '/api/owner.php',{ id },
            {
                headers: {'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
            }
        )
        .then(response => {
            return response
        })
        .catch(err => {
            return err.response;
        })
}
export const addOwnerDocuments = owner => {
    return axios
        .post(
            process.env.REACT_APP_APIPATH + '/api/ownerDocuments.php',
            owner,
            {
                headers: {'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
            }
        )
        .then(response => {
            return response
        })
        .catch(err => {
            return err.response;
        })
}
export const deleteOwnerDocuments = owner => {
    return axios
        .post(
            process.env.REACT_APP_APIPATH + '/api/ownerDocuments.php',
            {owner, delete:true},
            {
                headers: {'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
            }
        )
        .then(response => {
            return response
        })
        .catch(err => {
            return err.response;
        })
}
export const addEditCustomer = customer => {
    return axios
        .post(
            process.env.REACT_APP_APIPATH + '/api/addEditCustomer.php',
            customer,
            {
                headers: {'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
            }
        )
        .then(response => {
            return response
        })
        .catch(err => {
            return err.response;
        })
}
export const addCustomerDocuments = customer => {
    return axios
        .post(
            process.env.REACT_APP_APIPATH + '/api/customerDocuments.php',
            customer,
            {
                headers: {'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
            }
        )
        .then(response => {
            return response
        })
        .catch(err => {
            return err.response;
        })
}
export const deleteCustomerDocuments = customer => {
    return axios
        .post(
            process.env.REACT_APP_APIPATH + '/api/customerDocuments.php',
            {customer, delete:true},
            {
                headers: {'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
            }
        )
        .then(response => {
            return response
        })
        .catch(err => {
            return err.response;
        })
}

export const deleteCustomer = id => {
    return axios
        .post(
            process.env.REACT_APP_APIPATH + '/api/addEditCustomer.php',
            {id, delete:true},
            {
                headers: {'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
            }
        )
        .then(response => {
            return response
        })
        .catch(err => {
            return err.response;
        })
}

export const getCustomers = () => {
    return axios
        .post(
            process.env.REACT_APP_APIPATH + '/api/customer.php',{},
            {
                headers: {'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
            }
        )
        .then(response => {
            return response
        })
        .catch(err => {
            return err.response;
        })
}

export const getCustomer = (id) => {
    return axios
        .post(
            process.env.REACT_APP_APIPATH + '/api/customer.php',{ id },
            {
                headers: {'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
            }
        )
        .then(response => {
            return response
        })
        .catch(err => {
            return err.response;
        })
}

export const addEditBuilding = building => {
    return axios
        .post(
            process.env.REACT_APP_APIPATH + '/api/addEditBuilding.php',
            building,
            {
                headers: {'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
            }
        )
        .then(response => {
            return response
        })
        .catch(err => {
            return err.response;
        })
}
export const addBuildingDocuments = building => {
    return axios
        .post(
            process.env.REACT_APP_APIPATH + '/api/buildingDocuments.php',
            building,
            {
                headers: {'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
            }
        )
        .then(response => {
            return response
        })
        .catch(err => {
            return err.response;
        })
}
export const deleteBuildingDocuments = building => {
    return axios
        .post(
            process.env.REACT_APP_APIPATH + '/api/buildingDocuments.php',
            {building, delete:true},
            {
                headers: {'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
            }
        )
        .then(response => {
            return response
        })
        .catch(err => {
            return err.response;
        })
}
export const deleteBuilding = id => {
    return axios
        .post(
            process.env.REACT_APP_APIPATH + '/api/addEditBuilding.php',
            {id, delete:true},
            {
                headers: {'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
            }
        )
        .then(response => {
            return response
        })
        .catch(err => {
            return err.response;
        })
}

export const getBuildings = () => {
    return axios
        .post(
            process.env.REACT_APP_APIPATH + '/api/building.php',{},
            {
                headers: {'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
            }
        )
        .then(response => {
            return response
        })
        .catch(err => {
            return err.response;
        })
}

export const getBuilding = (id) => {
    return axios
        .post(
            process.env.REACT_APP_APIPATH + '/api/building.php',{ id },
            {
                headers: {'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
            }
        )
        .then(response => {
            return response
        })
        .catch(err => {
            return err.response;
        })
}

export const addEditRoom = room => {
    return axios
        .post(
            process.env.REACT_APP_APIPATH + '/api/addEditRoom.php',
            room,
            {
                headers: {'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
            }
        )
        .then(response => {
            return response
        })
        .catch(err => {
            return err.response;
        })
}

export const deleteRoom = id => {
    return axios
        .post(
            process.env.REACT_APP_APIPATH + '/api/addEditRoom.php',
            {id, delete:true},
            {
                headers: {'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
            }
        )
        .then(response => {
            return response
        })
        .catch(err => {
            return err.response;
        })
}

export const getRooms = () => {
    return axios
        .post(
            process.env.REACT_APP_APIPATH + '/api/room.php',{},
            {
                headers: {'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
            }
        )
        .then(response => {
            return response
        })
        .catch(err => {
            return err.response;
        })
}

export const getRoom = (id) => {
    return axios
        .post(
            process.env.REACT_APP_APIPATH + '/api/room.php',{ id },
            {
                headers: {'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
            }
        )
        .then(response => {
            return response
        })
        .catch(err => {
            return err.response;
        })
}

export const getBuildingRooms = (building_id) => {
    return axios
        .post(
            process.env.REACT_APP_APIPATH + '/api/room.php',{ building_id },
            {
                headers: {'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
            }
        )
        .then(response => {
            return response
        })
        .catch(err => {
            return err.response;
        })
}

export const addEditTransaction = transaction => {
    return axios
        .post(
            process.env.REACT_APP_APIPATH + '/api/addEditTransaction.php',
            transaction,
            {
                headers: {'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
            }
        )
        .then(response => {
            return response
        })
        .catch(err => {
            return err.response;
        })
}

export const getTransactions = (filters) => {
    return axios
        .post(
            process.env.REACT_APP_APIPATH + '/api/transaction.php',filters,
            {
                headers: {'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
            }
        )
        .then(response => {
            return response
        })
        .catch(err => {
            return err.response;
        })
}
export const getTransaction = (id) => {
    return axios
        .post(
            process.env.REACT_APP_APIPATH + '/api/transaction.php',{ id },
            {
                headers: {'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
            }
        )
        .then(response => {
            return response
        })
        .catch(err => {
            return err.response;
        })
}
export const deleteTransaction = transaction => {
    return axios
        .post(
            process.env.REACT_APP_APIPATH + '/api/addEditTransaction.php',
            {transaction, delete:true},
            {
                headers: {'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
            }
        )
        .then(response => {
            return response
        })
        .catch(err => {
            return err.response;
        })
}
export const collectedTransaction = id => {
    return axios
        .post(
            process.env.REACT_APP_APIPATH + '/api/addEditTransaction.php',
            {id, transactionCollected:true},
            {
                headers: {'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
            }
        )
        .then(response => {
            return response
        })
        .catch(err => {
            return err.response;
        })
}

export const getTransactionsReport = (filters) => {
    return axios
        .post(
            process.env.REACT_APP_APIPATH + '/api/transactionReport.php',filters,
            {
                headers: {'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
            }
        )
        .then(response => {
            return response
        })
        .catch(err => {
            return err.response;
        })
}

export const addEditRent = rent => {
    return axios
        .post(
            process.env.REACT_APP_APIPATH + '/api/addEditRent.php',
            rent,
            {
                headers: {'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
            }
        )
        .then(response => {
            return response
        })
        .catch(err => {
            return err.response;
        })
}

export const deleteRent = id => {
    return axios
        .post(
            process.env.REACT_APP_APIPATH + '/api/addEditRent.php',
            {id, delete:true},
            {
                headers: {'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
            }
        )
        .then(response => {
            return response
        })
        .catch(err => {
            return err.response;
        })
}

export const cancelRentContract = rent => {
    return axios
        .post(
            process.env.REACT_APP_APIPATH + '/api/addEditRent.php',
            {rent, delete:true},
            {
                headers: {'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
            }
        )
        .then(response => {
            return response
        })
        .catch(err => {
            return err.response;
        })
}
export const getRents = () => {
    return axios
        .post(
            process.env.REACT_APP_APIPATH + '/api/rent.php',{},
            {
                headers: {'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
            }
        )
        .then(response => {
            return response
        })
        .catch(err => {
            return err.response;
        })
}

export const getCurrentRents = (filters) => {
    return axios
        .post(
            process.env.REACT_APP_APIPATH + '/api/rent.php',filters,
            {
                headers: {'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
            }
        )
        .then(response => {
            return response
        })
        .catch(err => {
            return err.response;
        })
}

export const getRentsByCustomerId = (customerId) => {
    return axios
        .post(
            process.env.REACT_APP_APIPATH + '/api/rent.php',{customerId},
            {
                headers: {'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
            }
        )
        .then(response => {
            return response
        })
        .catch(err => {
            return err.response;
        })
}

export const getRent = (id) => {
    return axios
        .post(
            process.env.REACT_APP_APIPATH + '/api/rent.php',{ id },
            {
                headers: {'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
            }
        )
        .then(response => {
            return response
        })
        .catch(err => {
            return err.response;
        })
}

export const getAccount = (id) => {
    return axios
        .post(
            process.env.REACT_APP_APIPATH + '/api/account.php',{ id },
            {
                headers: {'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
            }
        )
        .then(response => {
            return response
        })
        .catch(err => {
            return err.response;
        })
}
export const getAccounts = () => {
    return axios
        .post(
            process.env.REACT_APP_APIPATH + '/api/account.php',{},
            {
                headers: {'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
            }
        )
        .then(response => {
            return response
        })
        .catch(err => {
            return err.response;
        })
}

export const addEditAccount = account => {
    return axios
        .post(
            process.env.REACT_APP_APIPATH + '/api/addEditAccount.php',
            account,
            {
                headers: {'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
            }
        )
        .then(response => {
            return response
        })
        .catch(err => {
            return err.response;
        })
}
export const deleteAccount = id => {
    return axios
        .post(
            process.env.REACT_APP_APIPATH + '/api/addEditAccount.php',
            {id, delete:true},
            {
                headers: {'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
            }
        )
        .then(response => {
            return response
        })
        .catch(err => {
            return err.response;
        })
}
export const getAccountTransactions = (filters) => {
    return axios
        .post(
            process.env.REACT_APP_APIPATH + '/api/accountTransaction.php',filters,
            {
                headers: {'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
            }
        )
        .then(response => {
            return response
        })
        .catch(err => {
            return err.response;
        })
}

export const addEditAccountTransaction = transaction => {
    return axios
        .post(
            process.env.REACT_APP_APIPATH + '/api/addEditAccountTransaction.php',
            transaction,
            {
                headers: {'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
            }
        )
        .then(response => {
            return response
        })
        .catch(err => {
            return err.response;
        })
}
export const deleteAccountTransaction = id => {
    return axios
        .post(
            process.env.REACT_APP_APIPATH + '/api/addEditAccountTransaction.php',
            {id, delete:true},
            {
                headers: {'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
            }
        )
        .then(response => {
            return response
        })
        .catch(err => {
            return err.response;
        })
}

export const get_response_or_errors = res => {
    if (res.status == 203) {
        remove_session();
    }else if (res && res.status == 422) {
        return res.data.errors;
    } else if ((res && res.status == 500) || (res && res.status == 200)|| (res && res.status == 400)) {
        return {
            responseMessage: res.data.responseMessage,
            responseFlag: res.data.responseFlag,
        }
    } else{
        return {
            responseMessage: 'Sever Error. Please Contact Administration.',
            responseFlag: 'danger',
        }
    }
}

export const add_session = (token) => {
    localStorage.setItem('usertoken', token)
}

export const set_user = (user) => {
    localStorage.setItem('user', JSON.stringify(user))
}

export const set_privileges = (privileges) => {
    localStorage.setItem('privileges', JSON.stringify(privileges))
}

export const remove_session = () => {
    localStorage.removeItem('usertoken')
    localStorage.removeItem('user')
    localStorage.removeItem('privileges')
}

export const check_response_status = res => {
    if (res.status == 203) {
        localStorage.removeItem('usertoken')
        window.location.reload();
    }
}

export const date_time_to_date_format = datetime => {
    const dateObject = new Date(datetime);
    const day = String(dateObject.getDate()).padStart(2, '0');
    const month = String(dateObject.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const year = dateObject.getFullYear();

    return `${day}-${month}-${year}`;
}

export const is_logged_in = () => {
    return localStorage.usertoken;
}

export const current_user = () => {
    return localStorage.user ? JSON.parse(localStorage.user) : "";
}

export const current_user_privilege = () => {
    return localStorage.privileges ? JSON.parse(localStorage.privileges) : "";
}

export const is_valid_slug = (slug) => {
    const regexExp = /^[a-z0-9]+(?:-[a-z0-9]+)*$/g;
    return regexExp.test(slug);
}

