import React, { useEffect, useState, useRef } from "react";
import {Link, useNavigate } from "react-router-dom";
import { getTransactions,getBuildings,collectedTransaction, remove_session, date_time_to_date_format } from "../../../functions/general";
import ModalImage from "react-modal-image";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Dropdown from 'react-bootstrap/Dropdown';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

function ListAllTransactions(props) {
  const currentUserPrivilege = props.currentUserPrivilege;
  const currentUser = props.currentUser;
  const adminId = (currentUserPrivilege && currentUserPrivilege.transactions == 1)? currentUserPrivilege.user_id: null;
  const transactionFor = (currentUserPrivilege && currentUserPrivilege.transactions == 1)? "admin": null;
  const navigate = useNavigate();
  const [transactions, setTransactions] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [buildings, setBuildings] = React.useState([]);
  const [buildingId, setBuildingId] = React.useState("");
  const [transactionType, setTransactionType] = React.useState("");
  const [approved, setApproved] = React.useState("");
  const [inOut, setInOut] = React.useState("");
  const [showFilters, setShowFilters] = useState(false);
  const [date, setDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [loading, setLoading] = useState(true); 
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(-1);
  const [inAmount, setInAmount] = useState(0);
  const [outAmount, setOutAmount] = useState(0);

  const tableRef = useRef();
  let totalTransactions = 0;
  let inTotal = 0;
    let outTotal = 0;
  useEffect(() => {
    
    getBuildings().then(res => {
      if (res && res.status == 200) {
        setBuildings(res.data.data);
      } else if (res && res.status == 203) {
        remove_session();
        props.getLoggedInUser();
        navigate('/login');
      } else {
        // Handle other cases
      }
    });
    getTransactions({transactionFor, adminId}).then((res) => {
      if (res && res.status === 200) {
        setLoading(false); 
        setTransactions(res.data.data);
        setItemsPerPage(res.data.data.length);
      
      } else if (res && res.status === 203) {
        remove_session();
        props.getLoggedInUser();
        navigate("/login");
      }
    });
    
 }, [adminId, transactionFor]);
 
  const collectedHandler = (e) => {
    let input = 'all';
    e.preventDefault()
    if (window.confirm("Are you sure you want to collect all pending rents?") == true) {
        collectedTransaction(input).then(res => {
            if (res && res.status == 200) {
                getTransactions().then(res => {
                    if (res && res.status == 200) {
                        setTransactions(res.data.data);
                    } else if (res && res.status == 203) {
                        remove_session();
                        props.getLoggedInUser();
                        navigate('/login');
                    } else {
                    }
                })
            } else if (res && res.status == 203) {
                remove_session();
                props.getLoggedInUser();
                navigate('/login');
            }
        })
    }
  }

  const onSubmit = (e) => {
    e.preventDefault();
  };
  
  
  const applyFilters = (transactions) => {
    return transactions.filter(transaction => {
      // if (transaction.transactionType != "4") {
        const transactionDate = new Date(transaction.transactionDate);
        const start = startDate ? new Date(startDate) : null;
        const end = endDate ? new Date(endDate) : null;
  
        // Check if transaction date falls within the start and end dates
        const isWithinDateRange = 
          (!start || transactionDate >= start) &&
          (!end || transactionDate <= end);
  
        if (!isWithinDateRange) {
          return false;
        }
  
        if (buildingId && buildingId != transaction.buildingId) {
          return false;
        }
        if(transactionType && transactionType != transaction.transactionType){
          return;
      }
        const tempString =
          transaction.transactionAmount + " " + transaction.buildingName + " " + transaction.roomName;
          
        if (searchQuery && !tempString.match(new RegExp(searchQuery, 'i'))) {
          return false;
        }
  
        return true;
      // }
      // return false;
    });
  };

  // Pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentTransactions = transactions.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const pageNumbers = Array.from(
    { length: Math.ceil(transactions?.length / itemsPerPage) },
    (_, i) => i + 1
  );

  const renderPageNumbers = pageNumbers.map((number) => {
    if (
      number === 1 ||
      number === currentPage ||
      number === currentPage - 1 ||
      number === currentPage + 1 ||
      number === pageNumbers?.length
    ) {
      return (
        <li
          key={number}
          className={`page-item ${currentPage === number ? "active" : ""}`}
        >
          <button className="page-link" onClick={() => paginate(number)}>
            {number}
          </button>
        </li>
      );
    }
    return null;
  });

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleItemsPerPageChange = (e) => {
    const value = Number(e.target.value);
    if (value === -1) {
        // Show all items
        setItemsPerPage(value);
    } else {
        // Set items per page to the selected value
        setItemsPerPage(value);
    }
    setCurrentPage(1); // Reset to first page when changing items per page
};

  const exportToCSV = () => {
    tableRef.current.link.click();
  };

  const exportToExcel = () => {
    const filteredTransactions = applyFilters(currentTransactions);
    const ws = XLSX.utils.json_to_sheet(filteredTransactions);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Transactions List");
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" });
    saveAs(data, "transactions-list.xlsx");
  };

  const exportToPDF = () => {
    const filteredTransactions = applyFilters(currentTransactions);
    const unit = "pt";
    const size = "A4";
    const orientation = "landscape";

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);
    const title = "Transactions List";
    const headers = [
      ["Receipt", "Amount", "Building", "Room", "Description", "Transaction Date"],
    ];

    const data = filteredTransactions.map((transaction) => [
      transaction.transactionReceipt,
      transaction.transactionAmount,
      transaction.buildingName,
      transaction.roomName,
      transaction.transactionDescription,
      transaction.transactionDate,
    ]);

     // Calculate total amount
    const totalAmount = filteredTransactions.reduce(
      (total, transaction) => total + parseFloat(transaction.transactionAmount),
      0
    );

    // Add the total amount row
    const totalRow = [
      "Total",
      totalAmount.toLocaleString(),
      "",
      "",
      "",
      ""
    ];

    // Append the total row to the data
    data.push(totalRow);

    let content = {
      startY: 50,
      head: headers,
      body: data,
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("transactions-list.pdf");
  };


  return (
    <>
   
      <div className="col-lg-12">
        <div className="d-flex flex-wrap align-items-center justify-content-between mb-4">
          <div>
            <h4 className="mb-3">Transactions List</h4>
            
          </div>
          {/* <div>
            {currentUser && currentUser.isSuperAdmin == "1" && (
              <a onClick={e => collectedHandler(e)}
                className="btn btn-primary mx-3 " data-toggle="tooltip"
                data-placement="top"
                title="Collect Rent"
                href="#"><i className="ri-pencil-line mr-0"></i> Approve All</a>
            )}
          </div> */}
        </div>
      </div>
      
      <div className="col-lg-12">
<div className="row  align-items-end px-3">
    <div className="col-lg-2 col-md-3 col-sm-6">
    <div className="form-group">
          <label>Show per page:</label>
          <select
            className="form-control"
            value={itemsPerPage === transactions.length ? -1 : itemsPerPage}
            onChange={handleItemsPerPageChange}
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={30}>30</option>
            <option value={-1}>Show All</option>
          </select>
        </div>
    </div>
    <div className="col-lg-2 col-md-3 col-sm-6">

<div className="form-group">
<label>Export</label>
<Dropdown className="w-100">
    <Dropdown.Toggle variant="light" id="dropdown-basic" className="w-100">
      Select Export Type
    </Dropdown.Toggle>

    <Dropdown.Menu>
      <Dropdown.Item href="#/action-1" onClick={exportToCSV}>Export to CSV</Dropdown.Item>
      <Dropdown.Item href="#/action-2" onClick={exportToExcel}>Export to Excel</Dropdown.Item>
      <Dropdown.Item href="#/action-3" onClick={exportToPDF}>Export to PDF</Dropdown.Item>
    </Dropdown.Menu>
  </Dropdown>
</div>
</div>
    <div className="col-md-3 col-sm-6">
        <div className="form-group">
          <label>Search</label>
          <input
            type="text"
            className="form-control"
            placeholder=""
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
    </div>
    <div className="col-lg-2 col-md-3 col-sm-6">
    <div className="form-group">
                        <label>Start Date</label>
                        <input
                          type="date"
                          className="form-control"
                          placeholder="Enter Date"
                          value={startDate}
                          onChange={(e) => setStartDate(e.target.value)}
                        />
                      </div>
    </div>
    <div className="col-lg-2 col-md-3 col-sm-6">
    <div className="form-group">
                        <label>End Date</label>
                        <input
                          type="date"
                          className="form-control"
                          placeholder="Enter Date"
                          value={endDate}
                          onChange={(e) => setEndDate(e.target.value)}
                        />
                      </div>
    </div>
    <div className="col-lg-2 col-md-4 col-sm-6">
        <div className="form-group">
          <label>Building/Villa</label>
          <select className="form-control" value={buildingId} onChange={(e) => setBuildingId(e.target.value)}>
              <option value="">All Buildings</option>
              { buildings?.length > 0 && buildings.map((building, index) => {
                  if(building.status == '1') {
                      return (
                          <option key={index}
                                  value={building.id}>{building.name} ({building.ownerName})</option>
                      );
                  }
              })}
          </select>
        </div>
      </div>
      <div className="col-lg-2 col-md-4 col-sm-6">
        <div className="form-group">
          <label>Transaction Type</label>
          <select className="form-control" value={transactionType} onChange={(e) => setTransactionType(e.target.value)}>
              <option value="">All Types</option>
              <option value="1">Rent Payment</option>
              <option value="9">Rent Received</option>
              <option value="10">Rent Adjustment</option>
              <option value="11">Rent Unpaid</option>
              <option value="2">Dewa Bill Payment</option>
              <option value="3">Maintenance Payment</option>
              <option value="4">Salary</option>
              <option value="5">Other Expense Payment</option>
              <option value="6">Security Refund</option>
              <option value="7">Security Deposit Refund</option>
              <option value="8">Advance Payment</option>
          </select>
        </div>
      </div>
      <div className="col-lg-2 col-md-4 col-sm-6">
            <div className="form-group">
              <label>Approved</label>
                <select className="form-control" value={approved} onChange={(e) => setApproved(e.target.value)}>
                    <option value="">Select</option>
                    <option value="1">Yes</option>
                    <option value="2">No</option>
                </select>
            </div>
      </div>
      <div className="col-lg-2 col-md-4 col-sm-6">
        <div className="form-group">
          <label>In/Out Amount</label>
            <select className="form-control" value={inOut} onChange={(e) => setInOut(e.target.value)}>
                <option value="">Select</option>
                <option value="1">IN</option>
                <option value="2">OUT</option>
            </select>
        </div>
      </div>
    {/* <div className="text-end col-sm-6">
    <div className="d-flex flex-wrap align-items-center justify-content-end mb-4">
          <button
            onClick={() => setShowFilters(!showFilters)}
            className="btn btn-primary add-list"
          >
            Filters
          </button>
        </div>
    </div> */}
  </div> 
</div>

      {/* {showFilters && (
        <>
          <div className="col-sm-12">
            <div className="card">
              <div className="bg-black-custom card-header d-flex justify-content-between rounded-top">
                <div className="header-title">
                  <h4 className="card-title text-white">Filters</h4>
                </div>
              </div>
              <div className="card-body">
                <form noValidate onSubmit={onSubmit}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Search</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder=""
                          value={searchQuery}
                          onChange={(e) => setSearchQuery(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Start Date</label>
                        <input
                          type="date"
                          className="form-control"
                          placeholder="Enter Date"
                          value={date}
                          onChange={(e) => setDate(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </>
      )} */}
    
      <div className="col-lg-12">
        <div className="table-responsive rounded mb-3">
          <table className="data-table table mb-0 tbl-server-info">
            <thead className="bg-black-custom text-uppercase">
              <tr className="text-white">
                <th>No</th>
                <th>Receipt</th>
                <th>Amount</th>
                <th>Building</th>
                <th>Room</th>
                <th>Description</th>
                <th>Approved</th>
                <th className="text-center">Type</th>
                <th>Transaction Date</th>
                <th></th>
                
              </tr>
            </thead>
            <tbody className="bg-white">
              {currentTransactions.map((transaction, index) => {
                const transAmount = parseInt(transaction.transactionAmount);
                {/* if (transaction.transactionType != "4") { */}
                  const transactionDate = new Date(transaction.transactionDate); // Assuming 'date' is the field for transaction date
                  const start = new Date(startDate);
                  const end = new Date(endDate);

                  // Check if transaction date falls within the start and end dates
                  const isWithinDateRange = 
                      (!startDate || transactionDate >= start) &&
                      (!endDate || transactionDate <= end);
                      
                  if(!isWithinDateRange){
                      return;
                  }    
                  if(transactionType && transactionType != transaction.transactionType){
                      return;
                  }
                  if ((!buildingId || buildingId == transaction.buildingId)) {
                    const tempString =
                    transaction.transactionAmount + " " + transaction.buildingName + " " + transaction.roomName;
                  if (searchQuery && !tempString.match(new RegExp(searchQuery, 'i'))) {
                    return null;
                  }
                  if (
                    searchQuery &&
                    !tempString.match(new RegExp(searchQuery, "i"))
                  ) {
                    return null;
                  }
                  if ((approved && transaction.transactionCollected != '1' && approved == '1') || (approved && transaction.transactionCollected == '1' && approved == '2')) {
                    return null;
                  }
                  if ((inOut && inOut != '1' && (transaction.transactionType == 10 || transaction.transactionType == 11 ||(transaction.transactionType == 9) || transaction.transactionType == 7 || transaction.transactionType == 8))) {
                 
                    return null;
                  }
                  if ((inOut && inOut != '2' && (transaction.transactionType == 10 || transaction.transactionType == 11 || transaction.transactionType == 2 || transaction.transactionType == 3 || transaction.transactionType == 4 || transaction.transactionType == 5 || transaction.transactionType == 6 || transaction.transactionType == 1))) {
                   
                    return null;
                  }
                  
                  if ((transaction.transactionType == 9) || transaction.transactionType == 7 || transaction.transactionType == 8) {
                    parseInt(inTotal += transAmount);
                  } else if((transaction.transactionType == 2) || transaction.transactionType == 3 || transaction.transactionType == 4 || transaction.transactionType == 5 || transaction.transactionType == 6 || transaction.transactionType == 1) {
                    parseInt(outTotal += transAmount);
                  }
                  if(transaction.transactionType != 10 && transaction.transactionType != 11) {
                    let amount = parseInt(transaction.transactionAmount);
                    parseInt(totalTransactions += amount);
                  }
                  return (
                    <tr key={index}>
                      <td>{transaction.id}</td>
                      <td>
                        {transaction.transactionReceipt && (
                          <div className="transactionTableImage">
                            <ModalImage
                              small={
                                process.env.REACT_APP_APIPATH +
                                "/api/images/" +
                                transaction.transactionReceipt
                              }
                              large={
                                process.env.REACT_APP_APIPATH +
                                "/api/images/" +
                                transaction.transactionReceipt
                              }
                            />
                          </div>
                        )}
                      </td>
                      <td>{transaction.transactionAmount.toLocaleString()}</td>
                      <td>
                        {transaction.buildingId > 0
                          ? transaction.buildingName
                          : ""}
                      </td>
                      <td>
                        {transaction.roomId > 0 ? transaction.roomName : ""}
                      </td>
                      <td className="text-ellipses">
                        {transaction.transactionDescription}
                      </td>
                      <td>{transaction.transactionCollected == 1 ? "Yes" : "No"}</td>
                      <td className="text-center">
                        <span className="badge badge-light">{transaction.transactionType == 1 && 'Rent'}</span>
                        <span className="badge badge-info">{transaction.transactionType == 2 && 'Dewa'}</span>
                        <span className="badge badge-dark">{transaction.transactionType == 3 && 'Maintenance'}</span>
                        <span className="badge badge-success">{transaction.transactionType == 4 && 'Salary'}</span>
                        <span className="badge badge-primary">{transaction.transactionType == 5 && 'Other Expense'}</span>
                        <span className="badge badge-warning">{transaction.transactionType == 6 && 'Security Refund'}</span>
                        <span className="badge badge-secondary">{transaction.transactionType == 7 && 'Security Deposit'}</span>
                        <span className="badge badge-danger">{transaction.transactionType == 8 && 'Advance Payment'}</span>
                        <span className="badge badge-light">{transaction.transactionType == 9 && 'Rent Received'}</span>
                        <span className="badge badge-light">{transaction.transactionType == 10 && 'Rent Adjustment'}</span>
                        <span className="badge badge-light">{transaction.transactionType == 11 && 'Rent Unpaid'}</span>
                        
                      </td>
                      <td>
                        {date_time_to_date_format(
                          transaction.transactionDate
                        )}
                      </td>
                      <td>
                        <Link
                            className="badge bg-primary mr-2"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Print"
                            data-original-title="Print"
                            // onClick={() => handlePrintModal(customer)}
                            to={"/transaction/print/" + transaction.id}
                          >
                            <i className="ri-printer-line mr-0"></i>
                          </Link>
                      </td>
                    </tr>
                  );
                }
                {/* } */}
              })}
           
            </tbody>
            <thead className="bg-black-custom text-uppercase">
              <tr className="text-white">
                  <th></th>
                  <th></th>
                  <th>Total Amount: {parseInt(totalTransactions).toLocaleString()}</th>
                  <th colSpan={7}></th>
              </tr>
            </thead>
          </table>

          <CSVLink
            data={applyFilters(transactions)}
            headers={[
              { label: "Receipt", key: "transactionReceipt" },
              { label: "Ammount", key: "transactionAmount" },
              { label: "Building", key: "buildingName" },
              { label: "Room", key: "roomName" },
              { label: "Description", key: "transactionDescription" },
              { label: "Start Date", key: "transactionDate" },
            ]}
            filename={"transaction-list.csv"}
            className="hidden"
            ref={tableRef}
          />
        </div>
      </div>
      

      <div className="col-lg-6 text-left">
        {loading ? (
                <div className="text-muted"><h3>Loading...</h3> </div>
            ) : (
                <div className="col-lg-12">
                </div>
            )}
            <p>In Amount: {parseInt(inTotal).toLocaleString()} <br/> Out Amount: {parseInt(outTotal).toLocaleString()}</p>
        <p>Total Number of Transactions: {applyFilters(currentTransactions)?.length}</p>
      </div>
      <div className="col-lg-6">
        <nav className="pagination justify-content-end">
          <ul className="pagination">
            <li
              className={`page-item ${
                currentPage === 1 ? "disabled" : ""
              }`}
            >
              <button
                className="page-link"
                onClick={() => paginate(currentPage - 1)}
              >
                Previous
              </button>
            </li>
            {renderPageNumbers}
            <li
              className={`page-item ${
                currentPage === Math.ceil(transactions?.length / itemsPerPage)
                  ? "disabled"
                  : ""
              }`}
            >
              <button
                className="page-link"
                onClick={() => paginate(currentPage + 1)}
              >
                Next
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
}

export default ListAllTransactions;
