import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { deleteBuilding, getBuildings, remove_session } from "../../../functions/general";
import ModalImage from "react-modal-image";
import { CSVLink } from "react-csv";
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import "jspdf-autotable";
import Dropdown from 'react-bootstrap/Dropdown';


function ListBuildingsVillas(props) {

  const currentUser = props.currentUser;
  const userType = (currentUser && currentUser.isSuperAdmin == 1)? 'superadmin': 'admin';
 
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 10 }, (_, index) => currentYear - index);

  const months = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  const currentDate = new Date().toISOString().split('T')[0];
  const navigate = useNavigate();
  const [buildings, setBuildings] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [showFilters, setShowFilters] = useState(false);
  const [searchStatus, setSearchStatus] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [year, setSelectedYear] = useState("");
  const [month, setSelectedMonth] = useState("");
  const [selectedMonthName, setSelectedMonthName] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true); 
  const [itemsPerPage, setItemsPerPage] = useState(-1);
  const tableRef = useRef();

  useEffect(() => {
    getBuildings().then((res) => {
      if (res && res.status == 200) {
        setLoading(false); 
        const selectedBuildingOptionsTemp = [];
        if(userType =='admin'){
          if(props.currentUserPrivilege){
            const parsedBuildingIds = JSON.parse(props.currentUserPrivilege.building_ids);
            res.data.data?.length > 0 && res.data.data.forEach((buildingValue) => {
                const value = buildingValue.id;
                if(parsedBuildingIds && parsedBuildingIds.includes(value)){ 
                    selectedBuildingOptionsTemp.push(buildingValue);
                } 
            });
            setBuildings(selectedBuildingOptionsTemp);
            setItemsPerPage(selectedBuildingOptionsTemp.length);
          }
         
        }else{
          setBuildings(res.data.data);
          setItemsPerPage(res.data.data.length);
        }
       
      } else if (res && res.status == 203) {
        remove_session();
        props.getLoggedInUser();
        navigate("/login");
      } else {
        // Handle other cases
      }
    });
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
  };

  const handleMonthChange = (monthIndex) => {
    setSelectedMonth(monthIndex);
    setSelectedMonthName(months[monthIndex-1]);
  };

  const deleteHandler = (e, id) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want to delete building?") == true) {
      deleteBuilding(id).then((res) => {
        if (res && res.status == 200) {
          setBuildings(res.data.data);
        } else if (res && res.status == 405) {
          alert(res.data.responseMessage);
        } else if (res && res.status == 203) {
          remove_session();
          props.getLoggedInUser();
          navigate("/login");
        }
      });
    }
  };

  const applyFilters = (transactions) => {
    return buildings.filter(building => {
      // Check if the building status matches the search status
      if (searchStatus && searchStatus != building.status) {
        return false;
      }
  
      // Check if the building's expiry date matches the expiry date filter
      if((expiryDate && expiryDate == '1'&& currentDate <= building.endDate ) || (expiryDate && expiryDate == '2'&& currentDate >= building.endDate )) {
        return false;
      }
  
      // Check if the building's creation month and year match the filters
      if (month || year) {
        const createdAtDate = new Date(building.created_at);
        const monthDate = createdAtDate.getMonth() + 1;
        const yearDate = createdAtDate.getFullYear();
  
        if (month && year && (month != monthDate || year != yearDate)) {
          return false;
        } else if (month && month != monthDate) {
          return false;
        } else if (year && year != yearDate) {
          return false;
        }
      }
  
      // Check if the search query matches any of the relevant fields
      const tempString = `${building.ownerName} ${building.ownerPhone} ${building.name} ${building.city} ${building.state} ${building.address} ${building.rent}`;
      if (searchQuery && !tempString.match(new RegExp(searchQuery, 'i'))) {
        return false;
      }
  
      return true;
    });
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentBuildings = buildings?.slice(indexOfFirstItem, indexOfLastItem);

  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = Math.max(1, currentPage - 2); i <= Math.min(currentPage + 2, Math.ceil(buildings?.length / itemsPerPage)); i++) {
      pageNumbers.push(
        <li key={i} className={`page-item ${i == currentPage ? 'active' : ''}`}>
          <button className="page-link" onClick={() => setCurrentPage(i)}>
            {i}
          </button>
        </li>
      );
    }
    return pageNumbers;
  };

  const handleItemsPerPageChange = (e) => {
      const value = Number(e.target.value);
      if (value === -1) {
          // Show all items
          setItemsPerPage(buildings.length);
      } else {
          // Set items per page to the selected value
          setItemsPerPage(value);
      }
      setCurrentPage(1); // Reset to first page when changing items per page
  };

  const exportToExcel = () => {
    const filteredBuildings = applyFilters(currentBuildings);
    const ws = XLSX.utils.json_to_sheet(filteredBuildings);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Buildings List");
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" });
    saveAs(data, "buildings-list.xlsx");
  };

  const exportToCSV = () => {
    tableRef.current.link.click();
  };


  const exportToPDF = () => {
    const filteredBuildings = applyFilters(currentBuildings);
    const unit = "pt";
    const size = "A4";
    const orientation = "landscape";

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);
    const title = "Buildings/Villas List";
    const headers = [
      ["Owner", "Name", "City", "State", "Address", "Rent", "Rent Type", "Dewa", "Maintenance", "Status"],
    ];

    const data = filteredBuildings.map((building) => [
      `${building.ownerName} (${building.ownerPhone})`,
      building.name,
      building.city,
      building.state,
      building.address,
      building.rent,
      building.rentType == "1" ? "Monthly" : "Yearly",
      building.dewa == "1" ? "Yes" : "No",
      building.maintenance == "1" ? "Yes" : "No",
      building.status == 1 ? "Active" : "Inactive",
    ]);

    let content = {
      startY: 50,
      head: headers,
      body: data,
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("buildings-villas-list.pdf");
  };

  return (
    <>
      <div className="col-lg-12">
        <div className="d-flex flex-wrap align-items-center justify-content-between mb-4">
          <div>
            <h4 className="mb-3">Buildings/Villas/Camps</h4>
          </div>
          <div>
          {currentUser && currentUser.isSuperAdmin == "1" && (
          <Link to="/buildings-villas/add" className="btn btn-primary add-list">
            <i className="las la-plus mr-3"></i>Add Building/Villa/Camp
          </Link>
          )}
          </div>
         
        </div>
      </div>
      <div className="col-lg-12">
        <div className="row  align-items-end">
            <div className="col-lg-2 col-md-3 col-sm-6">
            <div className="form-group">
                <label>Show per page:</label>
                <select
                  className="form-control"
                  value={itemsPerPage === buildings.length ? -1 : itemsPerPage}
                  onChange={handleItemsPerPageChange}
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={30}>30</option>
                  <option value={-1}>Show All</option>
                </select>
              </div>
            </div>
            <div className="col-lg-2 col-md-3 col-sm-6">

              <div className="form-group">
              <label>Export</label>
              <Dropdown className="w-100">
                  <Dropdown.Toggle variant="light" id="dropdown-basic" className="w-100">
                    Select Export Type
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1" onClick={exportToCSV}>Export to CSV</Dropdown.Item>
                    <Dropdown.Item href="#/action-2" onClick={exportToExcel}>Export to Excel</Dropdown.Item>
                    <Dropdown.Item href="#/action-3" onClick={exportToPDF}>Export to PDF</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              </div>
            <div className="col-lg-2 col-md-3 col-sm-6">
            <div className="form-group">
                        <label>Search</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder=""
                          value={searchQuery}
                          onChange={(e) => setSearchQuery(e.target.value)}
                        />
                      </div>
            </div>
            <div className="col-lg-2 col-md-3 col-sm-6">
              <div className="form-group">
                        <label>Status</label>
                        <select
                          className="form-control"
                          value={searchStatus}
                          onChange={(e) => setSearchStatus(e.target.value)}
                        >
                          <option value="">All</option>
                          <option value="1">Active</option>
                          <option value="2">Inactive</option>
                        </select>
              </div>
            </div>
            <div className="col-lg-2 col-md-3 col-sm-6">
              <div className="form-group">
                        <label>Expiry Date</label>
                        {/* <input  className="form-control"   value={expiryDate} type="date"   onChange={(e) => setExpiryDate(e.target.value)} /> */}
                        <select
                          className="form-control"
                          value={expiryDate}
                          onChange={(e) => setExpiryDate(e.target.value)}
                        >
                          <option value="">All</option>
                          <option value="1">Yes</option>
                          <option value="2">No</option>
                        </select>
              </div>
            </div>
            <div className="col-lg-3 mb-3">
                  <select
                    id="yearDropdown"
                    className="form-select form-control"
                    value={year}
                    onChange={(e) => setSelectedYear(e.target.value)}
                  >
                  <option value="">Select Year</option>
                    {years.map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="col-lg-3 mb-3">
                  <select
                    id="monthDropdown"
                    className="form-select form-control"
                    value={selectedMonthName}
                    onChange={(e) => handleMonthChange(e.target.selectedIndex)}
                  >
                   <option value="">Select Month</option>
                    {months.map((month,index) => (
                      <option key={index} value={month}>
                        {month}
                      </option>
                    ))}
                  </select>
                </div>
          </div> 
      </div>

      <div className="col-lg-12">
        <div className="table-responsive rounded mb-3">
          <table className="data-table table mb-0 tbl-server-info">
            <thead className="bg-black-custom text-uppercase">
              <tr className="text-white">
                <th>Owner</th>
                <th>Name</th>
                <th>City</th>
                <th>State</th>
                <th>Address</th>
                <th>Rent</th>
                <th>Rent&nbsp;Type</th>
                <th>Dewa</th>
                <th>Maintenance</th>
                <th>Status</th>
                <th>Expiry</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {currentBuildings.map((building, index) => {
                if (searchStatus && searchStatus != building.status) {
                  return null;
                }
                if((expiryDate && expiryDate == '1'&& currentDate <= building.endDate ) || (expiryDate && expiryDate == '2'&& currentDate >= building.endDate )) {
                  return null;
                }
                if(month || year){
                  // Parse the string into a Date object
                  const createdAtDate = new Date(building.created_at);

                  // Extract the month separately
                  const monthDate = createdAtDate.getMonth() + 1;
                  const yearDate = createdAtDate.getFullYear();
                  if(month && year && (month != monthDate) && (year != yearDate)){
                    return null;
                  }
                  else if(month && month != monthDate){
                    return null;
                  }
                  else if(year && year != yearDate){
                    return null;
                  }
                  
                }
               
               
                const tempString =
                  building.ownerName +
                  " " +
                  building.ownerPhone +
                  " " +
                  building.name +
                  " " +
                  building.city +
                  " " +
                  building.state +
                  " " +
                  building.address +
                  " " +
                  building.rent;
                if (searchQuery && !tempString.match(new RegExp(searchQuery, "i"))) {
                  return null;
                }
                return (
                  <tr key={index}>
                    <td>
                    {userType =='superadmin' ?  
                    (<p>{building.ownerName}({building.ownerPhone})</p>):
                    ('--')
                    }
                    </td>
                    <td>
                      <Link to={`/buildings-villas/${building.id}`}>{building.name}</Link>
                    </td>
                    <td>{building.city}</td>
                    <td>{building.state}</td>
                    <td>{building.address}</td>
                    <td>{building.rent.toLocaleString()}</td>
                    <td>
                      {building.rentType == "1" ? "Monthly" : "Yearly"}
                      {building.contractedImage && (
                        <div className="transactionTableImage">
                          <ModalImage
                            small={process.env.REACT_APP_APIPATH + "/api/images/" + building.contractedImage}
                            large={process.env.REACT_APP_APIPATH + "/api/images/" + building.contractedImage}
                          />
                        </div>
                      )}
                    </td>
                    <td>
                      {building.dewa == "1" ? 
                      
                      (<div className="text-success font-weight-bold">Yes</div>) :
                            (<div className="text-danger font-weight-bold">No&nbsp;</div>)}
                  
                    </td>
                    <td  className="text-center">
                      {building.maintenance == "1" ? 
                      (<div className="text-success font-weight-bold">Yes</div>) :
                      (<div className="text-danger font-weight-bold">No&nbsp;</div>)}
                    </td>
                    <td>
                      {building.status == 1 ? 
                      (<div className="text-success font-weight-bold">Active</div>) :
                      (<div className="text-danger font-weight-bold">Inactive</div>)}
                    </td>
                    <td>
                      {building.endDate}
                    </td>
                    <td>
                    {currentUser && currentUser.isSuperAdmin == "1" && (
                      <div className="d-flex align-items-center list-action">
                          <Link 
                          className="badge bg-primary mr-2 " data-toggle="tooltip"
                          data-placement="top"
                          title="Transaction" data-original-title="Transaction"
                          to={`/transactions/add?type=buildingrooms&id=${building.id}`} >
                        <i className="ri-money-dollar-circle-line mr-0"></i>
                        </Link>
                        <Link
                          className="badge bg-primary mr-2"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Edit"
                          data-original-title="Edit"
                          to={"/buildings-villas/edit/" + building.id}
                        >
                          <i className="ri-pencil-line mr-0"></i>
                        </Link>
                        <Link
                          className="badge bg-primary mr-2"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Print"
                          data-original-title="Print"
                          // onClick={() => handlePrintModal(customer)}
                          to={`/building/print/${building.id}`}
                          state={{ currentUser: currentUser }}
                        >
                          <i className="ri-printer-line mr-0"></i>
                        </Link>
                      
                          <a
                            onClick={(e) => deleteHandler(e, building.id)}
                            className="badge bg-primary mr-2"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Delete"
                            data-original-title="Delete"
                            href="#"
                          >
                            <i className="ri-delete-bin-line mr-0"></i>
                          </a>
                      </div>
                    )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          <CSVLink
            data={applyFilters(buildings)}
            headers={[
              { label: "Owner Name", key: "ownerName" },
              { label: "Name", key: "name" },
              { label: "City", key: "city" },
              { label: "State", key: "state" },
              { label: "Address", key: "address" },
              { label: "Rent", key: "rent" },
              { label: "Rent Type", key: "rentType" },
              { label: "Dewa", key: "dewa" },
              { label: "Maintenance", key: "maintenance" },
              { label: "Status", key: "status" },
            ]}
            filename={"rent-list.csv"}
            className="hidden"
            ref={tableRef}
          />
        </div>

       
        
        
        <div className="col-lg-12">
              <div className="row">
              <div className="col-sm-6  text-xl-left text-sm-left text-center">
              {loading ? (
                <div className="text-muted"><p>Loading...</p> </div>
            ) : (
                <div className="col-lg-12">
                <p>Total Number of Buildings: {applyFilters(buildings)?.length}</p>
                </div>
            )}
       
      </div>
      <div className="col-sm-6">
        <nav className="pagination justify-content-xl-end justify-content-sm-end justify-content-center">
          <ul className="pagination">
            <li className={`page-item ${currentPage == 1 ? "disabled" : ""}`}>
              <button className="page-link" onClick={() => setCurrentPage(currentPage - 1)}>
                Previous
              </button>
            </li>
            {renderPageNumbers()}
            <li className={`page-item ${currentPage == Math.ceil(buildings?.length / itemsPerPage) ? "disabled" : ""}`}>
              <button className="page-link" onClick={() => setCurrentPage(currentPage + 1)}>
                Next
              </button>
            </li>
          </ul>
         
        </nav>
      </div>
      </div>
      </div>
      </div>
    </>
  );
}

export default ListBuildingsVillas;
