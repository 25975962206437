
import React, { useState, useEffect } from "react";
import { getTransactionsReport, remove_session} from "../../../functions/general";
import { Link, useNavigate } from "react-router-dom";
import ModalImage from "react-modal-image";

function RevenueChartDetails(props) {
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 10 }, (_, index) => currentYear - index);

  const months = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  const currentMonthIndex = new Date().getMonth();
  const currentMonth = months[currentMonthIndex];
  
  const navigate = useNavigate();
  const [revenue, setRevenue] = useState([]);
  const [expense, setExpense] = useState([]);
  const [deposit, setDeposit] = useState([]);
  const [queryDate, setQueryDate] = useState("");
  const [year, setSelectedYear] = useState(currentYear);
  const [month, setSelectedMonth] = useState(currentMonthIndex+1);
  const [selectedMonthName, setSelectedMonthName] = useState(currentMonth);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(-1);
  const reportType= 'revenue';

  useEffect(() => {
    setQueryDate(`${selectedMonthName}, ${year}`);
      setStartDate("");
      setEndDate("");
    getTransactionsReport({reportType,year,month}).then((res) => {
      if (res && res.status === 200) {
        setRevenue(res.data.revenue);
        setExpense(res.data.expense);
        setDeposit(res.data.deposit);
      } else if (res && res.status === 203) {
        remove_session();
        props.getLoggedInUser();
        navigate("/login");
      }
    });
  }, [year, month]);

  const handleMonthChange = (monthIndex) => {
    setSelectedMonth(monthIndex+1);
    setSelectedMonthName(months[monthIndex]);
  };

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const handlePrint = () => {
      window.print();
  };
  const handleReportButtonClick = () => {
    setQueryDate(`${startDate} to ${endDate}`);
    getTransactionsReport({reportType,startDate,endDate}).then((res) => {
      if (res && res.status === 200) {
        setRevenue(res.data.revenue);
        setExpense(res.data.expense);
        setDeposit(res.data.deposit);
      } else if (res && res.status === 203) {
        remove_session();
        props.getLoggedInUser();
        navigate("/login");
      }
    });
  };

  return (
    <>
            <div className="w-100 mt-1 px-3 graph">
                <div className="d-flex justify-content-between">
                  <h4>Revenue Report -
                      {queryDate &&(
                        <span>
                          &nbsp;{queryDate}
                        </span>
                      )}
                    </h4>
                    <button className="btn btn-primary  print-button" onClick={handlePrint}>
                        <i className="ri-printer-line mr-0"></i>
                    </button>
                </div>
                
                <div className="row align-items-end mt-4">
              <div className="col-lg-6">
              <div className="row mb-3 align-items-end">
                <div className="col-lg-3 mb-3">
                  <select
                    id="yearDropdown"
                    className="form-select form-control"
                    value={year}
                    onChange={(e) => setSelectedYear(e.target.value)}
                  >
                    {years.map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="col-lg-3 mb-3">
                  <select
                    id="monthDropdown"
                    className="form-select form-control"
                    value={selectedMonthName}
                    onChange={(e) => handleMonthChange(e.target.selectedIndex)}
                  >
                    {months.map((month,index) => (
                      <option key={index} value={month}>
                        {month}
                      </option>
                    ))}
                  </select>
                </div>

                </div>
              </div>
              <div className="col-lg-6">
              <div className="row mb-3 align-items-end justify-content-end">
          
                <div className="col-lg-3 mb-3 ">
                  <label htmlFor="startDate">Start Date</label>
                  <input
                    type="date"
                    id="startDate"
                    className="form-control"
                    value={startDate}
                    onChange={handleStartDateChange}
                  />
                </div>

                <div className="col-lg-3 mb-3">
                  <label htmlFor="endDate">End Date</label>
                  <input
                    type="date"
                    id="endDate"
                    className="form-control"
                    value={endDate}
                    onChange={handleEndDateChange}
                  />
                </div>

                <div className="col-lg-2 mb-3">
                  <button className="btn btn-primary w-100 py-2" onClick={handleReportButtonClick}>
                    Report
                  </button>
                </div>

              </div>
              </div>
            </div>
      </div>


        <div className="col-lg-12 my-5">
          <span className="h6 text-dark">Date:&nbsp; </span>{startDate}<span>&nbsp;&nbsp; </span><span>{endDate}</span>
        </div>

        <div className="col-sm-5">
              <div className="card">
                  <div className="bg-black-custom card-header d-flex justify-content-between rounded-top">
                      <div className="header-title">
                          <h4 className="card-title text-white">Revenue Report</h4>
                      </div>
                  </div>
                  <div className="card-body">
                      <div className="row">
                          <div className="col-md-12 ">
                              <div className="form-group ">
                                <div className="d-flex justify-content-between pe-5"><label>Deposit: </label><span>{deposit.toLocaleString()}</span>  </div>
                                <div className="d-flex justify-content-between pe-5"><label>Expense: </label><span>{expense.toLocaleString()}</span> </div>
                                <div className="d-flex justify-content-between pe-5"><label>Revenue: </label><span>{revenue.toLocaleString()}</span> </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>



    
    
    </>
  )
}

export default RevenueChartDetails
